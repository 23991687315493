<template>
  <div id='basemapContainer' class='basemap'/>
</template>

<script>

import mapboxgl from 'mapbox-gl'

export default {
  name: 'BaseMap',
  data () {
    return {
      mapObject: null,
      accessToken: process.env.VUE_APP_MAP_TOKEN
    }
  },
  methods: {
    createMap () {
      return new Promise(resolve => {
        // initialize the map
        this.mapObject = new mapboxgl.Map({
          accessToken: this.accessToken,
          container: 'basemapContainer',
          style: 'mapbox://styles/connected-intelligence/ckhpx27eb02zt19qrm3qphq46',
          center: [24, 33],
          zoom: 1.62,
          maxZoom: 18,
          attributionControl: false,
          antialias: true,
          transformRequest: (url, resourceType) => {
            let endpointOne = this.$store.getters.getAppConfigFlag('useStagingStack') ? process.env.VUE_APP_CF_DIST_ONE_ENDPOINT_B : process.env.VUE_APP_CF_DIST_ONE_ENDPOINT
            let endpointTwo = this.$store.getters.getAppConfigFlag('useStagingStack') ? process.env.VUE_APP_CF_DIST_TWO_ENDPOINT_B : process.env.VUE_APP_CF_DIST_TWO_ENDPOINT
            if (resourceType === 'Tile' && (url.indexOf(endpointOne) === 0 || url.indexOf(endpointTwo) === 0)) {
              return {
                url: url,
                headers: { 'authorizationToken': this.$store.getters.getBearerToken }
              }
            }
          }
        })
        this.mapObject.addControl(new mapboxgl.NavigationControl({
          visualizePitch: true
        }), 'bottom-right')
        this.mapObject.on('style.load', _ => {
          resolve()
        })
      })
    }
  },
  mounted () {
    this.createMap().then(() => {
      this.$emit('handle-on-map-loaded', this.mapObject)
    })
  }
}
</script>

<style>
.basemap {
  top: 0;
  bottom: 0;
  width: 100%;
  height: calc(100vh - 77.8px);
}
</style>
