<template>
  <div>
    <b-table id="location-table"
             class="tableContainer"
             striped
             hover
             sticky-header
             :items="items"
             :fields="fields"
             select-mode="range"
             responsive="sm"
             ref="selectableTable"
             selectable
             @row-selected="onRowSelected"
             @sort-changed="onSortChanged"
    >
      <template #cell(selected)="{ rowSelected }">
        <template v-if="rowSelected">
          <span aria-hidden="true">&check;</span>
          <span class="sr-only">Selected</span>
        </template>
        <template v-else>
          <span aria-hidden="true">&nbsp;</span>
          <span class="sr-only">Not selected</span>
        </template>
      </template>
    </b-table>
  </div>
</template>

<script>
// import * as d3 from 'd3'
// import { orderBy } from 'lodash'
import { sortJsonOnKey } from '@/helpers/worker-api'

export default {
  name: 'LocationTable',
  props: {
    fields: {
      type: Array[Object],
      required: true
    }
  },
  data () {
    return {
      modes: ['multi', 'single', 'range'],
      selectMode: 'multi',
      selectedHexes: new Set(),
      currentPage: 0,
      perPage: 5,
      isBusy: false,
      items: [],
      currentItems: [],
      selectedItems: []
    }
  },
  computed: {
    selectedHexagons () {
      return this.$store.getters['location/getSelectedHexagons']
    },
    totalItems () {
      return this.database.length
    },
    database () {
      return this.$store.getters['location/getLocationAggregates']
    }
    //,
    // mapObject: {
    //   get () {
    //     return this.$store.getters.getBaseMap
    //   },
    //   set (newMap) {
    //     this.$store.commit('setBaseMap', newMap)
    //   }
    // },
    // locationColourScale () {
    //   let breakClass = 0
    //   let arrayRange = []
    //   let newArrayRange = []
    //   let tempArray = []
    //   const locationData = this.$store.getters['location/getLocationAggregates']
    //   const noStops = orderBy(locationData, 'no_stops', 'asc').map(p => p['no_stops'])
    //   if (noStops.length > 6) {
    //     breakClass = Math.floor(noStops.length / 6)
    //     arrayRange = this.chunk(noStops, breakClass)
    //     arrayRange.forEach(i => {
    //       newArrayRange.push(d3.max(i))
    //     })
    //     for (var x = 0; x < newArrayRange.length; x++) {
    //       if (x < 5) {
    //         tempArray.push(newArrayRange[x])
    //       } else {
    //         if (x === newArrayRange.length - 1) {
    //           tempArray.push(newArrayRange[x])
    //         }
    //       }
    //     }
    //     return d3.scaleThreshold()
    //       .domain(tempArray)
    //       .range([d3.rgb(255, 249, 214),
    //         d3.rgb(253, 241, 157),
    //         d3.rgb(251, 234, 110),
    //         d3.rgb(231, 211, 60),
    //         d3.rgb(211, 192, 53),
    //         d3.rgb(190, 172, 45)])
    //   }
    //   return d3.scaleOrdinal().domain(noStops)
    //     .range([d3.rgb(255, 249, 214),
    //       d3.rgb(253, 241, 157),
    //       d3.rgb(251, 234, 110),
    //       d3.rgb(231, 211, 60),
    //       d3.rgb(211, 192, 53),
    //       d3.rgb(190, 172, 45)])
    // }
  },
  mounted () {
    /* Missing error handling if call fails */
    const tableScrollBody = this.$refs.selectableTable.$el
    /* Consider debouncing the event call */
    tableScrollBody.addEventListener('scroll', this.onScroll)
    tableScrollBody.addEventListener('wheel', this.onScroll)
    this.fetchItems()
  },
  methods: {
    async onSortChanged (ctx) {
      this.items = []
      const dataToSort = this.$store.getters['location/getLocationAggregates']
      const sortedData = sortJsonOnKey(dataToSort, ctx.sortBy, ctx.sortDesc)
      Promise.all([sortedData]).then(response => {
        this.$store.dispatch('location/setLocationLayerAsync', response[0]).then(() => {
          console.log('layer replaced')
          this.fetchItems()
        })
      })
    },
    async fetchItems () {
      /* No need to call if all items retrieved */
      if (this.items.length === this.totalItems) return

      /* Enable busy state */
      this.isBusy = true

      /* Missing error handling if call fails */
      const startIndex = this.currentPage++ * this.perPage
      const endIndex = startIndex + this.perPage
      this.callDatabase(startIndex, endIndex).then(newItems => {
        /* Add new items to existing ones */
        this.items = this.items.concat(newItems)

        /* Disable busy state */
        this.isBusy = false
      })
    },
    callDatabase (startIndex, endIndex) {
      return new Promise((resolve) => {
        const delay = Math.floor(Math.random() * 125) + 250
        setTimeout(() => {
          resolve(this.database.slice(startIndex, endIndex))
        }, delay)
      })
    },
    onScroll (event) {
      if (
        event.target.scrollTop + event.target.clientHeight >=
          event.target.scrollHeight
      ) {
        if (!this.isBusy) {
          this.fetchItems()
        }
      }
    },
    // selectHexagonsOnMap (clickedHexagonArray) {
    //   const copySelectedHexes = [...clickedHexagonArray]
    //   let refreshedLayers = []
    //   let currLayers = this.mapObject.__deck.layerManager.getLayers()
    //   let layer = currLayers.filter(l => l.id === 'location')[0]
    //   const toRBGArray = (rgbObj) => { return [rgbObj.r, rgbObj.g, rgbObj.b] }
    //   let newLayer = layer.clone({
    //     getFillColor: (hexagon) => {
    //       const col = this.selectedHexes.has(hexagon.hexagons)
    //         ? [255, 0, 128]
    //         : toRBGArray(d3.rgb(this.locationColourScale(hexagon.no_stops)))
    //       return col
    //     },
    //     updateTriggers: {
    //       getFillColor: copySelectedHexes
    //     }
    //   })
    //   refreshedLayers.push(newLayer)
    //   this.mapObject.__deck.setProps({ layers: refreshedLayers })
    // },
    onRowSelected (items) {
      // check if items hexagon is selected in map
      // if already selected do nothing
      // else update map
      this.selectedItems = items
    },
    selectAllRows () {
      this.$refs.selectableTable.selectAllRows()
    },
    clearSelected () {
      this.$refs.selectableTable.clearSelected()
      this.selectedItems = []
    },
    chunk (arr, len) {
      let chunks = []
      let i = 0
      let n = arr.length
      while (i < n) {
        chunks.push(arr.slice(i, i += len))
      }
      return chunks
    }
  },
  watch: {
    selectedItems: function () {
      const notSelectedOnMapLayer = this.selectedItems.map(h => h.hexagons).filter(element => !this.selectedHexagons.has(element))
      // const deselectFromMapLayer = [...this.selectedHexagons].filter(element => !new Set(this.selectedItems.map(h => h.hexagons)).has(element))
      if (notSelectedOnMapLayer.length > 0) {
        const selectedHexagonsInState = this.$store.getters['location/getSelectedHexagons']
        let selectedHexes = new Set([...selectedHexagonsInState, ...notSelectedOnMapLayer])
        this.selectedHexes = new Set(selectedHexes)
      } else {
        this.selectedHexes = new Set(this.selectedItems.map(h => h.hexagons))
      }
      // this.$store.commit('location/setSelectedHexagons', this.selectedHexes)
      // this.selectHexagonsOnMap(this.selectedHexes)
      this.$emit('selected-locations-changed', this.selectedHexes)
    },
    database: function () {
      this.items = this.$store.getters['location/getLocationAggregates']
    },
    selectedHexagons: function () {
      const selectInTable = [...this.selectedHexagons].filter(element => !new Set(this.selectedItems.map(h => h.hexagons)).has(element))
      const deselectInTable = this.selectedItems.map(h => h.hexagons).filter(element => !this.selectedHexagons.has(element))
      if (selectInTable.length > 0) {
        this.$refs.selectableTable.clearSelected()
        const hexIds = [...this.$store.getters['location/getSelectedHexagons']]
        if (hexIds.length > 0) {
          hexIds.forEach(hexId => {
            for (let index = 0; index < this.items.length; index++) {
              if (this.items[index].hexagons === hexId) {
                this.$refs.selectableTable.selectRow(index)
              }
            }
          })
        }
      }
      if (deselectInTable.length > 0) {
        console.log('DESELECT in table', deselectInTable)
        const hexIds = deselectInTable
        if (hexIds.length > 0) {
          hexIds.forEach(hexId => {
            for (let index = 0; index < this.items.length; index++) {
              if (this.items[index].hexagons === hexId) {
                this.$refs.selectableTable.unselectRow(index)
              }
            }
          })
        }
      }
    }
  }
}
</script>

<style scoped>
.tableContainer {
  overflow: auto;
  height: 70vh;
}
</style>
