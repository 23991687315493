<template>
  <figure class="svg-container" :style="{'height':svgHeight}">
    <svg class="svg-content"
      :viewBox="viewBox"
      ref="svgContainer"
      preserveAspectRatio="xMidYMid meet"
    >
     <g :transform="`translate(${margin.left * 2}, ${margin.right})`">
      <g>
        <text class="chartTitle"
              :x="0" :dx="xaxis_dx"
              :y="0" :dy="-5">
          {{ graphTitle }}
        </text>
        <text class="yAxisLabel" :x="-margin.top" :dx="yaxis_dx"
            :y="0" :dy="yaxis_dy">
          {{ yAxisLabel }}
        </text>
        <text class="xAxisLabel" :x="w - margin.right" :y="h"
            :dx="xaxis_dx" :dy="xaxis_dy">
          {{ xAxisLabel }}
        </text>
      </g>
      <g v-for="(chassi, idx) in yValueKeys"
         :key="idx"
         :transform="`translate(${boxWidth} 0)`"
         @click="populateTooltip($event, chassi)"
         @mouseleave="removeTooltip()">
          <line
            :id="chassi.key"
            :x1="xScaleCalc(chassi.key)"
            :x2="xScaleCalc(chassi.key)"
            :y1="yScaleCalc(chassi.value.min)"
            :y2="yScaleCalc(chassi.value.max)"
            style="width: 40px;"
            stroke="black"
          >
          </line>
          <rect
            :x="xScaleCalc(chassi.key)-(boxWidth/2)"
            :y="yScaleCalc(chassi.value.q3)"
            :height="yScaleCalc(chassi.value.q1)-yScaleCalc(chassi.value.q3)"
            :width="boxWidth"
            stroke="black"
            :fill="getColourForChassi(chassi.key)"
          >
          </rect>
          <line
            :x1="xScaleCalc(chassi.key)-(boxWidth/2)"
            :x2="xScaleCalc(chassi.key)+(boxWidth/2)"
            :y1="yScaleCalc(chassi.value.median)"
            :y2="yScaleCalc(chassi.value.median)"
            style="width: 40px;"
            stroke="black">
          </line>
      </g>
      <g v-xaxis="{ scale: xScale, height: h}"
         :transform="`translate(0 ${h})`"
         :style="{ 'font-size': xAxisTickLabelFontSize }"
         >
      </g>
      <g v-yaxis="{ scale: yScale, width: w}"
         :transform="`translate(0 0)`"
         :style="{ 'font-size': fontSize }"
         >
      </g>
     </g>
    </svg>
    <div v-if="showTooltip" class="tooltipContainer"
         :class="{ activeTooltip: showTooltip}"
         :style="{top: tooltip.y, left: tooltip.x}">
        <slot name="tooltip" :lines="tooltip.values">
            <span v-for="(value, key) in tooltip.values" :key="key">{{ key }}: {{ value }}</span>
        </slot>
    </div>
  </figure>
</template>

<script src="https://d3js.org/d3-scale-chromatic.v1.min.js"></script>
<script>
import * as d3 from 'd3'

export default {
  name: 'BoxPlot',
  props: {
    boxPlotType: {
     type: String
    },
    getStateFunction: {
      type: String,
      required: true
    },
    fontSize: {
      default: '0.7rem',
      Type: String
    },
    svgHeight: {
      default: '90vh',
      Type: String
    },
    graphTitle: {
      type: String,
      default: 'This will be a graph title, that can be long'
    },
    width: {
      type: Number,
      default: 600
    },
    height: {
      type: Number,
      default: 270
    },
    xKey: {
      default: 'chassisnumber',
      Type: String
    },
    yKey: {
      default: '',
      Type: String
    },
    xAxisLabel: {
      type: String,
      default: 'Chassisnumber'
    },
    yAxisLabel: {
      type: String
    },
    xAxisLabelShift: {
      type: Object,
      default: function () {
        return { dx: 30, dy: 85}
      }
    },
    yAxisLabelShift: {
      type: Object,
      default: function () {
        return { dx: 5, dy: -45}
      }
    },
    margin: {
      type: Object,
      default: function () {
        return { top: 0, right: 20, bottom: 40, left: 40 }
      }
    },
    xAxisValue: {
      default: 'chassis',
      Type: String
    }
  },

  data () {
    return {
      padding: 60,
      showTooltip: false,
      tooltip: {
          x: 0,
          y: 0,
          values: {}
      }
    }
  },

  computed: {
    flattenValues () {
      return this.$store.getters[this.getStateFunction](this.boxPlotType)
    },
    w () {
      const strokeWidth = 80
      return this.width - this.margin.left - this.margin.right - (strokeWidth * 2)
    },
    h () {
      const strokeWidth = 40
      return this.height - this.margin.bottom - (strokeWidth * 2)
    },
    boxWidth () {
      let bw = this.w
      return (bw / this.yValueKeysLength) * 0.5
    },
    nestedByGroup () {
      if (this.xAxisValue === 'chassis') {
        return this.getRollupData(this.flattenValues, d => d.chassisnumber)
            .sort((a, b) => d3.ascending(a.value.max, b.value.max));
      } else {
        return this.getRollupData(this.flattenValues, d => d.hour_bucket)
            .sort((a, b) => d3.ascending(a[0], b[0]));
      }
    },
    xAxisTickLabelFontSize () {
      if (this.yValueKeysLength <= 30) {
        return '11px'
      } else if (this.yValueKeysLength > 30 && this.yValueKeysLength <= 60) {
        return '8px'
      } else {
        return '4px'
      }
    },
    yValueKeys () {
      return Object.values(this.nestedByGroup).filter(item => item !== this.xKey).sort()
    },
    yValueKeysLength () {
      return this.yValueKeys.length
    },
    xScale () {
      return d3.scaleBand()
        .domain(this.yValueKeys.map(vals => vals.key))
        .range([0, this.w])
    },
    yScale () {
      return d3.scaleLinear()
        .domain([d3.min(this.nestedByGroup, function (d) { return d.value.min }), d3.max(this.nestedByGroup, function (d) { return d.value.max })])
        .range([this.h, 0])
        .nice()
    },
    viewBox () {
      return `0 0 ${this.width} ${this.height}`
    },
    xaxis_dx () {
      return 'dx' in this.xAxisLabelShift ? this.xAxisLabelShift.dx : undefined
    },
    xaxis_dy () {
      return 'dy' in this.xAxisLabelShift ? this.xAxisLabelShift.dy : undefined
    },
    yaxis_dx () {
      return 'dx' in this.yAxisLabelShift ? this.yAxisLabelShift.dx : undefined
    },
    yaxis_dy () {
      return 'dy' in this.yAxisLabelShift ? this.yAxisLabelShift.dy : undefined
    }
  },
  methods: {
    getRollupData (flattenValues, groupByFunction) {
      return Array.from(
          d3.rollups(flattenValues,
              v => {
                let q1 = v.map(function(g) { return g.q1 })[0]
                let min = v.map(function(g) { return g.min })[0]
                let median = v.map(function(g) { return g.median })[0]
                let q3 = v.map(function(g) { return g.q3 })[0]
                let max = v.map(function(g) { return g.max })[0]
                let interQuantileRange = q3 - q1

                return {
                  q1: q1,
                  median: median,
                  q3: q3,
                  interQuantileRange: interQuantileRange,
                  min: min,
                  max: max
                }
              },
              groupByFunction
          ),
          ([key, value]) => {
            return {
              key: key,
              value: value
            }
          }
      )
    },
    objFilter (data, keys_to_keep){
        return Object.fromEntries(Object.entries(data).filter(a=>keys_to_keep.includes(a[0])))
    },
    getColourForChassi (chassisnumber) {
      return this.xKey === 'time' ?
      'rgb(110, 64, 170)' :
      this.$store.getters['chassis/getGraphColorStructure'](chassisnumber)
    },
    xScaleCalc (x) {
      return this.xScale(x)
    },
    yScaleCalc (y) {
      return this.yScale(y)
    },
    populateTooltip(evt, chassis) {
      this.tooltip.x = `${evt.pageX + 5}px`
      this.tooltip.y = `${evt.pageY + 5}px`
      this.tooltip.values = {}

      this.tooltip.values['5th percentile'] = chassis.value.min
      this.tooltip.values['25th percentile'] = chassis.value.q1
      this.tooltip.values['50th percentile'] = chassis.value.median
      this.tooltip.values['75th percentile'] = chassis.value.q3
      this.tooltip.values['95th percentile'] = chassis.value.max

      this.$logger.message(`{"populateTooltip":"Clicked data in ${this.boxPlotType}"}`, 'info')
      this.showTooltip = true
    },
    removeTooltip() {
      this.showTooltip = false
    }
  },
  directives: {
    xaxis (el, binding) {
      const scale = binding.value.scale
      d3.select(el).transition().duration(500)
        .call(d3.axisBottom().scale(scale))
        .selectAll('text')
        .attr('transform', 'rotate(90)')
        .attr('y', 0)
        .attr('x', 9)
        .attr('dy', '.35em')
        .style('text-anchor', 'start')
    },
    yaxis (el, binding) {
      const scale = binding.value.scale
      const yFormatter = (num, digits) => {
        const lookup = [
          { value: 0, symbol: '' },
          { value: 1e3, symbol: 'k' },
          { value: 1e6, symbol: 'M' },
          { value: 1e9, symbol: 'G' },
          { value: 1e12, symbol: 'T' },
          { value: 1e15, symbol: 'P' },
          { value: 1e18, symbol: 'E' }
        ]
        const rx = /\.0+$|(\.[0-9]*[1-9])0+$/
        let item = lookup.slice().reverse().find(function(item) {
          return num >= item.value
        })
        return item ? (num).toFixed(digits).replace(rx, '$1') + item.symbol : '0'
      }
      d3.select(el).transition().duration(500)
        .call(d3.axisLeft().scale(scale).tickFormat((interval, i) => {
          return yFormatter(interval, 1)
        }))
    }
  }
}
</script>
<style scoped>
.line-chart__line {
  fill: none;
  stroke-width: 1.5;
}
.yAxisLabel {
    transform: rotate(-90deg);
    text-anchor: end;
    font-size: 0.90rem;
    fill: #2c3e50;
}
.xAxisLabel {
    text-anchor: end;
    font-size: 0.90rem;
    fill: #2c3e50;
}
.tooltipContainer {
    position: fixed;
    font-size: 0.8rem;
    padding: 10px;
    border: solid 1px black;
    box-shadow: 4px 4px 10px rgba(0, 0, 0, 0.4);
    background-color: #ffffff;
    opacity: 0;
    pointer-events: none;
    transition: opacity 0.3s;
}
.activeTooltip {
    opacity: 0.95;
    transition: opacity 0.3s;
    z-index: 3;
}
.tooltipContainer span {
    display: block;
}
.svg-container {
  display: inline-block;
  position: relative;
  width: 100%;
  height: 90vh;
  vertical-align: top;
  overflow: hidden;
  margin-top: 30px;
}
.svg-content {
  display: inline-block;
  position: absolute;
  top: 0;
  left: 0;
}
</style>
