<template>
  <div class="rangeComponent">
    <div class="inputTitle">
      <span> {{ filterName }} </span>
    </div>
    <div class="form">
      <input class="inputField left" type="number" :placeholder="'Min value in ' + unit"
             v-model="minUserInput">
      <input class="inputField right" type="number" :placeholder="'Max value in ' + unit"
             v-model="maxUserInput">
    </div>
    <div class="labelDiv">
      <label @click="setToMin()" class="inputFieldLabelsClickable minLabel">
        Min: {{ minRange }} {{ unit }}
      </label>
      <label @click="setToMax()" class="inputFieldLabelsClickable maxLabel">
        Max: {{ maxRange }} {{ unit }}
      </label>
    </div>

  </div>
</template>

<script>
export default {
  name: 'RangeInput',
  props: {
    range: {
      required: true,
      type: String
    },
    filterName: {
      required: true,
      type: String
    },
    filterKey: {
      required: true,
      type: String
    },
    unit: {
      required: true,
      type: String
    },
    minRange: {
      required: true,
      type: Number
    },
    maxRange: {
      required: true,
      type: Number
    },
    minStateFunctionString: {
      required: true,
      type: String
    },
    maxStateFunctionString: {
      required: true,
      type: String
    },
    setStateFunctionString: {
      required: false,
      type: String
    }
  },
  methods: {
    setToMin () {
      this.minUserInput = this.minRange
    },
    setToMax () {
      this.maxUserInput = this.maxRange
    }
  },
  data () {
    return {
      minUserInput: '',
      maxUserInput: ''
    }
  },
  watch: {
    minUserInput (val) {
      this.$store.commit(this.minStateFunctionString, val)
    },
    maxUserInput (val) {
      this.$store.commit(this.maxStateFunctionString, val)
    }
  }
}

</script>
<style scoped>
.form {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.inputField {
  font-family: 'Segoe UI', sans-serif;
  max-width: 100px;
  min-width: 60px;
  margin-right: 0.8rem;
  font-size: 100%;
  padding: 0.4em;
  outline: none;
  background-color: transparent;
  border-radius: 10px;
  border: 2px solid lightgrey;
}
.labelDiv {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.inputFieldLabelsClickable {
  color: #9BADBF;
  font-family: 'Segoe UI', sans-serif;
  max-width: 100px;
  min-width: 60px;
  margin-right: 0.9rem;
  font-size: small;
}
.inputFieldLabelsClickable:hover {
  cursor: pointer;
  color: #42b983;
}
.minLabel:hover {
  margin-left: 5px;
}
.maxLabel:hover {
  margin-right: 5px;
}
.rangeComponent {
  width: 100%;
  font-size: small;
}
.inputTitle {
  justify-content: center;
  font-weight: bold;
  width: 100%;
  display: inline-block;
  margin-bottom: 4px;
}
</style>
