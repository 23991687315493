<template>
  <div>
    <b-button v-if="!isSignedIn" variant="outline-dark" @click="onLoginClick()">Sign in</b-button>
    <b-button v-if="isSignedIn" variant="outline-success" @click="onLogoutClick()">Sign out</b-button>
  </div>
</template>

<script>
import * as jwt from 'jsonwebtoken'

export default {
  methods: {
    onLoginClick () {
      this.$auth.signIn()
    },
    onLogoutClick () {
      this.$auth.signOut()
        .then(() => {
          this.$store.commit('setSignedIn', false)
          this.$store.commit('setBearerToken', null)
        })
        .catch(err => {
          this.$logger.message(`{"onLogoutClick":"LoginComponent.vue onLogoutClick: ${err}"}`, 'error')
        })
    },
    startInterval () {
      setInterval(() => {
        const msalToken = this.$store.getters.getBearerToken
        if (msalToken !== null) {
          if ((Date.now() - (10 * 60000)) >= jwt.decode(msalToken.split(' ')[1]).exp * 1000) {
            this.$logger.message(`{"setInterval":"Signing in again: ${jwt.decode(msalToken.split(' ')[1]).exp * 1000} ${new Date(jwt.decode(msalToken.split(' ')[1]).exp * 1000)} compare with: ${Date.now() - (10 * 60000)} ${new Date(Date.now() - (10 * 60000))}"}`)
            this.$auth.getTokenPopup()
          }
        }
      }, 60000)
    }
  },
  mounted () {
    this.startInterval()
  },
  computed: {
    isSignedIn () {
      return this.$store.getters.isSignedIn
    }
  }
}
</script>
