<template>
  <div id="layerPanelContainer">
    <div>
      <div id="dataLayerButtons">
        <b-button-group>
          <span v-for="(btn, idx) in buttons"
                :key="idx"
                :id="'layer-btn-wrapper-'+idx">
            <b-button style="margin: 1px"
                      variant="light"
                      @click="setLayerFunction(btn.switchVariable)"
                      :disabled="btn.disabled || !isLayerDataLoaded(btn.switchVariable)"
                      :class="{ active: isLayerVisible(btn.switchVariable),
                      }">
              {{ btn.caption }}
            </b-button>
          </span>
          <span v-for="(btn, idx) in buttons"
                :key="'btn-tooltip-' + idx">
            <b-tooltip v-if="!isLayerDataLoaded(btn.switchVariable)" :target="'layer-btn-wrapper-'+idx" triggers="hover">
              There is no data for the "{{ btn.caption }}" layer.
            </b-tooltip>
          </span>
        </b-button-group>
      </div>
      <div id="hexagonResolutionSelector" >
        <div id="hexagonResolutionElements"
             v-show="isLayerVisible('hexagon') || isLayerVisible('edge')">
          <span style="color:white;">Hexagon Resolution: </span>
          <input class="inputField" type="number" min="2" max="13" :placeholder="'2-13'"
                 v-model.lazy="hexagonResolutionForRange"
                 :disabled="requestingHexagonsAndEdgesWithNewResolution">
          <div v-show="requestingHexagonsAndEdgesWithNewResolution" class="spinner-border spinner-border-sm"
               role="status" style="color:white; margin-left: 9px; position: absolute; margin-top: 5px;" >
            <span class="sr-only">Loading...</span>
          </div>
        </div>
      </div>
    </div>
    <div id="hexagonResolutionError" v-show="errorMessageHexagonResolution">
      <div id="hexagonResolutionErrorText" v-show="errorMessageHexagonResolution">
        <span style="color:white;"> {{ errorMessageHexagonResolution }} </span>
      </div>
    </div>
  </div>
</template>
<script>
import snowflake from '../../mixins/snowflake'

export default {
  name: 'LayerPanel',
  mixins: [snowflake],
  data () {
    return {
      buttons: {
        stop: { caption: 'Stop', switchVariable: 'stop', disabled: false },
        hexagon: { caption: 'Hexagon', switchVariable: 'hexagon', disabled: false },
        edge: { caption: 'Trip', switchVariable: 'edge', disabled: false }
      },
      defaultHexagonResolutionValue: 7,
      errorMessageHexagonResolution: false,
      requestingHexagonsAndEdgesWithNewResolution: false,
      layerFilter: [
        { text: 'Stop', default_text: 'stop', value: 'stop', id: 0, checked: true },
        { text: 'Hexagon', default_text: 'hexagon', value: 'hexagon', id: 1, checked: false },
        { text: 'Trip', default_text: 'edge', value: 'edge', id: 2, checked: false }
      ],
      selectedLayer: [],
      hexCountComponent: false
    }
  },
  computed: {
    hexagonResolutionForRange: {
      get () {
        if (this.$store.getters['chassis/getHexagonResolution']) {
          return this.$store.getters['chassis/getHexagonResolution'].split('_')[3]
        }
        return this.defaultHexagonResolutionValue
      },
      set (value) {
        this.$logger.message(`{"hexagonResolutionForRange":"New resolution for hexagon/trip layer: ${value}."}`, 'info')
        this.$store.commit('chassis/setHexagonResolution', value)
      }
    },
    isMarketUserType () {
      if (this.$store.getters.getAppConfigFlag('isMarketUser')) {
        this.$store.commit('chassis/setUserType', true)
      }
      return this.$store.getters['chassis/getUserType']
    }
  },
  methods: {
    isLayerVisible (layer) {
      return this.$store.getters.getLayerVisibility(layer)
    },
    isLayerDataLoaded (layer) {
      return this.$store.getters['chassis/getMapLayerDataLoaded'](layer)
    },
    openModal () {
      this.$store.commit('setModalVisibility', true)
    },
    setLayerFunction (layer) {
      if (this.$store.getters.getLayerVisibility(layer)) {
        this.$store.commit('setLayerVisibility', [ layer, false ])
        this.buttons[layer].selected = false
        this.$logger.message(`{"setLayerFunction":"Layer off: ${layer}"}`, 'info')
      } else {
        this.$store.commit('setLayerVisibility', [ layer, true ])
        this.buttons[layer].selected = true
        this.$logger.message(`{"setLayerFunction":"Layer on: ${layer}"}`, 'info')
      }
    }
  },
  watch: {
    selectedLayer: function () {
      this.layerFilter.forEach(lyrSwitch => {
        if (this.selectedLayer.indexOf(lyrSwitch.value) > -1) {
          this.$store.commit('setLayerVisibility', [ lyrSwitch.value, true ])
        } else {
          this.$store.commit('setLayerVisibility', [ lyrSwitch.value, false ])
        }
      })
      if (this.selectedLayer.indexOf('hexagon') > -1 || this.selectedLayer.indexOf('edge') > -1) {
        this.hexCountComponent = true
      } else {
        this.hexCountComponent = false
      }
    },
    '$store.state.chassis.hexagonResolution': function () {
      this.$store.commit('chassis/setSelectedH3CellIndex', [])
      if (this.$store.getters['chassis/getHexagonResolution']) {
        this.errorMessageHexagonResolution = false
        this.requestingHexagonsAndEdgesWithNewResolution = true
        const getHexagonLayerQueryId = this.getQueryIdFromSnowflake('HEXAGONS', 'hexagons')
        const getEdgesQueryId = this.getQueryIdFromSnowflake('EDGES', 'edges')
        Promise.all([getHexagonLayerQueryId, getEdgesQueryId]).then(() => {
          const getEdgeLayerFromSnowflake = this.getEdgeLayerFromSnowflake()
          const getHexagonLayerFromSnowflake = this.getHexagonLayerFromSnowflake()

          Promise.all([getEdgeLayerFromSnowflake, getHexagonLayerFromSnowflake])
            .then(() => {
              this.$emit('handle-deck-layer-changed')
              this.requestingHexagonsAndEdgesWithNewResolution = false
              this.$store.commit('chassis/setH3CellIndexOptions', [])
            }).catch((e) => {
              this.$logger.message(`{"Watch_hexagonResolution":"Watcher on chassis.hexagonResolution error: ${e}"}`, 'error')
              this.requestingHexagonsAndEdgesWithNewResolution = false
              this.errorMessageHexagonResolution = 'Could not fetch hexagons for the data set at the specified resolution. ' +
              'Try to decrease hexagon resolution or try with a smaller data set'
            })
        })
      }
    }
  }
}
</script>
<style scoped>
#layerPanelContainer {
  display: flex;
  width: 100%;
  justify-content: flex-start;
  flex-direction: column;
}

.btn.disabled {
  pointer-events: auto;
}

#dataLayerButtons {
  padding: 10px;
  border-radius: 2px;
  justify-content: center;
}

#hexagonResolutionSelector {
  margin-top: -18px;
  display: flex;
  justify-content: center;
}

#hexagonResolutionElements {
  margin-top: 30px;
  font-size: small;
}

#hexagonResolutionError {
  display: flex;
  justify-content: center;
}

#stopFilterContainer {
  display: flex;
  justify-content: center;
  margin-top: 20px;
}
#stopFilter {
  background-color: #f8f9fa;
  color: #212529;
  padding: 5px;
  width: 70%;
}
#hexagonResolutionErrorText {
  padding: 10px;
  border-radius: 2px;
  margin-top: 4px;
  width: 70%;
  font-size: small;
}
.inputField {
  width: 55px;
  margin-left: 5px;
}
</style>
<style>
.btn-light {
  border: 2px solid white;
}
.btn-light.active {
  background-color: #bacde8 !important;
}
.btn-light:hover:not(.active) {
  background-color: #bacde8 !important;
}
.btn-light:hover.active:hover {
  background-color: #f8f9fa !important;
}
</style>
