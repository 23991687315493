import { textPaint, undergroundP, font } from '@/components/layers/common.js'
import { localNameTags } from './l10n.js'

const voltageScale = [
  [null, '#7A7A85'],
  [10, '#6E97B8'],
  [25, '#55B555'],
  [52, '#B59F10'],
  [132, '#B55D00'],
  [220, '#C73030'],
  [310, '#B54EB2'],
  [550, '#00C1CF']
]

const specialVoltages = {
  HVDC: '#4E01B5',
  'Traction (<50 Hz)': '#A8B596'
}

const plantTypes = {
  coal: 'power_plant_coal',
  geothermal: 'power_plant_geothermal',
  hydro: 'power_plant_hydro',
  nuclear: 'power_plant_nuclear',
  oil: 'power_plant_oilgas',
  gas: 'power_plant_oilgas',
  diesel: 'power_plant_oilgas',
  solar: 'power_plant_solar',
  wind: 'power_plant_wind',
  biomass: 'power_plant_biomass',
  waste: 'power_plant_waste',
  battery: 'power_plant_battery'
}

// === Frequency predicates
const tractionFreqP = [
  'all',
  ['has', 'frequency'],
  ['!=', ['get', 'frequency'], ''],
  ['!=', ['to-number', ['get', 'frequency']], 50],
  ['!=', ['to-number', ['get', 'frequency']], 60]
]

const hvdcP = [
  'all',
  ['has', 'frequency'],
  ['!=', ['get', 'frequency'], ''],
  ['==', ['to-number', ['get', 'frequency']], 0]
]

// Stepwise function to assign colour by voltage:
function voltageColor (field) {
  let voltageFunc = ['step', ['to-number', ['coalesce', ['get', field], 0]]]
  for (let row of voltageScale) {
    if (row[0] == null) {
      voltageFunc.push(row[1])
      continue
    }
    voltageFunc.push(row[0] - 0.01)
    voltageFunc.push(row[1])
  }

  return [
    'case',
    hvdcP,
    specialVoltages['HVDC'], // HVDC (frequency == 0)
    tractionFreqP,
    specialVoltages['Traction (<50 Hz)'], // Traction power
    voltageFunc
  ]
}

const multiVoltageMinZoom = 10

// Generate an expression to determine the offset of a power line
// segment with multiple voltages
function voltageOffset (index) {
  const spacing = 7

  let offset = (index - 1) * spacing
  return ['interpolate',
    ['linear'],
    ['zoom'],
    multiVoltageMinZoom - 0.001, 0,
    multiVoltageMinZoom,
    [
      'case',
      ['has', 'voltage_3'],
      (offset - spacing) * 0.5,
      ['has', 'voltage_2'],
      (offset - spacing / 2) * 0.5,
      0
    ],
    13,
    [
      'case',
      ['has', 'voltage_3'],
      offset - spacing,
      ['has', 'voltage_2'],
      offset - spacing / 2,
      0
    ]
  ]
}

// Function to assign power line thickness.
// Interpolate first by zoom level and then by voltage.
const voltageLineThickness = [
  'interpolate',
  ['linear'],
  ['zoom'],
  2,
  0.5,
  10,
  [
    'match',
    ['get', 'line'],
    'bay',
    1,
    'busbar',
    1,
    [
      'interpolate',
      ['linear'],
      ['coalesce', ['get', 'voltage'], 0],
      0,
      1,
      100,
      1.8,
      800,
      4
    ]
  ]
]

const voltage = ['to-number', ['coalesce', ['get', 'voltage'], 0]]
const output = ['to-number', ['coalesce', ['get', 'output'], 0]]

// Determine substation visibility
const substationVisibleP = [
  'all',
  [
    'any',
    ['>', voltage, 200],
    [
      'all',
      ['>', voltage, 200],
      ['>', ['zoom'], 6]
    ],
    [
      'all',
      ['>', voltage, 100],
      ['>', ['zoom'], 7]
    ],
    ['all', ['>', voltage, 25], ['>', ['zoom'], 9]],
    ['all', ['>', voltage, 9], ['>', ['zoom'], 10]],
    ['>', ['zoom'], 11]
  ],
  ['any',
    ['!=', ['get', 'substation'], 'transition'],
    ['>', ['zoom'], 12]
  ]
]

const substationRadius = [
  'interpolate',
  ['linear'],
  ['zoom'],
  5,
  [
    'interpolate',
    ['linear'],
    voltage,
    0,
    0,
    200,
    1,
    750,
    3
  ],
  12,
  [
    'interpolate',
    ['linear'],
    voltage,
    10,
    1,
    30,
    3,
    100,
    5,
    300,
    7,
    600,
    9
  ],
  15, 3
]

// Determine the minimum zoom a point is visible at (before it can be seen as an
// area), based on the area of the substation.
const substationPointVisibleP = [
  'any',
  ['==', ['coalesce', ['get', 'area'], 0], 0], // Area = 0 - mapped as node
  ['all', ['<', ['coalesce', ['get', 'area'], 0], 100], ['<', ['zoom'], 16]],
  ['all', ['<', ['coalesce', ['get', 'area'], 0], 250], ['<', ['zoom'], 15]],
  ['<', ['zoom'], 13]
]

const converterP = ['all',
  ['==', ['get', 'substation'], 'converter'],
  ['any',
    ['>', voltage, 100],
    ['>', ['zoom'], 6]
  ]
]

const substationLabelVisibleP = [
  'all',
  [
    'any',
    ['>', voltage, 399],
    [
      'all',
      ['>', voltage, 200],
      ['>', ['zoom'], 8]
    ],
    [
      'all',
      ['>', voltage, 100],
      ['>', ['zoom'], 10]
    ],
    [
      'all',
      ['>', voltage, 50],
      ['>', ['zoom'], 12]
    ],
    ['>', ['zoom'], 13]
  ],
  ['any', ['==', ['to-number', ['get', 'area']], 0], ['<', ['zoom'], 17]],
  ['!=', ['get', 'substation'], 'transition']
]

// Power line / substation visibility
const powerVisibleP = [
  'all',
  [
    'any',
    ['>', voltage, 199],
    ['all', ['>', voltage, 99], ['>=', ['zoom'], 4]],
    ['all', ['>', voltage, 49], ['>=', ['zoom'], 5]],
    ['all', ['>', voltage, 24], ['>=', ['zoom'], 6]],
    ['all', ['>', voltage, 9], ['>=', ['zoom'], 9]],
    ['>', ['zoom'], 10]
  ],
  [
    'any',
    ['all', ['!=', ['get', 'line'], 'busbar'], ['!=', ['get', 'line'], 'bay']],
    ['>', ['zoom'], 12]
  ]
]

// Power line ref visibility
const powerRefVisibleP = [
  'all',
  [
    'any',
    [
      'all',
      ['>', voltage, 330],
      ['>', ['zoom'], 7]
    ],
    [
      'all',
      ['>', voltage, 200],
      ['>', ['zoom'], 8]
    ],
    [
      'all',
      ['>', voltage, 100],
      ['>', ['zoom'], 9]
    ],
    ['>', ['zoom'], 10]
  ],
  [
    'any',
    ['all', ['!=', ['get', 'line'], 'busbar'], ['!=', ['get', 'line'], 'bay']],
    ['>', ['zoom'], 12]
  ]
]

const constructionP = ['get', 'construction']

const constructionLabel = [
  'case',
  constructionP,
  ' (under construction) ',
  ''
]

const plantLabelVisibleP = [
  'any',
  ['>', output, 1000],
  ['all', ['>', output, 750], ['>', ['zoom'], 5]],
  ['all', ['>', output, 250], ['>', ['zoom'], 6]],
  ['all', ['>', output, 100], ['>', ['zoom'], 7]],
  ['all', ['>', output, 10], ['>', ['zoom'], 9]],
  ['all', ['>', output, 1], ['>', ['zoom'], 11]],
  ['>', ['zoom'], 12]
]

const prettyOutput = ['case',
  ['>', output, 1],
  ['concat', output, ' MW'],
  ['concat', ['round', ['*', output, 1000]], ' kW']
]

const localName = ['coalesce'].concat(localNameTags.map(tag => ['get', tag]))

const plantLabel = ['step', ['zoom'],
  ['concat', localName],
  9,
  ['case',
    ['all', ['!', ['has', 'name']], ['has', 'output']],
    ['concat', prettyOutput, constructionLabel],
    ['has', 'output'],
    ['concat', localName, ' \n', prettyOutput, '\n', constructionLabel],
    localName
  ]
]

function plantImage () {
  let expr = ['match', ['get', 'source']]
  for (const [key, value] of Object.entries(plantTypes)) {
    expr.push(key, value)
  }
  expr.push('power_plant') // default
  return expr
}

const constructionOpacity = ['case', constructionP, 0.3, 1]
const powerLineOpacity = ['interpolate', ['linear'], ['zoom'],
  4, ['case', constructionP, 0.3, 0.6],
  8, ['case', constructionP, 0.3, 1]
]

// eslint-disable-next-line no-unused-vars
const plantConstructionOpacity = constructionOpacity

// eslint-disable-next-line no-unused-vars
const labelOffset = {
  stops: [[8, [0, 3]], [13, [0, 1]]]
}

const freq = [
  'case',
  hvdcP,
  ' DC',
  tractionFreqP,
  ['concat', ' ', ['get', 'frequency'], ' Hz'],
  ''
]

function round (field, places) {
  const pow = Math.pow(10, places)
  return ['/',
    ['round', ['*', field, pow]],
    pow
  ]
}

const lineVoltage = [
  'case',
  [
    'all',
    ['has', 'voltage_3'],
    ['!=', ['get', 'voltage_3'], ['get', 'voltage_2']]
  ],
  [
    'concat',
    round(['get', 'voltage'], 3),
    '/',
    round(['get', 'voltage_2'], 3),
    '/',
    round(['get', 'voltage_3'], 3),
    ' kV'
  ],
  [
    'all',
    ['has', 'voltage_2'],
    ['!=', ['get', 'voltage_2'], ['get', 'voltage']]
  ],
  ['concat', round(['get', 'voltage'], 3), '/', round(['get', 'voltage_2'], 3), ' kV'],
  ['has', 'voltage'],
  ['concat', round(['get', 'voltage'], 3), ' kV'],
  ''
]

const lineLabel = [
  'case',
  ['all', ['has', 'voltage'], ['has', 'name'], ['!=', localName, '']],
  [
    'concat',
    localName,
    ' (',
    lineVoltage,
    freq,
    ')',
    constructionLabel
  ],
  ['has', 'voltage'],
  ['concat', lineVoltage, freq, constructionLabel],
  localName
]

const substationLabelDetail = [
  'case',
  ['all', ['!=', localName, ''], ['has', 'voltage']],
  [
    'concat',
    localName,
    ' ',
    voltage,
    ' kV',
    freq,
    constructionLabel
  ],
  ['all', ['==', localName, ''], ['has', 'voltage']],
  [
    'concat',
    'Substation ',
    voltage,
    ' kV',
    freq,
    constructionLabel
  ],
  localName
]

const substationLabel = [
  'step',
  ['zoom'],
  localName,
  12,
  substationLabelDetail
]

const layers = [
  {
    zorder: 60,
    id: 'power_line_case',
    type: 'line',
    source: 'openinframap',
    'source-layer': 'power_line',
    filter: ['==', ['get', 'tunnel'], true],
    minzoom: 12,
    paint: {
      'line-opacity': ['case', constructionP, 0.2, 0.4],
      'line-color': '#7C4544',
      'line-width': ['interpolate', ['linear'], ['zoom'], 12, 4, 18, 10]
    },
    layout: {
      'line-join': 'round',
      'line-cap': 'round'
    }
  },
  {
    zorder: 61,
    id: 'power_line_underground_1',
    type: 'line',
    filter: ['all', undergroundP, powerVisibleP],
    source: 'openinframap',
    'source-layer': 'power_line',
    minzoom: 0,
    paint: {
      'line-color': voltageColor('voltage'),
      'line-width': voltageLineThickness,
      'line-dasharray': [3, 2],
      'line-offset': voltageOffset(1),
      'line-opacity': powerLineOpacity
    },
    layout: {
      'line-join': 'round',
      'line-cap': 'round'
    }
  },
  {
    zorder: 61,
    id: 'power_line_underground_2',
    type: 'line',
    filter: ['all', undergroundP, powerVisibleP, ['has', 'voltage_2']],
    source: 'openinframap',
    'source-layer': 'power_line',
    minzoom: multiVoltageMinZoom,
    paint: {
      'line-color': voltageColor('voltage_2'),
      'line-width': voltageLineThickness,
      'line-dasharray': [3, 2],
      'line-offset': voltageOffset(2),
      'line-opacity': powerLineOpacity
    },
    layout: {
      'line-join': 'round',
      'line-cap': 'round'
    }
  },
  {
    zorder: 61,
    id: 'power_line_underground_3',
    type: 'line',
    filter: ['all', undergroundP, powerVisibleP, ['has', 'voltage_3']],
    source: 'openinframap',
    'source-layer': 'power_line',
    minzoom: multiVoltageMinZoom,
    paint: {
      'line-color': voltageColor('voltage_3'),
      'line-width': voltageLineThickness,
      'line-dasharray': [3, 2],
      'line-offset': voltageOffset(3),
      'line-opacity': powerLineOpacity
    },
    layout: {
      'line-join': 'round',
      'line-cap': 'round'
    }
  },
  {
    zorder: 160,
    id: 'power_plant',
    type: 'fill',
    source: 'openinframap',
    minzoom: 5,
    'source-layer': 'power_plant',
    paint: {
      'fill-opacity': ['case', constructionP, 0.05, 0.2]
    }
  },
  {
    zorder: 161,
    id: 'power_plant_outline',
    type: 'line',
    filter: ['!', constructionP],
    source: 'openinframap',
    minzoom: 8,
    'source-layer': 'power_plant',
    paint: {
      'line-color': 'rgb(80,80,80)',
      'line-opacity': 0.4,
      'line-width': 1
    },
    layout: {
      'line-join': 'round'
    }
  },
  {
    zorder: 161,
    id: 'power_plant_outline_construction',
    type: 'line',
    filter: constructionP,
    source: 'openinframap',
    minzoom: 8,
    'source-layer': 'power_plant',
    paint: {
      'line-color': 'rgb(163,139,16)',
      'line-opacity': 0.4,
      'line-width': 1,
      'line-dasharray': [2, 2]
    },
    layout: {
      'line-join': 'round'
    }
  },
  {
    zorder: 161,
    id: 'power_substation',
    type: 'fill',
    filter: substationVisibleP,
    source: 'openinframap',
    'source-layer': 'power_substation',
    minzoom: 13,
    paint: {
      'fill-opacity': 0.3,
      'fill-color': voltageColor('voltage'),
      'fill-outline-color': 'rgba(0, 0, 0, 1)'
    }
  },
  {
    zorder: 162,
    id: 'power_solar_panel',
    type: 'fill',
    source: 'openinframap',
    'source-layer': 'power_generator_area',
    filter: ['==', ['get', 'source'], 'solar'],
    minzoom: 13,
    paint: {
      'fill-color': '#726BA9',
      'fill-outline-color': 'rgba(50, 50, 50, 1)'
    }
  },
  {
    zorder: 260,
    id: 'power_line_1',
    type: 'line',
    source: 'openinframap',
    'source-layer': 'power_line',
    filter: ['all', ['!', undergroundP], powerVisibleP],
    minzoom: 0,
    paint: {
      'line-color': voltageColor('voltage'),
      'line-width': voltageLineThickness,
      'line-offset': voltageOffset(1),
      'line-opacity': powerLineOpacity
    },
    layout: {
      'line-join': 'round',
      'line-cap': 'round'
    }
  },
  {
    zorder: 260,
    id: 'power_line_2',
    type: 'line',
    source: 'openinframap',
    'source-layer': 'power_line',
    filter: [
      'all',
      ['!', undergroundP],
      powerVisibleP,
      ['has', 'voltage_2']
    ],
    minzoom: multiVoltageMinZoom,
    paint: {
      'line-color': voltageColor('voltage_2'),
      'line-width': voltageLineThickness,
      'line-offset': voltageOffset(2),
      'line-opacity': powerLineOpacity
    },
    layout: {
      'line-join': 'round',
      'line-cap': 'round'
    }
  },
  {
    zorder: 260,
    id: 'power_line_3',
    type: 'line',
    source: 'openinframap',
    'source-layer': 'power_line',
    filter: [
      'all',
      ['!', undergroundP],
      powerVisibleP,
      ['has', 'voltage_3']
    ],
    minzoom: multiVoltageMinZoom,
    paint: {
      'line-color': voltageColor('voltage_3'),
      'line-width': voltageLineThickness,
      'line-offset': voltageOffset(3),
      'line-opacity': powerLineOpacity
    },
    layout: {
      'line-join': 'round',
      'line-cap': 'round'
    }
  },
  {
    zorder: 261,
    id: 'power_transformer',
    type: 'symbol',
    source: 'openinframap',
    'source-layer': 'power_transformer',
    minzoom: 14,
    paint: textPaint,
    layout: {
      'icon-image': 'power_transformer'
    }
  },
  {
    zorder: 262,
    id: 'power_compensator',
    type: 'symbol',
    source: 'openinframap',
    'source-layer': 'power_compensator',
    minzoom: 14,
    paint: textPaint,
    layout: {
      'icon-image': 'power_compensator'
    }
  },
  {
    zorder: 263,
    id: 'power_switch',
    type: 'symbol',
    source: 'openinframap',
    'source-layer': 'power_switch',
    minzoom: 14,
    paint: textPaint,
    layout: {
      'icon-image': 'power_switch'
    }
  },
  {
    zorder: 264,
    id: 'power_tower',
    type: 'symbol',
    filter: ['==', ['get', 'type'], 'tower'],
    source: 'openinframap',
    'source-layer': 'power_tower',
    minzoom: 13,
    paint: textPaint,
    layout: {
      'icon-image': [
        'case',
        ['get', 'transition'],
        'power_tower_transition',
        ['any', ['has', 'transformer'], ['has', 'substation']],
        'power_tower_transformer',
        'power_tower'
      ],
      'icon-offset': [
        'case',
        ['any', ['has', 'transformer'], ['has', 'substation']],
        ['literal', [12, 0]],
        ['literal', [0, 0]]
      ],
      'icon-allow-overlap': true,
      'icon-size': ['interpolate', ['linear'], ['zoom'], 13, 0.6, 17, 1],
      'text-field': '{ref}',
      'text-font': font,
      'text-size': [
        'step',
        // Set visibility by using size
        ['zoom'],
        0,
        14,
        10
      ],
      'text-offset': [0, 1.5],
      'text-max-angle': 10
    }
  },
  {
    zorder: 265,
    id: 'power_pole',
    type: 'symbol',
    filter: ['==', ['get', 'type'], 'pole'],
    source: 'openinframap',
    'source-layer': 'power_tower',
    minzoom: 13,
    paint: textPaint,
    layout: {
      'icon-image': [
        'case',
        ['get', 'transition'],
        'power_pole_transition',
        ['any', ['has', 'transformer'], ['has', 'substation']],
        'power_pole_transformer',
        'power_pole'
      ],
      'icon-offset': [
        'case',
        ['any', ['has', 'transformer'], ['has', 'substation']],
        ['literal', [10, 0]],
        ['literal', [0, 0]]
      ],
      'icon-allow-overlap': true,
      'icon-size': ['interpolate', ['linear'], ['zoom'], 13, 0.2, 17, 0.8],
      'text-field': '{ref}',
      'text-font': font,
      'text-size': [
        'step',
        // Set visibility by using size
        ['zoom'],
        0,
        14,
        10
      ],
      'text-offset': [0, 1],
      'text-max-angle': 10
    }
  },
  {
    zorder: 266,
    id: 'power_wind_turbine',
    type: 'symbol',
    source: 'openinframap',
    'source-layer': 'power_generator',
    filter: ['==', ['get', 'source'], 'wind'],
    minzoom: 11,
    paint: textPaint,
    layout: {
      'icon-image': 'power_wind',
      'icon-anchor': 'bottom',
      'icon-size': ['interpolate', ['linear'], ['zoom'], 11, 0.5, 14, 1],
      'text-field': '{name}',
      'text-font': font,
      'text-size': ['step', ['zoom'], 0, 12, 9],
      'text-offset': [0, 1],
      'text-anchor': 'top'
    }
  },
  {
    zorder: 267,
    id: 'power_wind_turbine_point',
    type: 'circle',
    source: 'openinframap',
    'source-layer': 'power_generator',
    // ['all',
    filter: ['==', ['get', 'source'], 'wind'],
    //      ['has', 'output'],
    //      ['>', ['get', 'output'], 1]
    //    ],
    minzoom: 9,
    maxzoom: 11,
    paint: {
      'circle-radius': 1.5,
      'circle-color': '#444444'
    }
  },
  {
    zorder: 268,
    id: 'power_substation_point',
    type: 'circle',
    filter: ['all', substationVisibleP, substationPointVisibleP, ['!', converterP]],
    source: 'openinframap',
    'source-layer': 'power_substation_point',
    minzoom: 5,
    layout: {},
    paint: {
      'circle-radius': substationRadius,
      'circle-color': voltageColor('voltage'),
      'circle-stroke-color': '#555',
      'circle-stroke-width': [
        'interpolate',
        ['linear'],
        ['zoom'],
        5,
        0.1,
        8,
        0.5,
        15,
        2
      ],
      'circle-opacity': powerLineOpacity,
      'circle-stroke-opacity': powerLineOpacity
    }
  },
  {
    zorder: 560,
    id: 'power_line_ref',
    type: 'symbol',
    filter: [
      'all',
      powerRefVisibleP,
      ['!=', ['coalesce', ['get', 'ref'], ''], ''],
      ['<', ['length', ['get', 'ref']], 5]
    ],
    source: 'openinframap',
    'source-layer': 'power_line',
    minzoom: 7,
    layout: {
      'icon-image': 'power_line_ref',
      'text-field': '{ref}',
      'text-font': font,
      'symbol-placement': 'line-center',
      'text-size': 10,
      'text-max-angle': 10
    }
  },
  {
    zorder: 561,
    id: 'power_line_label',
    type: 'symbol',
    filter: ['all', powerVisibleP],
    source: 'openinframap',
    'source-layer': 'power_line',
    minzoom: 11,
    paint: textPaint,
    layout: {
      'text-field': lineLabel,
      'text-font': font,
      'symbol-placement': 'line',
      'symbol-spacing': 400,
      'text-size': ['interpolate', ['linear'], ['zoom'], 11, 10, 18, 13],
      'text-offset': [
        'case',
        ['has', 'voltage_3'],
        ['literal', [0, 1.5]],
        ['has', 'voltage_2'],
        ['literal', [0, 1.25]],
        ['literal', [0, 1]]
      ],
      'text-max-angle': 10
    }
  },
  {
    zorder: 562,
    id: 'power_substation_ref_label',
    type: 'symbol',
    filter: substationLabelVisibleP,
    source: 'openinframap',
    'source-layer': 'power_substation_point',
    minzoom: 14.5,
    layout: {
      'symbol-z-order': 'source',
      'text-field': '{ref}',
      'text-font': font,
      'text-anchor': 'bottom',
      'text-offset': [0, -0.5],
      'text-size': ['interpolate', ['linear'], ['zoom'], 14, 9, 18, 12],
      'text-max-width': 8
    },
    paint: textPaint
  },
  {
    zorder: 562,
    id: 'power_substation_label',
    type: 'symbol',
    source: 'openinframap',
    filter: substationLabelVisibleP,
    'source-layer': 'power_substation_point',
    minzoom: 8,
    layout: {
      'symbol-sort-key': ['-', 10000, voltage],
      'symbol-z-order': 'source',
      'text-field': substationLabel,
      'text-font': font,
      'text-anchor': 'top',
      'text-offset': [0, 0.5],
      'text-size': [
        'interpolate',
        ['linear'],
        ['zoom'],
        8,
        10,
        18,
        [
          'interpolate',
          ['linear'],
          voltage,
          0,
          10,
          400,
          16
        ]
      ],
      'text-max-width': 8
    },
    paint: textPaint
  },
  {
    zorder: 562,
    id: 'power_converter_point',
    type: 'symbol',
    filter: ['all', converterP, substationPointVisibleP],
    source: 'openinframap',
    'source-layer': 'power_substation_point',
    minzoom: 5.5,
    layout: {
      'icon-image': 'converter',
      'icon-size': ['interpolate', ['linear'], ['zoom'],
        5, 0.4,
        9, 1
      ],
      'text-field': substationLabel,
      'text-font': font,
      'text-anchor': 'top',
      'text-offset': [0, 1.2],
      'text-size': [
        'interpolate',
        ['linear'],
        ['zoom'],
        7,
        10,
        18,
        [
          'interpolate',
          ['linear'],
          output,
          0,
          10,
          2000,
          16
        ]
      ],
      'text-optional': true
    },
    paint: Object.assign({}, textPaint, {
      'text-opacity': ['step', ['zoom'], 0, 7, 1]
    })
  },
  {
    zorder: 563,
    id: 'power_plant_label',
    type: 'symbol',
    source: 'openinframap',
    filter: plantLabelVisibleP,
    'source-layer': 'power_plant_point',
    minzoom: 6,
    maxzoom: 24,
    layout: {
      'symbol-sort-key': ['-', 10000, output],
      'symbol-z-order': 'source',
      'icon-allow-overlap': true,
      'icon-image': plantImage(),
      'icon-size': ['interpolate', ['linear'], ['zoom'], 6, 0.6, 10, 0.8],
      'text-field': plantLabel,
      'text-font': font,
      'text-anchor': 'top',
      'text-offset': [0, 1],
      'text-size': [
        'interpolate',
        ['linear'],
        ['zoom'],
        7,
        10,
        18,
        [
          'interpolate',
          ['linear'],
          output,
          0,
          10,
          2000,
          16
        ]
      ],
      'text-optional': true
    },
    paint: Object.assign({}, textPaint, {
      // Control visibility using the opacity property...
      'icon-opacity': ['step', ['zoom'],
        ['case', constructionP, 0.5, 1], 11,
        0],
      'text-opacity': ['step', ['zoom'], 0, 7, 1]
    })
  }
]

export { layers, voltageScale, specialVoltages, plantTypes }
