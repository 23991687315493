<template>
  <div v-if="visible" class="rangeComponent">
    <div class="rangeInputs">
      <div class="inputGroup">
        <input class="inputField"
               :class="activatedFilterValue ? 'activatedFilterValue': 'notActivatedFilterValue'"
               :min="+0"
               :max="maxUserInput"
               type="number"
               v-model.lazy="minUserInput">
        <label for="minHour">Min</label>
      </div>
      <div class="inputGroup">
        <input class="inputField"
               :class="activatedFilterValue ? 'activatedFilterValue': 'notActivatedFilterValue'"
               :min="minUserInput"
               :max="+23"
               type="number"
               v-model.lazy="maxUserInput">
        <label for="maxHour">Max</label>
      </div>
    </div>
    <div class="activateMinMaxCheck">
      <button class="removeButton" @click="removeItem">
        <v-icon class="removeInputIcon" name="times-circle"/>
      </button>
    </div>
  </div>
</template>

<script>
export default {
  name: 'MinMaxInput',
  props: {
    setStateFunctionString: {
      required: false,
      type: String
    }
  },
  data () {
    return {
      minUserInput: 20,
      maxUserInput: 21,
      activatedFilterValue: false,
      visible: true
    }
  },
  watch: {
    maxUserInput: function (newVal, oldVal) {
      let diffToRemove = parseInt(oldVal) - parseInt(this.minUserInput)
      let hoursToRemove = [...Array(diffToRemove + 1).keys()].map(value => value + parseInt(this.minUserInput))
      this.$store.commit('location/removeFromSelectedHour', hoursToRemove)

      let diffToAdd = parseInt(newVal) - parseInt(this.minUserInput)
      let hoursToAdd = [...Array(diffToAdd + 1).keys()].map(value => value + parseInt(this.minUserInput))
      this.$store.commit('location/addToSelectedHour', hoursToAdd)
      this.activatedFilterValue = true
    },

    minUserInput: function (newVal, oldVal) {
      let diffToRemove = parseInt(this.maxUserInput) - parseInt(oldVal)
      let hoursToRemove = [...Array(diffToRemove + 1).keys()].map(value => value + parseInt(oldVal))
      this.$store.commit('location/removeFromSelectedHour', hoursToRemove)

      let diffToAdd = parseInt(this.maxUserInput) - parseInt(newVal)
      let hoursToAdd = [...Array(diffToAdd + 1).keys()].map(value => value + parseInt(newVal))
      this.$store.commit('location/addToSelectedHour', hoursToAdd)
      this.activatedFilterValue = true
    }
  },
  methods: {
    setToInactive () {
      let diff = parseInt(this.maxUserInput) - parseInt(this.minUserInput)
      let hours = [...Array(diff + 1).keys()].map(value => value + parseInt(this.minUserInput))
      this.$store.commit('location/removeFromSelectedHour', hours)
    },
    removeItem () {
      this.$emit('handle-item-removed')
      this.setToInactive()
      this.visible = false
    }
  }
}

</script>
<style scoped>

@media only screen and (max-width: 1630px) {
  .inputGroup {
    width: 80px;
  }
}

@media only screen and (min-width: 1630px) {
  .inputGroup {
    font-family: 'Segoe UI', sans-serif;
    width: 100px;
    margin-right: 0.8rem;
  }
}

@media only screen and (min-width: 600px) {
  .rangeComponent {
    width: 100%;
    font-size: small;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-top: 1rem;
    margin-left: 0.2rem;
  }
  .rangeInputs {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
  }
}

@media only screen and (max-width: 600px) {
  .rangeComponent {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-left: 0px;
  }
  .rangeInputs {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-left: 0px;
  }
}

@media only screen and (min-width: 800px) {
  .rangeComponent {
    width: 100%;
    font-size: small;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-top: 1rem;
    margin-left: 0.2rem;
  }
}

@media only screen and (max-width: 800px) {
  .rangeComponent {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-left: 0px;
  }
}

@media only screen and (max-width: 1100px) {
  .inputGroup label {
    display: none;
  }
  .inputGroup {
    width: 60px;
    margin-right: 0px;
  }
}

.rangeComponent {
  width: 100%;
  font-size: small;
}

.inputGroup {
  font-family: 'Segoe UI', sans-serif;
  position: relative;
}

.inputGroup input {
  font-size: 100%;
  padding: 0.6em;
  outline: none;
  background-color: transparent;
  border-radius: 20px;
  width: 100%;
}

.notActivatedFilterValue {
  border: 2px solid lightgrey;
}

.activatedFilterValue {
  border: 2px solid #41b883;
}

.inputGroup input:focus {
  border: 2px solid lightcoral;
}

.inputGroup label {
  font-size: 100%;
  position: absolute;
  left: 0;
  padding: 0.7em;
  margin-left: 1.8em;
  pointer-events: none;
  transition: all 0.3s ease;
  color: rgb(100, 100, 100);
}

.activateMinMaxCheck {
  display: flex;
  flex-direction: row;
}

.removeButton {
  background-color: transparent;
  border: none;
  float: right;
}

.removeInputIcon {
  height: 20px;
  width: 20px;
}

</style>
