<template>
  <b-tab id="chassisSelectionTab" :title=tabName>
    <div v-if="this.$store.getters.getAppConfigFlag('isMarketUser')">
      <b-alert :show="!isGDPRChecked" variant="info" >
      <h5>Non-Disclosure Agreement</h5>
      <div class="form-check">
        <input class="form-check-input" data-toggle="tooltip" data-placement="top" title="Click to agree" type="checkbox"  id="isGDPRChecked" @click="isChecked">
        <label class="form-check-label" for="isGDPRChecked">
          <p>Position information is GDPR/Isec sensitive, it is not allowed to share a customer positions with another. Interaction is being logged and will be reviewed in case of an incident.</p>
        </label>
      </div>
    </b-alert></div>
    <div v-if="isGDPRChecked || !this.$store.getters.getAppConfigFlag('isMarketUser')">
      <b-form-textarea
      id="chassisInput"
      v-model="chassis"
      placeholder="Input one chassis per line.."
      row="3"
      max-rows="12"
      :state="isChassisValid"
    >
    </b-form-textarea>
      <b-form-input
        id="customSetNameInputField"
        v-model="customSetName"
        placeholder="Optionally give your dataset a name.."
        :state="isTileSetNameValid"
      ></b-form-input>
      <b-card-footer>
        <b-alert :show="!isChassisValid" variant="danger">
          <h5>The chassis set is not valid!</h5>
          <p>All chassis number should be 7 characters long & total number of chassis must be less than 1156.</p>
        </b-alert>
        <b-button id="create-new-tile-set-for-chassis" v-bind:class="button.state"
                  :disabled="!isChassisValid || !isTileSetNameValid  ||
                           button.isFetchingDataOnRequest || chassis.length <= 0"
                  @click="postSet">
          <b-spinner v-if="button.isFetchingDataOnRequest &&
                         button.state === 'btn-success'"
                     small type="grow"></b-spinner>
          {{ button.info }}
        </b-button>
      </b-card-footer>
      <div id="responseInfo" v-show="showInfo">
        <div v-show="receivedStops">
          <p class="infoField">Number of chassis in dataset / Number of chassis requested:
            <span>{{ numberOfChassisInSet }} / {{ numberOfChassisRequested }}</span>
          </p>
          <p class="infoField">Number of stops in set:
            <span id="noChassis">{{ stopCount }}</span>
          </p>
          <p class="infoField">Earliest stop in set:
            <span>{{ minTime }}</span>
          </p>
          <p class="infoField">Latest stop in set:
            <span>{{ maxTime }}</span>
          </p>
          <p class="infoField">Stop set name:
            <span>{{ setName }}</span>
          </p>
          <p style="color:darkred;">{{ errorMessage }}</p>
        </div>
        <div v-show="!receivedStops">
          <p> {{ message }} </p>
        </div>
      </div></div>
  </b-tab>
</template>

<script>
import axios from 'axios'
import axiosRetry from 'axios-retry'
import snowflake from '../mixins/snowflake'

export default {
  name: 'ChassisSelection',
  mixins: [snowflake],
  props: {
    receivedStops: {
      type: Boolean,
      required: false,
      default: false
    },
    stopCount: {
      type: Number,
      required: false,
      default: 0
    },
    minTime: {
      type: Number,
      required: false,
      default: 0
    },
    maxTime: {
      type: Number,
      required: false,
      default: 0
    },
    numberOfChassisRequested: {
      type: Number,
      required: false,
      default: 0
    },
    numberOfChassisInSet: {
      type: Number,
      required: false,
      default: 0
    },
    tabName: {
      type: String,
      required: false,
      default: 'Create new data'
    },
    setName: {
      type: String,
      required: false,
      default: ''
    },
    postEndpoint: {
      type: String,
      required: true
    },
    lambdaPath: {
      type: String,
      default: '/Prod/PostSnowflakeQueryCache'
    }
  },
  data () {
    return {
      chassis: '',
      customSetName: '',
      errorMessage: '',
      message: '',
      timeStart: 0,
      showInfo: false,
      isGDPRChecked: false,
      button: { info: 'Get data', isFetchingDataOnRequest: false, state: 'btn-success' }
    }
  },
  computed: {
    isTileSetNameValid () {
      return this.isValid(this.customSetName)
    },
    isChassisValid () {
      return this.allChassisValid()
    }
  },
  methods: {
    isValid () {
      if (this.customSetName.length > 0) {
        const regExpStr = '(^[a-zA-Z0-9_\\-]{2,1024}$)'
        const result = new RegExp(regExpStr, 'g').test(this.customSetName)
        return result
      } else {
        return true
      }
    },

    isChecked () {
      if (!this.isGDPRChecked) {
        this.isGDPRChecked = true
        this.$logger.message('{"isGDPRchecked":"Non Disclosre Agreement accepted"}', 'info')
      } else {
        this.isGDPRChecked = false
      }
    },
    allChassisValid () {
      if (this.getChassisString().length > 0 && this.getChassisString().length <= 9248) {
        const regExp = new RegExp('[0-9]{7}')
        const result = this.chassis.trimEnd().split(/[\s]+/).map(chassi => {
          return regExp.test(chassi)
        })
        return !result.includes(false)
      } else {
        return false
      }
    },

    getChassisString () {
      let string = this.chassis.split(/[\r\n]+/).join()
      return string
    },

    async apiTilesCall (url, bucketPath) {
      const axiosClient = axios.create()

      const retryCondition = (error) => {
        return (
          axiosRetry.isNetworkOrIdempotentRequestError(error) || error.response.status === 404
        )
      }

      axiosRetry(axiosClient, { retries: 200, retryDelay: axiosRetry.exponentialDelay, retryCondition })

      return axiosClient
        .get(url, {
          headers: { 'Authorization': this.$store.getters.getBearerToken, 'aggregatekey': bucketPath }
        })
    },

    postSet () {
      this.timeStart = Date.now()
      this.$store.commit('setStartTimeLog', Date.now())
      this.$logger.message('{"postSet":"PostSet starting"}', 'info')
      this.$store.commit('setTriggerDeckRedraw', false)
      this.$store.commit('setUserSelection', '')
      this.$store.commit('setUserSelectionType', '')
      this.$store.dispatch('chassis/resetState')
      this.$store.commit('resetVisibleLayers')
      this.$emit('handle-on-view-selection')

      if (process.env.VUE_APP_ENV === 'mock') {
        this.$store.commit('chassis/setTilesConstantMaxTime', new Date())
        this.$store.commit('chassis/setTilesChassis', this.chassis.split(/[\r\n]+/).join(','))
        this.$store.commit('setUserSelectionType', '_mock')
        this.$logger.message(`{"postSet":"Requested chassis: ${this.chassis}"}`, 'info')

        const lambdaPath = this.$store.getters.getAppConfigFlag('useStagingStack') ? process.env.VUE_APP_PREDICTION_ENDPOINT_B : process.env.VUE_APP_PREDICTION_ENDPOINT
        const predictionUrl = lambdaPath + '/Prod/GetQueryTimeInference'
        const requestPrediction = axios.create()
        requestPrediction.get(predictionUrl, {
          headers: {
            'Authorization': this.$store.getters.getBearerToken
          },
          params: {
            'num_stops': 20000,
            'num_chassis': 10,
            'inference_id': this.$logger.sessionId() + '_' + this.$store.getters['chassis/getRequestTime']
          }
        })
          .then(response => {
            this.$store.commit('chassis/setTimePrediction', Math.max(0, response.data))
          })
          .catch((e) => {
            this.$logger.message(`{"postSet":"setTimePrediction threw an exception: ${e}"}`, 'error')
            this.$store.commit('chassis/setTimePrediction', 0)
          })

        this.$logger.message(`{"postSet":"Prediction time is: ${this.$store.getters['chassis/getQueryTimeInSeconds']}"}`, 'info')
        const stopsSqlFile = 'STOPS'
        const rawStops = this.getQueryIdFromSnowflake(stopsSqlFile, 'rawStops')
        Promise.all([rawStops]).then(() => {
          const stopQueryState = this.getStopQueryState()
          return Promise.all([stopQueryState])
        }).then(() => {
          const getMinMaxQueryId = this.getQueryIdFromSnowflake('MIN_MAX_RANGES',
            'minMaxRanges')
          const getEdgesQueryId = this.getQueryIdFromSnowflake('EDGES', 'edges')
          const getDailyAggregatesQueryId = this.getQueryIdFromSnowflake('DAILY_AGGREGATES',
            'dailyAggregates')
          const getOdometerBoxplotQueryId = this.getQueryIdFromSnowflake('ODOMETER_BOXPLOT',
            'odometerBoxplot')
          const getFuelBoxplotQueryId = this.getQueryIdFromSnowflake('FUEL_BOXPLOT',
            'fuelBoxplot')
          const getHexagonLayerQueryId = this.getQueryIdFromSnowflake('HEXAGONS',
            'hexagons')
          const getDailyFuelBoxplotQueryId = this.getQueryIdFromSnowflake('DAILY_FUEL_BOXPLOT',
            'dailyFuelBoxplot')
          const getDailyOdometerBoxplotQueryId = this.getQueryIdFromSnowflake('DAILY_ODOMETER_BOXPLOT',
            'dailyOdometerBoxplot')
          const getHourlyLocationDistQueryId = this.getQueryIdFromSnowflake('HOURLY_ONGOING_STOPS',
            'hourlyLocationDistribution')
          const getDailyHourlyLocationDistQueryId = this.getQueryIdFromSnowflake('DAILY_HOURLY_ONGOING_STOPS',
            'dailyHourlyLocationDistribution')
          return Promise.all([getMinMaxQueryId, getEdgesQueryId, getDailyAggregatesQueryId, getOdometerBoxplotQueryId,
            getFuelBoxplotQueryId, getHexagonLayerQueryId, getDailyFuelBoxplotQueryId, getDailyOdometerBoxplotQueryId,
            getHourlyLocationDistQueryId, getDailyHourlyLocationDistQueryId])
        }).then(() => {
          const getOdometerBoxplotFromSnowflake = this.getOdometerBoxplotFromSnowflake()
          const getFuelBoxplotFromSnowflake = this.getFuelBoxplotFromSnowflake()
          const getMinMaxValuesFromSnowflake = this.getMinMaxValuesFromSnowflake()
          const getDailyGraphAggregateFromSnowflake = this.getDailyGraphAggregateFromSnowflake()
          const getEdgeLayerFromSnowflake = this.getEdgeLayerFromSnowflake()
          const getHexagonLayerFromSnowflake = this.getHexagonLayerFromSnowflake()
          const getDailyFuelBoxplot = this.getDailyFuelBoxplotFromSnowflake()
          const getDailyOdometerBoxplot = this.getDailyOdometerBoxplotFromSnowflake()
          const getHourlyLocationDistribution = this.getHourlyLocationDistributionFromSnowflake()
          const getDailyHourlyLocationDistribution = this.getDailyHourlyLocationDistributionFromSnowflake()

          return Promise.all([getOdometerBoxplotFromSnowflake, getFuelBoxplotFromSnowflake, getMinMaxValuesFromSnowflake,
            getDailyGraphAggregateFromSnowflake, getEdgeLayerFromSnowflake, getHexagonLayerFromSnowflake, getDailyFuelBoxplot,
            getDailyOdometerBoxplot, getHourlyLocationDistribution, getDailyHourlyLocationDistribution])
        }).then(() => {
          this.$store.commit('chassis/setRequestedTilesName', 'shabcj-123')
          this.$emit('handle-on-data-loaded')
          this.$store.commit('chassis/setGraphDataFinishedLoading', true)
          this.$logger.message('{"postSet":"postSet is done"}', 'info')
        }).catch(err => {
          console.log(err)
          this.$logger.message(`{"postSet":"ChassisSelection.vue requestChassisAxios: ${err.response}"}`, 'error')
          if (err.response !== undefined && err.response.status === 409) {
            this.message = 'You already have a set named like this. Please try again!'
            this.button = { info: 'Get data', isFetchingDataOnRequest: false, state: 'btn-success' }
          } else {
            this.button.info = 'You are not authorized to utilise SweetE.'
            this.button.state = 'btn-danger'
            this.message = 'Contact johan.nystrom@scania.com for access to the application.'
          }
        })
        return true
      }
      this.message = null
      let endpoint = this.$store.getters.getAppConfigFlag('useStagingStack') ? process.env.VUE_APP_QPGW_ENDPOINT_B : process.env.VUE_APP_QPGW_ENDPOINT
      const url = endpoint + this.postEndpoint
      this.button.isFetchingDataOnRequest = true

      let base64Payload = this.$store.getters.getBearerToken.split('.')[1]
      let payload = Buffer.from(base64Payload, 'base64')
      let decodedAndParsedString = JSON.parse(payload.toString())
      let email = decodedAndParsedString['preferred_username']
      let userName = email.slice(0, -11)

      const requestChassisAxios = axios.create()

      requestChassisAxios
        .post(url, {}, {
          headers: {
            user: userName,
            setname: this.customSetName,
            Authorization: this.$store.getters.getBearerToken
          },
          params: {
            chassis: this.getChassisString(),
            sessionid: this.$logger.sessionId()
          }
        })
        .then(res => {
          this.$logger.message('{"postSet":"Post is done"}')

          let bucketPath = res.data.bucket_path + '/meta_data.json'
          const url = endpoint + '/Prod/GetAggregate'
          this.apiTilesCall(url, bucketPath)
            .then(res => {
              let itemCount = res.data['item_count']
              if (itemCount === 0) {
                this.receivedStops = false
                this.message = res.data['message']
                this.button = { info: 'Get data', isFetchingDataOnRequest: false, state: 'btn-success' }
                throw new Error('Empty result')
              } else {
                this.$emit('handle-on-data-response', res.data)
                this.$emit('predict-query-time')
                const stopsSqlFile = this.stopSqlFile ? 'STOPS_LAST' : 'STOPS'
                const rawStops = this.getQueryIdFromSnowflake(stopsSqlFile, 'rawStops')
                Promise.all([rawStops])
                  .then(() => {
                    const stopQueryState = this.getStopQueryState()
                    return Promise.all([stopQueryState])
                  }).then(() => {
                    const getMinMaxQueryId = this.getQueryIdFromSnowflake('MIN_MAX_RANGES',
                      'minMaxRanges')
                    const getEdgesQueryId = this.getQueryIdFromSnowflake('EDGES',
                      'edges')
                    const getDailyAggregatesQueryId = this.getQueryIdFromSnowflake('DAILY_AGGREGATES',
                      'dailyAggregates')
                    const getOdometerBoxplotQueryId = this.getQueryIdFromSnowflake('ODOMETER_BOXPLOT',
                      'odometerBoxplot')
                    const getFuelBoxplotQueryId = this.getQueryIdFromSnowflake('FUEL_BOXPLOT',
                      'fuelBoxplot')
                    const getHexagonLayerQueryId = this.getQueryIdFromSnowflake('HEXAGONS',
                      'hexagons')
                    const getDailyFuelBoxplotQueryId = this.getQueryIdFromSnowflake('DAILY_FUEL_BOXPLOT',
                      'dailyFuelBoxplot')
                    const getDailyOdometerBoxplotQueryId = this.getQueryIdFromSnowflake('DAILY_ODOMETER_BOXPLOT',
                      'dailyOdometerBoxplot')
                    const getHourlyLocationDistQueryId = this.getQueryIdFromSnowflake('HOURLY_ONGOING_STOPS',
                      'hourlyLocationDistribution')
                    const getDailyHourlyLocationDistQueryId = this.getQueryIdFromSnowflake('DAILY_HOURLY_ONGOING_STOPS',
                      'dailyHourlyLocationDistribution')

                    return Promise.all([getMinMaxQueryId, getEdgesQueryId, getDailyAggregatesQueryId,
                      getOdometerBoxplotQueryId, getFuelBoxplotQueryId, getHexagonLayerQueryId,
                      getDailyFuelBoxplotQueryId, getDailyOdometerBoxplotQueryId, getHourlyLocationDistQueryId,
                      getDailyHourlyLocationDistQueryId])
                  }).then(() => {
                    const getOdometerBoxplotFromSnowflake = this.getOdometerBoxplotFromSnowflake()
                    const getFuelBoxplotFromSnowflake = this.getFuelBoxplotFromSnowflake()
                    const getMinMaxValuesFromSnowflake = this.getMinMaxValuesFromSnowflake()
                    const getDailyGraphAggregateFromSnowflake = this.getDailyGraphAggregateFromSnowflake()
                    const getEdgeLayerFromSnowflake = this.getEdgeLayerFromSnowflake()
                    const getHexagonLayerFromSnowflake = this.getHexagonLayerFromSnowflake()
                    const getDailyFuelBoxplot = this.getDailyFuelBoxplotFromSnowflake()
                    const getDailyOdometerBoxplot = this.getDailyOdometerBoxplotFromSnowflake()
                    const getHourlyLocationDistribution = this.getHourlyLocationDistributionFromSnowflake()
                    const getDailyHourlyLocationDistribution = this.getDailyHourlyLocationDistributionFromSnowflake()

                    return Promise.all([getOdometerBoxplotFromSnowflake, getFuelBoxplotFromSnowflake, getMinMaxValuesFromSnowflake,
                      getDailyGraphAggregateFromSnowflake, getEdgeLayerFromSnowflake, getHexagonLayerFromSnowflake, getDailyFuelBoxplot,
                      getDailyOdometerBoxplot, getHourlyLocationDistribution, getDailyHourlyLocationDistribution])
                  }).then(() => {
                    this.$logger.message('{"postSet":"All requested Snowflake data has been fetched"}', 'info')
                    this.$store.commit('chassis/setRequestedTilesName', this.setName)
                    this.$store.commit('chassis/setGraphDataFinishedLoading', true)
                    this.$emit('handle-on-data-loaded')
                  }).catch(err => {
                    if (err.response.status === 500) {
                      this.button = { info: 'Get data', isFetchingDataOnRequest: true, state: 'btn-danger' }
                      this.$logger.message(`{"postSet":"ChassisSelection.vue requestChassisAxios: ${err.response.data.message}"}`, 'error')
                      setTimeout(() => {
                        this.button = { info: 'Get data', isFetchingDataOnRequest: true, state: 'btn-success' }
                      }, 10000)
                      this.showInfo = true
                      this.message = err.response.data.message + ' Not all components will be available when finished loading.' +
                      'A solution might be reducing the amount chassis requested.'
                    }
                  })
              }
            })
        })
        .catch(err => {
          this.$logger.message(`{"postSet":"ChassisSelection.vue requestChassisAxios: ${err.response}"}`, 'error')
          if (err.response !== undefined && err.response.status === 409) {
            this.message = 'You already have a set named like this. Please try again!'
            this.button = { info: 'Get data', isFetchingDataOnRequest: false, state: 'btn-success' }
          } else {
            this.button.info = 'You are not authorized to utilise SweetE.'
            this.button.state = 'btn-danger'
            this.message = 'Contact johan.nystrom@scania.com for access to the application.'
          }
        })
        .then(() => {
          let timeEnd = Date.now()
          this.$logger.message(`{"postSet":{"message":"requestChassisAxios POST & GET is done","time":"${timeEnd - this.timeStart}"}}`, 'info')
          this.$emit('handle-button', this.button)
          this.showInfo = true
        })
    }
  },
  watch: {
    '$store.state.resetModal': function () {
      if (this.$store.getters.getResetModal) {
        this.showInfo = false
        this.chassis = ''
        this.customSetName = ''
        this.errorMessage = ''
        this.message = null
        this.stopCount = null
        this.minTime = null
        this.maxTime = null
        this.setName = null
        this.receivedStops = false
        this.button = { info: 'Get data', isFetchingDataOnRequest: false, state: 'btn-success' }
        this.$emit('handle-chassis-selection-reset', 'chassisSelection')
      }
    }
  }
}

</script>

<style scoped>
#customSetNameInputField {
  width: 100%;
  margin-top: 10px;
  margin-bottom: 10px;
}

#isGDPRChecked {
  height: 18px;
  width: 18px;
  margin-top: 0.1rem;
  margin-left: -1rem;
}

#responseInfo {
  padding-top: 40px;
}

.infoField {
  font-weight: 700;
  margin: 0;
}

</style>
