import axios from 'axios'
import store from '../store/store.js'
import { v4 as uuidv4 } from 'uuid'

require('setimmediate')

const winston = require('winston')
const Transport = require('winston-transport')
const { format } = winston
const { timestamp, combine, prettyPrint } = format

let sessionId = uuidv4()
class CloudWatchTransport extends Transport {
  log (info, callback) {
    try {
      setImmediate(() => {
        this.emit('logged', info)
      })
      let endpoint = process.env.VUE_APP_GRAPH_ENDPOINT
      try {
        endpoint = store.getters.getAppConfigFlag('useStagingStack') ? process.env.VUE_APP_GRAPH_ENDPOINT_B : process.env.VUE_APP_GRAPH_ENDPOINT
      } catch (e) {}
      const logURL = endpoint + '/Prod/PostLogs'
      const sendLogMessage = axios.create()
      sendLogMessage
        .post(logURL, {
          message: info.message,
          level: info.level,
          _timestamp: info.timestamp,
          sessionId: info.sessionId,
          sessionUser: store.getters.getUsername,
          service: info.service,
          application: info.application
        }, {
          headers: {
            Authorization: store.getters.getBearerToken
          }
        })
      callback()
    } catch (e) {}
  }
}
const trans = []

if (process.env.NODE_ENV === 'production') {
  trans.push(new winston.transports.Console({ level: 'info' }))
} else {
  trans.push(new winston.transports.Console({ level: 'silly' }))
}
if (process.env.VUE_APP_ENV !== 'mock') {
  trans.push(new CloudWatchTransport({ level: 'info' }))
}

const logger = winston.createLogger({
  level: 'silly',
  handleExceptions: true,
  defaultMeta: {
    'service': 'sweete-frontend',
    'sessionId': sessionId
  },
  format: combine(
    timestamp(),
    prettyPrint()
  ),
  transports: trans
})

export default {
  sessionId () {
    return sessionId
  },
  message (value, level = 'silly') {
    logger.log({
      'level': level,
      'message': value,
      'application': store.getters.getUserSelection + store.getters.getUserSelectionType
    })
  }
}
