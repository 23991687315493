<template>
  <div id="locationFilterPanel">
    <div id="layerPanelContainer">
      <div id="hexagonResolutionSelector">
        <div id="hexagonResolutionElements">
          <number-input v-model="hexagonResolutionToRequest" :min="10" :max="10" inline center controls></number-input>
          <label style="width:100%;font-size:small;color:#9BADBF;">Current zoom is {{ hexagonResolution }}</label>
        </div>
      </div>
      <div id="byChassis">
        <b-form-textarea
          id="chassisInput"
          v-model="chassis"
          placeholder="Optionally filter locations using a chassis list: Input one chassis number per line.."
          row="3"
          max-rows="12"
          :state="isChassisValid">
        </b-form-textarea>
      </div>
      <div id="byCountry" v-show="selectByCountry">
        <multiselect v-model="chosenCountries"
                     track-by="code"
                     placeholder="Select a country"
                     :preserve-search="true"
                     label="name"
                     :multiple="true"
                     :options="options"></multiselect>
      </div>
      <div id="byActivity">
        <number-input v-model="minNumStopsToRequest" :min="0" :max="2000" inline center controls></number-input>
        <label style="width:100%;font-size:small;color:#9BADBF;">Activity filter is set to show locations with more than {{ minNumStops }} stops</label>
      </div>
    </div>
    <div id="locationFilterButtonContainer">
      <div id="locationFilterButton">
        <b-button class="post-filter" @click="exportMarketSummaryToCSV">Export market summary</b-button>
        <b-button class="post-filter" @click="exportToCSV">Export locations</b-button>
        <b-button class="post-filter" variant="success" id="rangeLocationFilterButton"
                  @click="refreshAndFilter"
                  :disabled='refreshLocation || filterLocation || chosenCountries.length === 0'>
          <b-spinner v-if="filterLocation" small type="grow"></b-spinner>
          Refresh
        </b-button>
        <b-button class="post-filter" variant="outline-warning" id="resetAndRefreshLocation"
                  @click="resetAndRefresh"
                  :disabled='refreshLocation || filterLocation'>
          <b-spinner v-if="refreshLocation" small type="grow"></b-spinner>
          Reset
        </b-button>
      </div>
    </div>
    <div v-if="requestFailed" id="onLocationFilteringError">
      <p style="color:white;"> The requested refresh failed. Please try broaden your filters! {{ errorMessage }} </p>
    </div>
  </div>
</template>
<script>
import NumberInput from '@chenfengyuan/vue-number-input'
import snowflake from '../../mixins/snowflake'
import Multiselect from 'vue-multiselect/src'
import axios from 'axios'
import { json2csv } from 'json-2-csv'

export default {
  name: 'FilterPanel',
  mixins: [snowflake],
  components: { Multiselect, NumberInput },
  timeStart: 0,
  data () {
    return {
      chassis: '',
      selectByCountry: true,
      requestFailed: false,
      errorMessage: false,
      filterLocation: false,
      refreshLocation: false,
      chosenCountries: this.$store.getters['classified_location/getChosenCountries'],
      options: [{ code: 'UU', name: 'Unicorn Country' }],
      fieldArray: [
        { key: 'cluster_id', sortable: true, label: 'Cluster id' },
        { key: 'cluster_similarity_cluster_id', sortable: true, label: 'Cluster similarity cluster id' },
        { key: 'location_classification', sortable: true, label: 'Location type' },
        { key: 'med_morning_duration', sortable: true, label: 'Med. morning duration' },
        { key: 'med_afternoon_duration', sortable: true, label: 'Med. afternoon duration' },
        { key: 'med_evening_duration', sortable: true, label: 'Med. evening duration' },
        { key: 'med_night_duration', sortable: true, label: 'Med. night duration' },
        { key: 'median_long_stops_per_week_and_vehicle', sortable: true, label: 'Med. long stops per week/vehicle' },
        { key: 'most_common_chassisadaptation', sortable: true, label: 'Common chassis adaption' },
        { key: 'most_common_wheel_configuration', sortable: true, label: 'Common wheel config' },
        { key: 'p75_stop_duration', sortable: true, label: '75 perc stop duration' },
        { key: 'rel_freq_morning', sortable: true, label: 'Rel. morning stop freq.' },
        { key: 'rel_freq_afternoon', sortable: true, label: 'Rel. afternoon stop freq.' },
        { key: 'rel_freq_evening', sortable: true, label: 'Rel. evening stop freq.' },
        { key: 'rel_freq_night', sortable: true, label: 'Rel. night stop freq.' },
        { key: 'rel_freq_weight_increase', sortable: true, label: 'Rel. freq. weight inc.' },
        { key: 'rel_freq_weight_decrease', sortable: true, label: 'Rel. freq. weight dec.' },
        { key: 'relative_count_of_unique_customers', sortable: true, label: 'Rel. freq. unique customers' },
        { key: 'weekly_median_number_of_en_route_over_night_parkings', sortable: true, label: 'Weekly median number of en route overnight parkings' },
        { key: 'weekly_avg_number_of_en_route_over_night_parkings', sortable: true, label: 'Weekly mean number of en route overnight parkings' }
      ],
      marketSummaryFieldArray: [
        { key: 'country_code', sortable: true, label: 'Country code' },
        { key: 'p05_number_of_en_route_over_night_parkings', sortable: true, label: 'Number of en route overnight parkings (05% percentile)' },
        { key: 'p25_number_of_en_route_over_night_parkings', sortable: true, label: 'Number of en route overnight parkings (25% percentile)' },
        { key: 'med_number_of_en_route_over_night_parkings', sortable: true, label: 'Number of en route overnight parkings (median)' },
        { key: 'p75_number_of_en_route_over_night_parkings', sortable: true, label: 'Number of en route overnight parkings (75% percentile)' },
        { key: 'p95_number_of_en_route_over_night_parkings', sortable: true, label: 'Number of en route overnight parkings (75% percentile)' },
        { key: 'med_number_of_vehicles', sortable: true, label: 'Med. daily number of unique vehicles' },
        { key: 'med_number_of_en_route_over_night_parkings_per_vehicle', sortable: true, label: 'Med. daily number of en route nightly stops per vehicle - Milence vehicles' },
        { key: 'filtered_p05_number_of_en_route_over_night_parkings', sortable: true, label: 'Number of en route overnight parkings (05% percentile) - Milence vehicles' },
        { key: 'filtered_p25_number_of_en_route_over_night_parkings', sortable: true, label: 'Number of en route overnight parkings (25% percentile) - Milence vehicles' },
        { key: 'filtered_med_number_of_en_route_over_night_parkings', sortable: true, label: 'Number of en route overnight parkings (median) - Milence vehicles' },
        { key: 'filtered_p75_number_of_en_route_over_night_parkings', sortable: true, label: 'Number of en route overnight parkings (75% percentile) - Milence vehicles' },
        { key: 'filtered_p95_number_of_en_route_over_night_parkings', sortable: true, label: 'Number of en route overnight parkings (75% percentile) - Milence vehicles' },
        { key: 'filtered_med_number_of_vehicles', sortable: true, label: 'Med. daily number of unique vehicles - Milence vehicles' },
        { key: 'filtered_med_number_of_en_route_over_night_parkings_per_vehicle', sortable: true, label: 'Med. daily number of en route nightly stops per vehicle - Milence vehicles' }
      ]
    }
  },
  mounted () {
    const lambdaPath = '/Prod/GetCountryListFromS3'
    let endpoint = this.$store.getters.getAppConfigFlag('useStagingStack') ? process.env.VUE_APP_CLASSIFIED_LOCATIONS_ENDPOINT_B : process.env.VUE_APP_CLASSIFIED_LOCATIONS_ENDPOINT

    const url = endpoint + lambdaPath
    const request = axios.create()

    request
      .get(url, {
        headers: { 'Authorization': this.$store.getters.getBearerToken }
      })
      .then(response => {
        let options = []
        response.data.trim().split('\n').map(countryField => {
          let country = countryField.split(';')
          const countryCode = country[0]
          const countryName = country[1]
          options.push({ 'code': countryCode, 'name': countryName.replaceAll('"', '') })
        })
        options.sort((c1, c2) => {
          if (c1.name < c2.name) { return -1 }
          if (c1.name > c2.name) { return 1 }
          return 0
        })
        this.options = options
      })
  },
  computed: {
    formatedChassiRequestList () {
      return this.chassis.split('\n').map(number => number.trim()).reduce((acc, number) => {
        if (acc === '') {
          return number
        } else {
          return acc + ',' + number
        }
      }, '')
    },
    isChassisValid () {
      return this.allChassisValid()
    },
    hexagonResolutionToRequest: {
      get () {
        return this.$store.getters['classified_location/getResolutionToRequest']
      },
      set (value) {
        this.$store.commit('classified_location/setResolutionToRequest', parseInt(value))
      }
    },
    hexagonResolution: {
      get () {
        return this.$store.getters['classified_location/getHexagonResolution']
      },
      set (value) {
        this.$store.commit('classified_location/setHexagonResolution', parseInt(value))
      }
    },
    minNumStopsToRequest: {
      get () {
        return this.$store.getters['classified_location/getMinNumStopsToRequest']
      },
      set (value) {
        this.$store.commit('classified_location/setMinNumStopsToRequest', parseInt(value))
      }
    },
    minNumStops: {
      get () {
        return this.$store.getters['classified_location/getMinNumStops']
      },
      set (value) {
        this.$store.commit('classified_location/setMinNumStops', parseInt(value))
      }
    }
  },
  methods: {
    allChassisValid () {
      if (this.getChassisString().length === 0) {
        return true
      } else if (this.getChassisString().length > 0 && this.getChassisString().length <= 9248) {
        const regExp = new RegExp('[0-9]{7}')
        const result = this.chassis.trimEnd().split(/[\s]+/).map(chassi => {
          return regExp.test(chassi)
        })
        return !result.includes(false)
      } else {
        return false
      }
    },
    getChassisString () {
      let string = this.chassis.split(/[\r\n]+/).join()
      return string
    },
    getImageUrl (imageSrc) {
      return require('../../assets/' + imageSrc)
    },
    addCustomRange () {
      this.customHourRanges += 1
      if (this.customHourRanges === 1) {
        this.selectedHour = []
      }
    },
    removeCustomRange () {
      if (this.customHourRanges > 0) {
        this.customHourRanges -= 1
      }
    },
    toggleFilters () {
    },
    onResetFilter () {
    },
    resetAndRefresh () {
    },
    prepareLogReq (reqParams) {
      let reqParamsForLog = {
        ...reqParams,
        currhexresolution: 'H3_CELL_INDEX_' + this.$store.getters['classified_location/getHexagonResolution']
      }
      return reqParamsForLog
    },

    resetGraphData () {
      this.$store.commit('classified_location/setGraphData', {
        data: [],
        datasetKeyName: 'numberOfParkedVehicleDistribution'
      })
      this.$store.commit('classified_location/setGraphData', {
        data: [],
        datasetKeyName: 'longHaulageNumberOfParkedVehicleDistribution'
      })
      this.$store.commit('classified_location/setGraphData', {
        data: [],
        datasetKeyName: 'homeDepotNumberOfParkedVehicleDistribution'
      })
      this.$store.commit('classified_location/setGraphData', {
        data: [],
        datasetKeyName: 'homeDepotNumberOfArrivalsDistribution'
      })
      this.$store.commit('classified_location/setGraphData', {
        data: [],
        datasetKeyName: 'homeDepotNumberOfDeparturesDistribution'
      })
      this.$store.commit('classified_location/setGraphData', {
        data: [],
        datasetKeyName: 'energyNeedDistribution'
      })
    },

    refreshAndFilter () {
      this.resetGraphData()
      this.filterLocation = true
      this.timeStart = Date.now()
      let reqParams = { snowflakequeryid: this.$store.getters.getQueryIdFor('classifiedLocationsCache'),
        hexresolution: 'H3_CELL_INDEX_' + this.$store.getters['classified_location/getResolutionToRequest'],
        minnumstops: this.$store.getters['classified_location/getMinNumStopsToRequest'],
        chassilist: this.formatedChassiRequestList
      }
      const requestParamsCountry = { ...reqParams,
        sqlfilename: 'FILTER_BY_COUNTRY',
        countrycodes: this.chosenCountries.map((countries) => { return countries['code'] }).join(',') }
      this.$logger.message(`{"refreshAndFilter":"${JSON.stringify(this.prepareLogReq(requestParamsCountry))}"}`, 'info')
      this.postQuery(requestParamsCountry)
    },
    postQuery (requestParams) {
      const results = this.getQueryResultsForClassifiedLocations('classifiedLocationsCache', requestParams)

      const requestParamsGeneralInfo = {
        hexresolution: 'H3_CELL_INDEX_' + this.$store.getters['classified_location/getResolutionToRequest'],
        sqlfilename: 'GET_GENERNAL_INFO',
        countrycodes: this.chosenCountries.map((countries) => { return countries['code'] }).join(',')
      }
      const resultsGeneralInfo = this.getQueryResultsForClassifiedLocations('classifiedLocationsCache', requestParamsGeneralInfo)

      Promise.all([results, resultsGeneralInfo])
        .then((response) => {
          const dataObj = this.$store.dispatch('classified_location/setLocationLayerAsync', response[0].data)
          const generalInfoDataObj = this.$store.dispatch(
            'classified_location/setGeneralInfoLayerAsync',
            response[1].data
          )
          this.$store.commit('setTriggerDeckRedraw', true)
          this.$emit('handle-on-data-response')
          this.$emit('handle-on-data-loaded')
          this.errorMessage = ''
          this.requestFailed = false
          this.hexagonResolution = this.hexagonResolutionToRequest
          this.minNumStops = this.minNumStopsToRequest
          return Promise.all([dataObj, generalInfoDataObj])
        })
        .catch(error => {
          this.$logger.message(`{"postQuery":"Error in postQuery: ${error}"}`, 'error')
          this.requestFailed = true
          try {
            this.errorMessage = 'Could not fetch hexagons due to: ' + error.response.data.message
          } catch {
            this.errorMessage = error
          }
        })
        .then(() => {
          if (this.filterLocation === true) {
            this.toggleFilters()
          }
          let endTime = Date.now()
          if (this.filterLocation) {
            this.$logger.message(`{"postQuery":{"message":"Filtering done.","time":"${(endTime - this.timeStart) / 1000} seconds."}}`, 'info')
          }
          if (this.refreshLocation) {
            this.$logger.message(`{"postQuery":{"message":"Resetting done","time":"${(endTime - this.timeStart) / 1000} seconds."}}`, 'info')
          }
          this.$emit('handle-on-filter')
          this.refreshLocation = false
          this.filterLocation = false
        })
    },
    showCountryDropdown () {
      this.selectByCountry = true
    },
    exportToCSV () {
      // Convert the table data to JSON
      // Access the table data and fields
      const tableData = this.$store.getters['classified_location/getHexagonClusters']
      const tableFields = this.fieldArray

      // Convert the table data to JSON
      const jsonData = tableData.map(item => {
        const row = {}
        for (const field of tableFields) {
          row[field.key] = item[field.key]
        }
        return row
      })

      // Convert JSON to CSV
      json2csv(jsonData, (err, csv) => {
        if (err) {
          console.error(err)
          return
        }

        // Create a Blob object for the CSV data
        const blob = new Blob([csv], { type: 'text/csv;charset=utf-8;' })

        // Use the `URL.createObjectURL` to create a download link
        const downloadLink = document.createElement('a')
        downloadLink.href = URL.createObjectURL(blob)

        // Set the file name
        downloadLink.download = 'location_features.csv'
        console.info('Downloading...')
        // Simulate a click on the link to trigger the download
        downloadLink.click()
      })
    },
    exportMarketSummaryToCSV () {
      // Fetch market summary
      let startTime = Date.now()
      let reqParams = {
        hexresolution: 'H3_CELL_INDEX_' + this.$store.getters['classified_location/getResolutionToRequest'],
        sqlfilename: 'GET_MARKET_SUMMARY'
      }
      this.$logger.message(`{"exportMarketSummaryToCSV":"${JSON.stringify(reqParams)}"}`, 'info')
      const results = this.getQueryResultsForClassifiedLocations('marketSummary', reqParams)
      Promise.all([results])
        .then((response) => {
          // export data
          // Convert the table data to JSON
          // Access the table data and fields
          const tableData = response[0].data
          const tableFields = this.marketSummaryFieldArray

          // Convert the table data to JSON
          const jsonData = tableData.map(item => {
            const row = {}
            for (const field of tableFields) {
              row[field.key] = item[field.key]
            }
            return row
          })

          // Convert JSON to CSV
          json2csv(jsonData, (err, csv) => {
            if (err) {
              console.error(err)
              return
            }

            // Create a Blob object for the CSV data
            const blob = new Blob([csv], { type: 'text/csv;charset=utf-8;' })

            // Use the `URL.createObjectURL` to create a download link
            const downloadLink = document.createElement('a')
            downloadLink.href = URL.createObjectURL(blob)

            // Set the file name
            downloadLink.download = 'market_summary.csv'
            console.info('Downloading...')
            // Simulate a click on the link to trigger the download
            downloadLink.click()
          })
        })
        .catch(error => {
          this.$logger.message(`{"exportMarketSummaryToCSV":"Error: ${error}"}`, 'error')
        })
        .finally(() => {
          let endTime = Date.now()
          this.$logger.message(`{"exportMarketSummaryToCSV":{"message":"Request locations finished","time":"${endTime - startTime}"}}`, 'info')
        })
    }
  },
  watch: {
    chassis: function () {
      this.$emit('update-chassis-filter', this.formatedChassiRequestList)
    }
  }
}
</script>

<style scoped>
#addCustomRangeButton {
  margin-top: 0.8rem;
  margin-bottom: 0.8rem;
  border: none;
  background: none;
}

#addCustomRangeButton span{
  padding-bottom: 7px;
  letter-spacing: 1px;
  font-size: 14px;
  padding-right: 15px;
  text-transform: uppercase;
}

.hover-underline-animation {
  position: relative;
  color: black;
  padding-bottom: 20px;
}

.hover-underline-animation:after {
  content: "";
  position: absolute;
  width: 100%;
  transform: scaleX(0);
  height: 2px;
  bottom: 0;
  left: 0;
  background-color: #000000;
  transform-origin: bottom right;
  transition: transform 0.25s ease-out;
}

#addCustomRangeButton:hover .hover-underline-animation:after {
  transform: scaleX(1);
  transform-origin: bottom left;
}

.addCustomRangeButtonIcon {
  height: 18px;
  width: 18px;
}

#addCustomRangeButton svg {
  transform: translateX(-8px);
  transition: all 0.3s ease;
}

#addCustomRangeButton:hover svg {
  transform: translateX(0);
}

#addCustomRangeButton:active svg {
  transform: scale(0.9);
}

#hourRangeButtons {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
}

#hourRangeButtonsVertical {
  display: inline-block;
  flex-direction: row;
  justify-content: space-evenly;
}

.hourOfDayCheckbox {
  display: flex;
  flex-direction: column;
  align-items: center;
  transition: all .2s ease-in-out;
}

.hourOfDayCheckbox:hover {
  transform: scale(1.3);
}

.hourOfDayCheckbox input {
  display: none!important;
}

.hourOfDayLabel {
  border-radius: 50%;
  border: 2px solid rgb(200, 200, 200);
  background-color: transparent;
  height: 36px;
  width: 36px;
  margin-bottom: 0px;
  line-height: 36px;
  text-align: center;
  cursor: pointer;
}

.hourOfDayCheckbox input[type=checkbox]:checked + label {
  border: 2px solid #41b883;
  background-color: transparent;
  color: #ffffff;
}

.hourOfDayCheckbox input[type=checkbox]:checked ~ b {
  color: #41b883;
}

#locationFilterPanel {
  display: flex;
  width: 100%;
  justify-content: flex-start;
  flex-direction: column;
}

.datePicker {
  display: flex;
  justify-content: center;
  margin-top: 4px;
}

#dateRange {
  padding-top: 0px;
  border-radius: 2px;
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
}

.card-body {
  padding: 3px;
}

@media only screen and (max-height: 669px) {
  #stopFilterContainer {
    height: 42vh;
  }
}

@media only screen and (min-height: 669px) and (max-height: 894px) {
  #stopFilterContainer {
    height: 53vh;
  }
}

@media only screen and (min-height: 895px) {
  #stopFilterContainer {
    height: 60vh;
  }
}

#stopFilterContainer {
  display: flex;
  justify-content: center;
}

#filterContainer {
  display: flex;
  justify-content: center;
  margin-top: 20px;
}

#filterActivityContainer {
  display: flex;
  justify-content: center;
  margin-top: 0px;
}

.cardFilterContainer {
  width: 75%;
  margin-left: 12.5%;
  border: 0.5px solid darkgray;
  margin-top: 1rem;
}

.stopFilter {
  display: flex;
  color: #212529;
  padding: 0px;
  width: 80%;
  margin-left: 10%;
  text-align: left;
}

.filterInfo {
  margin-left: 10%;
  padding: 0px;
  margin: 1px;
}

.stopActivityFilter {
  display: flex;
  color: #212529;
  padding: 0px;
  width: 80%;
  margin-left: 22%;
  text-align: left;
}

.labelFilter {
  font-size:small;
  color:black;
  margin-bottom:0px;
  position: relative;
  top: -0.8em;
  background-color: white;
}

#locationFilterButtonContainer {
  display: flex;
  justify-content: flex-end;
  margin-right: 15%;
  margin-top: 5px;
  position: absolute;
  bottom: 0;
  margin-bottom: 30px;
  width: 95%;
}

#onLocationFilteringError {
  margin-top: 10px;
  width: 100%;
  color: white;
  text-align: center;
  font-size: small;
}

#energy-filter {
  text-align: left;
  columns: 3 auto ;
}

.post-filter {
  height: 30px;
  font-size: small;
}

.dayOfWeekCheckbox {
  display: inline;
}

.dayOfWeekCheckbox input {
  display: none!important;
}

.weekdayLabel {
  display: inline-block;
  border-radius: 50%;
  border: 2px solid rgb(200, 200, 200);
  background-color: transparent;
  height: 30px;
  width: 30px;
  margin-right: 0.2vw;
  line-height: 30px;
  text-align: center;
  cursor: pointer;
  transition: all .2s ease-in-out;
}

.weekdayLabel:hover {
  transform: scale(1.2);
}

.dayOfWeekCheckbox input[type=checkbox]:checked + label {
  background: #41b883;
  color: #ffffff;
}

.post-filter {
  margin-top: 20px;
  margin-left: 10px;
}

</style>

<style>
#hexagonResolutionElements .number-input__button--plus {
  background-image: url('../../assets/zoom_in.svg');
  background-repeat: no-repeat;
  background-position: 50% 50%;
  height: 40px;
  width: 40px;
  border: none;
}

#hexagonResolutionElements .number-input__button--minus {
  background-image: url('../../assets/zoom_out.svg');
  background-repeat: no-repeat;
  background-position: 50% 50%;
  height: 40px;
  width: 40px;
  border: none;
}

#hexagonResolutionElements .label {
  /* ... */
  /* Show an ellipsis if the text takes more than one line */
  margin-left: 5px;
  text-overflow: ellipsis;
  white-space: nowrap;
  margin-right: 15px;
}

#hexagonResolutionElements .number-input__button--plus::after,#hexagonResolutionElements .number-input__button--plus::before,
#hexagonResolutionElements .number-input__button--minus::after,#hexagonResolutionElements .number-input__button--minus::before {
  display: none;
}

#byCountry {
  height: 25vh;
}

#byActivity {
  height: 25vh;
}

::-webkit-scrollbar {
  width: 8px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #888;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}

</style>
