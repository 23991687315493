<template>
  <div id="app">
    <the-header/>
    <router-view/>
  </div>
</template>

<script>
import TheHeader from '@/components/TheHeader.vue'
export default {
  name: 'app',
  data () {
    return {
      activeUser: null,
      errorMsg: null,
      appVersion: null
    }
  },
  watch: {
    // every time a route is changed refresh the activeUser
    $route () {
      this.errorMsg = {}
    }
  },
  async created () {
    this.$logger.message(`{"created":{"browser":"${navigator.userAgent}"},{"resolution":"${window.screen.availHeight}x${window.screen.availWidth}"},{"environment":"${process.env.VUE_APP_API_CLIENT}"}}`, 'info')
    import('../package.json').then(data => {
      this.appVersion = data.version
    })
  },
  mounted () {
    document.addEventListener('mouseup', e => {
      let name = e.target.className.baseVal ?? e.target.className
      if (e.target.className === 'mapboxgl-canvas') return
      this.$logger.message(`{"mounted":{"event":"${e.type}"}, {"className":"${name}", "id":"${e.target.id}"}}`, 'info')
    })
  },
  errorCaptured (err) {
    this.errorMsg = JSON.stringify(err)
    this.$logger.message(`{"errorCaptured":"App.vue errorCaptured: ${err}"}`, 'error')
    return false
  },
  components: {
    TheHeader
  }
}
</script>
<style>
#app {
  font-family: 'Avenir', Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}
#nav {
  padding: 30px;
}

#nav a {
  font-weight: bold;
  color: #2c3e50;
}

#nav a.router-link-exact-active {
  color: #42b983;
}
</style>
