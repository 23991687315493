<template>
  <div class="top-nav">
    <b-navbar toggleable="lg">
      <b-navbar-brand to="/">
        <span class="scania symbol"></span>
        <img :src=logo style="margin-top: 5px; margin-left: 10px; height: 46px;">
      </b-navbar-brand>
      <h5 v-if="this.$store.getters.getAppConfigFlag('useStagingStack')" class="current-stack-text">Running in Staging environment</h5>
      <b-collapse id="nav-collapse" is-nav>
        <b-navbar-nav v-if="isSignedIn" class="ml-auto main-navbar">
        </b-navbar-nav>
      </b-collapse>
      <loginComponent v-if="!signingIn" class="ml-auto"/>
    </b-navbar>
  </div>
</template>

<script>
import LoginComponent from './LoginComponent'

export default {
  name: 'TheHeader',
  components: {
    LoginComponent
  },
  data () {
    return {
      signingIn: false
    }
  },
  computed: {
    isSignedIn () {
      return this.$store.getters.isSignedIn
    },
    logo () {
      return this.$store.getters.getUserSelection === 'classified_location'
        ? require('@/assets/depot_analytics_5.jpeg') // Path to the asset when signingIn is true
        : require('@/assets/sweete.png') // Path to the default asset
    }
  }
}
</script>

<style scoped>
.scania .symbol, .scania.symbol {
    vertical-align: middle;
    background-image: url('https://static.scania.com/resources/logotype/scania/scania-symbol.png');
    background-repeat: no-repeat;
    background-size: 48px 46px;
    width: 48px;
    height: 46px;
    float: left;
}
.main-navbar {
  font-size: 1.2rem;
}
.top-nav {
  border-bottom: 1px solid #0000001a
}
a.router-link-exact-active {
  color: rgba(0, 0, 0, 0.7) !important;
}

.current-stack-text {
  margin-top: 10px;
  left: 0;
  right: 0;
  position: absolute;
  z-index: 1;
  color: blue;
  font-family: 'Montserrat', sans-serif;
  font-size: 13px;
}
</style>
