<template>
  <g>
    <g v-if="alignment==='horizontal'" :transform="`translate(${margin.left} ${margin.top})`">
      <g v-for="(item, i) in legendData" :key="i+'inLegend'"
          :transform="'translate('+ (x*(i+1)) +', ' + y + ')'">
        <rect :transform="`translate(-${rectSize}, -${rectSize-3})`" :width="rectSize" :height="rectSize"
              :fill="marker[i]" :class="{toggleMarker: enableToggle}"
              :rx="4"
              :ry="4"
        >
        </rect>
        <text :style="{ 'font-size': fontSize }"
              :class="{toggle: enableToggle}"
              :id="item.colour">
          {{ item.domain }}
        </text>
      </g>
    </g>
    <g v-if="alignment==='vertical'" :transform="`translate(${padding} ${margin.top})`">
      <g v-for="(item, i) in legendData" :key="i+'inLegend'"
          :transform="'translate('+ x +', ' + (y*(i+1)) + ')'">
        <rect :transform="`translate(-${rectSize}, -${rectSize-3})`"
              :width="rectSize" :height="rectSize"
              :fill="marker[i]" :class="{toggleMarker: enableToggle}"
              :rx="4"
              :ry="4"
        >
        </rect>
        <text :style="{ 'font-size': fontSize }"
              :class="{toggle: enableToggle}"
              :id="item.colour">
          {{ item.domain }}
        </text>
      </g>
    </g>
  </g>
</template>

<script>
export default {
  name: 'ColourLegend',
  props: {
    colourScale: {
      type: Function,
      default: function () {
        return { }
      }
    },
    alignment: {
      type: String,
      default: 'horizontal'
    },
    enableToggle: {
      type: Boolean,
      default: false
    },
    scaleType: {
      type: String,
      default: 'threshold'
    },
    margin: {
      type: Object,
      default: function () {
        return { top: 20, right: 20, bottom: 20, left: 20 }
      }
    },
    x: {
      type: Number
    },
    y: {
      type: Number
    },
    rectSize: {
      type: Number,
      default: 15
    },
    fontSize: {
      type: String,
      default: '0.9rem'
    },
    padding: {
      type: Number,
      default: 0
    }
  },
  computed: {
    itemAlignment () {
      return {
        display: this.alignment === 'horizontal' ? 'inline-block' : 'block'
      }
    },
    legendData () {
      let colourObject = []

      switch (this.scaleType) {
        case 'threshold': // iterate over range/colours
          for (let i = 0; i < this.colourScale.range().length; i++) {
            colourObject.push({
              colour: this.colourScale.range()[i],
              domain: this.colourScale.invertExtent(this.colourScale.range()[i]).map(v => v.toFixed(1)).join('-')
            })
          }
          break
        case 'ordinal': // iterate over domain/labels/categories
          for (let i = 0; i < this.colourScale.domain().length; i++) {
            colourObject.push({ colour: this.colourScale.range()[i], domain: this.colourScale.domain()[i] })
          }
          break
      }

      return colourObject
    },
    marker () {
      if (this.enableToggle) {
        return this.legendData.map(item => (
          item.colour
        ))
      } else if (!this.enableToggle) {
        return this.legendData.map(item => (
          item.colour
        ))
      }
      return {}
    }
  }
}
</script>

<style scoped>
ul {
    list-style-type: none;
    text-align: left;
    font-size: 0.7rem;
    margin: 5px 0 5px 5px;
    padding: 0 0 0 0;
}
li {
    margin-right: 10px;
}
span {
    display: inline-block;
    width: 12px;
    height: 12px;
    margin-right: 3px;
}
div {
    display: flex;
    align-items: center;
}
p {
    margin: 0;
}
.legendItem span {
    border: solid 1px transparent;
    transition: border 0.2s;
    cursor: pointer;
}
.legendItem:hover span {
    border: solid 1px #2141c1;
    transition: border 0.2s;
}
.toggle {
    cursor: pointer;
    color: #4a4a4a;
    transition: color 0.2s;
}
.toggle:hover {
    color: black;
    transition: color 0.2s;
}
</style>
