<template>
  <div id="locationFilterPanel">
    <div id="layerPanelContainer">
      <div id="hexagonResolutionSelector">
        <div id="hexagonResolutionElements">
          <number-input v-model="hexagonResolutionToRequest" :min="2" :max="13" inline center controls></number-input>
          <label style="width:100%;font-size:small;color:#9BADBF;">Current zoom is {{ hexagonResolution }}</label>
        </div>
      </div>
    </div>
    <div id="stopFilterContainer" >
      <div style="overflow-y:auto ;width:75%">
      <div id="filterAccordion" role="tablist" class="accordion" style="width:100% ;justify-content: center;">
        <b-card no-body >
          <b-card-header header-tag="header"  role="tab">
            <b-button block v-b-toggle.stopFilterAccordion style="background: #130f40" v-if="!selectedStopFilter" >Stop Filters</b-button>
            <b-button block v-b-toggle.stopFilterAccordion style="background: #28a745" v-if="selectedStopFilter" >Stop Filters</b-button>
          </b-card-header>
          <b-collapse id="stopFilterAccordion" accordion="filterAccordion" role="tabpanel" >
            <b-card-body >
              <b></b>
               <div id="dateRange" class="cardFilterContainer" >
                <label class="labelFilter">Date Range</label>
                <div class="datePicker">
                  <vc-date-picker v-model="startDate">
                    <template v-slot="{ inputValue, togglePopover }">
                      <div class="flex items-center">
                        <button
                          class="p-2 bg-blue-100 border border-blue-200 hover:bg-blue-200 text-blue-600 rounded-l
                              focus:bg-blue-500 focus:text-white focus:border-blue-500 focus:outline-none"
                          @click="togglePopover()"
                        >
                          <v-icon name="calendar"/>
                        </button>
                        <input
                          :value="inputValue"
                          class="bg-white text-gray-700 w-full py-1 px-2 appearance-none border rounded-r focus:outline-none focus:border-blue-500"
                          readonly
                        />
                      </div>
                    </template>
                  </vc-date-picker>
                </div>
                 <div class="labelDiv" >
                   <label   @click="setDateToMin()" class="inputFieldLabelsClickable minLabel">
                     Min: {{ this.$store.getters.getMinDate }}
                   </label>
                 </div>
                  <div class="datePicker">
                  <vc-date-picker v-model="endDate">
                    <template v-slot="{ inputValue, togglePopover }">
                      <div class="flex items-center">
                        <button
                          class="p-2 bg-blue-100 border border-blue-200 hover:bg-blue-200 text-blue-600 rounded-l
                            focus:bg-blue-500 focus:text-white focus:border-blue-500 focus:outline-none"
                          @click="togglePopover()"
                        >
                          <v-icon name="calendar"/>
                        </button>
                        <input
                          :value="inputValue"
                          class="bg-white text-gray-700 w-full py-1 px-2 appearance-none border rounded-r focus:outline-none focus:border-blue-500"
                          readonly
                        />
                      </div>
                    </template>
                  </vc-date-picker>
                </div>
                 <div class="labelDiv" >
                   <label   @click="setDateToMax()" class="inputFieldLabelsClickable minLabel">
                     Max: {{ this.$store.getters.getMaxDate }}
                   </label>
                 </div>
               </div>
              <b></b>
              <div class="cardFilterContainer">
                <label class="labelFilter">Stops Duration</label>
                <div class="stopFilter" >
                  <rangeInput :minRange="minDurationForRange"
                              :maxRange="maxDurationForRange"
                              range="location/getSelectedMinMaxDuration"
                              minStateFunctionString="location/setSelectedMinDuration"
                              maxStateFunctionString="location/setSelectedMaxDuration"
                              setStateFunctionString="location/setSelectedMinMaxDuration"
                              filterKey="stop_duration"
                              filterName=""
                              unit="s">
                  </rangeInput>
                </div>
              </div>
              <b></b>
              <div class="cardFilterContainer">
                <label class="labelFilter">Days of Week</label>
                <div class="dayOfWeekSelection">
                  <div class="dayOfWeekCheckbox"
                       v-for="(dayName, dayNumber) in dayOfWeek"
                       :key="'weekday-' + dayName">
                    <input class="weekday"
                           :id="'weekday-' + dayName"
                           v-model="selectedWeekday"
                           :value="(dayNumber+1)%7"
                           type="checkbox"/>
                    <label class="weekdayLabel"
                           :for="'weekday-' + dayName">
                      {{dayName}}
                    </label>
                  </div>
                </div>
              </div>
              <b></b>
              <div class="cardFilterContainer">
                <label class="labelFilter">Hour of Day</label>
                <div id="hourRangeButtons" v-if="!customRangesActive">
                  <div class="hourOfDayCheckbox"
                       v-for="(hourBucket, bucketNumber) in hourOfDay"
                       :key="'hour-bucket-' + bucketNumber">
                    <input class="weekday"
                           :id="'hour-' + hourBucket[0]"
                           v-model="selectedHour"
                           :value="hourBucket[0]"
                           type="checkbox"/>
                    <label class="hourOfDayLabel"
                           :for="'hour-' + hourBucket[0]">
                      <img :src="getImageUrl(hourBucket[1])" style="width: 24px;height: 24px;"/>
                    </label>
                    <b class="hourLabel">
                      {{hourBucket[0]}}
                    </b>
                  </div>
                </div>
                <div id="hourRangeButtons" v-else>
                    <div id="hourRangeButtonsVertical">
                    <min-max-input v-for="inputItem in customHourRanges"
                                   :key="'custom-hour-input-' + inputItem" @handle-item-removed="removeCustomRange"></min-max-input>
                    </div>
                  </div>
                <button id="addCustomRangeButton"
                        @click="addCustomRange">
                  <span class="hover-underline-animation">Add range</span>
                  <font-awesome-icon icon="fa-solid fa-add"
                                     class="addCustomRangeButtonIcon"/>
                </button>
              </div>
              <b></b>
              <div class="cardFilterContainer" >
                <label class="labelFilter">Activities</label>
                <div id="filterContainer" style="display: inherit">
                  <label class="labelFilter">Time based constraint: weight data available from January 2023</label>
                  <div class="stopFilter" >
                    <b-form-group class="stopFilter">
                      <b-form-checkbox-group
                        id="activity-filter"
                        v-model=selectedActivity
                        :options="activityFilter"
                        name="activity-filter"
                        switches>
                      </b-form-checkbox-group>
                    </b-form-group>
                  </div>
                </div>
              </div>
            </b-card-body>
          </b-collapse>
        </b-card>
        <b-card no-body >
          <b-card-header header-tag="header" role="tab">
            <b-button block v-b-toggle.edgeFilterRangeAccordion style="background: #130f40" v-if="!selectedEdgeFilter">Edge Filters</b-button>
            <b-button block v-b-toggle.edgeFilterRangeAccordion style="background: #28a745" v-if="selectedEdgeFilter">Edge Filters</b-button>
          </b-card-header>
          <b-collapse id="edgeFilterRangeAccordion" accordion="filterAccordion" role="tabpanel">
            <b-card-body>
              <b><label style="width:100%;font-size:small;color:black; margin-bottom:0px">Ingoing Distance (km)</label></b>
              <div class="cardFilterContainer">
                <div class="stopFilter" >
                  <rangeInput :minRange="inOdoMinForRange"
                              :maxRange="inOdoMaxForRange"
                              range="location/getInOdoSelectedMinMax"
                              minStateFunctionString="location/setInOdoSelectedMin"
                              maxStateFunctionString="location/setInOdoSelectedMax"
                              setStateFunctionString="location/setInOdoSelectedMinMax"
                              filterKey="ingoing_distance"
                              filterName=""
                              unit="km">
                  </rangeInput>
                </div>
              </div>
              <b><label style="width:100%;font-size:small;color:black; margin-bottom:0px">Outgoing Distance (km)</label></b>
              <div class="cardFilterContainer">
                <div class="stopFilter" >
                  <rangeInput :minRange="outOdoMinForRange"
                              :maxRange="outOdoMaxForRange"
                              range="location/getOutOdoSelectedMinMax"
                              minStateFunctionString="location/setOutOdoSelectedMin"
                              maxStateFunctionString="location/setOutOdoSelectedMax"
                              setStateFunctionString="location/setOutOdoSelectedMinMax"
                              filterKey="outgoing_distance"
                              filterName=""
                              unit="km">
                  </rangeInput>
                </div>
              </div>
              <b><label style="width:100%;font-size:small;color:black; margin-bottom:0px">Ingoing Trip Duration (s)</label></b>
              <div class="cardFilterContainer">
                <div class="stopFilter" >
                  <rangeInput :minRange="inDurMinForRange"
                              :maxRange="inDurMaxForRange"
                              range="location/getInDurSelectedMinMax"
                              minStateFunctionString="location/setInDurSelectedMin"
                              maxStateFunctionString="location/setInDurSelectedMax"
                              setStateFunctionString="location/setInDurSelectedMinMax"
                              filterKey="ingoing_duration"
                              filterName=""
                              unit="s">
                  </rangeInput>
                </div>
              </div>
              <b><label style="width:100%;font-size:small;color:black; margin-bottom:0px">Outgoing Trip Duration (s)</label></b>
              <div class="cardFilterContainer">
                <div class="stopFilter" >
                  <rangeInput :minRange="outDurMinForRange"
                              :maxRange="outDurMaxForRange"
                              range="location/getOutDurSelectedMinMax"
                              minStateFunctionString="location/setOutDurSelectedMin"
                              maxStateFunctionString="location/setOutDurSelectedMax"
                              setStateFunctionString="location/setOutDurSelectedMinMax"
                              filterKey="outgoing_duration"
                              filterName=""
                              unit="s">
                  </rangeInput>
                </div>
              </div>
            </b-card-body>
          </b-collapse>
        </b-card>
        <b-card no-body>
          <b-card-header header-tag="header" role="tab">
            <b-button block v-b-toggle.vehicleFilterAccordion style="background: #130f40" v-if="!selectedVehicleFilter">Vehicle Filters</b-button>
            <b-button block v-b-toggle.vehicleFilterAccordion style="background: #28a745" v-if="selectedVehicleFilter">Vehicle Filters</b-button>
          </b-card-header>
          <b-collapse id="vehicleFilterAccordion" accordion="filterAccordion" role="tabpanel">
            <b-card-body>
              <b><label style="width:100%;font-size:small;color:black; margin-bottom:0px">Vehicle Type</label></b>
              <div id="filterContainer">
                <div class="stopFilter">
                  <b-form-group class="stopFilter">
                    <b-form-checkbox-group
                      id="type-filter"
                      v-model=selectedProduct
                      :options="productClassFilter"
                      name="type-filter"
                      switches>
                    </b-form-checkbox-group>
                  </b-form-group>
                </div>
              </div>
              <b><label style="width:100%;font-size:small;color:black; margin-bottom:0px">Energy Type</label></b>
              <div id="filterContainer">
                <div class="stopFilter">
                  <b-form-group class="stopFilter" >
                    <b-form-checkbox-group
                      id="energy-filter"
                      v-model="selectedEnergy"
                      :options="energyTypeFilter"
                      name="energy-filter"
                      switches
                      stacked>
                    </b-form-checkbox-group>
                  </b-form-group>
                </div>
              </div>
              <b><label style="width:100%;font-size:small;color:black; margin-bottom:0px">Chassis Adaptation</label></b>
              <div id="filterContainer" >
                <div class="stopFilter">
                  <b-form-group class="stopFilter" >
                    <b-form-checkbox-group
                      id="chassis-adaptation-filter"
                      v-model="selectedChassisAdaptation"
                      :options="chassisAdaptationFilter"
                      name="chassis-adaptation-filter"
                      switches>
                    </b-form-checkbox-group>
                  </b-form-group>
                </div>
              </div>
              <b><label style="width:100%;font-size:small;color:black; margin-bottom:0px">Cab Type</label></b>
              <div id="filterContainer" >
                <div class="stopFilter">
                  <b-form-group class="stopFilter" >
                    <b-form-checkbox-group
                      id="cab-type-filter"
                      v-model="selectedCabType"
                      :options="cabTypeFilter"
                      name="cab-type-filter"
                      switches>
                    </b-form-checkbox-group>
                  </b-form-group>
                </div>
              </div>
              <b></b>
              <div class="cardFilterContainer">
                <div class="stopFilter" style=" padding-top: 10px; margin-left: 23%;">
                  <b-form-group class="stopFilter">
                  <b-form-checkbox-group
                  id="enable-annualmileage-filter"
                  v-model=enableAnnualMileage
                  :options="annualMileageFilter"
                  name="annualmileage-filter"
                  switches>
                </b-form-checkbox-group>
                  </b-form-group>
                </div>
                <b><label class="filterInfo" v-if="this.enableAnnualMileage.length > 0">Not available for vehicles newer than 6 months and these vehicles will be filtered</label></b>
                <div class="stopFilter" v-if="this.enableAnnualMileage.length > 0" >
                  <rangeInput :minRange="minAnnualMileageForRange"
                              :maxRange="maxAnnualMileageForRange"
                              range="location/getSelectedMinMaxAnnualMileage"
                              minStateFunctionString="location/setSelectedMinAnnualMileage"
                              maxStateFunctionString="location/setSelectedMaxAnnualMileage"
                              setStateFunctionString="location/setSelectedMinMaxAnnualMileage"
                              filterKey="annual_mileage"
                              filterName=""
                              unit="km">
                  </rangeInput>
                </div>
              </div>
            </b-card-body>
          </b-collapse>
        </b-card>
        <b-card no-body>
          <b-card-header header-tag="header" role="tab">
            <b-button block v-b-toggle.locationFilterAccordion style="background: #130f40" v-if="!selectedLocationFilter">Location Filters</b-button>
            <b-button block v-b-toggle.locationFilterAccordion style="background: #28a745" v-if="selectedLocationFilter">Location Filters</b-button>
          </b-card-header>
          <b-collapse id="locationFilterAccordion" accordion="filterAccordion" role="tabpanel">
            <b-card-body>
              <div id="selectedHexagons" v-if="filteredHexes && this.$store.getters.getAppConfigFlag('isTestingWithoutPreCache')">
                <p class="notification">
                  You've filtered locations at resolution {{ selectedRes }}. Do you want to continue to filter on the selected locations/hexagons?
                  <span class="selected" v-if="selected" @click="toggleHexagonSelected()">Yes</span>
                  <span class="selected" v-if="!selected" @click="toggleHexagonSelected()">No</span>
                </p>
              </div>
              <div id="minSelectedVehicles" >
                <label style="width:100%;font-size:small;color:#9BADBF;">Min number of vehicles:</label>
                <number-input v-model="minVehiclesToRequest" :min="minimumVehicleCount" inline center controls></number-input>
              </div>
              <div id="minSelectedCustomers" >
                <label style="width:100%;font-size:small;color:#9BADBF;">Min number of customers:</label>
                <number-input v-model="minCustomersToRequest" :min="1" inline center controls></number-input>
              </div>
              <div class="cardFilterContainer" >
                <div class="stopFilter" style=" padding-top: 10px;place-content: center;">
                  <b-form-group class="stopFilter">
                    <b-form-checkbox-group
                      id="enable-toplocation-filter"
                      v-model=enableTopLocation
                      :options="topLocationFilter"
                      name="toplocation-filter"
                      switches>
                    </b-form-checkbox-group>
                  </b-form-group>
                </div>
                <div  class="stopFilter" v-if="this.enableTopLocation.length > 0" style="place-content: center;padding-bottom: 10px;">
                  <number-input v-model="minLocationsToRequest"  :min="0" inline center controls></number-input>
                </div>
              </div>
              <div id="locationTable" v-if="this.$store.getters.getAppConfigFlag('isTestingTableMethods')">
                <location-table :fields="fieldArray"
                                @selected-locations-changed="selectLocations">
                </location-table>
              </div>
            </b-card-body>
          </b-collapse>
        </b-card>
      </div>
      </div>
    </div>
    <div id="locationFilterButtonContainer">
      <div id="locationFilterButton">
        <b-button class="post-filter" variant="success" id="rangeLocationFilterButton"
                  @click="refreshAndFilter"
                  :disabled='refreshLocation || filterLocation'>
          <b-spinner v-if="filterLocation" small type="grow"></b-spinner>
          Refresh
        </b-button>
        <b-button class="post-filter" variant="outline-warning" id="resetAndRefreshLocation"
                  @click="resetAndRefresh"
                  v-if="!this.$store.getters.getAppConfigFlag('isTestingWithoutPreCache')"
                  :disabled='refreshLocation || filterLocation'>
          <b-spinner v-if="refreshLocation" small type="grow"></b-spinner>
          Reset
        </b-button>
      </div>
    </div>
    <div class="modal-alert" v-if="requestFailed" id="onLocationFilteringError">
      <div class="modal-alert-content" >
        <div class="modal-alert-header">
          <span @click="closeError()" class="close"> &times;</span>
          <h2>Error</h2>
        </div>
        <div class="modal-alert-body">
          <p> Request failed. Error - {{ errorMessage }} </p>
        </div>
        <div class="modal-alert-footer">
        </div>
      </div>
    </div>

</div>
</template>
<script>
import LocationTable from '@/components/LocationTable'
import MinMaxInput from '@/components/MinMaxInput'
import RangeInput from '@/components/RangeInput.vue'
import NumberInput from '@chenfengyuan/vue-number-input'
import * as lz from 'lz-string'
import snowflake from '../../mixins/snowflake'

export default {
  name: 'FilterPanel',
  mixins: [snowflake],
  components: { MinMaxInput, RangeInput, NumberInput, LocationTable },
  timeStart: 0,
  timeStartpostQuery: 0,
  data () {
    return {
      selected: true,
      selectedStopFilter: false,
      selectedVehicleFilter: false,
      selectedEdgeFilter: false,
      selectedLocationFilter: false,
      sliderCheck: false,
      defaultMinDate: '',
      defaultMaxDate: '',
      productTypeCount: null,
      chassisAdaptationCount: null,
      cabTypeCount: null,
      activityTypeCount: null,
      energyTypeCount: null,
      filterLocation: false,
      refreshLocation: false,
      requestFailed: false,
      errorMessage: false,
      activityFilter: [
        { text: 'Weight Increase', default_text: 'Weight Increase', value: 'weight_increase', id: 0, checked: true },
        { text: 'Weight Decrease', default_text: 'Weight Decrease', value: 'weight_decrease', id: 1, checked: true },
        { text: 'Weight Similar', default_text: 'Weight Similar', value: 'weight_similar', id: 2, checked: true },
        { text: 'Weight Unclear', default_text: 'Weight Unclear', value: 'weight_unclear', id: 3, checked: true }
      ],
      selectedActivity: [],
      annualMileageFilter: [
        { text: 'Annual mileage', default_text: 'Annual mileage', value: 'true', id: 0, checked: false }
      ],
      enableAnnualMileage: [],
      enableTopLocation: [],
      topLocationFilter: [
        { text: 'Top locations in number of stops', default_text: 'Top locations in number of stops', value: 'true', id: 0, checked: false }
      ],
      productClassFilter: [
        { text: 'Bus', default_text: 'Bus', value: 'bus', id: 0, checked: true },
        { text: 'Truck', default_text: 'Truck', value: 'truck', id: 1, checked: true }
      ],
      selectedProduct: [],
      energyTypeFilter: [
        { text: 'Diesel', default_text: 'Diesel', value: 'diesel', checked: true },
        { text: 'Ethanol', default_text: 'Ethanol', value: 'ethanol', checked: true },
        { text: 'BEV', default_text: 'BEV', value: 'bev', checked: true },
        { text: 'CNG', default_text: 'CNG', value: 'cng', checked: true },
        { text: 'LNG', default_text: 'LNG', value: 'lng', checked: true }
      ],
      selectedEnergy: [],
      chassisAdaptationFilter: [
        { text: 'Basic', default_text: 'Basic', value: 'basic', checked: true },
        { text: 'Articulated', default_text: 'Articulated', value: 'articulated', checked: true },
        { text: 'Double Decker', default_text: 'Double Decker', value: 'double decker', checked: true }
      ],
      selectedChassisAdaptation: [],
      cabTypeFilter: [
        { text: 'CP', default_text: 'CP', value: 'CP', checked: true },
        { text: 'CR', default_text: 'CR', value: 'CR', checked: true },
        { text: 'CS', default_text: 'CS', value: 'CS', checked: true },
        { text: 'CG', default_text: 'CG', value: 'CG', checked: true },
        { text: 'CL', default_text: 'CL', value: 'CL', checked: true },
        { text: 'CT', default_text: 'CT', value: 'CT', checked: true }
      ],
      selectedCabType: [],
      dayOfWeek: ['Mo', 'Tu', 'We', 'Th', 'Fr', 'Sa', 'Su'],
      hourOfDay: [
        ['6-11', 'morning.png'],
        ['11-14', 'noon.png'],
        ['14-18', 'evening.png'],
        ['18-22', 'lateevening.png'],
        ['22-6', 'night.png']
      ],
      selectedHour: [],
      selectedWeekday: [],
      buttons: {
        location: { caption: 'Location', switchVariable: 'location', disabled: false, selected: true }
      },
      fieldArray: [
        { key: 'selected', sortable: true },
        { key: 'hexagons', sortable: true, label: 'Location id' },
        { key: 'no_stops', sortable: true, label: 'Stop count' },
        { key: 'vehicles', sortable: true, label: 'Unique vehicles' },
        { key: 'median_duration', sortable: true, label: 'Median stop duration' }
      ],
      customHourRanges: 0,
      selectedRes: null
    }
  },
  mounted () {
    this.selectedProduct = ['truck', 'bus']
    this.selectedActivity = [ 'weight_increase', 'weight_decrease', 'weight_similar', 'weight_unclear' ]
    this.selectedEnergy = ['diesel', 'ethanol', 'bev', 'cng', 'lng']
    this.selectedCabType = ['CP', 'CR', 'CS', 'CG', 'CL', 'CT']
    this.selectedChassisAdaptation = ['basic', 'articulated', 'double decker']
    this.enableAnnualMileage = []
    this.enableTopLocation = []
    this.productTypeCount = this.selectedProduct.length
    this.cabTypeCount = this.selectedCabType.length
    this.chassisAdaptationCount = this.selectedChassisAdaptation.length
    this.energyTypeCount = this.selectedEnergy.length
    this.activityTypeCount = this.selectedActivity.length
    this.defaultMinDate = this.$store.getters['location/getMinDate']
    this.defaultMaxDate = this.$store.getters['location/getMaxDate']
    this.defaultExportParams = { hexresolution: 'H3_CELL_INDEX_' + this.$store.getters['location/getResolutionToRequest'],
      mindate: this.$store.getters['location/getMinDate'],
      maxdate: this.$store.getters['location/getMaxDate']
    }
    this.$store.commit('setRequestedFilters', [this.defaultExportParams])
  },
  computed: {
    filteredHexes () {
      return Object.keys(this.$store.getters['location/getFilteredHexes']).length > 0
    },
    minimumVehicleCount () {
      if (this.$store.getters.getAppConfigFlag('isAllowedToSeeOnlyOneVehicle')) {
        return 1
      }
      return 5
    },
    customRangesActive () {
      if (this.customHourRanges === 0) return false
      return true
    },
    hexagonResolutionToRequest: {
      get () {
        return this.$store.getters['location/getResolutionToRequest']
      },
      set (value) {
        this.$store.commit('location/setResolutionToRequest', parseInt(value))
      }
    },
    hexagonResolution () {
      return this.$store.getters['location/getHexagonResolution']
    },
    startDate: {
      get () {
        return new Date(this.$store.getters['location/getMinDate'])
      },
      set (value) {
        this.$store.commit('location/setMinDate', value)
      }
    },
    endDate: {
      get () {
        return new Date(this.$store.getters['location/getMaxDate'])
      },
      set (value) {
        this.$store.commit('location/setMaxDate', value)
      }
    },
    maxDurationForRange () {
      return this.$store.getters['location/getMinMaxDuration'][1]
    },
    minDurationForRange () {
      return this.$store.getters['location/getMinMaxDuration'][0]
    },
    maxAnnualMileageForRange () {
      return this.$store.getters['location/getMinMaxAnnualMileage'][1]
    },
    minAnnualMileageForRange () {
      return this.$store.getters['location/getMinMaxAnnualMileage'][0]
    },
    inOdoMaxForRange () {
      return this.$store.getters['location/getInOdoMinMax'][1]
    },
    inOdoMinForRange () {
      return this.$store.getters['location/getInOdoMinMax'][0]
    },
    outOdoMaxForRange () {
      return this.$store.getters['location/getOutOdoMinMax'][1]
    },
    outOdoMinForRange () {
      return this.$store.getters['location/getOutOdoMinMax'][0]
    },
    inDurMaxForRange () {
      return this.$store.getters['location/getInDurMinMax'][1]
    },
    inDurMinForRange () {
      return this.$store.getters['location/getInDurMinMax'][0]
    },
    outDurMaxForRange () {
      return this.$store.getters['location/getOutDurMinMax'][1]
    },
    outDurMinForRange () {
      return this.$store.getters['location/getOutDurMinMax'][0]
    },
    minVehiclesToRequest: {
      get () {
        return this.$store.getters['location/getSelectedMinVehicles']
      },
      set (value) {
        this.$store.commit('location/setSelectedMinVehicles', parseInt(value))
      }
    },
    minLocationsToRequest: {
      get () {
        return this.$store.getters['location/getSelectedMinLocations']
      },
      set (value) {
        this.$store.commit('location/setSelectedMinLocations', parseInt(value))
      }
    },
    minCustomersToRequest: {
      get () {
        return this.$store.getters['location/getSelectedMinCustomers']
      },
      set (value) {
        this.$store.commit('location/setSelectedMinCustomers', parseInt(value))
      }
    }
  },
  methods: {
    toggleHexagonSelected () {
      this.selected ? this.selected = false : this.selected = true
    },
    selectLocations (selectedHexes) {
      this.$emit('update-selected-hexes', selectedHexes)
    },
    getImageUrl (imageSrc) {
      return require('../../assets/' + imageSrc)
    },
    setDateToMin () {
      this.$store.commit('location/setMinDate', this.$store.getters.getMinDate)
    },
    closeError () {
      this.requestFailed = false
    },
    setDateToMax () {
      this.$store.commit('location/setMaxDate', this.$store.getters.getMaxDate)
    },
    addCustomRange () {
      this.customHourRanges += 1
      if (this.customHourRanges === 1) {
        this.selectedHour = []
      }
    },
    removeCustomRange () {
      if (this.customHourRanges > 0) {
        this.customHourRanges -= 1
      }
    },
    toggleFilters () {
      if (!this.$store.getters.getAppConfigFlag('isTestingWithoutPreCache')) {
        if (this.selectedWeekday.length !== 0 ||
              this.$store.getters['location/getMinMaxDuration'][0] !== this.$store.getters['location/getSelectedMinMaxDuration'][0] ||
              this.$store.getters['location/getMinMaxDuration'][1] !== this.$store.getters['location/getSelectedMinMaxDuration'][1] ||
              this.defaultMinDate !== this.$store.getters['location/getMinDate'] ||
              this.defaultMaxDate !== this.$store.getters['location/getMaxDate'] ||
              this.selectedHour.length > 0 || this.customHourRanges > 1 ||
              this.selectedActivity.length < this.activityTypeCount) {
          this.selectedStopFilter = true
        } else {
          this.selectedStopFilter = false
        }
        if (this.selectedProduct.length < this.productTypeCount ||
              this.selectedEnergy.length < this.energyTypeCount ||
              this.selectedChassisAdaptation.length < this.chassisAdaptationCount ||
              this.enableAnnualMileage.length > 0 ||
              this.selectedCabType.length < this.cabTypeCount) {
          this.selectedVehicleFilter = true
        } else {
          this.selectedVehicleFilter = false
        }
        if (this.$store.getters['location/getInOdoMinMax'][0] !== this.$store.getters['location/getInOdoSelectedMinMax'][0] ||
              this.$store.getters['location/getInOdoMinMax'][1] !== this.$store.getters['location/getInOdoSelectedMinMax'][1] ||
              this.$store.getters['location/getOutOdoMinMax'][0] !== this.$store.getters['location/getOutOdoSelectedMinMax'][0] ||
              this.$store.getters['location/getOutOdoMinMax'][1] !== this.$store.getters['location/getOutOdoSelectedMinMax'][1] ||
              this.$store.getters['location/getInDurMinMax'][0] !== this.$store.getters['location/getInDurSelectedMinMax'][0] ||
              this.$store.getters['location/getInDurMinMax'][1] !== this.$store.getters['location/getInDurSelectedMinMax'][1] ||
              this.$store.getters['location/getOutDurMinMax'][0] !== this.$store.getters['location/getOutDurSelectedMinMax'][0] ||
              this.$store.getters['location/getOutDurMinMax'][1] !== this.$store.getters['location/getOutDurSelectedMinMax'][1]) {
          this.selectedEdgeFilter = true
        } else {
          this.selectedEdgeFilter = false
        }
        if (this.$store.getters['location/getSelectedMinVehicles'] > 5 || this.$store.getters['location/getSelectedMinCustomers'] > 1 || this.$store.getters['location/getSelectedMinLocations'] > 0) {
          this.selectedLocationFilter = true
        } else {
          this.selectedLocationFilter = false
        }
      }
    },
    resetAndRefresh () {
      this.timeStart = Date.now()
      this.$logger.message(`{LA_"resetAndRefresh":"Resetting: ${'H3_CELL_INDEX_' + this.$store.getters['location/getResolutionToRequest']}"}`, 'info')
      this.refreshLocation = true
      this.selectedStopFilter = false
      this.selectedVehicleFilter = false
      this.selectedEdgeFilter = false
      this.selectedLocationFilter = false
      this.enableAnnualMileage = []
      this.enableTopLocation = []
      this.selectedWeekday = []
      this.selectedProduct = ['truck', 'bus']
      this.selectedActivity = [ 'weight_increase', 'weight_decrease', 'weight_similar', 'weight_unclear' ]
      this.selectedEnergy = ['diesel', 'ethanol', 'bev', 'cng', 'lng']
      this.selectedCabType = ['CP', 'CR', 'CS', 'CG', 'CL', 'CT']
      this.selectedChassisAdaptation = ['basic', 'articulated', 'double decker']
      this.$store.commit('location/setSelectedMinVehicles', 5)
      this.$store.commit('location/setSelectedMinCustomers', 1)
      this.$store.commit('location/setHexagonResolution', 2)
      this.$store.commit('location/setResolutionToRequest', 2)
      this.$store.commit('location/setSelectedMinLocations', null)
      const requestParamsLocations = {
        sqlfilename: 'LOCATIONS',
        snowflakequeryid: this.$store.getters.getQueryIdFor('locationsCache'),
        hexresolution: 'H3_CELL_INDEX_' + this.$store.getters['location/getResolutionToRequest']
      }
      this.postQuery(requestParamsLocations)
      this.sliderCheck = false
      this.selectedHour = []
      this.customHourRanges = 0
      this.$store.commit('setRequestedFilters', [this.defaultExportParams])
    },
    prepareLogReq (reqParams) {
      let reqParamsForLog = {
        ...reqParams,
        currhexresolution: 'H3_CELL_INDEX_' + this.$store.getters['location/getHexagonResolution'],
        selectedhexcount: this.$store.getters['location/getSelectedHexagons'].size
      }
      return reqParamsForLog
    },
    refreshAndFilter () {
      this.filterLocation = true
      this.timeStartpostQuery = Date.now()
      let reqParams = { snowflakequeryid: this.$store.getters.getQueryIdFor('locationsCache'),
        hexresolution: 'H3_CELL_INDEX_' + this.$store.getters['location/getResolutionToRequest'],
        mindate: this.$store.getters['location/getMinDate'],
        maxdate: this.$store.getters['location/getMaxDate'],
        minduration: this.$store.getters['location/getSelectedMinMaxDuration'][0],
        maxduration: this.$store.getters['location/getSelectedMinMaxDuration'][1],
        selectedweekdays: this.$store.getters['location/getSelectedWeekdays'].length > 0
          ? this.$store.getters['location/getSelectedWeekdays'].join(',') : 'none',
        hourofday: this.$store.getters['location/getSelectedHour'].length > 0
          ? this.$store.getters['location/getSelectedHour'].join(',') : 'none',
        activitytypes: this.$store.getters['location/getSelectedActivity'].length > 0
          ? this.$store.getters['location/getSelectedActivity'].join(',') : 'none',
        ingoingdistancemin: this.$store.getters['location/getInOdoSelectedMinMax'][0],
        ingoingdistancemax: this.$store.getters['location/getInOdoSelectedMinMax'][1],
        outgoingdistancemin: this.$store.getters['location/getOutOdoSelectedMinMax'][0],
        outgoingdistancemax: this.$store.getters['location/getOutOdoSelectedMinMax'][1],
        ingoingdurationmin: this.$store.getters['location/getInDurSelectedMinMax'][0],
        ingoingdurationmax: this.$store.getters['location/getInDurSelectedMinMax'][1],
        outgoingdurationmin: this.$store.getters['location/getOutDurSelectedMinMax'][0],
        outgoingdurationmax: this.$store.getters['location/getOutDurSelectedMinMax'][1],
        producttypes: this.$store.getters['location/getSelectedProduct'].length > 0
          ? this.$store.getters['location/getSelectedProduct'].join(',') : 'none',
        energytypes: this.$store.getters['location/getSelectedEnergy'].length > 0
          ? this.$store.getters['location/getSelectedEnergy'].join(',') : 'none',
        chassisadaptation: this.$store.getters['location/getSelectedChassisAdaptation'].length > 0
          ? this.$store.getters['location/getSelectedChassisAdaptation'].join(',') : 'none',
        cabtypes: this.$store.getters['location/getSelectedCabType'].length > 0
          ? this.$store.getters['location/getSelectedCabType'].join(',') : 'none',
        enablemileage: this.$store.getters['location/getEnableAnnualMileage'].length > 0
          ? this.$store.getters['location/getEnableAnnualMileage'].join(',') : 'false',
        minannualmileage: this.$store.getters['location/getSelectedMinMaxAnnualMileage'][0],
        maxannualmileage: this.$store.getters['location/getSelectedMinMaxAnnualMileage'][1],
        minnumbervehicles: Math.max(this.minimumVehicleCount, this.$store.getters['location/getSelectedMinVehicles']),
        minnumbercustomers: Math.max(1, this.$store.getters['location/getSelectedMinCustomers']),
        enabletoplocation: this.$store.getters['location/getEnableTopLocation'].length > 0
          ? this.$store.getters['location/getEnableTopLocation'].join(',') : 'false',
        minnumberlocations: this.$store.getters['location/getSelectedMinLocations'],
        basehexes: this.$store.getters['location/getBaseSelection']('baseHexes'),
        countrycodes: this.$store.getters['location/getBaseSelection']('baseCountries')
      }
      this.$logger.message(`{"LA_refreshAndFilter":"${JSON.stringify(this.prepareLogReq(reqParams))}"}`, 'info')
      if (this.$store.getters.getAppConfigFlag('isTestingWithoutPreCache')) {
        if (this.$store.getters['location/getSelectedHexagons'].size > 0) {
          this.$store.commit('location/setFilteredHexagons',
            {
              hexFilter: lz.compressToEncodedURIComponent(Array.from(this.$store.getters['location/getSelectedHexagons']).join(',')),
              hexFilterRes: 'H3_CELL_INDEX_' + this.$store.getters['location/getHexagonResolution']
            })
          this.selectedRes = this.$store.getters['location/getHexagonResolution']

          reqParams = {
            ...reqParams,
            currhexresolution: 'H3_CELL_INDEX_' + this.$store.getters['location/getHexagonResolution'],
            hexagonshexes: lz.compressToEncodedURIComponent(Array.from(this.$store.getters['location/getSelectedHexagons']).join(','))
          }
        }
        if (this.filteredHexes && this.selected) {
          reqParams = {
            ...reqParams,
            hexfilter: this.$store.getters['location/getFilteredHexes'].hexFilter,
            hexfilterres: this.$store.getters['location/getFilteredHexes'].hexFilterRes
          }
        }
      }
      if (!this.$store.getters.getAppConfigFlag('isTestingWithoutPreCache')) {
        if (this.$store.getters['location/getSelectedHexagons'].size > 0) {
          reqParams = {
            ...reqParams,
            currhexresolution: 'H3_CELL_INDEX_' + this.$store.getters['location/getHexagonResolution'],
            hexagonshexes: lz.compressToEncodedURIComponent(Array.from(this.$store.getters['location/getSelectedHexagons']).join(','))
          }
        }
      }
      const fieldsToRemoveFromExport = ['snowflakequeryid', 'enablemileage', 'enabletoplocation', 'basehexes']
      fieldsToRemoveFromExport.push(this.selectedHour.length > 0 ? '' : 'hourofday')
      fieldsToRemoveFromExport.push(this.selectedActivity.length < this.activityTypeCount ? '' : 'activitytypes')
      fieldsToRemoveFromExport.push(this.selectedProduct.length < this.productTypeCount ? '' : 'producttypes')
      fieldsToRemoveFromExport.push(this.selectedCabType.length < this.cabTypeCount ? '' : 'cabtypes')
      fieldsToRemoveFromExport.push(this.selectedEnergy.length < this.energyTypeCount ? '' : 'energytypes')
      fieldsToRemoveFromExport.push(this.selectedChassisAdaptation.length < this.chassisAdaptationCount ? '' : 'chassisadaptation')
      fieldsToRemoveFromExport.push(this.enableAnnualMileage.length > 0 ? '' : 'minannualmileage')
      fieldsToRemoveFromExport.push(this.enableAnnualMileage.length > 0 ? '' : 'maxannualmileage')
      fieldsToRemoveFromExport.push(
        (this.$store.getters['location/getMinMaxDuration'][0] <= this.$store.getters['location/getSelectedMinMaxDuration'][0]) ? ''
          : 'minduration'
      )
      fieldsToRemoveFromExport.push(
        this.$store.getters['location/getMinMaxDuration'][1] >= this.$store.getters['location/getSelectedMinMaxDuration'][1] ? ''
          : 'maxduration'
      )
      fieldsToRemoveFromExport.push(
        this.$store.getters['location/getInOdoMinMax'][0] <= this.$store.getters['location/getInOdoSelectedMinMax'][0] ? ''
          : 'ingoingdistancemin'
      )
      fieldsToRemoveFromExport.push(
        this.$store.getters['location/getInOdoMinMax'][1] >= this.$store.getters['location/getInOdoSelectedMinMax'][1] ? ''
          : 'ingoingdistancemax'
      )
      fieldsToRemoveFromExport.push(
        this.$store.getters['location/getOutOdoMinMax'][0] <= this.$store.getters['location/getOutOdoSelectedMinMax'][0] ? ''
          : 'outgoingdistancemin'
      )
      fieldsToRemoveFromExport.push(
        this.$store.getters['location/getOutOdoMinMax'][1] >= this.$store.getters['location/getOutOdoSelectedMinMax'][1] ? ''
          : 'outgoingdistancemax'
      )
      fieldsToRemoveFromExport.push(
        this.$store.getters['location/getInDurMinMax'][0] <= this.$store.getters['location/getInDurSelectedMinMax'][0] ? ''
          : 'ingoingdurationmin'
      )
      fieldsToRemoveFromExport.push(
        this.$store.getters['location/getInDurMinMax'][1] >= this.$store.getters['location/getInDurSelectedMinMax'][1] ? ''
          : 'ingoingdurationmax'
      )
      fieldsToRemoveFromExport.push(
        this.$store.getters['location/getOutDurMinMax'][0] <= this.$store.getters['location/getOutDurSelectedMinMax'][0] ? ''
          : 'outgoingdurationmin'
      )
      fieldsToRemoveFromExport.push(
        this.$store.getters['location/getOutDurMinMax'][1] >= this.$store.getters['location/getOutDurSelectedMinMax'][1] ? ''
          : 'outgoingdurationmax'
      )
      fieldsToRemoveFromExport.push(this.$store.getters['location/getSelectedMinVehicles'] > 5 ? '' : 'minnumbervehicles')
      fieldsToRemoveFromExport.push(this.$store.getters['location/getSelectedMinCustomers'] > 1 ? '' : 'minnumbercustomers')
      fieldsToRemoveFromExport.push(
        this.$store.getters['location/getSelectedMinLocations'] > 0 ? '' : 'minnumberlocations'
      )
      fieldsToRemoveFromExport.push(this.selectedWeekday.length !== 0 ? '' : 'selectedweekdays')
      const exportFilters = { ...reqParams }
      fieldsToRemoveFromExport.forEach((field) => delete exportFilters[field])
      this.$store.commit('setRequestedFilters', [exportFilters])
      this.postQuery(reqParams)
    },
    postQuery (requestParams) {
      this.errorMessage = false
      this.requestFailed = false

      const durationRequest = { ...requestParams, sqlfilename: 'DURATION_DISTRIBUTION' }
      this.getQueryIdForLocations('durationDistribution', durationRequest)
      const startHourRequest = { ...requestParams, sqlfilename: 'START_HOUR_DISTRIBUTION' }
      this.getQueryIdForLocations('startHourDistribution', startHourRequest)
      const reqParamsMinMax = { ...requestParams, sqlfilename: 'MIN_MAX_RANGES' }
      const locationRequest = { ...requestParams, sqlfilename: 'LOCATIONS' }
      const locations = this.getQueryIdForLocations('locations', locationRequest)
      this.$store.commit('location/setLocationRequestObject', requestParams)
      Promise.all([locations])
        .then(() => {
          this.timeStart = Date.now()
          const locationQueryState = this.getStatusForLocationQuery(this.$store.getters.getQueryIdFor('locations'))
          return Promise.all([locationQueryState])
        })
        .then(() => {
          let endTime = Date.now()
          this.$logger.message(`{"LA_postQuerylocationQueryState":{"message":"Filtering done.","time":"${(endTime - this.timeStart) / 1000} seconds."}}`, 'info')
          this.timeStart = Date.now()
          let minMaxRanges = true
          if (!this.$store.getters.getAppConfigFlag('isTestingWithoutPreCache')) {
            const reqParamsMinMax = {
              sqlfilename: 'MIN_MAX_RANGES',
              snowflakequeryid: this.$store.getters.getQueryIdFor('locations')
            }
            minMaxRanges = this.getQueryIdForLocations('minMaxRanges', reqParamsMinMax)
          } else {
            minMaxRanges = this.getQueryIdForLocations('minMaxRanges', reqParamsMinMax)
          }
          return Promise.all([minMaxRanges])
        })
        .then(() => {
          const minMaxRangesState = this.getStatusForLocationQuery(this.$store.getters.getQueryIdFor('minMaxRanges'))
          return Promise.all([minMaxRangesState])
        })
        .then(() => {
          const minmaxData = this.getLocationDataForQueryId(this.$store.getters.getQueryIdFor('minMaxRanges'))
          return Promise.all([minmaxData])
        })
        .then(response => {
          if (!this.$store.getters.getAppConfigFlag('isTestingWithoutPreCache')) {
            let endTime = Date.now()
            this.$logger.message(`{"LA_postQueryminmaxData":{"message":"Filtering done.","time":"${(endTime - this.timeStart) / 1000} seconds."}}`, 'info')
            this.timeStart = Date.now()
            if (!(Object.keys(response[0].data[0]).length > 0)) throw new Error('No data!')
            const responseData = response[0].data[0]
            this.$store.commit('location/setSelectedMinMaxDuration', [responseData.min_duration_in_set, responseData.max_duration_in_set])
            this.$store.commit('location/setMinDate', responseData.min_date_in_set)
            this.$store.commit('location/setMaxDate', responseData.max_date_in_set)
            this.$store.commit('location/setInOdoSelectedMinMax', [responseData.min_ingoing_distance_in_set, responseData.max_ingoing_distance_in_set])
            this.$store.commit('location/setOutOdoSelectedMinMax', [responseData.min_outgoing_distance_in_set, responseData.max_outgoing_distance_in_set])
            this.$store.commit('location/setInDurSelectedMinMax', [responseData.min_ingoing_duration_in_set, responseData.max_ingoing_duration_in_set])
            this.$store.commit('location/setOutDurSelectedMinMax', [responseData.min_outgoing_duration_in_set, responseData.max_outgoing_duration_in_set])
          }
          const locationData = this.getLocationDataForQueryId(this.$store.getters.getQueryIdFor('locations'))
          return Promise.all([locationData])
        })
        .then(response => {
          const dataObj = this.$store.dispatch('location/setLocationLayerAsync', response[0].data)
          this.$store.commit('location/setSelectedHexagons', new Set())
          this.$store.commit('location/setHexagonResolution', this.$store.getters['location/getResolutionToRequest'])
          return Promise.all([dataObj])
        })
        .then(() => {
          let endTime = Date.now()
          this.$logger.message(`{"LA_postQuerylocationData":{"message":"Filtering done.","time":"${(endTime - this.timeStart) / 1000} seconds."}}`, 'info')
          this.timeStart = Date.now()
          const durationDistributionState = this.getStatusForLocationQuery(this.$store.getters.getQueryIdFor('durationDistribution'))
          const startHourDistributionState = this.getStatusForLocationQuery(this.$store.getters.getQueryIdFor('startHourDistribution'))
          return Promise.all([durationDistributionState, startHourDistributionState])
        })
        .then(() => {
          const durationDistribution = this.getLocationDataForQueryId(this.$store.getters.getQueryIdFor('durationDistribution'))
          return Promise.all([durationDistribution])
        })
        .then(response => {
          this.$store.commit('location/setDurationGraphDataStructure', response[0].data)
          return true
        })
        .then(() => {
          const startHourDistribution = this.getLocationDataForQueryId(this.$store.getters.getQueryIdFor('startHourDistribution'))
          return Promise.all([startHourDistribution])
        })
        .then(response => {
          this.$store.commit('location/setStartTimeGraphDataStructure', response[0].data)
          return true
        })
        .catch(error => {
          this.$logger.message(`{"LA_postQuery":"Error in postQuery: ${error}"}`, 'error')
          this.requestFailed = true
          try {
            this.errorMessage = error.response.data.message
          } catch {
            this.errorMessage = error
          }
        })
        .then(() => {
          if (this.filterLocation === true) {
            this.toggleFilters()
          }
          let endTime = Date.now()
          if (this.filterLocation) {
            this.$logger.message(`{"LA_postQuery":{"message":"Filtering done.","time":"${(endTime - this.timeStartpostQuery) / 1000} seconds."}}`, 'info')
          }
          if (this.refreshLocation) {
            this.$logger.message(`{"LA_postQuery":{"message":"Resetting done","time":"${(endTime - this.timeStartpostQuery) / 1000} seconds."}}`, 'info')
          }
          this.$logger.message(`{"LA_postQueryDistributionStartHourState":{"message":"Filtering done.","time":"${(endTime - this.timeStart) / 1000} seconds."}}`, 'info')
          this.$emit('handle-on-filter')
          this.refreshLocation = false
          this.filterLocation = false
        })
        .then(() => {
          if (!this.$store.getters.getAppConfigFlag('isTestingWithoutPreCache')) {
            const graphSet = this.$store.dispatch('location/setCountDistributionGraphAsync')
            const enrichedGeoData = this.$store.dispatch('location/setLocationDataWithGeoInfoAsync')
            return Promise.all([graphSet, enrichedGeoData])
          } return true
        })
    }
  },
  watch: {
    selectedHour: function (newVal, oldVal) {
      let itemsOnlyInNew = newVal.filter(x => !oldVal.includes(x))
      let itemsOnlyInOld = oldVal.filter(x => !newVal.includes(x))
      if (itemsOnlyInOld.length > 0) {
        let removeVal = itemsOnlyInOld[0].split('-')
        let minHour = parseInt(removeVal[0])
        let maxHour = parseInt(removeVal[1])
        let diff = maxHour - minHour
        let hours = [...Array(diff + 1).keys()].map(value => value + minHour)
        this.$store.commit('location/removeFromSelectedHour', hours)
      } else if (itemsOnlyInNew.length > 0) {
        let addVal = itemsOnlyInNew[0].split('-')
        let minHour = parseInt(addVal[0])
        let maxHour = parseInt(addVal[1])
        let diff = maxHour - minHour
        let hours = [...Array(diff + 1).keys()].map(value => value + minHour)
        this.$store.commit('location/addToSelectedHour', hours)
      }
    },
    selectedWeekday: function () {
      this.$store.commit('location/setSelectedWeekdays', this.selectedWeekday)
    },
    selectedProduct: function () {
      this.$store.commit('location/setSelectedProduct', this.selectedProduct)
    },
    selectedActivity: function () {
      this.$store.commit('location/setSelectedActivity', this.selectedActivity)
    },
    selectedEnergy: function () {
      this.$store.commit('location/setSelectedEnergy', this.selectedEnergy)
    },
    selectedChassisAdaptation: function () {
      this.$store.commit('location/setSelectedChassisAdaptation', this.selectedChassisAdaptation)
    },
    enableAnnualMileage: function () {
      this.$store.commit('location/setEnableAnnualMileage', this.enableAnnualMileage)
    },
    enableTopLocation: function () {
      this.$store.commit('location/setEnableTopLocation', this.enableTopLocation)
    },
    selectedCabType: function () {
      this.$store.commit('location/setSelectedCabType', this.selectedCabType)
    }
  }
}
</script>

<style scoped>
.modal-alert {
  display: block; /* Hidden by default */
  position: fixed; /* Stay in place */
  z-index: 1; /* Sit on top */
  padding-top: 100px; /* Location of the box */
  left: 0;
  top: 0;
  width: 100%; /* Full width */
  height: 100%; /* Full height */
  overflow: auto; /* Enable scroll if needed */
  background-color: rgb(0,0,0); /* Fallback color */
  background-color: rgba(0,0,0,0.4); /* Black w/ opacity */
}

/* Modal Content */
.modal-alert-content {
  position: relative;
  background-color: #fefefe;
  margin: auto;
  padding: 0;
  border: 1px solid #888;
  width: 80%;
  box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2),0 6px 20px 0 rgba(0,0,0,0.19);
  -webkit-animation-name: animatetop;
  -webkit-animation-duration: 0.4s;
  animation-name: animatetop;
  animation-duration: 0.4s
}
/* Add Animation */
@-webkit-keyframes animatetop {
  from {top:-300px; opacity:0}
  to {top:0; opacity:1}
}

@keyframes animatetop {
  from {top:-300px; opacity:0}
  to {top:0; opacity:1}
}

/* The Close Button */
.close {
  color: white;
  float: right;
  font-size: 28px;
  font-weight: bold;
}

.close:hover,
.close:focus {
  color: #000;
  text-decoration: none;
  cursor: pointer;
}

.modal-alert-header {
  padding: 2px 16px;
  background-color: gray;
  color: white;
}

.modal-alert-body {
   padding: 32px 16px;
   font-size: 20px;
   color: black;
}

.modal-alert-footer {
  padding: 2px 16px;
  background-color: gray;
  color: white;
}
#addCustomRangeButton {
  margin-top: 0.8rem;
  margin-bottom: 0.8rem;
  border: none;
  background: none;
}

#addCustomRangeButton span{
  padding-bottom: 7px;
  letter-spacing: 1px;
  font-size: 14px;
  padding-right: 15px;
  text-transform: uppercase;
}

.hover-underline-animation {
  position: relative;
  color: black;
  padding-bottom: 20px;
}

.hover-underline-animation:after {
  content: "";
  position: absolute;
  width: 100%;
  transform: scaleX(0);
  height: 2px;
  bottom: 0;
  left: 0;
  background-color: #000000;
  transform-origin: bottom right;
  transition: transform 0.25s ease-out;
}

#addCustomRangeButton:hover .hover-underline-animation:after {
  transform: scaleX(1);
  transform-origin: bottom left;
}

.addCustomRangeButtonIcon {
  height: 18px;
  width: 18px;
}

#addCustomRangeButton svg {
  transform: translateX(-8px);
  transition: all 0.3s ease;
}

#addCustomRangeButton:hover svg {
  transform: translateX(0);
}

#addCustomRangeButton:active svg {
  transform: scale(0.9);
}

#hourRangeButtons {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
}

#hourRangeButtonsVertical {
  display: inline-block;
  flex-direction: row;
  justify-content: space-evenly;
}

.hourOfDayCheckbox {
  display: flex;
  flex-direction: column;
  align-items: center;
  transition: all .2s ease-in-out;
}

.hourOfDayCheckbox:hover {
  transform: scale(1.3);
}

.hourOfDayCheckbox input {
  display: none!important;
}

.hourOfDayLabel {
  border-radius: 50%;
  border: 2px solid rgb(200, 200, 200);
  background-color: transparent;
  height: 36px;
  width: 36px;
  margin-bottom: 0px;
  line-height: 36px;
  text-align: center;
  cursor: pointer;
}

.hourOfDayCheckbox input[type=checkbox]:checked + label {
  border: 2px solid #41b883;
  background-color: transparent;
  color: #ffffff;
}

.hourOfDayCheckbox input[type=checkbox]:checked ~ b {
  color: #41b883;
}

#locationFilterPanel {
  display: flex;
  width: 100%;
  justify-content: flex-start;
  flex-direction: column;
}

.datePicker {
  display: flex;
  justify-content: center;
  margin-top: 4px;
}

#dateRange {
  padding-top: 0px;
  border-radius: 2px;
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
}

.card-body {
  padding: 3px;
}

@media only screen and (max-height: 669px) {
  #stopFilterContainer {
    height: 42vh;
  }
}

@media only screen and (min-height: 669px) and (max-height: 894px) {
  #stopFilterContainer {
    height: 53vh;
  }
}

@media only screen and (min-height: 895px) {
  #stopFilterContainer {
    height: 60vh;
  }
}

#stopFilterContainer {
  display: flex;
  justify-content: center;
}

#filterContainer {
  display: flex;
  justify-content: center;
  margin-top: 20px;
}

#filterActivityContainer {
  display: flex;
  justify-content: center;
  margin-top: 0px;
}

.cardFilterContainer {
  width: 75%;
  margin-left: 12.5%;
  border: 0.5px solid darkgray;
  margin-top: 1rem;
}

.stopFilter {
  display: flex;
  color: #212529;
  padding: 0px;
  width: 80%;
  margin-left: 10%;
  text-align: left;
}

.filterInfo {
  margin-left: 10%;
  padding: 0px;
  margin: 1px;
}

.stopActivityFilter {
  display: flex;
  color: #212529;
  padding: 0px;
  width: 80%;
  margin-left: 22%;
  text-align: left;
}

.labelFilter {
  font-size:small;
  color:black;
  margin-bottom:0px;
  position: relative;
  top: -0.8em;
  background-color: white;
}

#locationFilterButtonContainer {
  display: flex;
  justify-content: flex-end;
  margin-right: 15%;
  margin-top: 5px;
  position: absolute;
  bottom: 0;
  margin-bottom: 30px;
  width: 95%;
}

#onLocationFilteringError {
  margin-top: 77px;
  height: 92.1vh;
  width: 37vw;
  color: white;
  text-align: center;
  font-size: small;
}

#energy-filter {
  text-align: left;
  columns: 3 auto ;
}

.post-filter {
  height: 30px;
  font-size: small;
}

.dayOfWeekCheckbox {
  display: inline;
}

.dayOfWeekCheckbox input {
  display: none!important;
}

.weekdayLabel {
  display: inline-block;
  border-radius: 50%;
  border: 2px solid rgb(200, 200, 200);
  background-color: transparent;
  height: 30px;
  width: 30px;
  margin-right: 0.2vw;
  line-height: 30px;
  text-align: center;
  cursor: pointer;
  transition: all .2s ease-in-out;
}

.weekdayLabel:hover {
  transform: scale(1.2);
}

.dayOfWeekCheckbox input[type=checkbox]:checked + label {
  background: #41b883;
  color: #ffffff;
}

.post-filter {
  margin-top: 20px;
  margin-left: 10px;
}
labelDiv {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.inputFieldLabelsClickable {
  color: #9BADBF;
  font-family: 'Segoe UI', sans-serif;
  max-width: 100px;
  min-width: 60px;
  margin-right: 0.9rem;
  font-size: small;
}
.inputFieldLabelsClickable:hover {
  cursor: pointer;
  color: #42b983;
}
.minLabel:hover {
  margin-left: 5px;
}
.maxLabel:hover {
  margin-right: 5px;
}
</style>

<style>
#hexagonResolutionElements .number-input__button--plus {
  background-image: url('../../assets/zoom_in.svg');
  background-repeat: no-repeat;
  background-position: 50% 50%;
  height: 40px;
  width: 40px;
  border: none;
}

#hexagonResolutionElements .number-input__button--minus {
  background-image: url('../../assets/zoom_out.svg');
  background-repeat: no-repeat;
  background-position: 50% 50%;
  height: 40px;
  width: 40px;
  border: none;
}

#hexagonResolutionElements .label {
  /* ... */
  /* Show an ellipsis if the text takes more than one line */
  margin-left: 5px;
  text-overflow: ellipsis;
  white-space: nowrap;
  margin-right: 15px;
}

#hexagonResolutionElements .number-input__button--plus::after,#hexagonResolutionElements .number-input__button--plus::before,
#hexagonResolutionElements .number-input__button--minus::after,#hexagonResolutionElements .number-input__button--minus::before {
  display: none;
}

::-webkit-scrollbar {
  width: 8px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #888;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}

#selectedHexagons {
  text-align: -webkit-center;
}
/* Toggle switch */
.notification {
  font-size: medium;
  width: 100%;
  color: #9BADBF;
  cursor: pointer;
}

.selected {
  border-bottom: 2px solid;
}

</style>
