<template>
  <div id="mapStylePanel">
    <div id='baseStyleButtons'>
      <b-form-radio-group
        id="base-style-choice"
        v-model="selectedBaseLayer"
        name="base-style-choice"
      >
        <b-form-radio class="baseLayerSelection" value="scania">Scania</b-form-radio>
        <b-form-radio class="baseLayerSelection" value="light-v10">Light</b-form-radio>
        <b-form-radio class="baseLayerSelection" value="outdoors-v11">Bright</b-form-radio>
        <b-form-radio class="baseLayerSelection" value="satellite-v9">Satellite</b-form-radio>
        <b-form-radio class="baseLayerSelection" value="navigation-night-v1">Dark streets</b-form-radio>
        <b-form-radio class="baseLayerSelection" value="navigation-day-v1">Light streets</b-form-radio>
        <b-form-radio class="baseLayerSelection" value="satellite-streets-v11">Streets+Satellite</b-form-radio>
       </b-form-radio-group>
    </div>
    <div style="margin-top: 10px" v-if="this.$store.getters.getAppConfigFlag('canSeeUploadedGeoLayer')">
      <b-form-group id="dataLayers">
        <div id="listLayers" v-show=true>
          <multiselect v-model="chosenLayer"
                       id="customerLayers"
                       label="name"
                       track-by="name"
                       placeholder="Select a layer"
                       open-direction="bottom"
                       :options="availableLayers"
                       :multiple="true"
                       :searchable="true"
                       :loading="isLoading"
                       :internal-search="false"
                       :clear-on-select="false"
                       :close-on-select="false"
                       :options-limit="300"
                       :limit="3"
                       :limit-text="limitText"
                       :max-height="600"
                       :show-no-results="false"
                       :hide-selected="true"
                       @open="populateLayerList"
                       @select="fetchLayer"
                       @remove="removeLayer">
            <template slot="tag" slot-scope="{ option, remove }">
                <span class="custom__tag">
                  <span>{{ option.name }}</span>
                  <span class="custom__remove" @click="remove(option)">❌</span></span>
            </template>
            <template slot="clear" slot-scope="props">
              <div class="multiselect__clear"
                   v-if="chosenLayer.length"
                   @mousedown.prevent.stop="clearAll(props.search)"></div>
            </template>
            <span slot="noResult">Oops! No layers found.</span>
          </multiselect>
        </div>
      </b-form-group>
    </div>
    <div style="margin-top: 10px" v-if="this.$store.getters.getAppConfigFlag('canSeePowerLayers')">
      <b-form-group id="powerLayers">
        <div>
          <multiselect v-model="powerLayer"
                       id="powerLayers"
                       label="name"
                       track-by="name"
                       placeholder="Add power data to map..."
                       open-direction="bottom"
                       :options="availPowerLayers"
                       :multiple="true"
                       :clear-on-select="false"
                       :close-on-select="false"
                       :options-limit="30"
                       :limit="3"
                       :limit-text="limitText"
                       :max-height="600"
                       :show-no-results="false"
                       @select="addLayerToMap"
                       @remove="hideLayer">
            <template slot="tag" slot-scope="{ option, remove }">
                <span class="custom__tag">
                  <span>{{ option.name }}</span>
                  <span class="custom__remove" @click="remove(option)">❌</span></span>
            </template>
            <template slot="clear" slot-scope="props">
              <div class="multiselect__clear"
                   v-if="powerLayer.length"
                   @mousedown.prevent.stop="clearAll(props.search)"></div>
            </template>
            <span slot="noResult">Oops! No layers found.</span>
          </multiselect>
        </div>
      </b-form-group>
    </div>
  </div>
</template>

<script>
import Multiselect from '../../node_modules/vue-multiselect/src'
import axios from 'axios'

export default {
  name: 'MapStylePanel',
  components: { Multiselect },
  data () {
    return {
      selectedBaseLayer: 'scania',
      chosenLayer: [],
      powerLayer: [],
      availPowerLayers: [{ name: 'Power' }],
      isLoading: false,
      initialPowerLayer: 'Power'
    }
  },
  computed: {
    mapObject: {
      get () {
        return this.$store.getters.getBaseMap
      },
      set (newMap) {
        this.$store.commit('setBaseMap', newMap)
      }
    },
    availableLayers: {
      get () {
        return this.$store.getters['getAvailableLayers']
      },
      set (value) {
        this.$store.commit('setAvailableLayers', value)
      }
    }
  },
  mounted () {
    this.populateLayerList()
    this.powerLayer = this.availPowerLayers.find(option => option.name === this.initialPowerLayer)
  },
  methods: {
    limitText (count) {
      return `and ${count} other layers`
    },
    clearAll () {
      this.chosenLayer = []
    },
    populateLayerList () {
      let startTime = Date.now()
      let availableLayers = []

      let lambdaPath = '/Prod/ListLocationFiles'
      let endpoint = this.$store.getters.getAppConfigFlag('useStagingStack') ? process.env.VUE_APP_UPLOAD_ENDPOINT_B : process.env.VUE_APP_UPLOAD_ENDPOINT
      let getUrl = endpoint + lambdaPath
      try {
        axios
          .get(getUrl, {
            headers: { 'Authorization': this.$store.getters.getBearerToken },
            params: { 'foldername': 'points' }
          })
          .then(res => {
            res.data.file_folders.forEach(element => {
              let rx = /points\/(.+)\.json/g
              let name = rx.exec(element)
              availableLayers.push({ name: name[1] })
            })
            this.availableLayers = availableLayers
          })
        this.$logger.message(`{"populateLayerList":"Fetched datalayers: ${JSON.stringify(this.availableLayers)}, it took: ${(Date.now() - startTime) / 1000}"}`, 'info')
      } catch (e) {
        this.$logger.message(`{"populateLayerList":"There was an error in populateLayerList: ${e}"}`, 'error')
      }
    },
    removeLayer (id) {
      this.$logger.message(`{"removeLayer":"emit an event to hide/remove layer from map for id + ${id.name}"}`)
      this.$emit('remove-map-layer', id.name)
    },
    hideLayer (id) {
      this.$logger.message(`{"hideLayer":"emit an event to hide Power layer from map for id + ${id.name}"}`)
      this.$emit('hide-power-layer', id.name)
    },
    addLayerToMap (id) {
      this.$logger.message(`{"addPowerLayer":"emit an event to hide Power layer from map for id + ${id.name}"}`)
      this.$emit('add-power-layer', id.name)
    },
    async fetchLayer (id) {
      let lambdaPath = '/Prod/FetchLocationFile'
      let endpoint = this.$store.getters.getAppConfigFlag('useStagingStack') ? process.env.VUE_APP_UPLOAD_ENDPOINT_B : process.env.VUE_APP_UPLOAD_ENDPOINT
      let getUrl = endpoint + lambdaPath
      try {
        await axios
          .get(getUrl, {
            headers: { 'Authorization': this.$store.getters.getBearerToken
            },
            params: {
              filename: id.name,
              foldername: 'points'
            }
          })
          .then(res => {
            this.$emit('fetch-customer-data', { geojson: res.data, name: id.name })
          })
        this.$logger.message(`{"fetchLayer":"Selected datalayer: ${JSON.stringify(this.chosenLayer)}"}`, 'info')
      } catch (e) {
        this.$logger.message(`{"fetchLayer":"There was an error in fetchLayer: ${e}"}`, 'error')
      }
    }
  },
  watch: {
    selectedBaseLayer: function () {
      this.$emit('select-map-style', this.selectedBaseLayer)
      this.$logger.message(`{"selectedBaseLayer":"select-map-style ${this.selectedBaseLayer}"}`, 'info')
    }
  }
}
</script>

<style scoped>
#mapStylePanel {
  display: flex;
  width: 75%;
  justify-content: center;
  flex-direction: column;
  margin-left: 12.5%;
}

#baseStyleButtons {
  background: #f8f9fa;
  color: #212529;
  padding: 10px 2px;
  border-radius: 2px;
  font-size: 14px;
  font-family: 'Montserrat', sans-serif;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}

.baseLayerSelection {
  width: 140px;
}

#base-style-choice {
  padding-left: 10px;
}

</style>
