<template>
  <div>
    <h5 id='privacyNotice' class='loadingBar'>FOR INTERNAL USE ONLY</h5>
    <side-menu @handle-deck-layer-changed="handleDeckLayerChanged"
               @update-selected-hexes-on-map="selectHexagons"
               @handle-on-filter="handleDeckLayerChanged"
               @update-graph-options="updateGraphOptions"
               @update-graph-selection="updateGraphSelection"
               @select-map-style="selectMapStyle"
               @add-map-layer="addGeoJSONLayer"
               @remove-map-layer="removeCustomerLayer"
               @add-power-map-layer="addPowerLayer"
               @hide-power-map-layer="hidePowerLayer"/>
    <div id="location-chartWidget" :class="checkGraphWidgetSize()">
      <div v-show="selectedGraphs.includes('durationDistribution')"
           class="vehicleChart"
           :class="checkGraphSize('durationDistribution')"
           id="location-div-durationDistribution">
        <div class="chartHeader">
          <button id="location-durationDistribution-expand"
                  v-if="enlargedChart !== 'durationDistribution'"
                  @click="handleClick('durationDistribution')"
                  class="expandCompressChartButton" title="Expand">
            <v-icon name="expand" class="expandChartButtonIcon"/>
          </button>
          <button id="location-durationDistribution-compress"
                  v-if="enlargedChart === 'durationDistribution'"
                  @click="closeLarge('durationDistribution')"
                  class="expandCompressChartButton" title="Compress">
            <v-icon name="compress" class="compressChartButtonIcon"/>
          </button>
        </div>
        <bar-chart getStateFunction="location/getGraphDataStructure"
                   :width="enlargedChart === 'durationDistribution' ? chartWidthLarge : chartWidth"
                   :height="enlargedChart === 'durationDistribution' ? chartHeightLarge : chartHeight"
                   :svgHeight="enlargedChart === 'durationDistribution' ? chartSVGHeightLarge : chartSVGHeight"
                   :margin="enlargedChart === 'durationDistribution' ? chartMarginLarge : chartMarginSmall"
                   yAxisLabel="Number of stops"
                   xAxisLabel="Duration in (h)"
                   :graphTitle="getGraphTitle('durationDistribution')"
                   chartDataName="durationDistribution"
                   plotDataName="durationDistribution"
                   yKey="no_stops"
                   xKey="bucket"/>
      </div>
      <div v-show="selectedGraphs.includes('countDistribution')"
           class="vehicleChart"
           :class="checkGraphSize('countDistribution')"
           id="location-div-countDistribution">
        <div class="chartHeader">
          <button id="location-countDistribution-expand"
                  v-if="enlargedChart !== 'countDistribution'"
                  @click="handleClick('countDistribution')"
                  class="expandCompressChartButton" title="Expand">
            <v-icon name="expand" class="expandChartButtonIcon"/>
          </button>
          <button id="location-countDistribution-compress"
                  v-if="enlargedChart === 'countDistribution'"
                  @click="closeLarge('countDistribution')"
                  class="expandCompressChartButton" title="Compress">
            <v-icon name="compress" class="compressChartButtonIcon"/>
          </button>
        </div>
        <bar-chart getStateFunction="location/getGraphDataStructure"
                   :width="enlargedChart === 'countDistribution' ? chartWidthLarge : chartWidth"
                   :height="enlargedChart === 'countDistribution' ? chartHeightLarge : chartHeight"
                   :svgHeight="enlargedChart === 'countDistribution' ? chartSVGHeightLarge : chartSVGHeight"
                   :margin="enlargedChart === 'countDistribution' ? chartMarginLarge : chartMarginSmall"
                   yAxisLabel="Number of stops"
                   xAxisLabel="Locations (max 100 visible in graph)"
                   :legend=true
                   :graphTitle="getGraphTitle('countDistribution')"
                   chartDataName="countDistribution"
                   plotDataName="countDistribution"
                   yKey="no_stops"
                   xKey="hexagons"/>
      </div>
      <div v-show="selectedGraphs.includes('startHourDistribution')"
           class="vehicleChart"
           :class="checkGraphSize('startHourDistribution')"
           id="location-div-countDistributionForStopTime">
        <div class="chartHeader">
          <button id="location-startHourDistribution-expand"
                  v-if="enlargedChart !== 'startHourDistribution'"
                  @click="handleClick('startHourDistribution')"
                  class="expandCompressChartButton" title="Expand">
            <v-icon name="expand" class="expandChartButtonIcon"/>
          </button>
          <button id="location-startHourDistribution-compress"
                  v-if="enlargedChart === 'startHourDistribution'"
                  @click="closeLarge('startHourDistribution')"
                  class="expandCompressChartButton" title="Compress">
            <v-icon name="compress" class="compressChartButtonIcon"/>
          </button>
        </div>
        <bar-chart getStateFunction="location/getGraphDataStructure"
                   :width="enlargedChart === 'startHourDistribution' ? chartWidthLarge : chartWidth"
                   :height="enlargedChart === 'startHourDistribution' ? chartHeightLarge : chartHeight"
                   :svgHeight="enlargedChart === 'startHourDistribution' ? chartSVGHeightLarge : chartSVGHeight"
                   :margin="enlargedChart === 'startHourDistribution' ? chartMarginLarge : chartMarginSmall"
                   yAxisLabel="Number of stops"
                   xAxisLabel="Stop hour"
                   :graphTitle="getGraphTitle('startHourDistribution')"
                   :useOnlyXKey=true
                   chartDataName="startHourDistribution"
                   plotDataName="startHourDistribution"
                   yKey="no_stops"
                   xKey="bucket"/>
      </div>
      <div v-show="selectedGraphs.includes('countDistributionForCustomers')"
           class="vehicleChart"
           :class="checkGraphSize('countDistributionForCustomers')"
           id="location-div-countDistributionForCustomers">
        <div class="chartHeader">
          <button id="location-countDistributionForCustomers-expand"
                  v-if="enlargedChart !== 'countDistributionForCustomers'"
                  @click="handleClick('countDistributionForCustomers')"
                  class="expandCompressChartButton" title="Expand">
            <v-icon name="expand" class="expandChartButtonIcon"/>
          </button>
          <button id="location-countDistributionForCustomers-compress"
                  v-if="enlargedChart === 'countDistributionForCustomers'"
                  @click="closeLarge('countDistributionForCustomers')"
                  class="expandCompressChartButton" title="Compress">
            <v-icon name="compress" class="compressChartButtonIcon"/>
          </button>
        </div>
        <bar-chart getStateFunction="location/getGraphDataStructure"
                   :width="enlargedChart === 'countDistributionForCustomers' ? chartWidthLarge : chartWidth"
                   :height="enlargedChart === 'countDistributionForCustomers' ? chartHeightLarge : chartHeight"
                   :svgHeight="enlargedChart === 'countDistributionForCustomers' ? chartSVGHeightLarge : chartSVGHeight"
                   :margin="enlargedChart === 'countDistributionForCustomers' ? chartMarginLarge : chartMarginSmall"
                   yAxisLabel="Number of customers"
                   xAxisLabel="Locations (max 100 visible in graph)"
                   :legend=true
                   :graphTitle="getGraphTitle('countDistributionForCustomers')"
                   chartDataName="countDistributionForCustomers"
                   plotDataName="countDistributionForCustomers"
                   yKey="no_customers"
                   xKey="hexagons"/>
      </div>
      <div v-show="selectedGraphs.includes('countDistributionForVehicles')"
           class="vehicleChart"
           :class="checkGraphSize('countDistributionForVehicles')"
           id="location-div-countDistributionForVehicles">
        <div class="chartHeader">
          <button id="location-countDistributionForVehicles-expand"
                  v-if="enlargedChart !== 'countDistributionForVehicles'"
                  @click="handleClick('countDistributionForVehicles')"
                  class="expandCompressChartButton" title="Expand">
            <v-icon name="expand" class="expandChartButtonIcon"/>
          </button>
          <button id="location-countDistributionForVehicles-compress"
                  v-if="enlargedChart === 'countDistributionForVehicles'"
                  @click="closeLarge('countDistributionForVehicles')"
                  class="expandCompressChartButton" title="Compress">
            <v-icon name="compress" class="compressChartButtonIcon"/>
          </button>
        </div>
        <bar-chart getStateFunction="location/getGraphDataStructure"
                   :width="enlargedChart === 'countDistributionForVehicles' ? chartWidthLarge : chartWidth"
                   :height="enlargedChart === 'countDistributionForVehicles' ? chartHeightLarge : chartHeight"
                   :svgHeight="enlargedChart === 'countDistributionForVehicles' ? chartSVGHeightLarge : chartSVGHeight"
                   :margin="enlargedChart === 'countDistributionForVehicles' ? chartMarginLarge : chartMarginSmall"
                   yAxisLabel="Number of vehicles"
                   xAxisLabel="Locations (max 100 visible in graph)"
                   :legend=true
                   :graphTitle="getGraphTitle('countDistributionForVehicles')"
                   chartDataName="countDistributionForVehicles"
                   plotDataName="countDistributionForVehicles"
                   yKey="vehicles"
                   xKey="hexagons"/>
      </div>
    </div>
    <div id="mapButtons" v-if="showMapButtons">
      <button id="legendButton"
              @click="showLegend = true; showMapButtons = false">
        <span class="toolTipText">Legend</span>
        <v-icon name="info-circle" class="mapButtonIcon"/>
      </button>
    </div>
    <div id="legend" v-if="showLegend">
      <button id="closeLegendButton" @click="showLegend = false; showMapButtons = true">
        <v-icon name="times" id="closeLegendButtonIcon"/>
      </button>
      <div v-if="locationLayerVisible">
         <div class="legendRow">
           <div class="legendDescriptor" ><strong style="font-family: Montserrat; margin-top: 0.5vw; margin-bottom: 0.3vw; display:block;">Hexagon count / Total hexagons (Ratio of no. of hexagons)</strong></div>
           <div class="legendDescriptor"><strong style="font-family: Montserrat; margin-top: 0.5vw; margin-bottom: 0.3vw; display:block;">Number of stops</strong></div>
           <div class="legendDescriptor" style="margin-left:12px"><strong style="font-family: Montserrat; margin-top: 0.5vw; margin-bottom: 0.3vw; display:block;">Stops count / Total stops (Ratio of no. of stops)</strong></div>
         </div>
         <div @click="setFilterRange(colour.domain[0], colour.domain[1])"
             class="legendRow"
             v-for="colour in locationColours" :key="JSON.stringify(colour.colour)">
          <div class="legendDescriptor"> {{ hexagonCountLegend(colour.domain[0], colour.domain[1]) }}/{{ hexagonCountLegend(stopsCountMinMax[0], stopsCountMinMax[1]) }} ({{ (100*hexagonCountLegend(colour.domain[0], colour.domain[1])/hexagonCountLegend(stopsCountMinMax[0], stopsCountMinMax[1])).toFixed(1)  }}%)</div>
          <div :style="{ background: colour.colour, width: '12px', height: '12px'}">
            <div v-if="isFiltered(colour.domain[0], colour.domain[1])"
                 class="highlightSelectedLegendGroup"></div>
          </div>
          <div class="legendDescriptor"> {{ Math.round(colour.domain[0]) }} - {{ Math.round(colour.domain[1]) }}</div>
          <div class="legendDescriptor"> {{ stopsCountLegend(colour.domain[0], colour.domain[1]) }}/{{ stopsCountLegend(stopsCountMinMax[0], stopsCountMinMax[1]) }} ({{ (100*stopsCountLegend(colour.domain[0], colour.domain[1])/stopsCountLegend(stopsCountMinMax[0], stopsCountMinMax[1])).toFixed(1) }}%)</div>
        </div>
      </div>
      <b-card v-for="layer in layerNames" :key="layer.layerName"  >
        <strong style="font-family: Montserrat">{{ layer.layerName }}</strong>
        <div>
          <label :for="layer.layerName + 'color'">Stroke color:</label>
          <b-form-input :id="layer.layerName + 'color'"
                        :style="{width: '24px', height: '24px'}"
                        style="display: inline; margin-right: 1vw"
                        @change="handlePointPropChanged(layer.layerName, 'circle-stroke-color', layer.color)"
                        v-model="layer.color"
                        type="color">
          </b-form-input>
          <label :for="layer.layerName + 'radius'">Size/radius:</label>
          <b-form-input :id="layer.layerName + 'radius'"
                        :style="{width: '60px', height: '24px', display: 'inline'}"
                        :min="0"
                        style="display: inline; margin-right: 1vw"
                        v-model.number="layer.radius"
                        @change="handlePointPropChanged(layer.layerName, 'circle-radius', layer.radius)"
                        type="number">
          </b-form-input>
          <label :for="layer.layerName + 'stroke-width'">Stroke width:</label>
          <b-form-input :id="layer.layerName + 'stroke-width'"
                        :min="0"
                        :style="{width: '60px', height: '24px', display: 'inline'}"
                        style="display: inline; margin-right: 1vw"
                        @change="handlePointPropChanged(layer.layerName, 'circle-stroke-width', layer.strokeWidth)"
                        v-model.number="layer.strokeWidth"
                        type="number">
          </b-form-input>
          <span @click="filterGeoJSON(layer, null)">❌</span>
        </div>
      </b-card>
    </div>
  </div>
</template>

<script>

import mapboxgl from 'mapbox-gl'
import { H3HexagonLayer } from '@deck.gl/geo-layers'
import { MapboxLayer } from '@deck.gl/mapbox'
import { h3ToGeo } from 'h3-js'
import snowflake from '../../mixins/snowflake'
import SideMenu from './SideMenu.vue'
import * as d3 from 'd3'
import BarChart from '@/components/D3Graphs/BarChart'
import { orderBy } from 'lodash'
import * as powerLayer from '@/components/layers/power'

export default {
  name: 'LocationMap',
  components: { SideMenu, BarChart },
  mixins: [snowflake],
  data () {
    return {
      powerLayerVisible: false,
      selectedBaseLayer: 'scania',
      layerNames: [],
      currentZoom: 0,
      showLegend: false,
      showMapButtons: false,
      mapDataFinishedLoading: false,
      hexagonObject: null,
      hexagonPopup: null,
      selectedHexagons: new Set(),
      selectedGraphs: [],
      graphOptions: [
        { text: 'Stop duration', default_text: 'Stop duration', value: 'durationDistribution', stateFunction: 'durationDistribution', tabName: 'Stop duration', checked: false, visible: false },
        { text: 'Stops at location', default_text: 'Stops at location', value: 'countDistribution', stateFunction: 'countDistribution', tabName: 'countDistribution', checked: false, visible: false },
        { text: 'Stop start time', default_text: 'Stop start time', value: 'startHourDistribution', stateFunction: 'startHourDistribution', tabName: 'startHourDistribution', checked: false, visible: false },
        { text: 'Customers at location', default_text: 'Customers at location', value: 'countDistributionForCustomers', stateFunction: 'countDistributionForCustomers', tabName: 'countDistribution', checked: false, visible: false, featureOn: true, chartHeader: ['hexagons', 'no_customers'] },
        { text: 'Vehicles at location', default_text: 'Vehicles at location', value: 'countDistributionForVehicles', stateFunction: 'countDistributionForVehicles', tabName: 'countDistribution', checked: false, visible: false, featureOn: true, chartHeader: ['hexagons', 'vehicles'] }
      ],
      chartWidth: 600,
      chartHeight: 270,
      chartSVGHeight: '28vh',
      chartWidthLarge: 860,
      chartHeightLarge: 340,
      chartSVGHeightLarge: '90vh',
      chartMarginSmall: { top: 30, right: 20, bottom: 100, left: 80 },
      chartMarginLarge: { top: 20, right: 10, bottom: 100, left: 60 },
      chartFontSize: '0.7rem',
      enlargedChart: null,
      customerLayers: [],
      selectedPropValue: null,
      selectedPropKey: null,
      newPropList: [],
      newValList: [],
      hasRun: false
    }
  },
  created () {
    this.customerLayers.push('locations')
    this.setupMapEvents()
    if (this.$store.getters.getUserSelection === 'locations') {
      this.mapDataFinishedLoading = false
      this.addLocationLayerToMap()
      this.$store.commit('setResetModal', true)
      this.$store.commit('setModalVisibility', false)
      this.mapDataFinishedLoading = true
      this.$logger.message('{LA_created":"Location map created"}')
      this.showMapButtons = true
      this.$store.commit('setLayerVisibility', ['location', true])
      if (this.$store.getters.getAppConfigFlag('canSeePowerLayers')) {
        this.addGridSource()
        this.addPowerLayer()
      }
      this.showSelectedDataLayers()
    }
  },

  computed: {
    locationLayerVisible () {
      return this.$store.getters.getLayerVisibility('location')
    },
    mapObject: {
      get () {
        return this.$store.getters.getBaseMap
      },
      set (newMap) {
        this.$store.commit('setBaseMap', newMap)
      }
    },
    center () {
      return this.$store.getters['getCenterFor']('locationsMapCenter')
    },
    locationColours () {
      const colourScale = this.locationColourScale
      let colourObject = []
      const noStops = orderBy(this.fetchLocationData(), 'no_stops', 'asc')
        .map(p => p['no_stops'])
      if (noStops.length > 6) {
        for (var i = 0; i < colourScale.range().length; i++) {
          colourObject.push({ colour: colourScale.range()[i], domain: colourScale.invertExtent(colourScale.range()[i]) })
        }
        for (var n = 1; n < colourObject.length; n++) {
          colourObject[0].domain[0] = 0
          colourObject[n].domain[0] = colourObject[n].domain[0] + 1
        }
      } else {
        for (var x = 0; x < noStops.length; x++) {
          colourObject.push({ colour: colourScale.range()[x], domain: [noStops[x], noStops[x]] })
        }
        for (var p = 1; p < colourObject.length; p++) {
          colourObject[0].domain[0] = 0
          colourObject[p].domain[0] = colourObject[p - 1].domain[1] + 1
        }
      }
      return colourObject
    },
    locationColourScale () {
      let breakClass = 0
      let arrayRange = []
      let newArrayRange = []
      let tempArray = []
      let locColorScale
      const noStops = orderBy(this.fetchLocationData(), 'no_stops', 'asc').map(p => p['no_stops'])
      if (noStops.length > 6) {
        breakClass = Math.floor(noStops.length / 6)
        arrayRange = this.chunk(noStops, breakClass)
        arrayRange.forEach(i => {
          newArrayRange.push(d3.max(i))
        })
        for (var x = 0; x < newArrayRange.length; x++) {
          if (x < 5) {
            tempArray.push(newArrayRange[x])
          } else {
            if (x === newArrayRange.length - 1) {
              tempArray.push(newArrayRange[x])
            }
          }
        }
      }
      if (noStops.length > 6) {
        locColorScale = d3.scaleThreshold()
          .domain(tempArray)
          .range([d3.rgb(255, 249, 214),
            d3.rgb(253, 241, 157),
            d3.rgb(251, 234, 110),
            d3.rgb(231, 211, 60),
            d3.rgb(211, 192, 53),
            d3.rgb(190, 172, 45)])
      } else {
        locColorScale = d3.scaleOrdinal()
          .domain(noStops)
          .range([d3.rgb(255, 249, 214),
            d3.rgb(253, 241, 157),
            d3.rgb(251, 234, 110),
            d3.rgb(231, 211, 60),
            d3.rgb(211, 192, 53),
            d3.rgb(190, 172, 45)])
      }
      return locColorScale
    },
    stopsCountMinMax () {
      const noStops = this.fetchLocationData().map(p => p['no_stops'])
      const maxMinStops = [d3.min(noStops), d3.max(noStops)]
      return maxMinStops
    }
  },
  methods: {
    handlePointPropChanged (layerName, paintProperty, layerColor) {
      this.mapObject.setPaintProperty(layerName, paintProperty, layerColor)
    },
    chunk (arr, len) {
      var chunks = []
      var i = 0
      var n = arr.length
      while (i < n) {
        chunks.push(arr.slice(i, i += len))
      }
      return chunks
    },
    handleClick (activeEnlargedGraph) {
      this.enlargedChart = activeEnlargedGraph
    },
    closeLarge (activeEnlargedGraph) {
      this.enlargedChart = null
    },
    getGraphTitle (graph) {
      return this.graphOptions.find(item => item.value === graph).text
    },

    getGraphTabName (graph) {
      return this.graphOptions.find(item => item.value === graph).tabName
    },
    checkGraphSize (graph) {
      if (this.enlargedChart === graph) {
        return 'largeChart'
      } else if (this.selectedGraphs.length <= 4) {
        return 'smallChart'
      } else {
        return 'xSmallChart'
      }
    },

    checkGraphWidgetSize () {
      if (this.enlargedChart !== null) {
        return 'largeChartWidget'
      } else if (this.selectedGraphs.length <= 4) {
        return 'smallChartWidget'
      } else {
        return 'xSmallChartWidget'
      }
    },

    isFiltered (minValue, maxValue) {
      const filtered = this.fetchLocationData().filter((h) => h.no_stops >= minValue && h.no_stops <= maxValue).map(h => h.hexagons)

      let copySelectedHexes = [...this.selectedHexagons]
      let countMatches = 0
      for (let i = 0; i < filtered.length; i++) {
        let index = copySelectedHexes.indexOf(filtered[i])
        if (index !== -1) {
          countMatches++
        }
      }
      if (countMatches !== 0 && filtered.length === countMatches) return true
      return false
    },
    showSelectedDataLayers () {
      // Hide all custom layers
      let numberOfMapIds = this.$store.getters.getMapLayerIds.length
      while (numberOfMapIds--) {
        let layerId = this.$store.getters.getMapLayerIds[numberOfMapIds]
        if (typeof this.mapObject.getLayer(layerId) !== 'undefined') {
          this.mapObject.setLayoutProperty(layerId, 'visibility', 'none')
        }
      }

      // Show the layer set to visible
      let numberOfVisibleMapIds = this.$store.getters.getLayers.length
      while (numberOfVisibleMapIds--) {
        let layerId = this.$store.getters.getLayers[numberOfVisibleMapIds]
        if (typeof this.mapObject.getLayer(layerId) !== 'undefined') {
          this.mapObject.setLayoutProperty(layerId, 'visibility', 'visible')
        }
      }
    },

    switchBaseLayer () {
      if (this.mapObject.getLayer('location')) {
        this.mapObject.removeLayer('location')
      }
      return new Promise(resolve => {
        // Get source and layer to add them back after the style change,
        // since the map's style object is the one holding the layers and the respective filters, etc.
        let layer = this.selectedBaseLayer
        if (layer === 'scania') {
          this.mapObject.setStyle('mapbox://styles/connected-intelligence/ckhpx27eb02zt19qrm3qphq46')
        } else {
          this.mapObject.setStyle('mapbox://styles/mapbox/' + layer)
        }
        this.mapObject.on('style.load', _ => {
          resolve()
        })
      })
    },

    setupMapEvents () {
      this.mapObject.on('click', this.setUpLocationClickEvent)
      this.mapObject.on('moveend', this.setUpZoom)
    },

    popupDataInfoOnClick (e) {
      let coordinates = e.features[0].geometry.coordinates.slice()
      let description = '<strong>'
      description += JSON.stringify(e.features[0].properties)
      description += '</br></strong>'

      while (Math.abs(e.lngLat.lng - coordinates[0]) > 180) {
        coordinates[0] += e.lngLat.lng > coordinates[0] ? 360 : -360
      }
      new mapboxgl.Popup()
        .setLngLat(coordinates)
        .setHTML(description)
        .addTo(this.mapObject)
      this.$logger.message(`{"LA_popupDataInfoOnClick":[{"event":"mouseup"}, {"className":"mapboxgl-popup-open"}]}`, 'info')
    },
    setFilterRange (minValue, maxValue) {
      const filtered = this.fetchLocationData()
        .filter((h) => h.no_stops >= minValue && h.no_stops <= maxValue)
        .map(h => h.hexagons)

      let copySelectedHexes = new Set(this.selectedHexagons)
      for (let i = 0; i < filtered.length; i++) {
        let inSet = copySelectedHexes.has(filtered[i])
        if (inSet) {
          copySelectedHexes.delete(filtered[i])
        } else {
          if (filtered[i]) copySelectedHexes.add(filtered[i])
        }
      }
      this.selectHexagons(copySelectedHexes)
    },
    hexagonCountLegend (minValue, maxValue) {
      const hexFiltered = this.fetchLocationData().filter((h) => h.no_stops >= minValue && h.no_stops <= maxValue).map(h => h.hexagons)
      const hexCountPerBucket = hexFiltered.length
      return hexCountPerBucket
    },
    stopsCountLegend (minValue, maxValue) {
      const hexFiltered = this.fetchLocationData().filter((h) => h.no_stops >= minValue && h.no_stops <= maxValue).map(h => h.no_stops)
      const stopsCountBucket = d3.sum(hexFiltered)
      return stopsCountBucket
    },
    setUpLocationClickEvent (e) {
      const deck = this.mapObject.__deck
      if (deck !== null) {
        let pointerObject = deck._lastPointerDownInfo
        if (pointerObject.layer !== null) {
          if (pointerObject.layer.id === 'location' &&
            this.$store.getters.getLayerVisibility('location')) {
            this.popupDataInfoOnClickPolygon(e)
            // toggle hex selected state
            let copySelectedHexes = new Set(this.selectedHexagons)
            if (copySelectedHexes.has(this.hexagonObject.hexagons)) {
              copySelectedHexes.delete(this.hexagonObject.hexagons)
            } else {
              copySelectedHexes.add(this.hexagonObject.hexagons)
            }
            this.selectHexagons(copySelectedHexes)
          }
        }
      }
      this.$logger.message(`{"LA_setUpLocationClickEvent":"Map center is at: ${this.mapObject.getCenter()}"}`, 'info')
    },
    selectHexagons (selectedHexes) {
      this.$store.commit('location/setSelectedHexagons', selectedHexes)
      const copySelectedHexes = [...selectedHexes]
      let refreshedLayers = []
      let currLayers = this.mapObject.__deck.layerManager.getLayers()
      let layer = currLayers.filter(l => l.id === 'location')[0]
      const toRBGArray = (rgbObj) => { return [rgbObj.r, rgbObj.g, rgbObj.b] }

      this.selectedHexagons = new Set(selectedHexes)

      let newLayer = layer.clone({
        getFillColor: (hexagon) => {
          const col = this.selectedHexagons.has(hexagon.hexagons)
            ? [255, 0, 128]
            : toRBGArray(d3.rgb(this.locationColourScale(hexagon.no_stops)))
          return col
        },
        updateTriggers: {
          getFillColor: copySelectedHexes
        }
      })
      refreshedLayers.push(newLayer)
      this.mapObject.__deck.setProps({ layers: refreshedLayers })
    },
    popupDataInfoOnClickPolygon (e) {
      if (this.hexagonObject) {
        let coordinates = this.transposeCoordinates(this.hexagonObject['hexagons'])
        let description = '<strong> Hexagon id: ' + this.hexagonObject['hexagons'] +
          '<br/><strong>Number of stops: </strong>' + this.hexagonObject.no_stops +
          '<br/><strong>Min stand still duration: </strong>' + Math.floor(this.hexagonObject.min_duration_second / 60) +
          '<strong> (m)</strong>' +
          '<br/><strong>Max stand still duration: </strong>' + Math.floor(this.hexagonObject.max_duration_second / 60) +
          '<strong> (m)</strong>' +
          '<br/><strong>Unique vehicles: </strong>' + Math.floor(this.hexagonObject.vehicles) +
          '<br/><strong>Number of customers: </strong>' + Math.floor(this.hexagonObject.no_customers)
        // Ensure that if the map is zoomed out such that multiple
        // copies of the feature are visible, the popup appears
        // over the copy being pointed to.
        while (Math.abs(e.lngLat.lng - coordinates[0] > 180)) {
          coordinates[0] += e.lngLat.lng > coordinates[0] ? 360 : -360
        }
        new mapboxgl.Popup()
          .setLngLat(coordinates)
          .setHTML(description)
          .addTo(this.mapObject)
      }
      this.$logger.message(`{"LA_popupDataInfoOnClickPolygon":[{"event":"mouseup"}, {"className":"mapboxgl-popup-open"}]}`, 'info')
    },
    fetchLocationData () {
      return this.$store.getters['location/getLocationAggregates']
    },
    addGridSource () {
      this.mapObject.addSource('openinframap', {
        'type': 'vector',
        'minzoom': 4,
        'maxzoom': 17,
        'tiles': ['https://openinframap.org/tiles/{z}/{x}/{y}.pbf']
      })
    },
    addPowerLayer () {
      if (!this.mapObject.getSource('openinframap')) {
        this.addGridSource()
      }
      this.powerLayerVisible = true
      this.$logger.message(`{"LA_addPowerLayer":"Will show the power layer"}`, 'info')
      powerLayer.layers.forEach(layer => {
        if (this.mapObject.getLayer(layer.id)) {
          this.mapObject.removeLayer(layer.id)
        }
        this.mapObject.addLayer(layer)
        this.$store.commit('setLayerVisibility', [layer.id, true])
        this.mapObject.setLayoutProperty(layer.id, 'visibility', 'visible')
      })
    },
    hidePowerLayer () {
      this.powerLayerVisible = false
      this.$logger.message(`{"LA_hidePowerLayer":"Hiding the power layer from map"}`, 'info')
      powerLayer.layers.forEach(layer => {
        this.mapObject.setLayoutProperty(layer.id, 'visibility', 'none')
        this.$store.commit('setLayerVisibility', [layer.id, false])
      })
    },
    addLocationLayerToMap () {
      if (this.mapObject.getLayer('location')) {
        this.mapObject.removeLayer('location')
      }
      if (!this.mapObject.getLayer('location')) {
        const addLayerUnderneathWaterLayer = !this.mapObject.getStyle().sprite.includes('satellite')
        const toRBGArray = (rgbObj) => { return [rgbObj.r, rgbObj.g, rgbObj.b] }
        const locationData = this.fetchLocationData()
        const locationHexagonLayer = new MapboxLayer({
          id: 'location',
          type: H3HexagonLayer,
          parameters: {
            depthTest: false
          },
          data: locationData,
          autoHighlight: true,
          opacity: 1,
          getHexagon: (hexagon) => hexagon.hexagons,
          getFillColor: (hexagon) => {
            const col = this.selectedHexagons.has(hexagon.hexagons)
              ? [255, 0, 128]
              : toRBGArray(d3.rgb(this.locationColourScale(hexagon.no_stops)))
            return col
          },
          pickable: true,
          extruded: false,
          updateTriggers: {
            getFillColor: this.selectedHexagons
          },
          onClick: hexagon => {
            this.hexagonObject = { ...hexagon.object }
            return true
          }
        })
        if (addLayerUnderneathWaterLayer) {
          this.mapObject.addLayer(locationHexagonLayer, 'waterway')
        } else {
          this.mapObject.addLayer(locationHexagonLayer)
        }
        if (!this.hasRun) {
          this.flyInOnLoad()
          this.hasRun = true
        }
      }
    },
    removeCustomerLayer (layerName) {
      this.mapObject.removeLayer(layerName)
      this.mapObject.removeSource(layerName)
      this.customerLayers = this.customerLayers.filter(layer => layer !== layerName)
      this.layerNames = this.layerNames.filter(layer => layer.layerName !== layerName)
    },
    reAddCustomerLayers (layer) {
      this.mapObject.addSource(layer.layerName, {
        type: 'geojson',
        data: layer.jsonSource
      })
      let newLayer = {
        'id': layer.layerName,
        'type': 'circle',
        'source': layer.layerName,
        'layout': {
          'visibility': 'visible'
        },
        'paint': {
          'circle-stroke-color': layer.color,
          'circle-stroke-width': layer.strokeWidth,
          'circle-color': 'rgba(0,0,0,.6)',
          'circle-radius': layer.radius
        }
      }
      this.mapObject.addLayer(newLayer)
      this.$store.commit('setLayerVisibility', [layer.layerName, true])
    },
    addGeoJSONLayer (geoJSON) {
      this.customerLayers.push(geoJSON.name)
      let defaultColor = '#DFFE00'
      let defaultRadius = 8
      let defaultStrokeWidth = 4

      this.layerNames.push({
        jsonSource: geoJSON.geojson,
        layerName: geoJSON.name,
        color: defaultColor,
        radius: defaultRadius,
        strokeWidth: defaultStrokeWidth
      })
      this.mapObject.addSource(geoJSON.name, {
        type: 'geojson',
        data: geoJSON.geojson
      })

      let newLayer = {
        'id': geoJSON.name,
        'type': 'circle',
        'source': geoJSON.name,
        'layout': {
          'visibility': 'visible'
        },
        'paint': {
          'circle-stroke-color': defaultColor,
          'circle-stroke-width': defaultStrokeWidth,
          'circle-color': 'rgba(0,0,0,.6)',
          'circle-radius': defaultRadius
        }
      }
      this.mapObject.addLayer(newLayer)
      this.$store.commit('setLayerVisibility', [geoJSON.name, true])
      this.showSelectedDataLayers()
      this.mapObject.on('click', geoJSON.name, this.popupDataInfoOnClick)
    },
    transposeCoordinates (h3CellIndex) {
      let invertedCoordinates = h3ToGeo(h3CellIndex)
      return [invertedCoordinates[1], invertedCoordinates[0]]
    },
    flyInOnLoad () {
      const locationAggregates = this.$store.getters['location/getLocationAggregates']
      if (locationAggregates === null) return
      const maxObj = locationAggregates.reduce((prev, current) => (prev.no_stops > current.no_stops) ? prev : current)
      const maxHex = maxObj.hexagons
      this.mapObject.easeTo({ center: this.transposeCoordinates(maxHex), zoom: 5, duration: 8000 })
    },
    handleDeckLayerChanged () {
      this.selectedHexagons = new Set()
      this.addLocationLayerToMap()
    },
    updateGraphOptions (options) {
      this.graphOptions = options
    },
    updateGraphSelection (selection) {
      this.selectedGraphs = selection
    },
    selectMapStyle (mapStyle) {
      this.selectedBaseLayer = mapStyle
    },
    setUpZoom () {
      this.currentZoom = this.mapObject.getZoom()
    }
  },

  watch: {
    '$store.state.triggerDeckRedraw': function () {
      if (this.$store.getters.getUserSelection === 'locations') {
        this.$store.commit('setLayerVisibility', ['location', true])
        this.selectedHexagons = new Set()
        this.hexagonObject = null
        this.addLocationLayerToMap()
        this.$store.commit('setTriggerDeckRedraw', false)
        this.$store.commit('setResetModal', true)
        this.$store.commit('setModalVisibility', false)
      }
    },

    currentZoom: function () {
      if (this.mapDataFinishedLoading) {
        this.$logger.message(`{"LA_currentZoom":"Zoom level changed to: ${this.mapObject.getZoom()}"}`, 'info')
      }
    },

    selectedBaseLayer: function () {
      this.switchBaseLayer().finally(() => {
        if (this.powerLayerVisible) {
          this.addGridSource()
          this.addPowerLayer()
        }
        this.addLocationLayerToMap()
        this.layerNames.forEach(dataLayer => {
          this.reAddCustomerLayers(dataLayer)
        })
        this.showSelectedDataLayers()
      })
    }
  },

  beforeDestroy () {
    this.$nextTick(() => {
      this.mapObject.off('click', this.setUpLocationClickEvent)
      this.customerLayers.map(layer => {
        this.mapObject.off('click', layer, this.popupDataInfoOnClick)
      })
      this.mapObject.off('moveend', this.setUpZoom)
    })
  }

}
</script>

<style scoped>
.loadingBar {
  margin-top: 10px;
  left: 0;
  right: 0;
  position: absolute;
  z-index: 1;
  color: white;
  font-family: 'Montserrat', sans-serif;
  font-size: 13px;
}
#privacyNotice {
  animation: cssAnimation2 0s 6s forwards;
  visibility: hidden;
}

span.block {
  display: block;
}
@keyframes cssAnimation1 {
  to {
    width: 0;
    height: 0;
    overflow: hidden;
  }
}
@-webkit-keyframes cssAnimation1 {
  to {
    width: 0;
    height: 0;
    visibility: hidden;
  }
}
@keyframes cssAnimation2 {
  to {
    visibility: visible;
  }
}

button {
  cursor: pointer;
}

/* remove blue outline */
button:focus {
  outline: 0;
}

.legendDescriptor {
  margin: 0.5vw;
  width:160px;
  font-family: 'Montserrat', sans-serif;
}

.legendRow {
  justify-content: left;
  display: flex;
  align-items: center;
  cursor: pointer;
}

#closeLegendButton {
  position: absolute;
  top: 1px;
  right: 4px;
  background-color: transparent;
  border: none;
}
#closeLegendButtonIcon {
  height: 12px;
  width: 12px;
}
#legend {
  background-color: white;
  border-radius: 3px;
  position: absolute;
  right: 20px;
  top: 98px;
  z-index: 3;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
  padding: 1rem 20px 1rem 20px;
  opacity: 0.9;
}
#legendButton .toolTipText {
  visibility: hidden;
  width: 100px;
  background-color: black;
  color: #fff;
  font-family: 'Montserrat', sans-serif;;
  text-align: center;
  border-radius: 6px;
  padding: 5px 0;

  /* Position the tooltip */
  position: absolute;
  z-index: 1;
  top: -3px;
  right: 105%;
}
#legendButton:hover .toolTipText {
  visibility: visible;
}
.mapButtonIcon {
  fill: white;
  height: 25px;
  width: 25px;
}
#legendButton {
  background-color: transparent;
  border: none;
  position: relative;
  display: inline-block;
}
#mapButtons {
  position: absolute;
  z-index: 1;
  right: 0;
  top: 80px;
  padding: 1rem 18px 1rem 20px;
}

.highlightSelectedLegendGroup {
  background: rgb(255, 0, 128);
  margin-top: 4px;
  width: 12px;
  height: 4px;
}
.expandCompressChartButton {
  position: absolute;
  top: 1vh;
  right: 1.5vw;
  background-color: transparent;
  border: none;
  z-index: 5;
}

.compressChartButtonIcon {
  height: 35px;
  width: 35px;
}

.expandChartButtonIcon {
  height: 18px;
  width: 18px;
}

.xSmallChartWidget {
  position: absolute;
  z-index: 3;
  max-width: 60vw;
  background-color: rgba(255,255,255,0.8);
  margin-left: 37vw;
  bottom: 8px;
  margin-bottom: 0.5vh;
}

.smallChartWidget {
  position: absolute;
  z-index: 3;
  max-width: 60vw;
  background-color: rgba(255,255,255,0.8);
  margin-left: 37vw;
  bottom: 8px;
  margin-bottom: 0.5vh;
}

.largeChartWidget {
  position: absolute;
  z-index: 4;
  width: 100%;
  height: 90vh;
  overflow: hidden;
  background-color: rgba(255,255,255,1);
  padding: 20px;
}

.xSmallChart {
  width: 20vw;
  height: 32vh;
  position: relative;
  float: left;
}

.smallChart {
  width: 30vw;
  height: 32vh;
  position: relative;
  float: left;
}

.largeChart {
  width: 100%;
  position: absolute;
  background-color: rgba(255,255,255,1);
  z-index: 10;
}
</style>

<style>
.mapboxgl-popup-content {
  word-wrap: break-word;
}
</style>

<style src="../../../node_modules/vue-multiselect/dist/vue-multiselect.min.css"></style>
