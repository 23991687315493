<template>
  <div id="titleBar">
    <div id="tileTitle">
      <p style="text-align: left;"> {{ tileSetName }} </p>
    </div>
    <button class="dataButton" @click="openModal">
      <v-icon class="dataButtonIcon" name="cloud-download-alt"/>
    </button>
  </div>
</template>
<script>
export default {
  components: { },
  name: 'DataSetSelector',
  computed: {
    tileSetName () {
      if (this.$store.getters.getUserSelection.includes('chassis')) {
        return this.$store.getters['chassis/getRequestedTilesName']
      } else {
        return ''
      }
    }
  },
  methods: {
    openModal () {
      this.$store.commit('setModalVisibility', true)
    }
  }
}
</script>
<style scoped>
#titleBar {
  display: flex;
  justify-content: space-around;
}

#tileTitle {
  width: 70%;
  color: white;
  font-size: 15px;

}

.dataButton {
  background-color: #f8f9fa;
  border: none;
  height: 35px;
  width: 35px;
  border-radius: 5px;
  margin-right: 8.5%;
  margin-bottom: 10px;
}

.dataButtonIcon {
  height: 25px;
  width: 25px;
}

p {
  text-align: left;
  max-width: 480px;
  word-wrap: break-word;
  font-weight: bold;
}
</style>
