<template>
  <div id="filterPanel">
    <div v-if="this.$store.getters.getAppConfigFlag('isLayerToggleUser')">
      <div style="height: 90%;">
        <div id="filterSelect" >
          <div id="filterMultiSelect">
            <multiselect
              ref="chassisMultiSelect"
              v-model="chosenChassis"
              :options="chassisOptions"
              :multiple="true"
              track-by="text"
              placeholder="Filter on chassis"
              label="text"
              :clear-on-select="false"
              :limit="3"
              @input="updateSelectedChassis"
            >
            </multiselect>
          </div>
          <button class="resetChassisButton"
                  @click="deselectChassis()"
                  :disabled='filteringRanges'>
            <font-awesome-icon icon="fa-solid fa-xmark" inverse
                               class="resetChassisButtonIcon"/>
          </button>
        </div>
        <div id="locationFilterContainer">
          <div id="locationFilter">
            <multiselect
              v-model="selectedHexagons"
              :options="hexagonOptions"
              :multiple="true"
              track-by="text"
              placeholder="Select a hexagon to also filter on location"
              :preserve-search="true"
              label="text"
              :clear-on-select="false"
              @input="updateSelectedH3CellIndex"
            />
          </div>
          <button class="resetChassisButton"
                  @click="deselectH3CellIndex()"
                  :disabled='filteringRanges'>
            <font-awesome-icon icon="fa-solid fa-xmark" inverse
                               class="resetChassisButtonIcon"/>
          </button>
        </div>
        <div id="dateRange">
          <div class="datePicker">
            <vc-date-picker v-model="startDate">
              <template v-slot="{ inputValue, togglePopover }">
                <div class="flex items-center">
                  <button
                    class="p-2 bg-blue-100 border border-blue-200 hover:bg-blue-200 text-blue-600 rounded-l
                              focus:bg-blue-500 focus:text-white focus:border-blue-500 focus:outline-none"
                    @click="togglePopover()"
                  >
                    <v-icon name="calendar"/>
                  </button>
                  <input
                    :value="inputValue"
                    class="bg-white text-gray-700 w-full py-1 px-2 appearance-none border rounded-r focus:outline-none focus:border-blue-500"
                    readonly
                  />
                </div>
              </template>
            </vc-date-picker>
          </div>
          <div class="datePicker">
            <vc-date-picker v-model="endDate">
              <template v-slot="{ inputValue, togglePopover }">
                <div class="flex items-center">
                  <button
                    class="p-2 bg-blue-100 border border-blue-200 hover:bg-blue-200 text-blue-600 rounded-l
                            focus:bg-blue-500 focus:text-white focus:border-blue-500 focus:outline-none"
                    @click="togglePopover()"
                  >
                    <v-icon name="calendar"/>
                  </button>
                  <input
                    :value="inputValue"
                    class="bg-white text-gray-700 w-full py-1 px-2 appearance-none border rounded-r focus:outline-none focus:border-blue-500"
                    readonly
                  />
                </div>
              </template>
            </vc-date-picker>
          </div>
        </div>
        <div id="stopFilterContainer">
          <div style="height: 200px;overflow-y:auto ;width:75% ">
          <div id="stopFilter" style="width: 100%;">
            <rangeInput :minRange="minOdometerForRange"
                        :maxRange="maxOdometerForRange"
                        range="chassis/getSelectedMinMaxOdometer"
                        minStateFunctionString="chassis/setSelectedMinOdometer"
                        maxStateFunctionString="chassis/setSelectedMaxOdometer"
                        setStateFunctionString="chassis/setSelectedMinMaxOdometer"
                        filterKey="odometer"
                        filterName="Stop ingoing delta odometer (km)"
                        unit="km">
            </rangeInput>
            <rangeInput :minRange="minFuelForRange"
                        :maxRange="maxFuelForRange"
                        range="chassis/getSelectedMinMaxFuel"
                        minStateFunctionString="chassis/setSelectedMinFuel"
                        maxStateFunctionString="chassis/setSelectedMaxFuel"
                        setStateFunctionString="chassis/setSelectedMinMaxFuel"
                        filterKey="totalfuel"
                        filterName="Stop ingoing delta fuel (l)"
                        unit="l"
            >
            </rangeInput>
            <rangeInput :minRange="minDurationForRange"
                        :maxRange="maxDurationForRange"
                        range="chassis/getSelectedMinMaxDuration"
                        minStateFunctionString="chassis/setSelectedMinDuration"
                        maxStateFunctionString="chassis/setSelectedMaxDuration"
                        setStateFunctionString="chassis/setSelectedMinMaxDuration"
                        filterKey="stop_duration"
                        filterName="Stop duration (s)"
                        unit="s"
                        >
            </rangeInput>
            <div class="dayOfWeekSelection">
              <div class="dayOfWeekCheckbox"
                   v-for="(dayName, dayNumber) in dayOfWeek"
                   :key="'weekday-' + dayName">
                <input class="weekday"
                       :id="'weekday-' + dayName"
                       v-model="selectedWeekday"
                       :value="(dayNumber+1)%7"
                       type="checkbox"/>
                <label class="weekdayLabel"
                       :for="'weekday-' + dayName">
                  {{dayName}}
                </label>
              </div>
            </div>
          </div>
          </div>
        </div>
      </div>
      <div id="filterButtonContainer">
        <div id="filterButton">
          <p v-if="filteringRequestFailed" id="onFilteringError">
            The requested filter failed. Please try broaden your filters!
          </p>
          <b-button pill variant="success" size="sm" id="rangeFilterButton"
                    @click="onFilterButton"
                    :disabled='filteringRanges'>
            <b-spinner v-if="filteringRanges" small type="grow"></b-spinner>
            Filter data
          </b-button>
        </div>
      </div>
    </div>
    <div v-if="this.$store.getters.getAppConfigFlag('isMarketUser')">
      <div id="filterSelect">
        <div id="filterMultiSelect">
          <multiselect
            ref="chassisMultiSelect"
            v-model="chosenChassis"
            :options="chassisOptions"
            :multiple="true"
            track-by="text"
            placeholder="Filter on chassis"
            label="text"
            :clear-on-select="false"
            :limit="3"
            @input="updateSelectedChassis"
          >
          </multiselect>
        </div>
        <button class="resetChassisButton"
                @click="deselectChassis()"
                :disabled='filteringRanges'>
          <font-awesome-icon icon="fa-solid fa-xmark" inverse
                             class="resetChassisButtonIcon"/>
        </button>
      </div>
      <div id="dateRange">
        <div class="datePicker">
          <vc-date-picker v-model="startDate">
            <template v-slot="{ inputValue, togglePopover }">
              <div class="flex items-center">
                <button
                  class="p-2 bg-blue-100 border border-blue-200 hover:bg-blue-200 text-blue-600 rounded-l
                              focus:bg-blue-500 focus:text-white focus:border-blue-500 focus:outline-none"
                  @click="togglePopover()"
                >
                  <v-icon name="calendar"/>
                </button>
                <input
                  :value="inputValue"
                  class="bg-white text-gray-700 w-full py-1 px-2 appearance-none border rounded-r focus:outline-none focus:border-blue-500"
                  readonly
                />
              </div>
            </template>
          </vc-date-picker>
        </div>
        <div class="datePicker">
          <vc-date-picker v-model="endDate">
            <template v-slot="{ inputValue, togglePopover }">
              <div class="flex items-center">
                <button
                  class="p-2 bg-blue-100 border border-blue-200 hover:bg-blue-200 text-blue-600 rounded-l
                            focus:bg-blue-500 focus:text-white focus:border-blue-500 focus:outline-none"
                  @click="togglePopover()"
                >
                  <v-icon name="calendar"/>
                </button>
                <input
                  :value="inputValue"
                  class="bg-white text-gray-700 w-full py-1 px-2 appearance-none border rounded-r focus:outline-none focus:border-blue-500"
                  readonly
                />
              </div>
            </template>
          </vc-date-picker>
        </div>
      </div>
      <div id="stopFilterContainer">
        <div id="stopFilter">
          <rangeInput :minRange="minDurationForRange"
                      :maxRange="maxDurationForRange"
                      range="chassis/getSelectedMinMaxDuration"
                      minStateFunctionString="chassis/setSelectedMinDuration"
                      maxStateFunctionString="chassis/setSelectedMaxDuration"
                      setStateFunctionString="chassis/setSelectedMinMaxDuration"
                      filterKey="stop_duration"
                      filterName="Stop duration (min)"
                      unit="min"
          >
          </rangeInput>
          <b-link size="sm" id="rangeFilterButton"
                  @click="onResetFilter"> Clear
          </b-link>
        </div>
      </div>
      <div id="locationFilterContainer">
        <div id="locationFilter">
          <multiselect
            v-model="selectedHexagons"
            :options="hexagonOptions"
            :multiple="true"
            track-by="text"
            placeholder="Select a hexagon to also filter on location"
            :preserve-search="true"
            label="text"
            :clear-on-select="false"
            @input="updateSelectedH3CellIndex"
          />
        </div>
      </div>
      <div id="filterButtonContainer">
        <div id="filterButton">
          <p v-if="filteringRequestFailed" id="onFilteringError">
            The requested filter failed. Please try broaden your filters!
          </p>
          <b-button pill variant="success" size="sm" id="rangeFilterButton"
                    @click="onFilterButton"
                    :disabled='filteringRanges'>
            <b-spinner v-if="filteringRanges" small type="grow"></b-spinner>
            Filter data
          </b-button>
        </div>
      </div>
    </div>
    <div v-else>
      <div id="filterSelect">
        <div id="filterMultiSelect">
          <multiselect
            ref="chassisMultiSelect"
            v-model="chosenChassis"
            :options="chassisOptions"
            :multiple="true"
            track-by="text"
            placeholder="Filter on chassis"
            label="text"
            :clear-on-select="false"
            :limit="3"
            @input="updateSelectedChassis"
          >
          </multiselect>
        </div>
        <button class="resetChassisButton"
                @click="deselectChassis()"
                :disabled='filteringRanges'>
          <font-awesome-icon icon="fa-solid fa-xmark" inverse
                             class="resetChassisButtonIcon"/>
        </button>
      </div>
      <div id="dateRange">
        <div class="datePicker">
          <vc-date-picker v-model="startDate">
            <template v-slot="{ inputValue, togglePopover }">
              <div class="flex items-center">
                <button
                  class="p-2 bg-blue-100 border border-blue-200 hover:bg-blue-200 text-blue-600 rounded-l
                              focus:bg-blue-500 focus:text-white focus:border-blue-500 focus:outline-none"
                  @click="togglePopover()"
                >
                  <v-icon name="calendar"/>
                </button>
                <input
                  :value="inputValue"
                  class="bg-white text-gray-700 w-full py-1 px-2 appearance-none border rounded-r focus:outline-none focus:border-blue-500"
                  readonly
                />
              </div>
            </template>
          </vc-date-picker>
        </div>
        <div class="datePicker">
          <vc-date-picker v-model="endDate">
            <template v-slot="{ inputValue, togglePopover }">
              <div class="flex items-center">
                <button
                  class="p-2 bg-blue-100 border border-blue-200 hover:bg-blue-200 text-blue-600 rounded-l
                            focus:bg-blue-500 focus:text-white focus:border-blue-500 focus:outline-none"
                  @click="togglePopover()"
                >
                  <v-icon name="calendar"/>
                </button>
                <input
                  :value="inputValue"
                  class="bg-white text-gray-700 w-full py-1 px-2 appearance-none border rounded-r focus:outline-none focus:border-blue-500"
                  readonly
                />
              </div>
            </template>
          </vc-date-picker>
        </div>
      </div>
      <div id="stopFilterContainer">
        <div id="stopFilter">
          <rangeInput :minRange="minOdometerForRange"
                      :maxRange="maxOdometerForRange"
                      range="chassis/getSelectedMinMaxOdometer"
                      minStateFunctionString="chassis/setSelectedMinOdometer"
                      maxStateFunctionString="chassis/setSelectedMaxOdometer"
                      setStateFunctionString="chassis/setSelectedMinMaxOdometer"
                      filterKey="odometer"
                      filterName="Stop ingoing delta odometer (km)"
                      unit="km"
          >
          </rangeInput>
          <rangeInput :minRange="minFuelForRange"
                      :maxRange="maxFuelForRange"
                      range="chassis/getSelectedMinMaxFuel"
                      minStateFunctionString="chassis/setSelectedMinFuel"
                      maxStateFunctionString="chassis/setSelectedMaxFuel"
                      setStateFunctionString="chassis/setSelectedMinMaxFuel"
                      filterKey="totalfuel"
                      filterName="Stop ingoing delta fuel (l)"
                      unit="l"
          >
          </rangeInput>
          <rangeInput :minRange="minDurationForRange"
                      :maxRange="maxDurationForRange"
                      range="chassis/getSelectedMinMaxDuration"
                      minStateFunctionString="chassis/setSelectedMinDuration"
                      maxStateFunctionString="chassis/setSelectedMaxDuration"
                      setStateFunctionString="chassis/setSelectedMinMaxDuration"
                      filterKey="stop_duration"
                      filterName="Stop duration (s)"
                      unit="s"
          >
          </rangeInput>
          <div class="dayOfWeekSelection">
            <div class="dayOfWeekCheckbox"
                 v-for="(dayName, dayNumber) in dayOfWeek"
                 :key="'weekday-' + dayName">
              <input class="weekday"
                     :id="'weekday-' + dayName"
                     v-model="selectedWeekday"
                     :value="(dayNumber+1)%7"
                     type="checkbox"/>
              <label class="weekdayLabel"
                     :for="'weekday-' + dayName">
                {{dayName}}
              </label>
            </div>
          </div>
          <b-link size="sm" id="rangeFilterButton" v-if="!this.$store.getters.getAppConfigFlag('isMarketUser')"
                    @click="onResetFilter"> Clear
          </b-link>
        </div>
      </div>
      <div id="locationFilterContainer">
        <div id="locationFilter">
          <multiselect
            v-model="selectedHexagons"
            :options="hexagonOptions"
            :multiple="true"
            track-by="text"
            placeholder="Select a hexagon to also filter on location"
            :preserve-search="true"
            label="text"
            :clear-on-select="false"
            @input="updateSelectedH3CellIndex"
          />
        </div>
      </div>
      <div id="filterButtonContainer">
        <div id="filterButton">
          <p v-if="filteringRequestFailed" id="onFilteringError">
            The requested filter failed. Please try broaden your filters!
          </p>
          <b-button pill variant="success" size="sm" id="rangeFilterButton"
                    @click="onFilterButton"
                    :disabled='filteringRanges'>
            <b-spinner v-if="filteringRanges" small type="grow"></b-spinner>
            Filter data
          </b-button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Multiselect from 'vue-multiselect'
import RangeInput from '@/components/RangeInput.vue'
import snowflake from '../../mixins/snowflake'
export default {
  name: 'FilterPanel',
  mixins: [snowflake],
  components: { Multiselect, RangeInput },
  timeStart: 0,
  data () {
    return {
      chosenChassis: [],
      filteringRanges: false,
      filteringRequestFailed: false,
      selectedHexagons: [],
      dayOfWeek: ['Mo', 'Tu', 'We', 'Th', 'Fr', 'Sa', 'Su'],
      selectedWeekday: []
    }
  },
  methods: {
    chassisOnLocationOptions () {
      const chassisReturned = new Set(this.$store.getters['chassis/getHexagonLayer'].flatMap(hexagon => hexagon.chassi_list.map(chassi => chassi)))

      for (let i = 0; i < this.chosenChassis.length; i++) {
        this.$refs.chassisMultiSelect.$emit('remove', this.chosenChassis[i])
        this.chosenChassis.splice(i, 1)
        i--
      }

      chassisReturned.forEach(chassi => {
        this.chosenChassis.push({ text: chassi })
        this.$refs.chassisMultiSelect.$emit('select', { text: chassi })
      })
      this.updateSelectedChassis()
    },
    updateSelectedChassis () {
      this.$store.commit('chassis/setSelectedChassis', this.chosenChassis.map(function (d) { return d['text'] }))
    },
    updateSelectedH3CellIndex () {
      this.$store.commit('chassis/setSelectedH3CellIndex', this.selectedHexagons.map(function (d) { return d['text'] }))
    },
    deselectChassis () {
      this.chosenChassis = []
      this.$store.commit('chassis/setSelectedChassis', [])
    },
    deselectH3CellIndex () {
      this.selectedHexagons = []
      this.$store.commit('chassis/setSelectedH3CellIndex', [])
    },
    onResetFilter () {
      this.$store.commit('chassis/setSelectedMinMaxOdometer', [null, null])
      this.$store.commit('chassis/setSelectedMinMaxFuel', [null, null])
      this.$store.commit('chassis/setSelectedMinMaxDuration', [ null, null ])
      this.selectedWeekday = []
    },
    prepareLogReq (reqParams) {
      let reqParamsForLog = { ...reqParams,
        chassiscount: this.$store.getters['chassis/getSelectedChassis'].length,
        selectedhexcount: this.$store.getters['chassis/getSelectedH3CellIndex'].length
      }
      delete reqParamsForLog.hexagonhexes
      delete reqParamsForLog.chassis
      return reqParamsForLog
    },
    onFilterButton () {
      this.timeStart = Date.now()
      this.filteringRanges = true
      this.filteringRequestFailed = false

      const getMinMaxQueryId = this.getQueryIdFromSnowflake('MIN_MAX_RANGES',
        'minMaxRanges')
      const getEdgesQueryId = this.getQueryIdFromSnowflake('EDGES', 'edges')
      const getDailyAggregatesQueryId = this.getQueryIdFromSnowflake('DAILY_AGGREGATES',
        'dailyAggregates')
      const getOdometerBoxplotQueryId = this.getQueryIdFromSnowflake('ODOMETER_BOXPLOT',
        'odometerBoxplot')
      const getFuelBoxplotQueryId = this.getQueryIdFromSnowflake('FUEL_BOXPLOT',
        'fuelBoxplot')
      const getHexagonLayerQueryId = this.getQueryIdFromSnowflake('HEXAGONS',
        'hexagons')
      const getDailyFuelBoxplotQueryId = this.getQueryIdFromSnowflake('DAILY_FUEL_BOXPLOT',
        'dailyFuelBoxplot')
      const getDailyOdometerBoxplotQueryId = this.getQueryIdFromSnowflake('DAILY_ODOMETER_BOXPLOT',
        'dailyOdometerBoxplot')
      const getHourlyLocationDistQueryId = this.getQueryIdFromSnowflake('HOURLY_ONGOING_STOPS',
        'hourlyLocationDistribution')
      const getDailyHourlyLocationDistQueryId = this.getQueryIdFromSnowflake('DAILY_HOURLY_ONGOING_STOPS',
        'dailyHourlyLocationDistribution')

      let reqParams = {
        chassis: this.$store.getters['chassis/getSelectedChassis'].join(','),
        hexresolution: this.$store.getters['chassis/getHexagonResolution'],
        mindate: this.$store.getters['chassis/getSelectedMinTime'].toLocaleString('sv-SE') + 'T00:00',
        maxdate: this.$store.getters['chassis/getSelectedMaxTime'].toLocaleString('sv-SE') + 'T23:59',
        constantmaxdate: this.$store.getters['chassis/getTilesConstantMaxTime'].toLocaleString('sv-SE'),
        minduration: this.$store.getters['chassis/getSelectedMinMaxDuration'][0] == null ? this.$store.getters['chassis/getMinMaxDuration'][0] : this.$store.getters['chassis/getSelectedMinMaxDuration'][0],
        maxduration: this.$store.getters['chassis/getSelectedMinMaxDuration'][1] == null ? this.$store.getters['chassis/getMinMaxDuration'][1] : this.$store.getters['chassis/getSelectedMinMaxDuration'][1],
        minodometer: this.$store.getters['chassis/getSelectedMinMaxOdometer'][0] == null ? this.$store.getters['chassis/getMinMaxOdometer'][0] : this.$store.getters['chassis/getSelectedMinMaxOdometer'][0],
        maxodometer: this.$store.getters['chassis/getSelectedMinMaxOdometer'][1] == null ? this.$store.getters['chassis/getMinMaxOdometer'][1] : this.$store.getters['chassis/getSelectedMinMaxOdometer'][1],
        minfuel: this.$store.getters['chassis/getSelectedMinMaxFuel'][0] == null ? this.$store.getters['chassis/getMinMaxFuel'][0] : this.$store.getters['chassis/getSelectedMinMaxFuel'][0],
        maxfuel: this.$store.getters['chassis/getSelectedMinMaxFuel'][1] == null ? this.$store.getters['chassis/getMinMaxFuel'][1] : this.$store.getters['chassis/getSelectedMinMaxFuel'][1],
        selectedweekdays: this.$store.getters['chassis/getSelectedWeekdays'].length > 0
          ? this.$store.getters['chassis/getSelectedWeekdays'].join(',') : 'none',
        hexagonhexes: this.$store.getters['chassis/getSelectedH3CellIndex'].length > 0
          ? this.$store.getters['chassis/getSelectedH3CellIndex'].join(',') : 'none',
        stopqueryid: this.$store.getters.getQueryIdFor('rawStops')
      }
      let fieldsToRemoveFromExport = []
      this.$store.getters.getAppConfigFlag('isMarketUser') ? fieldsToRemoveFromExport = ['stopqueryid', 'hexagonhexes', 'constantmaxdate', 'minfuel', 'maxfuel', 'minodometer', 'maxodometer'] : fieldsToRemoveFromExport = ['stopqueryid', 'hexagonhexes', 'constantmaxdate']
      fieldsToRemoveFromExport.push(this.$store.getters['chassis/getSelectedWeekdays'].length > 0 ? '' : 'selectedweekdays')
      fieldsToRemoveFromExport.push(
        this.$store.getters['chassis/getSelectedMinMaxDuration'][0] !== null
          ? ''
          : 'minduration'
      )
      fieldsToRemoveFromExport.push(
        this.$store.getters['chassis/getSelectedMinMaxDuration'][1] !== null
          ? ''
          : 'maxduration'
      )
      fieldsToRemoveFromExport.push(
        this.$store.getters['chassis/getSelectedMinMaxOdometer'][0] !== null
          ? ''
          : 'minodometer'
      )
      fieldsToRemoveFromExport.push(
        this.$store.getters['chassis/getSelectedMinMaxOdometer'][1] !== null
          ? ''
          : 'maxodometer'
      )
      fieldsToRemoveFromExport.push(
        this.$store.getters['chassis/getSelectedMinMaxOdometer'][0] !== null
          ? ''
          : 'minfuel'
      )
      fieldsToRemoveFromExport.push(
        this.$store.getters['chassis/getSelectedMinMaxOdometer'][1] !== null
          ? ''
          : 'maxfuel'
      )
      const exportFilters = { ...reqParams }
      fieldsToRemoveFromExport.forEach((field) => delete exportFilters[field])
      this.$store.commit('setRequestedFilters', [exportFilters])
      this.$logger.message(`{"onFilterButton":"${JSON.stringify(this.prepareLogReq(reqParams))}"}`, 'info')
      Promise.all([getMinMaxQueryId, getEdgesQueryId, getDailyAggregatesQueryId, getOdometerBoxplotQueryId,
        getFuelBoxplotQueryId, getHexagonLayerQueryId, getDailyFuelBoxplotQueryId, getDailyOdometerBoxplotQueryId,
        getHourlyLocationDistQueryId, getDailyHourlyLocationDistQueryId])
        .then(() => {
          const getOdometerBoxplotFromSnowflake = this.getOdometerBoxplotFromSnowflake()
          const getFuelBoxplotFromSnowflake = this.getFuelBoxplotFromSnowflake()
          const getMinMaxValuesFromSnowflake = this.getSelectedMinMaxValuesFromSnowflake()
          const getDailyGraphAggregateFromSnowflake = this.getDailyGraphAggregateFromSnowflake()
          const getEdgeLayerFromSnowflake = this.getEdgeLayerFromSnowflake()
          const getHexagonLayerFromSnowflake = this.getHexagonLayerFromSnowflake()
          const getDailyFuelBoxplot = this.getDailyFuelBoxplotFromSnowflake()
          const getDailyOdometerBoxplot = this.getDailyOdometerBoxplotFromSnowflake()
          const getHourlyLocationDistribution = this.getHourlyLocationDistributionFromSnowflake()
          const getDailyHourlyLocationDistribution = this.getDailyHourlyLocationDistributionFromSnowflake()

          Promise.all([getOdometerBoxplotFromSnowflake, getFuelBoxplotFromSnowflake, getMinMaxValuesFromSnowflake,
            getDailyGraphAggregateFromSnowflake, getEdgeLayerFromSnowflake, getHexagonLayerFromSnowflake, getDailyFuelBoxplot,
            getDailyOdometerBoxplot, getHourlyLocationDistribution, getDailyHourlyLocationDistribution])
            .then(() => {
              this.chassisOnLocationOptions()
              this.$emit('handle-on-filter')
              this.filteringRanges = false
              let endTime = Date.now()
              this.$logger.message(`{"onFilterButton":{"message":"Filtering done.","time":"${(endTime - this.timeStart) / 1000} seconds."}}`, 'info')
            })
            .catch((e) => {
              this.$logger.message(`{"onFilterButton":"Error in onFilterButton: ${e}"}`, 'error')
              this.filteringRequestFailed = true
              this.filteringRanges = false
            })
        })
    }
  },
  mounted () {
    this.defaultExportParams = { chassis: this.$store.getters['chassis/getSelectedChassis'].join(','),
      hexresolution: this.$store.getters['chassis/getHexagonResolution'],
      mindate: this.$store.getters['chassis/getSelectedMinTime'].toLocaleString('sv-SE') + 'T00:00',
      maxdate: this.$store.getters['chassis/getSelectedMaxTime'].toLocaleString('sv-SE') + 'T23:59'
    }
    this.$store.commit('setRequestedFilters', [this.defaultExportParams])
  },
  computed: {
    chassisOptions () {
      return this.$store.getters['chassis/getTilesChassis'].map((str) => ({ text: str }))
    },
    hexagonOptions () {
      return this.$store.getters['chassis/getH3CellIndexOptions'].map((str) => ({ text: str }))
    },
    startDate: {
      get () {
        return this.$store.getters['chassis/getSelectedMinTime']
      },
      set (value) {
        this.$store.commit('chassis/setSelectedMinTime', value)
      }
    },
    endDate: {
      get () {
        return this.$store.getters['chassis/getSelectedMaxTime']
      },
      set (value) {
        this.$store.commit('chassis/setSelectedMaxTime', value)
      }
    },
    maxOdometerForRange () {
      return this.$store.getters['chassis/getMinMaxOdometer'][1]
    },
    minOdometerForRange () {
      return this.$store.getters['chassis/getMinMaxOdometer'][0]
    },
    maxFuelForRange () {
      return this.$store.getters['chassis/getMinMaxFuel'][1]
    },
    minFuelForRange () {
      return this.$store.getters['chassis/getMinMaxFuel'][0]
    },
    maxDurationForRange () {
      return this.$store.getters['chassis/getMinMaxDuration'][1]
    },
    minDurationForRange () {
      return this.$store.getters['chassis/getMinMaxDuration'][0]
    }
  },
  watch: {
    selectedWeekday: function () {
      this.$store.commit('chassis/setSelectedWeekdays', this.selectedWeekday)
    }
  }
}
</script>

<style scoped>
#filterPanel {
  display: flex;
  width: 100%;
  justify-content: flex-start;
  flex-direction: column;
}

#dateRange {
  margin-top: 10px;
  padding-top: 8px;
  border-radius: 2px;
  width: 100%;
}

.datePicker {
  display: flex;
  justify-content: center;
  margin-top: 4px;
}

#filterSelect {
  margin-top: 10px;
  display: flex;
  justify-content: center;
  height: 43px;
}

#filterMultiSelect {
  width: 70%;
}

#dateRange {
  margin-top: 10px;
  padding-top: 8px;
  border-radius: 2px;
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
}

#stopFilterContainer {
  display: flex;
  justify-content: center;
  margin-top: 20px;
}

#stopFilter {
  background-color: #f8f9fa;
  color: #212529;
  padding: 15px;
  width: 70%;
}

#filterButtonContainer {
  display: flex;
  justify-content: flex-end;
  margin-right: 15%;
  margin-top: 15px;
}

#filterButton {
  display: flex;
  justify-content: center;
}

#onFilteringError {
  margin-top: 5px;
  margin-right: 10px;
  color: white;
  font-size: small;
}

#rangeFilterButton {
  height: 30px;
  font-size: small;
}

#locationFilterContainer {
  display: flex;
  justify-content: center;
  margin-top: 20px;
}

#locationFilter {
  width: 70%;
}

.resetChassisButton {
  background-color: transparent;
  border: none;
}

.resetChassisButtonIcon {
  height: 18px;
  width: 18px;
  margin-left: 10px;
  margin-top: 10px;
}

.dayOfWeekSelection {
  margin-top: 2vh;
}
.dayOfWeekCheckbox {
  display: inline;
}

.dayOfWeekCheckbox input {
  display: none!important;
}

.dayOfWeekCheckbox label {
  display: inline-block;
  border-radius: 50%;
  background: #dddddd;
  height: 30px;
  width: 30px;
  margin-right: 0.2vw;
  line-height: 30px;
  text-align: center;
  cursor: pointer;
}

.dayOfWeekCheckbox input[type=checkbox]:checked + label {
  background: #41b883;
  color: #ffffff;
}

</style>
