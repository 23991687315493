<template>
  <div class="step-progress-container">
    <div class="progressing-bar">
      <div class="background-bar"></div>
      <div class="tracker-bar" :style="'width: ' + percentage + '%'"></div>
    </div>
    <div class="step-progress-wrapper">
      <div class="step-progress" v-for="(item, i) in steps" :key="i+'inSteps'">
        <div class="step" v-if="activeStep < ( i + 1 )">
          <div class="step-progress-number" v-if="(i + 1) !== activeStep">{{ i + 1 }}</div>
        </div>
        <div class="step step-done" v-if="activeStep > ( i + 1 )">
          <font-awesome-icon icon="fa-solid fa-check" inverse
                             class="step-content step-progress-icon"
                             transform="shrink-4 rotate-360 grow-8"/>
        </div>
        <div class="step step-loading" v-if="activeStep === ( i + 1 )">
          <hex-spinner class="step-content step-content-loader"></hex-spinner>
        </div>
        <small class="step-progress-label">{{ item }}</small>
      </div>
    </div>
  </div>
</template>

<script>
import HexSpinner from '@/components/HexSpinner'

export default {
  name: 'StepProgress',
  components: { HexSpinner },
  props: {
    steps: {
      required: true,
      type: Array
    },
    activeStep: {
      required: true,
      type: Number
    }
  },
  computed: {
    percentage () {
      return ((this.activeStep - 1) / (this.steps.length - 1)) * 100
    }
  }
}

</script>
<style scoped>
.step-progress-container {
  width: calc(100% - 60px);
  height: 8vh;
}

.progressing-bar {
  width: 100%;
  position: relative;
  margin-left: 20px;
}

.background-bar {
  background: #ccc;
  width: 100%;
  height: 5px;
  position: absolute;
  margin-top: 2rem;
}

.tracker-bar {
  background: #218838;
  height: 8px;
  position: absolute;
  margin-top: 1.9rem;
  transition-property: all;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 1s;
}

.step-progress-wrapper {
  z-index: 15;
  align-self: center;
  position: absolute;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: calc(100% - 60px);
}

.step-progress {
  display: flex;
  flex-direction: column;
}

.step-progress-label {
  align-self: center;
}

.step {
  border: #218838 solid 10px;
  background-color: white;
  width: 4rem;
  height: 4rem;
  border-radius: 50%;
  align-self: center;
}

.step-loading {
  border: none;
  background-color: #218838;
}

.step-done {
  border: none;
  background-color: #218838;
}

.step-progress-number {
  margin-top: 25%;
}

.step-content {
  align-items: center;
  justify-content: center;
  text-align: center;
}

.step-content-loader {
  align-items: center;
  justify-content: center;
  text-align: center;
  margin: 7px;
}

.step-progress-icon {
  height: 50%;
  width: 50%;
  margin-top: 25%;
}
</style>
