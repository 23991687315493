<template>
  <div>
    <div id="burger"
              :class="{ 'active' : isBurgerActive }"
              @click.prevent="toggleMenu">
      <button type="button" class="burger-button" title="Menu">
        <span class="hidden">Toggle menu</span>
        <span class="burger-bar burger-bar--1"></span>
        <span class="burger-bar burger-bar--2"></span>
        <span class="burger-bar burger-bar--3"></span>
      </button>
    </div>
    <div class="sidemenu">
      <div class="sidemenu-backdrop" @click="closeSideMenuPanel" v-show="isMenuOpen"></div>
      <transition name="slide">
        <div v-show="isMenuOpen"
              class="sidemenu-panel">
          <div id="sidemenu-set-container">
            <data-set-selector/>
          </div>
          <div id="sidemenu-tab-container">
            <side-menu-tab @click.native="selectTab('tabLayer')" tabId="tabLayer" iconName="layer-group" :color="selectedTab === 'tabLayer' ? 'white' :'#3E6990'"/>
            <side-menu-tab @click.native="selectTab('tabFilter')" tabId="tabFilter" iconName="filter" :color="selectedTab === 'tabFilter' ? 'white' :'#3E6990'"/>
            <side-menu-tab @click.native="selectTab('tabGraph')" tabId="tabGraph" iconName="chart-line" :color="selectedTab === 'tabGraph' ? 'white' :'#3E6990'"/>
            <side-menu-tab @click.native="selectTab('tabMapStyle')" tabId="tabMapStyle" iconName="map" :color="selectedTab === 'tabMapStyle' ? 'white' :'#3E6990'"/>
            <side-menu-tab v-if="this.$store.getters.getAdminAccess" @click.native="selectTab('tabAdmin')" tabId="tabAdmin" iconName="dragon" :color="selectedTab === 'tabAdmin' ? 'white' :'#3E6990'"/>
          </div>
          <div id="sidemenu-panel-container">
            <layer-panel v-show="selectedTab === 'tabLayer'" @handle-deck-layer-changed="handleDeckLayerChanged"/>
            <filter-panel v-show="selectedTab === 'tabFilter'" @handle-on-filter="handleOnFilter"/>
            <graph-panel v-show="selectedTab === 'tabGraph'" @update-graph-options="updateGraphOptions" @update-graph-selection="updateGraphSelection"/>
            <map-style-panel v-show="selectedTab === 'tabMapStyle'"
                             @select-map-style="selectMapStyle"
                             @add-power-layer="addPowerMapLayer"
                             @hide-power-layer="hidePowerMapLayer"/>
            <admin-panel v-show="selectedTab === 'tabAdmin'" @select-map-style="selectAdmin"/>
          </div>
          <div style="position:absolute;margin-left:7vw;color:white;bottom:0;" v-if="this.$store.getters.getAppConfigFlag('isAlphaTester')">
            <p>Predicted query time: {{ predictedQueryTime }} seconds</p>
          </div>
        </div>
      </transition>
    </div>
  </div>
</template>
<script>
import FilterPanel from './FilterPanel.vue'
import SideMenuTab from '../../components/SideMenuTab.vue'
import DataSetSelector from '../../components/DataSetSelector.vue'
import LayerPanel from './LayerPanel.vue'
import GraphPanel from './GraphPanel.vue'
import AdminPanel from '../AdminPanel.vue'
import MapStylePanel from '../../components/MapStylePanel.vue'

export default {
  components: { SideMenuTab, DataSetSelector, LayerPanel, FilterPanel, GraphPanel, MapStylePanel, AdminPanel },
  name: 'SideMenu',
  data () {
    return {
      isMenuOpen: false,
      isBurgerActive: false,
      selectedTab: 'tabLayer'
    }
  },
  methods: {
    toggleMenu () {
      if (this.isBurgerActive && this.isMenuOpen) {
        this.isBurgerActive = false
        this.isMenuOpen = false
      } else {
        this.isBurgerActive = true
        this.isMenuOpen = true
      }
    },

    closeSideMenuPanel () {
      this.isMenuOpen = false
      this.isBurgerActive = false
    },

    selectTab (tabName) {
      this.selectedTab = tabName
    },

    handleDeckLayerChanged () {
      this.$emit('handle-deck-layer-changed')
    },

    handleOnFilter () {
      this.$emit('handle-on-filter')
    },

    updateGraphOptions (options) {
      this.$emit('update-graph-options', options)
    },

    updateGraphSelection (selection) {
      this.$emit('update-graph-selection', selection)
    },

    selectMapStyle (mapStyle) {
      this.$emit('select-map-style', mapStyle)
    },

    selectAdmin (admin) {
      this.$emit('select-admin', admin)
    },
    addPowerMapLayer (mapLayerName) {
      this.$emit('add-power-map-layer', mapLayerName)
    },
    hidePowerMapLayer (mapLayerName) {
      this.$emit('hide-power-map-layer', mapLayerName)
    }
  },
  computed: {
    predictedQueryTime () {
      return this.$store.getters['chassis/getQueryTimeInSeconds']
    }
  }
}
</script>

<style scoped>
  /* remove blue outline */
  button:focus {
    outline: 0;
  }

  #burger {
    position: fixed;
    z-index: 10;
  }

  .burger-button {
    position: relative;
    top: 4px;
    height: 30px;
    width: 32px;
    display: block;
    z-index: 2;
    border: 0;
    border-radius: 0;
    background-color: transparent;
    pointer-events: all;
    transition: transform .6s cubic-bezier(.165,.84,.44,1);
  }

  .burger-bar {
    background-color: #fff;
    position: absolute;
    top: 50%;
    right: 6px;
    left: 6px;
    height: 2px;
    width: auto;
    margin-top: -1px;
    transition: transform .6s cubic-bezier(.165,.84,.44,1),opacity .3s cubic-bezier(.165,.84,.44,1),background-color .6s cubic-bezier(.165,.84,.44,1);
  }

  .burger-bar--1 {
    -webkit-transform: translateY(-6px);
    transform: translateY(-6px);
  }

  .burger-bar--2 {
    transform-origin: 100% 50%;
    transform: scaleX(.8);
  }

  .burger-button:hover .burger-bar--2 {
    transform: scaleX(1);
  }

  .no-touchevents .burger-bar--2:hover {
    transform: scaleX(1);
  }

  .burger-bar--3 {
    transform: translateY(6px);
  }

  #burger.active .burger-button {
    transform: rotate(-180deg);
  }

  #burger.active .burger-bar {
    background-color: #fff;
  }

  #burger.active .burger-bar--1 {
    transform: rotate(45deg)
  }

  #burger.active .burger-bar--2 {
    opacity: 0;
  }

  #burger.active .burger-bar--3 {
    transform: rotate(-45deg)
  }
  .slide-enter-active,
  .slide-leave-active
  {
    transition: transform 0.2s ease;
  }

  .slide-enter,
  .slide-leave-to {
    transform: translateX(-100%);
    transition: all 150ms ease-in 0s
  }

  .sidemenu-backdrop {
    background-color: rgba(0,0,0,.5);
    width: 100vw;
    height: 100vh;
    position: fixed;
    top: 0;
    left: 0;
    cursor: pointer;
  }

  .sidemenu-panel {
    overflow-y: auto;
    overflow-x: hidden;
    background-color: #130f40;
    position: fixed;
    left: 0;
    height: calc(100vh - 78px);
    z-index: 3;
    width: 37vw;
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
  }

  .hidden {
    visibility: hidden;
  }

  button {
    cursor: pointer;
  }

  #sidemenu-set-container {
    margin-top: 10px;
    margin-right: 20px;
    padding-top: 24px;
    width: 100%;
    height: 90px;
  }

  #sidemenu-tab-container {
    padding-top: 15px;
    width: 100%;
    height: 50px;
    display: flex;
    justify-content: space-evenly;
  }

  #sidemenu-panel-container {
    margin-top: 15px;
    width: 100%;
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
  }

</style>
