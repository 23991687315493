<template>
  <div>
    <b-button v-b-toggle.survey-sidebar id="showSurvey">
      <v-icon name="comment" class="surveyIcon"/>
    </b-button>
    <b-sidebar id="survey-sidebar"
               width="65%"
               right
               :visible="visible"
               backdrop
               shadow>
      <div v-for="index in currentIndex" :key="'vff' + index">
        <flow-form
          ref="flowform"
          v-on:complete="onComplete"
          v-on:submit="onSubmit"
          v-bind:questions="questions"
          v-bind:language="language"
          v-bind:standalone="true"
          v-if="index === currentIndex"
        >
          <!-- Custom content for the Complete/Submit screen slots in the FlowForm component -->
          <!-- We've overriden the default "complete" slot content -->
          <template v-slot:complete>
            <div class="f-section-wrap" style="margin-bottom: 120px;">
              <p>
                <span class="f-section-text" v-if="!submitted">
                  Use the arrows if you want change anything.
                </span>
              </p>
            </div>
          </template>
          <!-- We've overriden the default "completeButton" slot content -->
          <template v-slot:completeButton>
            <div class="f-submit" v-if="!submitted">
              <button
                class="o-btn-action"
                ref="button"
                type="submit"
                v-on:click.prevent="onSendData()"
                aria-label="Send your feedback"
              >
                <span>{{ language.submitText }}</span>
              </button>
            </div>
            <p class="text-success" v-if="submitted">Submitted successfully.</p>
            <span class="fh2" v-if="submitted">Thank you. 🙏 </span>
            <button v-if="submitted" class="o-btn-action" @click="resetSurvey()">Send new answers</button>
          </template>
        </flow-form>
      </div>
    </b-sidebar>
  </div>
</template>

<script>
import FlowForm, { LanguageModel } from '@ditdot-dev/vue-flow-form'
import questionModel from './sweete-feedback'
import axios from 'axios'
import lz from 'lz-string'

export default {
  name: 'Survey',
  components: {
    FlowForm
  },
  data () {
    return {
      submitted: false,
      completed: false,
      language: new LanguageModel(),
      timer: null,
      visible: false,
      questions: [],
      currentIndex: 1
    }
  },
  created () {
    this.questions = questionModel.QUESTIONS
  },
  mounted () {
    document.addEventListener('keyup', this.onKeyListener)
    this.timer = setInterval(() => {
      this.visible = true
    }, 10 * 60000)
  },
  beforeDestroy () {
    document.removeEventListener('keyup', this.onKeyListener)
  },
  methods: {
    resetSurvey () {
      // eslint-disable-next-line no-return-assign
      this.questions.forEach(question => question.answer = null)
      ++this.currentIndex
    },
    onKeyListener ($event) {
      // We've overriden the default "complete" slot so
      // we need to implement the "keyup" listener manually.
      if (this.completed && !this.submitted) {
        this.onSendData()
      }
    },
    /* eslint-disable-next-line no-unused-vars */
    onComplete (completed, questionList) {
      // This method is called whenever the "completed" status is changed.
      this.completed = completed
    },
    /* eslint-disable-next-line no-unused-vars */
    onSubmit (questionList) {
      // This method will only be called if you don't override the
      // completeButton slot.
      this.onSendData()
    },

    onSendData () {
      const data = this.getData()
      /* eslint-disable-next-line no-unused-vars */
      const feedbackObj = {
        feedback: data,
        userName: this.$store.getters.getUsername,
        sessionId: this.$logger.sessionId(),
        timeForResponse: new Date().getTime()
      }
      const lambdaPath = '/Prod/PostFeedback'
      let endpoint = process.env.VUE_APP_UX_FEEDBACK_ENDPOINT

      const url = endpoint + lambdaPath
      const request = axios.create()

      request
        .post(url, lz.compressToEncodedURIComponent(JSON.stringify(feedbackObj)), {
          headers: {
            Authorization: this.$store.getters.getBearerToken
          }
        }).then(res => {
          // Set `submitted` to true so the form knows not to allow back/forward
          // navigation anymore.
          this.$refs.flowform.submitted = true
          this.submitted = true
          this.$logger.message(`{survey:{"file":"Survey.vue"},{"method":"onSendData"},{"feedbackObj":"${JSON.stringify(feedbackObj)}"}}`, 'info')
        })
    },
    getData () {
      const data = []
      this.questions.forEach(question => {
        if (question.title) {
          let answer = question.answer
          if (Array.isArray(answer)) {
            answer = answer.join(', ')
          }
          data.push({ 'question': question.title, 'answer': answer })
        }
      })
      return data
    }
  },
  watch: {
    visible: function () {
      if (!this.submitted) {
        this.timer = setInterval(() => {
          this.visible = true
        }, 10 * 60000)
      }
    }
  }
}
</script>

<style>
@import '~@ditdot-dev/vue-flow-form/dist/vue-flow-form.css';
@import '~@ditdot-dev/vue-flow-form/dist/vue-flow-form.theme-purple.css';

#showSurvey {
  position: absolute;
  z-index: 1;
  right: 0;
  bottom: 20px;
  padding: 1rem 1rem 1rem 1rem;
  background: hotpink;
  margin-right: 45px;
}
.surveyIcon {
  height: 35px;
  width: 35px;
}
</style>
