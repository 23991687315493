<template>
  <transition name="modal-fade">
    <div class="chassis-selection-modal-backdrop" v-show="isVisible">
      <div class="chassis-selection-modal">
        <section class="modal-mid">
          <slot name="body">
            <div>
              <button id="closeModal" @click="closeModal">
                <v-icon id="closeModalIcon" name="times-circle"/>
              </button>
            </div>
            <b-tabs pills card>
              <b-alert v-if="errorMessageHistorical.length > 0" variant="danger" dismissible>
                {{ errorMessageHistorical }}
              </b-alert>
              <chassis-selection v-if="chassisAccessAllowed"
                                 :receivedStops="receivedStops"
                                 :stopCount="stopCount"
                                 :minTime="minTime"
                                 :maxTime="maxTime"
                                 :numberOfChassisRequested="numberOfChassisRequested"
                                 :numberOfChassisInSet="numberOfChassisInSet"
                                 :setName="setName"
                                 postEndpoint="/Prod/PostQuery"
                                 @handle-button="setButton"
                                 @handle-on-data-response="setInfo"
                                 @predict-query-time="predictQueryTime"
                                 @handle-on-response-error="setErrorMessage"
                                 @handle-chassis-selection-reset="handleReset"
                                 @handle-on-view-selection="setUserSelection('chassis')"
                                 @handle-on-data-loaded="dataDoneLoading"
              />
              <data-set-selection v-if="chassisAccessAllowed"
                                  @handle-on-data-response="setInfo"
                                  @handle-data-set-selection-reset="handleReset"
                                  @handle-on-response-error="setErrorMessage"
                                  @handle-on-view-selection="setUserSelection('chassis')"
                                  @handle-on-data-loaded="dataDoneLoading"
              />
              <locations-selection-old v-if="locationAccessAllowed && !this.$store.getters.getAppConfigFlag('isTestingWithoutPreCache')"
                                       @handle-on-data-response="setUserSelection('locations')"
                                       @handle-on-data-loaded="dataDoneLoading"
              />
              <locations-selection v-if="locationAccessAllowed && this.$store.getters.getAppConfigFlag('isTestingWithoutPreCache')"
                                   @handle-on-data-response="setUserSelection('locations')"
                                   @handle-on-data-loaded="dataDoneLoading"
              />
              <classified-locations-selection v-if="classifiedLocationAccessAllowed"
                                   @handle-on-data-response="setUserSelection('classified_location')"
                                   @handle-on-data-loaded="dataDoneLoading"
              />
              <outbound-selection v-if="outboundAccessAllowed"
                                  @handle-on-data-response="setUserSelection('outbound')"
                                  @handle-on-data-loaded="outboundDoneLoading"
              />
              <chassis-selection v-if="marketAccessAllowed"
                                 :receivedStops="receivedStops"
                                 :stopCount="stopCount"
                                 :minTime="minTime"
                                 :maxTime="maxTime"
                                 :numberOfChassisRequested="numberOfChassisRequested"
                                 :numberOfChassisInSet="numberOfChassisInSet"
                                 :setName="setName"
                                 postEndpoint="/Prod/PostMarketQuery"
                                 lambdaPath='/Prod/PostSnowflakeMarketQueryCache'
                                 tabName="New fleet"
                                 @handle-button="setButton"
                                 @handle-on-data-response="setInfo"
                                 @predict-query-time="predictQueryTime"
                                 @handle-on-response-error="setErrorMessage"
                                 @handle-chassis-selection-reset="handleReset"
                                 @handle-on-view-selection="setUserSelection('chassis-bu')"
                                 @handle-on-data-loaded="dataDoneLoading"
              />
              <data-set-selection v-if="marketAccessAllowed"
                                  lambdaPath='/Prod/PostSnowflakeMarketQueryCache'
                                  tabName="My fleets"
                                  @handle-on-data-response="setInfo"
                                  @handle-data-set-selection-reset="handleReset"
                                  @handle-on-response-error="setErrorMessage"
                                  @handle-on-view-selection="setUserSelection('chassis-bu')"
                                  @handle-on-data-loaded="dataDoneLoading"
              />

              <survey-responses v-if="this.$store.getters.getAppConfigFlag('isUXAdmin')"/>
            </b-tabs>
          </slot>
        </section>
      </div>
    </div>
  </transition>
</template>

<script>
import snowflake from '../mixins/snowflake'
import LocationsSelection from '@/components/LocationsSelection'
import LocationsSelectionOld from '@/components/LocationsSelectionOld'
import ClassifiedLocationsSelection from '@/components/ClassifiedLocationsSelection'
import DataSetSelection from '@/components/DataSetSelection'
import ChassisSelection from '@/components/ChassisSelection'
import OutboundSelection from '@/components/OutboundSelection'
import axios from 'axios'
import SurveyResponses from '@/components/SurveyResponses'

export default {
  name: 'Selection',
  components: { ChassisSelection,
    DataSetSelection,
    LocationsSelection,
    LocationsSelectionOld,
    ClassifiedLocationsSelection,
    SurveyResponses,
    OutboundSelection
  },
  mixins: [snowflake],
  data () {
    return {
      readyForReset: [],
      receivedStops: false,
      chassis: '',
      requestedChassis: '',
      returnedChassis: [],
      numberOfChassisRequested: 0,
      numberOfChassisInSet: 0,
      customSetName: '',
      info: null,
      stopCount: null,
      minTime: null,
      maxTime: null,
      setName: null,
      message: null,
      type: '',
      isVisible: false,
      button: { info: 'Get data', isFetchingDataOnRequest: false, state: 'btn-success' },
      errorMessageHistorical: ''
    }
  },
  computed: {
    locationAccessAllowed () {
      return this.$store.getters.getLocationAccessAllowed
    },
    marketAccessAllowed () {
      return this.$store.getters.getMarketAccessAllowed
    },
    chassisAccessAllowed () {
      return this.$store.getters.getChassisAccessAllowed
    },
    outboundAccessAllowed () {
      return this.$store.getters.getAdminAccess
    },
    classifiedLocationAccessAllowed () {
      return this.$store.getters.getClassifiedLocationAccessAllowed
    }
  },
  methods: {
    handleReset (selectionComponentNameAsString) {
      this.readyForReset.push(selectionComponentNameAsString)
    },
    setButton (buttonObject) {
      this.button = buttonObject
    },
    setErrorMessage (errorMessage) {
      this.errorMessageHistorical = errorMessage
    },
    closeModal () {
      this.$store.commit('setModalVisibility', false)
    },
    dataDoneLoading () {
      this.$store.commit('setTriggerDeckRedraw', true)
      this.$emit('handle-on-data-loaded')
    },
    outboundDoneLoading () {
      this.$emit('handle-on-data-loaded')
    },
    setInfo (info) {
      this.receivedStops = true
      this.stopCount = info['item_count']
      this.minTime = info['min_time']
      this.maxTime = info['max_time']
      this.setName = info['name']
      this.requestedChassis = info['chassis']
      this.returnedChassis = info['returned_chassis']
      this.chassis = info['returned_chassis']
      this.$store.commit('chassis/setRequestTime', info['request_time'])
      this.numberOfChassisRequested = this.requestedChassis.split(',').length
      this.numberOfChassisInSet = this.returnedChassis.split(',').length
      this.$logger.message(`{"setInfo":"Requested chassis count: ${this.numberOfChassisRequested}"}`, 'info')
      this.$logger.message(`{"setInfo":"Returned chassis count: ${this.numberOfChassisInSet}"}`, 'info')
      this.$logger.message(`{"setInfo":"Stop count: ${this.stopCount}"}`, 'info')

      this.$store.commit('chassis/setTilesMinTime', this.minTime)
      this.$store.commit('chassis/setTilesMaxTime', this.maxTime)
      this.$store.commit('chassis/setStopCount', this.stopCount)
      this.$store.commit('chassis/setTilesConstantMaxTime', this.maxTime)
      this.$store.commit('chassis/setTilesChassis', this.returnedChassis)

      this.message = null
      this.button.info = 'Data is being created'
    },
    predictQueryTime () {
      const lambdaPath = this.$store.getters.getAppConfigFlag('useStagingStack') ? process.env.VUE_APP_PREDICTION_ENDPOINT_B : process.env.VUE_APP_PREDICTION_ENDPOINT
      const predictionUrl = lambdaPath + '/Prod/GetQueryTimeInference'
      const request = axios.create()
      request.get(predictionUrl, {
        headers: {
          'Authorization': this.$store.getters.getBearerToken
        },
        params: {
          'num_stops': this.stopCount,
          'num_chassis': this.numberOfChassisInSet,
          'inference_id': this.$logger.sessionId() + '_' + this.$store.getters['chassis/getRequestTime']
        }
      }).then(response => {
        this.$store.commit('chassis/setTimePrediction', Math.max(0, response.data))
      })
    },
    setUserSelection (userSelected) {
      this.$store.commit('setUserSelection', userSelected)
    }
  },

  watch: {
    '$store.state.signedIn': function () {
      if (this.$store.getters.isSignedIn) {
        this.isVisible = true
      } else {
        this.isVisible = false
      }
    },
    readyForReset: function () {
      if (this.readyForReset.length === 2) {
        this.chassis = ''
        this.info = null
        this.stopCount = null
        this.minTime = null
        this.maxTime = null
        this.setName = null
        this.message = null
        this.receivedStops = false
        this.showInfo = false
        this.button = { info: 'Get data', isFetchingDataOnRequest: false, state: 'btn-success' }
        this.$store.commit('setResetModal', false)
        this.readyForReset = []
      }
    },
    '$store.state.showModal': function () {
      if (!this.$store.getters.getModalVisibility) {
        this.isVisible = false
      } else if (this.$store.getters.getModalVisibility) {
        this.isVisible = true
      }
    }
  }
}
</script>

<style>
.chassis-selection-modal-backdrop {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.7);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 10;
}

.chassis-selection-modal {
  background: #FFFFFF;
  box-shadow: 2px 2px 20px 1px;
  overflow-x: auto;
  display: flex;
  flex-direction: column;
  width: 40vw;
  max-height: 99vh;
}

.modal-top,
.modal-bot {
  padding: 15px;
  display: flex;
}

.modal-top {
  position: relative;
  border-bottom: 1px solid #eeeeee;
  color: #4AAE9B;
  justify-content: space-between;
}

.modal-bot {
  border-top: 1px solid #eeeeee;
  flex-direction: column;
  justify-content: flex-end;
}

.modal-mid {
  position: relative;
  padding: 20px 10px;
}

.modal-fade-enter,
.modal-fade-leave-to {
  opacity: 0;
}

.modal-fade-leave-active {
  transition: opacity 2s ease;
}

.spinner-grow-sm {
  vertical-align: text-top;
}

.btn-green {
  color: white;
  background: #4AAE9B;
  border: 1px solid #4AAE9B;
  border-radius: 2px;
}

#closeModal {
  background-color: transparent;
  border: none;
  float: right;
}

#closeModalIcon {
  height: 20px;
  width: 20px;
}

</style>
