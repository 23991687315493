<template>
  <div id="graphPanel">
    <div id="graphSelection">
      <div id="graphs">
        <div class="graphChoiceButtons"
             v-for="choices in graphOptions"
             :key="choices.value">
          <label class="checkboxContainer" v-if="choices.featureOn">
            <input
              class="checkboxInput"
              type="checkbox"
              :checked="choices.checked"
              @change="onCheckboxChange(choices.value)"
            />
            <span class="switch"></span>
            <span class="label">{{ choices.text }}</span>
          </label>
        </div>
      </div>
    </div>
    <div id="exportButtonDiv" v-if="this.$store.getters.getAppConfigFlag('isAllowedToDownloadGeoLayer')">
      <export-button getStateFunction="location/getGraphDataStructure" :selectedGraphs="selectedGraphsForExport"></export-button>
    </div>
  </div>
</template>

<script>
import ExportButton from '@/components/ExportButton.vue'

export default {
  name: 'GraphPanel',
  components: { ExportButton },
  data () {
    return {
      selectedGraphs: [],
      graphOptions: [
        { text: 'Stop duration', default_text: 'Stop duration', value: 'durationDistribution', stateFunction: 'durationDistribution', tabName: 'durationDistribution', checked: false, visible: false, featureOn: true, chartHeader: ['no_stops'] },
        { text: 'Stops at location', default_text: 'Stops at location', value: 'countDistribution', stateFunction: 'location/getLocationDataWithGeoInfo', tabName: 'countDistribution', checked: false, visible: false, featureOn: true, chartHeader: ['hexagons', 'no_stops', 'vehicles', 'longitude', 'latitude', 'resolution', 'no_customers'] },
        { text: 'Stop start time', default_text: 'Stop start time', value: 'startHourDistribution', stateFunction: 'startHourDistribution', tabName: 'startHourDistribution', checked: false, visible: false, featureOn: true, chartHeader: ['no_stops'] },
        { text: 'Customers at location', default_text: 'Customers at location', value: 'countDistributionForCustomers', stateFunction: 'location/getLocationDataWithGeoInfo', tabName: 'countDistribution', checked: false, visible: false, featureOn: true, chartHeader: ['hexagons', 'no_customers'] },
        { text: 'Vehicles at location', default_text: 'Vehicles at location', value: 'countDistributionForVehicles', stateFunction: 'location/getLocationDataWithGeoInfo', tabName: 'countDistribution', checked: false, visible: false, featureOn: true, chartHeader: ['hexagons', 'vehicles'] }
      ]
    }
  },
  methods: {
    onCheckboxChange (graph) {
      for (var i = 0; i < this.graphOptions.length; i++) {
        if (this.graphOptions[i].value === graph) {
          if (this.graphOptions[i].visible) {
            for (let i = 0; i < this.selectedGraphs.length; i++) {
              if (this.selectedGraphs[i] === graph) {
                this.selectedGraphs.splice(i, 1)
              }
            }
            this.graphOptions[i].visible = false
            this.$logger.message(`{"LA_onCheckboxChange":[{"component":"LocationGraphPanel"}, {"state":"disabled"},{"name":"${graph}"}]}`, 'info')
          } else {
            this.graphOptions[i].visible = true
            this.selectedGraphs.push(graph)
            this.$logger.message(`{"LA_onCheckboxChange":[{"component":"LocationGraphPanel"}, {"state":"enable"},{"name":"${graph}"}]}`, 'info')
          }
        }
      }
      this.$emit('update-graph-options', this.graphOptions)
      this.$emit('update-graph-selection', this.selectedGraphs)
    },
    getGraphTitle (graph) {
      return this.graphOptions.find(item => item.value === graph).text
    },
    getGraphTabName (graph) {
      return this.graphOptions.find(item => item.value === graph).tabName
    }
  },
  computed: {
    selectedGraphsForExport () {
      let graphsForExport = []
      this.selectedGraphs.forEach(graphName => {
        let graphObj = {}
        graphObj['tabTitle'] = this.getGraphTabName(graphName)
        graphObj['graphVariable'] = this.graphOptions.find(item => item.text === this.getGraphTitle(graphName)).stateFunction
        graphObj['chartHeader'] = this.graphOptions.find(item => item.text === this.getGraphTitle(graphName)).chartHeader
        graphsForExport.push(graphObj)
      })
      return graphsForExport
    }
  }
}
</script>

<style scoped>
#graphPanel {
  display: flex;
  width: 100%;
  justify-content: flex-start;
  flex-direction: column;
}

#graphSelection {
  display: flex;
  justify-content: center;
}

#graphs {
  border-radius: 2px;
  font-family: 'Montserrat', sans-serif;
  background: #f8f9fa;
  color: #212529;
  margin-top: 10px;
  margin-bottom: 14px;
  width: 70%;
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  padding: 8px 4px;
}

.graphChoiceButtons {
  font-size: small;
  padding: 8px;
}

.checkboxContainer {
  cursor: pointer;
  display: flex;
  align-items: center;
  margin: 0;
  color: #1a202c;
}

.checkboxInput {
  display: none;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border-width: 0;
}

.switch {
  --switch-container-width: 35px;
  --switch-size: 20px;
}

.switch {
  --switch-container-width: 35px;
  --switch-size: 20px;

  /* Vertically center the inner circle */
  display: flex;
  align-items: center;
  position: relative;
  height: var(--switch-size);
  flex-basis: var(--switch-container-width);
  /* Make the container element rounded */
  border-radius: var(--switch-size);
  background-color: #e2e8f0;
}

.switch::before {
  content: "";
  /* Move a little bit the inner circle to the right */
  left: 1px;
  height: calc(var(--switch-size) - 4px);
  width: calc(var(--switch-size) - 4px);
  /* Make the inner circle fully rounded */
  border-radius: 9999px;
  background-color: white;
}

.checkboxInput:checked + .switch {
  /* Teal background */
  background-color: #4fd1c5;
}

.checkboxInput:checked + .switch::before {
  border-color: #4fd1c5;
  /* Move the inner circle to the right */
  transform: translateX(
    calc(var(--switch-container-width) - var(--switch-size))
  );
}

.switch {
  /* ... */
  transition: background-color 0.25s ease-in-out;
}

.switch::before {
  /* ... */
  transition: transform 0.375s ease-in-out;
}

.switch {
  /* ... */

  --light-gray: #e2e8f0;
  --gray: #cbd5e0;
  --dark-gray: #a0aec0;
  --teal: #4fd1c5;
  --dark-teal: #319795;

  /* ... */
  background-color: var(--light-gray);
}

.checkboxInput:checked + .switch {
  background-color: var(--teal);
}

.checkboxInput:checked + .switch::before {
  border-color: var(--teal);
  /* ... */
}

.switch::before {
  /* ... */
  border: 2px solid var(--light-gray);
}

.checkboxInput:focus + .switch::before {
  border-color: var(--dark-gray);
}

.checkboxInput:focus:checked + .switch::before {
  border-color: var(--dark-teal);
}
.checkboxInput:disabled + .switch {
  background-color: var(--gray);
}

.checkboxInput:disabled + .switch::before {
  background-color: var(--dark-gray);
  border-color: var(--dark-gray);
}

.switch {
  /* ... */
  /* In case the label gets long, the toggle shouldn't shrink. */
  flex-shrink: 0;
}

.label {
  /* ... */
  /* Show an ellipsis if the text takes more than one line */
  margin-left: 5px;
  text-overflow: ellipsis;
  white-space: nowrap;
  margin-right: 15px;
}

#exportButtonDiv {
  font-size: small;
  display: flex;
  justify-content: flex-end;
  margin-right: 15%;
}

</style>
