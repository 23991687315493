<template>
  <DeckGLComponent
    :layers="layers"
    :viewState="viewState"
    :controller="controller"
    @click="handleClick"
    @view-state-change="updateViewState"
  >
    <div id="deckmap" ref="deckmap"></div>
  </DeckGLComponent>
</template>

<script>
import * as h3 from 'h3-js'
import DeckGLComponent from '@/components/DeckGLComponent.vue'
import mapboxgl from 'mapbox-gl'
import centroid from '@turf/centroid'
import { H3HexagonLayer } from '@deck.gl/geo-layers'
import { EditableGeoJsonLayer } from '@nebula.gl/layers'
import { ModifyMode } from '@nebula.gl/edit-modes'

export default {
  name: 'LocationSelectionMap',
  props: {
    showBBox: {
      type: Boolean,
      default: true
    },
    showHexagons: {
      type: Boolean,
      default: true
    },
    hexagonResolution: {
      type: Number,
      default: 2
    }
  },
  components: { DeckGLComponent },
  data () {
    return {
      accessToken: process.env.VUE_APP_MAP_TOKEN,
      style: 'mapbox://styles/connected-intelligence/ckhpx27eb02zt19qrm3qphq46',
      hexagons: [],
      polygonAdded: false,
      bboxFeatureCollection: {
        'type': 'FeatureCollection',
        'features': [
          {
            'type': 'Feature',
            'geometry': {
              'type': 'Polygon',
              'coordinates': [
                [
                  [
                    7.03125,
                    54.36775852406841
                  ],
                  [
                    27.421875,
                    54.36775852406841
                  ],
                  [
                    27.421875,
                    69.16255790810501
                  ],
                  [
                    7.03125,
                    69.16255790810501
                  ],
                  [
                    7.03125,
                    54.36775852406841
                  ]
                ]
              ]
            }
          }
        ]
      },
      selectedFeatureIndexes: [],
      viewState: {
        latitude: 60,
        longitude: 25,
        zoom: 1.5,
        bearing: 0,
        pitch: 0
      }
    }
  },
  created () {
    this.deckMapObject = null
  },
  mounted () {
    this.setHexagons()
    this.createHexagonMap()
    this.$emit('on-hexagon-update', this.hexes)
    this.$emit('on-bbox-update', centroid(this.bbox))
  },
  methods: {
    handleClick ({ info, event }) {
    },
    updateViewState (viewState) {
      this.viewState = {
        ...viewState
      }
      this.deckMapObject.jumpTo({
        center: [viewState.longitude, viewState.latitude],
        zoom: viewState.zoom,
        bearing: viewState.bearing,
        pitch: viewState.pitch
      })
      setTimeout(() => {
        window.dispatchEvent(new Event('resize'))
      }, 300)
    },
    createHexagonMap () {
      this.deckMapObject = new mapboxgl.Map({
        accessToken: this.accessToken,
        container: this.$refs.deckmap,
        interactive: false,
        width: '100%',
        height: '100%',
        style: this.style,
        center: [this.viewState.longitude, this.viewState.latitude],
        zoom: this.viewState.zoom,
        pitch: this.viewState.pitch,
        bearing: this.viewState.bearing,
        maxZoom: 9
      })
    },
    setHexagons () {
      this.hexagons = h3.polyfill(this.bbox.features[0].geometry.coordinates, this.hexResolution, true)
    }
  },
  computed: {
    hexResolution () {
      if (this.$store.getters.getAppConfigFlag('isTestingWithoutPreCache')) return 3
      return this.hexagonResolution
    },
    hexes () {
      return this.hexagons
    },
    controller () {
      return {
        doubleClickZoom: false
      }
    },
    bbox () {
      return this.bboxFeatureCollection
    },
    selectedBBoxIndexes () {
      return this.selectedFeatureIndexes
    },
    layers () {
      const hexagonLayer = new H3HexagonLayer({
        id: 'h3-location-select-layer',
        data: this.hexes,
        autoHighlight: true,
        visible: this.showHexagons,
        opacity: 0.8,
        highPrecision: true,
        filled: true,
        getHexagon: d => d,
        getFillColor: d => [255, (1 - 80 / 500) * 255, 0],
        pickable: true,
        onClick: (info, event) => this.$logger.message(`{"layers":"Clicked: ${JSON.stringify(event)} ${JSON.stringify(info)}"}`, 'info')
      })

      const editableLayer = new EditableGeoJsonLayer({
        id: 'bbox',
        data: this.bbox,
        selectedFeatureIndexes: this.selectedBBoxIndexes,
        visible: this.showBBox,
        mode: ModifyMode,
        filled: true,
        extruded: true,
        getElevation: 1000,
        getFillColor: [200, 0, 80, 180],
        pickable: true,
        autoHighlight: true,
        onEdit: ({ updatedData, editType, editContext }) => {
          this.bboxFeatureCollection = updatedData
          let updatedSelectedFeatureIndexes = this.selectedFeatureIndexes
          if (editType === 'addFeature') { // when a drawing is complete, the value of editType becomes addFeature
            const { featureIndexes } = editContext // extracting indexes of current features selected
            updatedSelectedFeatureIndexes = [...this.selectedFeatureIndexes, ...featureIndexes]
          }
          this.selectedFeatureIndexes = updatedSelectedFeatureIndexes // now update your state
          this.setHexagons()
          this.$emit('on-hexagon-update', this.hexes)
          this.$emit('on-bbox-update', centroid(this.bbox))
        },
        onClick: (info, event) => {
          this.selectedFeatureIndexes = info.object ? [info.index] : []
        }
      })
      return [ editableLayer, hexagonLayer ]
    }
  }
}

</script>

<style scoped>
#deckmap {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: #e5e9ec;
  overflow: hidden;
}
.boxdraw {
  background: rgba(56, 135, 190, 0.1);
  border: 2px solid #3887be;
  position: absolute;
  top: 0;
  left: 0;
  width: 0;
  height: 0;
}
</style>
