/* eslint-disable no-unused-vars */
import * as Msal from '@azure/msal-browser'
import store from '../store/store.js'
import logger from '../plugin/logger.js'

// Config object to be passed to Msal on creation
const msalConfig = {
  auth: {
    clientId: process.env.VUE_APP_OIDC_CLIENTID,
    authority: process.env.VUE_APP_OIDC_AUTHORITY,
    redirectUri: `${window.location.origin}/callback`
  },
  cache: {
    cacheLocation: 'sessionStorage', // This configures where your cache will be stored
    storeAuthStateInCookie: false // Set this to "true" if you are having issues on IE11 or Edge
  }
}

// Add here scopes for id token to be used at MS Identity Platform endpoints.
const loginRequest = {
  scopes: ['User.Read']
}

// Add here scopes for access token to be used at MS Graph API endpoints.
const tokenRequest = {
  scopes: ['User.Read'],
  forceRefresh: false // Set this to "true" to skip a cached token and go to the server to get a new token
}

export default class AuthService {
  constructor () {
    logger.message('{"constructor":"[AuthService.constructor] started constructor"}')
    // Create the main app instance
    // configuration parameters are located at authConfig.js
    this.app = new Msal.PublicClientApplication(msalConfig)

    this.signInType = 'loginRedirect' // 'loginPopup' or 'loginRedirect'
  }

  init = async () => {
    const currentAccounts = this.app.getAllAccounts()

    logger.message(`{"init":"[AuthService.init] started init ${currentAccounts}"}`)

    try {
      const tokenResponse = await this.app.handleRedirectPromise()
      logger.message(`{"init":"[AuthService.init] tokenResponse ${JSON.stringify(tokenResponse)}"}`)
      const accountObj = tokenResponse ? tokenResponse.account : this.app.getAllAccounts()
      if (accountObj) {
        logger.message(`{"init":"[AuthService.init] id_token acquired ${accountObj}"}`)
        await this.getTokenRedirect(loginRequest)
      } else if (tokenResponse && tokenResponse.tokenType === 'Bearer') {
        logger.message('{"init":"[AuthService.init] access_token acquired"}')
      } else if (tokenResponse === null) {
        // tokenResponse was null, attempt sign in or enter unauthenticated state for app
        logger.message('{"init":"[AuthService.init] signIn"}')
        await this.signIn()
      } else {
        logger.message(`{"init":"[AuthService.init] tokenResponse was not null but did not have any tokens: ${tokenResponse}"}`)
      }
    } catch (error) {
      logger.message(`{"init":"AuthService.js handleRedirectPromise error, ${error}"}`, 'error')
    }
  }

  signIn = async () => {
    logger.message(`{"signIn":"[AuthService.signIn] signInType: ${this.signInType}"}`)
    if (this.signInType === 'loginRedirect') {
      try {
        const loginResponse = await this.app.loginPopup(loginRequest)
        this.login = loginResponse
        logger.message(`{"signIn":"[AuthService.signIn] loginResponse: ${JSON.stringify(loginResponse)}"}`)
        store.commit('setSignedIn', true)
        store.commit('setBearerToken', this.getIdToken())

        let base64Payload = this.getIdToken().split('.')[1]
        let payload = Buffer.from(base64Payload, 'base64')
        let decodedAndParsedString = JSON.parse(payload.toString())
        let email = decodedAndParsedString['preferred_username']
        let username = email.slice(0, -11)
        store.commit('setUsername', username)
        logger.message(`{"signIn":"username: ${username}"}`, 'info')
        let roles = decodedAndParsedString['roles']
        store.commit('setUserRoles', roles)
      } catch (error) {
        logger.message(`{"signIn":"[AuthService.signIn] loginPopup error: ${JSON.stringify(error)}"}`, 'error')
      }
    } else if (this.signInType === 'loginRedirect') {
      await this.app.loginRedirect(loginRequest)
    }
    return null
  }

  signOut = () => {
    return this.app.logoutPopup()
  }

  getAccessToken = () => {
    if (this.login) return this.login.accessToken
    return null
  }

  getIdToken = () => {
    const currentAccounts = this.app.getAllAccounts()
    if (currentAccounts.length === 1) {
      // Only support single account
      return this.login.idToken
    }
    return null
  }

  getTokenPopup = async () => {
    try {
      const response = await this.app.acquireTokenSilent(loginRequest)
      logger.message(`{"getTokenPopup":"[AuthService.getTokenPopup] loginResponse: ${JSON.stringify(response)}"}`)
      store.commit('setBearerToken', this.getIdToken())
      return response
    } catch (error) {
      logger.message(`{"getTokenPopup":"silent token acquisition failed: ${JSON.stringify(error)}"}`, 'error')
      if (error instanceof Msal.InteractionRequiredAuthError) {
        // fallback to interaction when silent call fails
        logger.message('{"getTokenPopup":"acquiring token using popup"}', 'debug')
        try {
          await this.app.acquireTokenPopup(loginRequest)
        } catch (err) {
          logger.message(`{"getTokenPopup":"auth-service.js getTokenPopup: ${err}"}`, 'error')
        }
      }
      logger.message(`{"getTokenPopup":"auth-service.js getTokenPopup: ${error}"}`, 'error')
    }
    return null
  }

  // This function can be removed if you do not need to support IE
  getTokenRedirect = async () => {
    try {
      const response = await this.app.acquireTokenSilent(loginRequest)
      return response
    } catch (error) {
      logger.message(`{"getTokenRedirect":"auth-service.js getTokenRedirect: ${error}"}`, 'error')
      if (error instanceof Msal.InteractionRequiredAuthError) {
        // fallback to interaction when silent call fails
        logger.message('{"getTokenRedirect":"acquiring token using redirect"}', 'debug')
        await this.app.acquireTokenRedirect(loginRequest)
      }
    }
    return null
  }

  getProfile = async () => {
    if (!this.app.getAllAccounts()) return null

    let response
    if (this.signInType === 'loginPopup') {
      response = await this.getTokenPopup(loginRequest)
    } else {
      response = await this.getTokenRedirect(loginRequest)
    }

    // Add here the endpoints for MS Graph API services you would like to use.
    const graphConfig = { graphMeEndpoint: 'https://graph.microsoft.com/v1.0/me' }

    const user = await this.callMSGraph(graphConfig.graphMeEndpoint, response.accessToken)
    return user
  }

  // Helper function to call MS Graph API endpoint
  // using authorization bearer token scheme
  callMSGraph = async (endpoint, accessToken) => {
    const headers = new Headers()
    const bearer = `Bearer ${accessToken}`

    headers.append('Authorization', bearer)

    const options = {
      method: 'GET',
      headers
    }

    logger.message(`{"callMSGraph":"request made to Graph API at: ${new Date().toString()}"}`)

    const response = await fetch(endpoint, options)
    const data = await response.json()
    return data
  }
}
