<template>
  <div>
    <div style="display: flex">
    <div class='loadingBar'>
      <h5 id='privacyNotice'>FOR INTERNAL USE ONLY</h5>
      <div v-if="fetchingHexagonAggregates" class='loadingIndication'>
        <h5 class='loadingIndication'>Loading summary for selected hexagon</h5>
        <b-spinner small type="grow" class='loadingIndication'></b-spinner>
      </div>
      <h5 v-if="fetchDataErrorError" class='fetchError' id='fetchError'>Failed to fetch location aggregates, reloading
        the app or restoring network connectivity might help: {{ fetchDataErrorError }}</h5>
    </div>
    <side-menu @handle-deck-layer-changed="handleDeckLayerChanged"
               @handle-on-filter="handleDeckLayerChanged"
               @update-graph-options="updateGraphOptions"
               @update-graph-selection="updateGraphSelection"
               @select-map-style="selectMapStyle"
               @add-map-layer="addGeoJSONLayer"
               @remove-map-layer="removeCustomerLayer"
               @update-map-display-options="updateMapDisplayOptions"
               @update-chassis-filter="handleUpdateChassisFilter"
    />
    <div id="mapButtons" v-if="showMapButtons">
      <button id="legendButton"
              @click="showLegend = true; showMapButtons = false">
        <span class="toolTipText">Legend</span>
        <v-icon name="info-circle" class="mapButtonIcon"/>
      </button>
    </div>
    <div id="legend" v-if="showLegend">
      <button id="closeLegendButton" @click="showLegend = false; showMapButtons = true">
        <v-icon name="times" id="closeLegendButtonIcon"/>
      </button>
      <button class="legendRowButton" id="changeLegendType" @click="toggleShowLocationTypes()">
        <div v-if="!showLocationTypes">Hide stop count legend</div>
        <div v-if="showLocationTypes">Show stop count legend</div>
      </button>
      <div v-if="locationLayerVisible && showLocationTypes">
        <button class="legendRowButton" id="highlightHomeDepot" @click="toggleHighlightHomeDepots()">
          <div v-if="!highlightHomeDepots">Magnify home depots</div>
          <div v-if="highlightHomeDepots">Demagnify home depots </div>
        </button>
        <div class="legendRow">
          <div class="legendDescriptor" style="margin-left:12px"><strong style="font-family: Montserrat; margin-top: 0.5vw; margin-bottom: 0.3vw; display:block;">Type</strong></div>
        </div>
        <div class="legendRow"
             v-for="colour in locationClassColours" :key="colour.colour">
          <div :style="{ background: colour.colour, width: '12px', height: '12px', border: '2px solid black'}"/>
          <div class="legendDescriptor"> {{ colour.type }}</div>
        </div>
      </div>
      <div v-if="locationLayerVisible && !showLocationTypes">
        <div class="legendRow">
          <div class="legendDescriptor" ><strong style="font-family: Montserrat; margin-top: 0.5vw; margin-bottom: 0.3vw; display:block;">Cluster count / Total clusters (Ratio of no. of clusters)</strong></div>
          <div class="legendDescriptor"><strong style="font-family: Montserrat; margin-top: 0.5vw; margin-bottom: 0.3vw; display:block;">Number of stops</strong></div>
          <div class="legendDescriptor" style="margin-left:12px"><strong style="font-family: Montserrat; margin-top: 0.5vw; margin-bottom: 0.3vw; display:block;">Stops count / Total stops (Ratio of no. of stops)</strong></div>
        </div>
        <div @click="setFilterRange(colour.domain[0], colour.domain[1])"
             class="legendRow"
             v-for="colour in locationColours" :key="JSON.stringify(colour.colour)">
          <div class="legendDescriptor"> {{ hexagonCountLegend(colour.domain[0], colour.domain[1]) }}/{{ hexagonCountLegend(stopsCountMinMax[0], stopsCountMinMax[1]) }} ({{ (100*hexagonCountLegend(colour.domain[0], colour.domain[1])/hexagonCountLegend(stopsCountMinMax[0], stopsCountMinMax[1])).toFixed(1)  }}%)</div>
          <div :style="{ background: colour.colour, width: '12px', height: '12px'}">
            <div v-if="isFiltered(colour.domain[0], colour.domain[1])"
                 class="highlightSelectedLegendGroup"></div>
          </div>
          <div class="legendDescriptor"> {{ Math.round(colour.domain[0]) }} - {{ Math.round(colour.domain[1]) }}</div>
          <div class="legendDescriptor"> {{ stopsCountLegend(colour.domain[0], colour.domain[1]) }}/{{ stopsCountLegend(stopsCountMinMax[0], stopsCountMinMax[1]) }} ({{ (100*stopsCountLegend(colour.domain[0], colour.domain[1])/stopsCountLegend(stopsCountMinMax[0], stopsCountMinMax[1])).toFixed(1) }}%)</div>
        </div>
      </div>
      <div class="generalInformation" style="text-align: left; margin-top: 15px;">
        <div style="text-align: center; margin-bottom: 5px;">
          <strong>General Dataset Info</strong>
        </div>
        <div>
          Date range (From): {{ getGeneralDataInfo().from_date_time }}
        </div>
        <div>
          Date range (To): {{ getGeneralDataInfo().to_date_time }}
        </div>
        <div>
          Total number of cluster visits: {{ getGeneralDataInfo().total_number_of_cluster_visits }}
        </div>
      </div>
      <b-card v-for="layer in layerNames" :key="layer.layerName"  >
        <strong style="font-family: Montserrat">{{ layer.layerName }}</strong>
        <div>
          <label :for="layer.layerName + 'color'">Stroke color:</label>
          <b-form-input :id="layer.layerName + 'color'"
                        :style="{width: '24px', height: '24px'}"
                        style="display: inline; margin-right: 1vw"
                        @change="handlePointPropChanged(layer.layerName, 'circle-stroke-color', layer.color)"
                        v-model="layer.color"
                        type="color">
          </b-form-input>
          <label :for="layer.layerName + 'radius'">Size/radius:</label>
          <b-form-input :id="layer.layerName + 'radius'"
                        :style="{width: '60px', height: '24px', display: 'inline'}"
                        :min="0"
                        style="display: inline; margin-right: 1vw"
                        v-model.number="layer.radius"
                        @change="handlePointPropChanged(layer.layerName, 'circle-radius', layer.radius)"
                        type="number">
          </b-form-input>
          <label :for="layer.layerName + 'stroke-width'">Stroke width:</label>
          <b-form-input :id="layer.layerName + 'stroke-width'"
                        :min="0"
                        :style="{width: '60px', height: '24px', display: 'inline'}"
                        style="display: inline; margin-right: 1vw"
                        @change="handlePointPropChanged(layer.layerName, 'circle-stroke-width', layer.strokeWidth)"
                        v-model.number="layer.strokeWidth"
                        type="number">
          </b-form-input>
          <span @click="filterGeoJSON(layer, null)">❌</span>
        </div>
      </b-card>
    </div>
    </div>
    <div>
      <div id="location-chartWidget" :class="checkGraphWidgetSize()">
        <div v-show="this.$store.getters['classified_location/getGraphDataStructure']('numberOfParkedVehicleDistribution').length != 0 && selectedGraphs.includes('numberOfParkedVehicleDistribution')"
             class="vehicleChart"
             :class="checkGraphSize('numberOfParkedVehicleDistribution')"
             id="location-div-numberOfParkedVehicleDistribution">
          <div>
            <multiselect v-model="numberOfParkedVehicleDistributionDay"
                         :options="days"
                         :searchable="false"
                         :close-on-select="false"
                         :show-labels="false"
                         placeholder="Pick a value"
                         class="dayMultiSelect"></multiselect>
          </div>
          <div class="chartHeader">
            <button id="location-numberOfParkedVehicleDistribution-expand"
                    v-if="enlargedChart !== 'numberOfParkedVehicleDistribution'"
                    @click="handleClick('numberOfParkedVehicleDistribution')"
                    class="expandCompressChartButton" title="Expand">
              <v-icon name="expand" class="expandChartButtonIcon"/>
            </button>
            <button id="location-numberOfParkedVehicleDistribution-compress"
                    v-if="enlargedChart === 'numberOfParkedVehicleDistribution'"
                    @click="closeLarge('numberOfParkedVehicleDistribution')"
                    class="expandCompressChartButton" title="Compress">
              <v-icon name="compress" class="compressChartButtonIcon"/>
            </button>
          </div>
          <bar-chart getStateFunction="classified_location/getGraphDataStructure"
                     :width="enlargedChart === 'numberOfParkedVehicleDistribution' ? chartWidthLarge : chartWidth"
                     :height="enlargedChart === 'numberOfParkedVehicleDistribution' ? chartHeightLarge : chartHeight"
                     :svgHeight="enlargedChart === 'numberOfParkedVehicleDistribution' ? chartSVGHeightLarge : chartSVGHeight"
                     :margin="enlargedChart === 'numberOfParkedVehicleDistribution' ? chartMarginLarge : chartMarginSmall"
                     yAxisLabel="Number of vehicles"
                     xAxisLabel="Hour of day"
                     :graphTitle="getGraphTitle('numberOfParkedVehicleDistribution')"
                     chartDataName="numberOfParkedVehicleDistribution"
                     plotDataName="numberOfParkedVehicleDistribution"
                     yKey="med_number_of_visiting_vehicles_list"
                     xKey="hour_of_day"
                     :legend="enlargedChart === 'numberOfParkedVehicleDistribution'"/>
        </div>
        <div v-show="this.$store.getters['classified_location/getGraphDataStructure']('homeDepotNumberOfArrivalsDistribution').length != 0 && selectedGraphs.includes('homeDepotNumberOfArrivalsDistribution')"
             class="vehicleChart"
             :class="checkGraphSize('homeDepotNumberOfArrivalsDistribution')"
             id="location-div-numberOfParkedVehicleDistribution">
          <div>
            <multiselect v-model="homeDepotNumberOfArrivalsDistributionDay"
                         :options="days"
                         :searchable="false"
                         :close-on-select="false"
                         :show-labels="false"
                         placeholder="Pick a value"
                         class="dayMultiSelect"></multiselect>
          </div>
          <div class="chartHeader">
            <button id="location-homeDepotNumberOfArrivalsDistribution-expand"
                    v-if="enlargedChart !== 'homeDepotNumberOfArrivalsDistribution'"
                    @click="handleClick('homeDepotNumberOfArrivalsDistribution')"
                    class="expandCompressChartButton" title="Expand">
              <v-icon name="expand" class="expandChartButtonIcon"/>
            </button>
            <button id="location-homeDepotNumberOfArrivalsDistribution-compress"
                    v-if="enlargedChart === 'homeDepotNumberOfArrivalsDistribution'"
                    @click="closeLarge('homeDepotNumberOfArrivalsDistribution')"
                    class="expandCompressChartButton" title="Compress">
              <v-icon name="compress" class="compressChartButtonIcon"/>
            </button>
          </div>
          <bar-chart getStateFunction="classified_location/getGraphDataStructure"
                     :width="enlargedChart === 'homeDepotNumberOfArrivalsDistribution' ? chartWidthLarge : chartWidth"
                     :height="enlargedChart === 'homeDepotNumberOfArrivalsDistribution' ? chartHeightLarge : chartHeight"
                     :svgHeight="enlargedChart === 'homeDepotNumberOfArrivalsDistribution' ? chartSVGHeightLarge : chartSVGHeight"
                     :margin="enlargedChart === 'homeDepotNumberOfArrivalsDistribution' ? chartMarginLarge : chartMarginSmall"
                     yAxisLabel="Number of arriving vehicles"
                     xAxisLabel="Hour of day"
                     :graphTitle="getGraphTitle('homeDepotNumberOfArrivalsDistribution')"
                     chartDataName="homeDepotNumberOfArrivalsDistribution"
                     plotDataName="homeDepotNumberOfArrivalsDistribution"
                     yKey="med_number_of_arriving_vehicles_list"
                     xKey="hour_of_day"
                     :legend="enlargedChart === 'homeDepotNumberOfArrivalsDistribution'"/>
        </div>
        <div v-show="this.$store.getters['classified_location/getGraphDataStructure']('homeDepotNumberOfDeparturesDistribution').length != 0 && selectedGraphs.includes('homeDepotNumberOfDeparturesDistribution')"
             class="vehicleChart"
             :class="checkGraphSize('homeDepotNumberOfDeparturesDistribution')"
             id="location-div-numberOfParkedVehicleDistribution">
          <div>
            <multiselect v-model="homeDepotNumberOfDeparturesDistributionDay"
                         :options="days"
                         :searchable="false"
                         :close-on-select="false"
                         :show-labels="false"
                         placeholder="Pick a value"
                         class="dayMultiSelect"></multiselect>
          </div>
          <div class="chartHeader">
            <button id="location-homeDepotNumberOfDeparturesDistribution-expand"
                    v-if="enlargedChart !== 'homeDepotNumberOfDeparturesDistribution'"
                    @click="handleClick('homeDepotNumberOfDeparturesDistribution')"
                    class="expandCompressChartButton" title="Expand">
              <v-icon name="expand" class="expandChartButtonIcon"/>
            </button>
            <button id="location-homeDepotNumberOfDeparturesDistribution-compress"
                    v-if="enlargedChart === 'homeDepotNumberOfDeparturesDistribution'"
                    @click="closeLarge('homeDepotNumberOfDeparturesDistribution')"
                    class="expandCompressChartButton" title="Compress">
              <v-icon name="compress" class="compressChartButtonIcon"/>
            </button>
          </div>
          <bar-chart getStateFunction="classified_location/getGraphDataStructure"
                     :width="enlargedChart === 'homeDepotNumberOfDeparturesDistribution' ? chartWidthLarge : chartWidth"
                     :height="enlargedChart === 'homeDepotNumberOfDeparturesDistribution' ? chartHeightLarge : chartHeight"
                     :svgHeight="enlargedChart === 'homeDepotNumberOfDeparturesDistribution' ? chartSVGHeightLarge : chartSVGHeight"
                     :margin="enlargedChart === 'homeDepotNumberOfDeparturesDistribution' ? chartMarginLarge : chartMarginSmall"
                     yAxisLabel="Number of departing vehicles"
                     xAxisLabel="Hour of day"
                     :graphTitle="getGraphTitle('homeDepotNumberOfDeparturesDistribution')"
                     chartDataName="homeDepotNumberOfDeparturesDistribution"
                     plotDataName="homeDepotNumberOfDeparturesDistribution"
                     yKey="med_number_of_departing_vehicles_list"
                     xKey="hour_of_day"
                     :legend="enlargedChart === 'homeDepotNumberOfDeparturesDistribution'"/>
        </div>
        <div v-show="this.$store.getters['classified_location/getGraphDataStructure']('longHaulageNumberOfParkedVehicleDistribution').length != 0 && selectedGraphs.includes('longHaulageNumberOfParkedVehicleDistribution')"
             class="vehicleChart"
             :class="checkGraphSize('longHaulageNumberOfParkedVehicleDistribution')"
             id="location-div-longHaulageNumberOfParkedVehicleDistribution">
          <div>
            <multiselect v-model="longHaulageNumberOfParkedVehicleDistributionDay"
                         :options="days"
                         :searchable="false"
                         :close-on-select="false"
                         :show-labels="false"
                         placeholder="Pick a value"
                         class="dayMultiSelect"></multiselect>
          </div>
          <div class="chartHeader">
            <button id="location-longHaulageNumberOfParkedVehicleDistribution-expand"
                    v-if="enlargedChart !== 'longHaulageNumberOfParkedVehicleDistribution'"
                    @click="handleClick('longHaulageNumberOfParkedVehicleDistribution')"
                    class="expandCompressChartButton" title="Expand">
              <v-icon name="expand" class="expandChartButtonIcon"/>
            </button>
            <button id="location-longHaulageNumberOfParkedVehicleDistribution-compress"
                    v-if="enlargedChart === 'longHaulageNumberOfParkedVehicleDistribution'"
                    @click="closeLarge('longHaulageNumberOfParkedVehicleDistribution')"
                    class="expandCompressChartButton" title="Compress">
              <v-icon name="compress" class="compressChartButtonIcon"/>
            </button>
          </div>
          <bar-chart getStateFunction="classified_location/getGraphDataStructure"
                     :width="enlargedChart === 'longHaulageNumberOfParkedVehicleDistribution' ? chartWidthLarge : chartWidth"
                     :height="enlargedChart === 'longHaulageNumberOfParkedVehicleDistribution' ? chartHeightLarge : chartHeight"
                     :svgHeight="enlargedChart === 'longHaulageNumberOfParkedVehicleDistribution' ? chartSVGHeightLarge : chartSVGHeight"
                     :margin="enlargedChart === 'longHaulageNumberOfParkedVehicleDistribution' ? chartMarginLarge : chartMarginSmall"
                     yAxisLabel="Number of vehicles"
                     xAxisLabel="Hour of day"
                     :graphTitle="getGraphTitle('longHaulageNumberOfParkedVehicleDistribution')"
                     chartDataName="longHaulageNumberOfParkedVehicleDistribution"
                     plotDataName="longHaulageNumberOfParkedVehicleDistribution"
                     yKey="med_number_of_visiting_vehicles_list"
                     xKey="hour_of_day"
                     :legend="enlargedChart === 'longHaulageNumberOfParkedVehicleDistribution'"/>
        </div>
        <div v-show="this.$store.getters['classified_location/getGraphDataStructure']('homeDepotNumberOfParkedVehicleDistribution').length != 0 && selectedGraphs.includes('homeDepotNumberOfParkedVehicleDistribution')"
             class="vehicleChart"
             :class="checkGraphSize('homeDepotNumberOfParkedVehicleDistribution')"
             id="location-div-homeDepotNumberOfParkedVehicleDistribution">
          <div>
            <multiselect v-model="homeDepotNumberOfParkedVehicleDistributionDay"
                         :options="days"
                         :searchable="false"
                         :close-on-select="false"
                         :show-labels="false"
                         placeholder="Pick a value"
                         class="dayMultiSelect"></multiselect>
          </div>
          <div class="chartHeader">
            <button id="location-homeDepotNumberOfParkedVehicleDistribution-expand"
                    v-if="enlargedChart !== 'homeDepotNumberOfParkedVehicleDistribution'"
                    @click="handleClick('homeDepotNumberOfParkedVehicleDistribution')"
                    class="expandCompressChartButton" title="Expand">
              <v-icon name="expand" class="expandChartButtonIcon"/>
            </button>
            <button id="location-homeDepotNumberOfParkedVehicleDistribution-compress"
                    v-if="enlargedChart === 'homeDepotNumberOfParkedVehicleDistribution'"
                    @click="closeLarge('homeDepotNumberOfParkedVehicleDistribution')"
                    class="expandCompressChartButton" title="Compress">
              <v-icon name="compress" class="compressChartButtonIcon"/>
            </button>
          </div>
          <bar-chart getStateFunction="classified_location/getGraphDataStructure"
                     :width="enlargedChart === 'homeDepotNumberOfParkedVehicleDistribution' ? chartWidthLarge : chartWidth"
                     :height="enlargedChart === 'homeDepotNumberOfParkedVehicleDistribution' ? chartHeightLarge : chartHeight"
                     :svgHeight="enlargedChart === 'homeDepotNumberOfParkedVehicleDistribution' ? chartSVGHeightLarge : chartSVGHeight"
                     :margin="enlargedChart === 'homeDepotNumberOfParkedVehicleDistribution' ? chartMarginLarge : chartMarginSmall"
                     yAxisLabel="Number of vehicles"
                     xAxisLabel="Hour of day"
                     :graphTitle="getGraphTitle('homeDepotNumberOfParkedVehicleDistribution')"
                     chartDataName="homeDepotNumberOfParkedVehicleDistribution"
                     plotDataName="homeDepotNumberOfParkedVehicleDistribution"
                     yKey="med_number_of_visiting_vehicles_list"
                     xKey="hour_of_day"
                     :legend="enlargedChart === 'homeDepotNumberOfParkedVehicleDistribution'"/>
        </div>
        <div v-show="this.$store.getters['classified_location/getGraphDataStructure']('homeDepotEnergyNeedDistributionEstimatedByDistance').length != 0 && selectedGraphs.includes('homeDepotEnergyNeedDistributionEstimatedByDistance')"
             class="vehicleChart"
             :class="checkGraphSize('homeDepotEnergyNeedDistributionEstimatedByDistance')"
             id="location-div-homeDepotEnergyNeedDistributionEstimatedByDistance">
          <div>
            <multiselect v-model="homeDepotEnergyNeedDistributionEstimatedByDistanceDay"
                         :options="days"
                         :searchable="false"
                         :close-on-select="false"
                         :show-labels="false"
                         placeholder="Pick a value"
                         class="dayMultiSelect"></multiselect>
          </div>
          <div class="chartHeader">
            <button id="location-homeDepotEnergyNeedDistributionEstimatedByDistance-expand"
                    v-if="enlargedChart !== 'homeDepotEnergyNeedDistributionEstimatedByDistance'"
                    @click="handleClick('homeDepotEnergyNeedDistributionEstimatedByDistance')"
                    class="expandCompressChartButton" title="Expand">
              <v-icon name="expand" class="expandChartButtonIcon"/>
            </button>
            <button id="location-homeDepotEnergyNeedDistributionEstimatedByDistance-compress"
                    v-if="enlargedChart === 'homeDepotEnergyNeedDistributionEstimatedByDistance'"
                    @click="closeLarge('homeDepotEnergyNeedDistributionEstimatedByDistance')"
                    class="expandCompressChartButton" title="Compress">
              <v-icon name="compress" class="compressChartButtonIcon"/>
            </button>
          </div>
          <bar-chart getStateFunction="classified_location/getGraphDataStructure"
                     :width="enlargedChart === 'homeDepotEnergyNeedDistributionEstimatedByDistance' ? chartWidthLarge : chartWidth"
                     :height="enlargedChart === 'homeDepotEnergyNeedDistributionEstimatedByDistance' ? chartHeightLarge : chartHeight"
                     :svgHeight="enlargedChart === 'homeDepotEnergyNeedDistributionEstimatedByDistance' ? chartSVGHeightLarge : chartSVGHeight"
                     :margin="enlargedChart === 'homeDepotEnergyNeedDistributionEstimatedByDistance' ? chartMarginLarge : chartMarginSmall"
                     yAxisLabel="Energy need (kWh)"
                     xAxisLabel="Hour of day"
                     :graphTitle="getGraphTitle('homeDepotEnergyNeedDistributionEstimatedByDistance')"
                     chartDataName="homeDepotEnergyNeedDistributionEstimatedByDistance"
                     plotDataName="homeDepotEnergyNeedDistributionEstimatedByDistance"
                     yKey="home_depot_energy_need_estimated_by_distance_list"
                     xKey="hour_of_day"
                     :legend="enlargedChart === 'homeDepotEnergyNeedDistributionEstimatedByDistance'"/>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import mapboxgl from 'mapbox-gl'
import { H3ClusterLayer, H3HexagonLayer } from '@deck.gl/geo-layers'
import { MapboxLayer } from '@deck.gl/mapbox'
import { h3ToGeo } from 'h3-js'
import snowflake from '../../mixins/snowflake'
import SideMenu from './SideMenu.vue'
import * as d3 from 'd3'
import { orderBy } from 'lodash'
import { copyToClipboard, generateLocationPopupHtml, generateDepotHtml } from './LocationPopupUtil'
import BarChart from '@/components/D3Graphs/BarChartLocationClassification.vue'
import Multiselect from 'vue-multiselect'
import { ColumnLayer, LineLayer } from '@deck.gl/layers'

const pickupColor = '#00FF00'
const dropOffColor = '#FF0000'
const pickUpAndDropOffColor = '#0000FF'
const stoppingLocationColor = '#FF00FF'
const parkingColor = '#00FFFF'
const homeDepotColor = '#FFFF00'
const otherColor = '#FFFFFF'
const h38Color = '#FFFFFF'

const hexToRgb = (hex) => {
  const normal = hex.match(/^#([0-9a-f]{2})([0-9a-f]{2})([0-9a-f]{2})$/i)
  if (normal) return normal.slice(1).map(e => parseInt(e, 16))
  const shorthand = hex.match(/^#([0-9a-f])([0-9a-f])([0-9a-f])$/i)
  if (shorthand) return shorthand.slice(1).map(e => 0x11 * parseInt(e, 16))
  return null
}

const addAlpha = (rgb, alpha) => {
  return [...rgb, alpha]
}
const getColorByClassification = (classification) => {
  const alpha = 111
  switch (classification) {
    case 'pick-up':
      return addAlpha(hexToRgb(pickupColor), alpha)
    case 'drop-off':
      return addAlpha(hexToRgb(dropOffColor), alpha)
    case 'pick-up-and-drop-off':
      return addAlpha(hexToRgb(pickUpAndDropOffColor), alpha)
    case 'stop-location':
      return addAlpha(hexToRgb(stoppingLocationColor), alpha)
    case 'parking-location':
      return addAlpha(hexToRgb(parkingColor), alpha)
    case 'home-depot':
      return addAlpha(hexToRgb(homeDepotColor), alpha)
    case 'h3-8':
      return addAlpha(hexToRgb(h38Color), alpha)
    default:
      return addAlpha(hexToRgb(otherColor), alpha)
  }
}

export default {
  name: 'LocationMap',
  components: { SideMenu, BarChart, Multiselect },
  mixins: [snowflake],
  data () {
    return {
      fetchDataErrorError: null,
      fetchingHexagonAggregates: false,
      selectedBaseLayer: 'scania',
      layerNames: [],
      currentZoom: 0,
      showLegend: false,
      showLocationTypes: true,
      showMapButtons: false,
      mapDataFinishedLoading: false,
      hexagonCluster: null,
      hexagonPopup: null,
      selectedHexagons: new Set(),
      chassisFilterList: '',
      mapDisplayOptions: {
        showHomeDepotTransportPatternEdges: true,
        showHomeDepotTransportPatternDestination: false,
        showOriginEdges: true,
        showOriginContribution: false,
        showEnRouteOverNightParkings: true,
        showHomeDepotOverNightParkings: true
      },
      selectedGraphs: [
        'homeDepotNumberOfParkedVehicleDistribution',
        'homeDepotEnergyNeedDistributionEstimatedByDistance'
      ],
      numberOfParkedVehicleDistributionDay: 'Weekday',
      longHaulageNumberOfParkedVehicleDistributionDay: 'Weekday',
      homeDepotNumberOfParkedVehicleDistributionDay: 'Weekday',
      homeDepotNumberOfArrivalsDistributionDay: 'Weekday',
      homeDepotNumberOfDeparturesDistributionDay: 'Weekday',
      homeDepotEnergyNeedDistributionEstimatedByDistanceDay: 'Weekday',
      days: ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun', 'Weekday', 'Weekend'],
      graphOptions: [
        { text: 'Distribution of parked vehicles',
          value: 'numberOfParkedVehicleDistribution'
        },
        { text: 'Distribution of parked long haulage vehicles',
          value: 'longHaulageNumberOfParkedVehicleDistribution'
        },
        { text: 'Distribution of parked home depot vehicles',
          value: 'homeDepotNumberOfParkedVehicleDistribution'
        },
        { text: 'Distribution of arriving home depot vehicles',
          value: 'homeDepotNumberOfArrivalsDistribution'
        },
        { text: 'Distribution of departing home depot vehicles',
          value: 'homeDepotNumberOfDeparturesDistribution'
        },
        { text: 'Distribution of home depot energy need (v2) estimated by cycle distance',
          value: 'homeDepotEnergyNeedDistributionEstimatedByDistance'
        }
      ],
      chartWidth: 600,
      chartHeight: 270,
      chartSVGHeight: '28vh',
      chartWidthLarge: 860,
      chartHeightLarge: 340,
      chartSVGHeightLarge: '90vh',
      chartMarginSmall: { top: 30, right: 20, bottom: 100, left: 80 },
      chartMarginLarge: { top: 20, right: 10, bottom: 100, left: 60 },
      chartFontSize: '0.7rem',
      enlargedChart: null,
      customerLayers: [],
      selectedPropValue: null,
      selectedPropKey: null,
      newPropList: [],
      newValList: [],
      clickedTab: 'tab1',
      hasRun: false,
      highlightHomeDepots: false
    }
  },
  created () {
    this.customerLayers.push('locations')
    this.setupMapEvents()
    if (this.$store.getters.getUserSelection === 'classified_location') {
      this.mapDataFinishedLoading = false
      this.addLocationLayerToMap()
      this.addHomeDepotLayerToMap()
      this.addOverNightStopLayerToMap()
      this.addHomeDepotOverNightStopLayerToMap()
      this.$store.commit('setResetModal', true)
      this.$store.commit('setModalVisibility', false)
      this.mapDataFinishedLoading = true
      this.$logger.message('{"created":"Location map created"}')
      this.showMapButtons = true
      this.$store.commit('setLayerVisibility', ['location', true])
      this.showSelectedDataLayers()
    }
  },

  computed: {
    locationLayerVisible () {
      return this.$store.getters.getLayerVisibility('location')
    },
    mapObject: {
      get () {
        return this.$store.getters.getBaseMap
      },
      set (newMap) {
        this.$store.commit('setBaseMap', newMap)
      }
    },
    center () {
      return this.$store.getters['getCenterFor']('locationsMapCenter')
    },
    locationColours () {
      const colourScale = this.locationColourScale
      let colourObject = []
      const noStops = orderBy(this.fetchHexagonClusters().map(p => p['stop_count']))
      if (noStops.length > 6) {
        for (var i = 0; i < colourScale.range().length; i++) {
          colourObject.push({ colour: colourScale.range()[i], domain: colourScale.invertExtent(colourScale.range()[i]) })
        }
        for (var n = 1; n < colourObject.length; n++) {
          colourObject[0].domain[0] = 0
          colourObject[n].domain[0] = colourObject[n].domain[0] + 1
        }
      } else {
        for (var x = 0; x < noStops.length; x++) {
          colourObject.push({ colour: colourScale.range()[x], domain: [noStops[x], noStops[x]] })
        }
        for (var p = 1; p < colourObject.length; p++) {
          colourObject[0].domain[0] = 0
          colourObject[p].domain[0] = colourObject[p - 1].domain[1] + 1
        }
      }
      return colourObject
    },
    locationClassColours () {
      const colourObject = []
      const pickupColor = '#00FF00'
      const dropOffColor = '#FF0000'
      const pickUpAndDropOffColor = '#0000FF'
      const stoppingLocationColor = '#FF00FF'
      const parkingColor = '#00FFFF'
      const homeDepotColor = '#FFFF00'
      const vehicleHomeDepotColor = '#FFC800'
      const otherColor = '#FFFFFF'
      const overnightStopLocation = '#008000'
      const overnightHomeDepotStopLocation = '#800080'
      colourObject.push({ type: 'Pick-up', colour: pickupColor })
      colourObject.push({ type: 'Drop-off', colour: dropOffColor })
      colourObject.push({ type: 'Pick-up and drop-off', colour: pickUpAndDropOffColor })
      colourObject.push({ type: 'En route', colour: stoppingLocationColor })
      colourObject.push({ type: 'Parking', colour: parkingColor })
      colourObject.push({ type: 'Depot activity area', colour: homeDepotColor })
      colourObject.push({ type: 'Other', colour: otherColor })
      colourObject.push({ type: 'En route overnight parking indicator', colour: overnightStopLocation })
      colourObject.push({ type: 'Home depot overnight parking indicator', colour: overnightHomeDepotStopLocation })
      colourObject.push({ type: 'Vehicle home depot location indicator', colour: vehicleHomeDepotColor })
      return colourObject
    },
    locationColourScale () {
      let breakClass = 0
      let arrayRange = []
      let newArrayRange = []
      let tempArray = []
      let locColorScale
      const noStops = orderBy(this.fetchHexagonClusters().map(p => p['stop_count']))
      if (noStops.length > 6) {
        breakClass = Math.floor(noStops.length / 6)
        arrayRange = this.chunk(noStops, breakClass)
        arrayRange.forEach(i => {
          newArrayRange.push(d3.max(i))
        })
        for (var x = 0; x < newArrayRange.length; x++) {
          if (x < 5) {
            tempArray.push(newArrayRange[x])
          } else {
            if (x === newArrayRange.length - 1) {
              tempArray.push(newArrayRange[x])
            }
          }
        }
      }
      if (noStops.length > 6) {
        locColorScale = d3.scaleThreshold()
          .domain(tempArray)
          .range([d3.rgb(255, 249, 214),
            d3.rgb(253, 241, 157),
            d3.rgb(251, 234, 110),
            d3.rgb(231, 211, 60),
            d3.rgb(211, 192, 53),
            d3.rgb(190, 172, 45)])
      } else {
        locColorScale = d3.scaleOrdinal()
          .domain(noStops)
          .range([d3.rgb(255, 249, 214),
            d3.rgb(253, 241, 157),
            d3.rgb(251, 234, 110),
            d3.rgb(231, 211, 60),
            d3.rgb(211, 192, 53),
            d3.rgb(190, 172, 45)])
      }
      return locColorScale
    },
    stopsCountMinMax () {
      const noStops = this.fetchHexagonClusters().map(p => p['stop_count'])
      const maxMinStops = [d3.min(noStops), d3.max(noStops)]
      return maxMinStops
    }
  },
  methods: {
    handleUpdateChassisFilter (chassisFilter) {
      this.chassisFilterList = chassisFilter
    },
    updateMapDisplayOptions (optionUpdate) {
      this.mapDisplayOptions[optionUpdate.option] = optionUpdate.value
      this.addOverNightStopLayerToMap()
      this.addHomeDepotOverNightStopLayerToMap()
      this.addHomeDepotDestinationLayerToMap()
      this.addDestinationOriginLayerToMap()
      this.addHomeDepotTripLayerToMap()
      this.addDestinationOriginTripLayerToMap()
    },
    handlePointPropChanged (layerName, paintProperty, layerColor) {
      this.mapObject.setPaintProperty(layerName, paintProperty, layerColor)
    },
    chunk (arr, len) {
      var chunks = []
      var i = 0
      var n = arr.length
      while (i < n) {
        chunks.push(arr.slice(i, i += len))
      }
      return chunks
    },
    handleClick (activeEnlargedGraph) {
      this.enlargedChart = activeEnlargedGraph
    },
    closeLarge (activeEnlargedGraph) {
      this.enlargedChart = null
    },
    getGraphTitle (graph) {
      return this.graphOptions.find(item => item.value === graph).text
    },

    getGraphTabName (graph) {
      return this.graphOptions.find(item => item.value === graph).tabName
    },
    checkGraphSize (graph) {
      if (this.enlargedChart === graph) {
        return 'largeChart'
      } else if (this.selectedGraphs.length <= 4) {
        return 'smallChart'
      } else {
        return 'xSmallChart'
      }
    },

    checkGraphWidgetSize () {
      if (this.enlargedChart !== null) {
        return 'largeChartWidget'
      } else if (this.selectedGraphs.length <= 4) {
        return 'smallChartWidget'
      } else {
        return 'xSmallChartWidget'
      }
    },

    isFiltered (minValue, maxValue) {
      const filtered = this.fetchHexagonClusters().filter((h) => h['stop_count'] >= minValue && h['stop_count'] <= maxValue).map(h => h['cluster_id'])

      let copySelectedHexes = [...this.selectedHexagons]
      let countMatches = 0
      for (let i = 0; i < filtered.length; i++) {
        let index = copySelectedHexes.indexOf(filtered[i])
        if (index !== -1) {
          countMatches++
        }
      }
      if (countMatches !== 0 && filtered.length === countMatches) return true
      return false
    },
    showSelectedDataLayers () {
      // Hide all custom layers
      let numberOfMapIds = this.$store.getters.getMapLayerIds.length
      while (numberOfMapIds--) {
        let layerId = this.$store.getters.getMapLayerIds[numberOfMapIds]
        if (typeof this.mapObject.getLayer(layerId) !== 'undefined') {
          this.mapObject.setLayoutProperty(layerId, 'visibility', 'none')
        }
      }

      // Show the layer set to visible
      let numberOfVisibleMapIds = this.$store.getters.getLayers.length
      while (numberOfVisibleMapIds--) {
        let layerId = this.$store.getters.getLayers[numberOfVisibleMapIds]
        if (typeof this.mapObject.getLayer(layerId) !== 'undefined') {
          this.mapObject.setLayoutProperty(layerId, 'visibility', 'visible')
        }
      }
    },

    switchBaseLayer () {
      [ 'location',
        'home_depot_peak_destinations',
        'home_depot_trips',
        'destination_source_home_depots',
        'destination_source_home_depot_trips',
        'over_night_stops',
        'home_depot_over_night_stops',
        'vehicle_home_depots'
      ].forEach((layerId) => {
        if (this.mapObject.getLayer(layerId)) {
          this.mapObject.removeLayer(layerId)
        }
      })
      return new Promise(resolve => {
        // Get source and layer to add them back after the style change,
        // since the map's style object is the one holding the layers and the respective filters, etc.
        let layer = this.selectedBaseLayer
        if (layer === 'scania') {
          this.mapObject.setStyle('mapbox://styles/connected-intelligence/ckhpx27eb02zt19qrm3qphq46')
        } else {
          this.mapObject.setStyle('mapbox://styles/mapbox/' + layer)
        }
        this.mapObject.on('style.load', _ => {
          resolve()
        })
      })
    },

    setupMapEvents () {
      this.mapObject.on('click', this.setUpLocationClickEvent)
      this.mapObject.on('moveend', this.setUpZoom)
    },
    toggleShowLocationTypes () {
      this.showLocationTypes = !this.showLocationTypes
      this.addLocationLayerToMap()
    },
    toggleHighlightHomeDepots () {
      this.highlightHomeDepots = !this.highlightHomeDepots
      this.addHomeDepotLayerToMap()
    },
    setFilterRange (minValue, maxValue) {
      const filtered = this.fetchHexagonClusters()
        .filter((h) => h['stop_count'] >= minValue && h['stop_count'] <= maxValue)
        .map(h => h['cluster_id'])
      let copySelectedHexes = new Set(this.selectedHexagons)
      for (let i = 0; i < filtered.length; i++) {
        let inSet = copySelectedHexes.has(filtered[i])
        if (inSet) {
          copySelectedHexes.delete(filtered[i])
        } else {
          if (filtered[i]) copySelectedHexes.add(filtered[i])
        }
      }
      this.selectHexagons(copySelectedHexes)
    },
    getGeneralDataInfo () {
      const generalInfo = this.fetchGeneralInfoData()[0]
      return generalInfo
    },
    hexagonCountLegend (minValue, maxValue) {
      const hexFiltered = this.fetchHexagonClusters().filter((h) => h['stop_count'] >= minValue && h['stop_count'] <= maxValue)
        .map(h => h['cluster_id'])
      const hexCountPerBucket = hexFiltered.length
      return hexCountPerBucket
    },
    stopsCountLegend (minValue, maxValue) {
      const hexFiltered = this.fetchHexagonClusters()
        .filter((h) => h['stop_count'] >= minValue && h['stop_count'] <= maxValue)
        .map(h => h['stop_count'])
      const stopsCountBucket = d3.sum(hexFiltered)
      return stopsCountBucket
    },
    setUpLocationClickEvent (e) {
      const deck = this.mapObject.__deck
      if (deck !== null) {
        let pointerObject = deck._lastPointerDownInfo
        if (pointerObject.layer !== null) {
          if (pointerObject.layer.id === 'location' &&
            this.$store.getters.getLayerVisibility('location')) {
            this.popupDataInfoOnClickPolygon(e)
            this.popupAggregationsOnClickPolygon(e)
            // toggle hex selected state
            let copySelectedHexes = new Set(this.selectedHexagons)
            if (copySelectedHexes.has(this.hexagonCluster['cluster_id'])) {
              copySelectedHexes.delete(this.hexagonCluster['cluster_id'])
            } else {
              copySelectedHexes.add(this.hexagonCluster['cluster_id'])
            }
            this.selectHexagons(copySelectedHexes)
          }
        }
      }
      this.$logger.message(`{"setUpLocationClickEvent":"Map center is at: ${this.mapObject.getCenter()}"}`, 'info')
    },
    selectHexagons (selectedHexes) {
      this.$store.commit('classified_location/setSelectedHexagons', selectedHexes)
      const copySelectedHexes = [...selectedHexes]
      let refreshedLayers = []
      let currLayers = this.mapObject.__deck.layerManager.getLayers()
      let layer = currLayers.filter(l => l.id === 'location')[0]
      const toRBGArray = (rgbObj) => { return [rgbObj.r, rgbObj.g, rgbObj.b] }

      this.selectedHexagons = new Set(selectedHexes)

      let newLayer = layer.clone({
        getFillColor: (hexagon) => {
          let col
          if (this.showLocationTypes) {
            col = getColorByClassification(hexagon['location_classification'])
          } else {
            col = this.selectedHexagons.has(hexagon['h3_cell_index'])
              ? [255, 0, 128]
              : toRBGArray(d3.rgb(this.locationColourScale(hexagon['stop_count'])))
          }
          return col
        },
        updateTriggers: {
          getFillColor: [copySelectedHexes, this.showLocationTypes]
        }
      })
      refreshedLayers.push(newLayer)
      this.mapObject.__deck.setProps({ layers: refreshedLayers })
    },
    resetGraphData () {
      ['numberOfParkedVehicleDistribution',
        'longHaulageNumberOfParkedVehicleDistribution',
        'homeDepotNumberOfParkedVehicleDistribution',
        'homeDepotNumberOfArrivalsDistribution',
        'homeDepotNumberOfDeparturesDistribution',
        'homeDepotEnergyNeedDistributionEstimatedByDistance'
      ].forEach(key => this.$store.commit('classified_location/setGraphData', {
        data: [],
        datasetKeyName: key
      }))
    },
    popupAggregationsOnClickPolygon (e) {
      if (this.hexagonCluster) {
        this.fetchingHexagonAggregates = true
        // Reset each graph's dataset to an empty list
        this.resetGraphData()

        // Fetch hexagon aggregations for home-depot
        let startTime = Date.now()
        let reqParams = {
          hexresolution: 'H3_CELL_INDEX_' + this.$store.getters['classified_location/getResolutionToRequest'],
          sqlfilename: 'GET_HEXAGON_AGGREGATIONS',
          selectedclusterid: this.hexagonCluster['cluster_id'],
          chassilist: this.chassisFilterList
        }
        this.$logger.message(`{"popupAggregationsOnClickPolygon":"${JSON.stringify(reqParams)}"}`, 'info')
        const results = this.getQueryResultsForClassifiedLocations('aggregationsForSelectedHexagon', reqParams)
        Promise.all([results])
          .then((response) => {
            // this.$logger.message(`{"popupAggregationsOnClickPolygon":"${JSON.stringify(response[0].data[0])}"}`, 'info')
            this.$store.commit('classified_location/setHexagonAggregates', response[0].data[0])
            if (this.hexagonCluster.hasOwnProperty('home_depot_statistics') && (this.chassisFilterList.length === 0 || this.hexagonCluster.home_depot_statistics.number_of_matched_home_depot_chassis > 0)) {
              this.addHomeDepotTripLayerToMap()
              this.addHomeDepotDestinationLayerToMap()
              this.setVehicleDistributionData('home_depot_median_visits', 'homeDepotNumberOfParkedVehicleDistribution')
              this.setVehicleDistributionData('home_depot_median_arrivals', 'homeDepotNumberOfArrivalsDistribution')
              this.setVehicleDistributionData('home_depot_median_departures', 'homeDepotNumberOfDeparturesDistribution')
              this.setVehicleDistributionData('home_depot_energy_need_based_on_distance', 'homeDepotEnergyNeedDistributionEstimatedByDistance')
            }
            this.addDestinationOriginTripLayerToMap()
            this.addDestinationOriginLayerToMap()
            this.setVehicleDistributionData('median_visits', 'numberOfParkedVehicleDistribution')
            this.setVehicleDistributionData('long_haulage_median_visits', 'longHaulageNumberOfParkedVehicleDistribution')
            this.fetchDataErrorError = null
          })
          .catch(error => {
            this.$logger.message(`{"popupAggregationsOnClickPolygon":"Error: ${error}"}`, 'error')
            try {
              this.fetchDataErrorError = error.response.data.message
            } catch {
              this.fetchDataErrorError = error
            }
          })
          .finally(() => {
            this.fetchingHexagonAggregates = false
            let endTime = Date.now()
            this.$logger.message(`{"popupAggregationsOnClickPolygon":{"message":"Request locations finished","time":"${endTime - startTime}"}}`, 'info')
          })
      } else {
        // Not a home-depot
        this.$logger.message(`{"popupAggregationsOnClickPolygon":{"message":"not a home-depot"}}`, 'info')
      }
    },
    loadDepotInformation (clusterId) {
      let startTime = Date.now()
      let reqParams = {
        hexresolution: 'H3_CELL_INDEX_' + this.$store.getters['classified_location/getResolutionToRequest'],
        sqlfilename: 'GET_HOME_DEPOT_CYCLE_STATISTICS',
        selectedclusterid: clusterId,
        chassilist: this.chassisFilterList
      }
      const results = this.getQueryResultsForClassifiedLocations('aggregationsForSelectedHexagon', reqParams)
      const depotTab = document.querySelector('.tab-popup.depot-tab')
      Promise.all([results])
        .then((response) => {
          // this.$logger.message(`{"popupAggregationsOnClickPolygon":"${JSON.stringify(response[0].data[0])}"}`, 'info')
          this.populateDepotTab(response[0].data[0], this.chassisFilterList)
          // Show the tab content
          this.showTabContent(depotTab)
        })
        .catch(error => {
          this.$logger.message(`{"loadDepotInformation":"Error: ${error}"}`, 'error')
          try {
            this.fetchDataErrorError = error.response.data.message
          } catch {
            this.fetchDataErrorError = error
          }
          return []
        })
        .finally(() => {
          let endTime = Date.now()
          this.$logger.message(`{"loadDepotInformation":{"message":"Request finished","time":"${endTime - startTime}"}}`, 'info')
        })
    },
    popupDataInfoOnClickPolygon (e) {
      if (this.hexagonCluster) {
        let coordinates = this.transposeCoordinates(this.hexagonCluster['cluster_id'])
        const description = generateLocationPopupHtml(this.hexagonCluster, this.chassisFilterList)
        // Ensure that if the map is zoomed out such that multiple
        // copies of the feature are visible, the popup appears
        // over the copy being pointed to.
        while (Math.abs(e.lngLat.lng - coordinates[0] > 180)) {
          coordinates[0] += e.lngLat.lng > coordinates[0] ? 360 : -360
        }
        new mapboxgl.Popup({ maxWidth: 550 })
          .setLngLat(coordinates)
          .setHTML(description)
          .addTo(this.mapObject)
        document.querySelectorAll('.copyMatched').forEach(button => {
          button.addEventListener('click', function () {
            const text = document.getElementById('chassisString').getAttribute('title')
            const split = text.split(':')
            if (split.length > 1) {
              copyToClipboard(split[1].trim())
            }
          })
        })
        document.querySelectorAll('.copyMatchedDepot').forEach(button => {
          button.addEventListener('click', function () {
            const text = document.getElementById('depotChassisString').getAttribute('title')
            const split = text.split(':')
            if (split.length > 1) {
              copyToClipboard(split[1].trim())
            }
          })
        })
        const tabs = document.querySelectorAll('.tab-popup')
        tabs.forEach((tab, index) => {
          if (tab.classList.contains('depot-tab')) {
            tab.addEventListener('click', (e) => {
              this.clickedTab = e.currentTarget.getAttribute('data-tab')
              this.loadDepotInformation(this.hexagonCluster['cluster_id'])
            })
          }
        })
      }
      const tabs = document.querySelectorAll('.tab-popup')
      tabs.forEach((tab, index) => {
        if (!tab.classList.contains('depot-tab')) {
          tab.addEventListener('click', (e) => {
            this.clickedTab = e.currentTarget.getAttribute('data-tab')
            this.showTabContent(e.currentTarget)
          })
        }
      })
      this.stateTab()
      this.$logger.message(`{"popupDataInfoOnClickPolygon":[{"event":"mouseup"}, {"className":"mapboxgl-popup-open"}]}`, 'info')
    },
    populateDepotTab (depotInfo, chassisList) {
      document.getElementById('depot-content').innerHTML = this.generateDepotContentHtml(depotInfo, chassisList)
    },
    generateDepotContentHtml (depotInfo, chassisList) {
      return generateDepotHtml(depotInfo, chassisList)
    },
    stateTab () {
      const tabContentToShow = document.querySelector(`.tab-popup-content[data-tab='${this.clickedTab}']`)
      const tabsSelect = document.querySelectorAll(`.tab-popup[data-tab='${this.clickedTab}']`)
      const tabContents = document.querySelectorAll('.tab-popup-content')
      const tabsAll = document.querySelectorAll('.tab-popup')
      if (tabContentToShow && tabsSelect) {
        tabContents.forEach((content) => {
          content.style.display = 'none'
        })
        if (tabContentToShow) {
          tabContentToShow.style.display = 'block'
        }
        tabsAll.forEach((tab) => {
          tab.classList.remove('active')
        })
        // Update active class for tabs
        if (tabsSelect) {
          tabsSelect.forEach((tab) => {
            tab.classList.add('active')
          })
        }
      } else {
        this.clickedTab = 'tab1'
      }
      if (this.clickedTab === 'tab2') {
        this.loadDepotInformation(this.hexagonCluster['cluster_id'])
      }
    },
    showTabContent (tab) {
      const activeTab = tab
      const activeTabName = activeTab.getAttribute('data-tab')
      // Hide all tab contents
      const tabContents = document.querySelectorAll('.tab-popup-content')
      tabContents.forEach((content) => {
        content.style.display = 'none'
      })
      // Show the content of the clicked tab
      const tabContentToShow = document.querySelector(`.tab-popup-content[data-tab='${activeTabName}']`)
      if (tabContentToShow) {
        tabContentToShow.style.display = 'block'
      }

      // Update active class for tabs
      const tabs = document.querySelectorAll('.tab-popup')
      tabs.forEach((tab) => {
        if (tab === activeTab) {
          tab.classList.add('active')
        } else {
          tab.classList.remove('active')
        }
      })
    },
    fetchHexagonClusters () {
      return this.$store.getters['classified_location/getHexagonClusters']
    },
    fetchGeneralInfoData () {
      return this.$store.getters['classified_location/getGeneralInfo']
    },
    addHomeDepotDestinationLayerToMap () {
      if (this.mapObject.getLayer('home_depot_peak_destinations')) {
        this.mapObject.removeLayer('home_depot_peak_destinations')
      }
      if (this.mapDisplayOptions['showHomeDepotTransportPatternDestination'] &&
        this.hexagonCluster &&
        this.hexagonCluster.home_depot_statistics.home_depot_number_of_vehicles > 2) {
        const hexagonAggregates = this.$store.getters['classified_location/getHexagonAggregates']
        if (hexagonAggregates) {
          const destinationData = hexagonAggregates.vehicle_peak_locations
          if (destinationData) {
            const tripLayer = new MapboxLayer({
              id: 'home_depot_peak_destinations',
              type: ColumnLayer,
              data: destinationData,
              opacity: 0.15,
              radius: 50,
              extruded: true,
              angle: 15,
              elevationScale: 20,
              getPosition: d => d.position.coordinates,
              getElevation: d => d.number_of_vehicles,
              getFillColor: d => [0, 128, 255, 255],
              getLineColor: [0, 0, 0]
            })
            this.mapObject.addLayer(tripLayer)
          }
        }
      }
    },
    addHomeDepotTripLayerToMap () {
      if (this.mapObject.getLayer('home_depot_trips')) {
        this.mapObject.removeLayer('home_depot_trips')
      }
      if (this.mapDisplayOptions['showHomeDepotTransportPatternEdges'] &&
        this.hexagonCluster &&
        (this.hexagonCluster.home_depot_statistics.home_depot_number_of_vehicles > 2 ||
          this.hexagonCluster.home_depot_statistics.number_of_matched_home_depot_chassis > 0)) {
        const hexagonAggregates = this.$store.getters['classified_location/getHexagonAggregates']
        if (hexagonAggregates) {
          const tripData = hexagonAggregates.home_depot_trips
          if (tripData) {
            const tripLayer = new MapboxLayer({
              id: 'home_depot_trips',
              type: LineLayer,
              data: tripData,
              opacity: 0.07,
              getWidth: d => Math.min(Math.sqrt(d.count) * 2, 40),
              getSourcePosition: d => d.from_position.coordinates,
              getTargetPosition: d => d.to_position.coordinates,
              getColor: d => [Math.min(d.med_time_index * 5, 255), 40, 200]
            })
            this.mapObject.addLayer(tripLayer)
          }
        }
      }
    },
    addDestinationOriginLayerToMap () {
      if (this.mapObject.getLayer('destination_source_home_depots')) {
        this.mapObject.removeLayer('destination_source_home_depots')
      }
      if (this.mapDisplayOptions['showOriginContribution']) {
        const hexagonAggregates = this.$store.getters['classified_location/getHexagonAggregates']
        if (hexagonAggregates) {
          const destinationData = hexagonAggregates.destination_source_home_depots
          if (destinationData) {
            const destinationLayer = new MapboxLayer({
              id: 'destination_source_home_depots',
              type: ColumnLayer,
              data: destinationData,
              opacity: 0.15,
              radius: 50,
              extruded: true,
              angle: 15,
              elevationScale: 20,
              getPosition: d => d.position.coordinates,
              getElevation: d => Math.sqrt(d.origin_total_stop_count),
              getFillColor: d => [Math.min(d.origin_total_duration / 3600, 255), 255, 128, 255],
              getLineColor: [0, 0, 0]
            })
            this.mapObject.addLayer(destinationLayer)
          }
        }
      }
    },
    addDestinationOriginTripLayerToMap () {
      if (this.mapObject.getLayer('destination_source_home_depot_trips')) {
        this.mapObject.removeLayer('destination_source_home_depot_trips')
      }
      if (this.mapDisplayOptions['showOriginEdges']) {
        const hexagonAggregates = this.$store.getters['classified_location/getHexagonAggregates']
        if (hexagonAggregates) {
          const tripData = hexagonAggregates.destination_source_home_depot_trips
          if (tripData) {
            const tripLayer = new MapboxLayer({
              id: 'destination_source_home_depot_trips',
              type: LineLayer,
              data: tripData,
              opacity: 0.07,
              getWidth: d => Math.min(Math.sqrt(d.count) * 2, 40),
              getSourcePosition: d => d.from_position.coordinates,
              getTargetPosition: d => d.to_position.coordinates,
              getColor: d => [Math.min(d.med_time_index * 5, 255), 200, 40]
            })
            this.mapObject.addLayer(tripLayer)
          }
        }
      }
    },
    addLocationLayerToMap () {
      if (this.mapObject.getLayer('location')) {
        this.mapObject.removeLayer('location')
      }
      if (!this.mapObject.getLayer('location')) {
        const addLayerUnderneathWaterLayer = !this.mapObject.getStyle().sprite.includes('satellite')
        const toRBGArray = (rgbObj) => { return [rgbObj.r, rgbObj.g, rgbObj.b] }
        const hexagonClusters = this.fetchHexagonClusters()
        const locationHexagonLayer = new MapboxLayer({
          id: 'location',
          type: H3ClusterLayer,
          parameters: {
            depthTest: false
          },
          data: hexagonClusters,
          autoHighlight: true,
          opacity: 1,
          getHexagons: c => c['h3_cell_index_array'],
          filled: true,
          getLineColor: [255, 255, 255],
          lineWidthMinPixels: 2,
          getFillColor: c => {
            let col
            if (this.showLocationTypes) {
              col = getColorByClassification(c['location_classification'])
            } else {
              col = this.selectedHexagons.has(c['h3_cell_index'])
                ? [255, 0, 128]
                : toRBGArray(d3.rgb(this.locationColourScale(c['stop_count'])))
            }
            return col
          },
          highPrecision: false,
          pickable: true,
          extruded: false,
          updateTriggers: {
            getFillColor: [this.selectedHexagons, this.showLocationTypes]
          },
          onClick: hexagon => {
            this.hexagonCluster = { ...hexagon.object }
            return true
          }
        })
        if (addLayerUnderneathWaterLayer) {
          this.mapObject.addLayer(locationHexagonLayer, 'waterway')
        } else {
          this.mapObject.addLayer(locationHexagonLayer)
        }
        if (!this.hasRun) {
          this.flyInOnLoad()
          this.hasRun = true
        }
      }
    },
    addOverNightStopLayerToMap () {
      if (this.mapObject.getLayer('over_night_stops')) {
        this.mapObject.removeLayer('over_night_stops')
      }
      if (this.mapDisplayOptions['showEnRouteOverNightParkings']) {
        const locationData = this.fetchHexagonClusters()
        // this.$logger.message(locationData, 'debug')
        const overNightLocationData = locationData.filter((i) => i.weekly_median_number_of_en_route_over_night_parkings >= 0.4)

        const overNightStopLayer = new MapboxLayer({
          id: 'over_night_stops',
          type: H3HexagonLayer,
          parameters: {
            depthTest: false
          },
          data: overNightLocationData,
          opacity: 0.7,
          coverage: 0.33,
          extruded: true,
          getHexagon: (hexagon) => hexagon['cluster_id'],
          elevationScale: 20,
          highPrecision: false,
          getElevation: d => d.weekly_median_number_of_en_route_over_night_parkings,
          getFillColor: d => [0, 128, 0]
        })
        this.mapObject.addLayer(overNightStopLayer)
      }
    },
    addHomeDepotOverNightStopLayerToMap () {
      if (this.mapObject.getLayer('home_depot_over_night_stops')) {
        this.mapObject.removeLayer('home_depot_over_night_stops')
      }
      if (this.mapDisplayOptions['showHomeDepotOverNightParkings']) {
        const locationData = this.fetchHexagonClusters()
        // this.$logger.message(locationData, 'debug')
        const overNightLocationData = locationData.filter((i) => i.home_depot_statistics &&
          i.weekly_median_number_of_home_depot_over_night_parkings >= 0.4)

        const overNightStopLayer = new MapboxLayer({
          id: 'home_depot_over_night_stops',
          type: H3HexagonLayer,
          parameters: {
            depthTest: false
          },
          data: overNightLocationData,
          opacity: 0.7,
          coverage: 0.25,
          extruded: true,
          getHexagon: (hexagon) => hexagon['cluster_id'],
          elevationScale: 5,
          highPrecision: false,
          getElevation: d => d['weekly_median_number_of_home_depot_over_night_parkings'],
          getFillColor: d => [128, 0, 128]
        })
        this.mapObject.addLayer(overNightStopLayer)
      }
    },
    addHomeDepotLayerToMap () {
      if (this.mapObject.getLayer('vehicle_home_depots')) {
        this.mapObject.removeLayer('vehicle_home_depots')
      }
      if (!this.mapObject.getLayer('vehicle_home_depots')) {
        const locationData = this.fetchHexagonClusters()
        // this.$logger.message(locationData, 'debug')
        const vehicleHomeDepotData = locationData.filter((i) => i.home_depot_statistics && i.home_depot_statistics.home_depot_number_of_vehicles > 0)
        const vehicleHomeDepotLayer = new MapboxLayer({
          id: 'vehicle_home_depots',
          type: H3HexagonLayer,
          parameters: {
            depthTest: false
          },
          data: vehicleHomeDepotData,
          opacity: 0.4,
          coverage: 0.6,
          extruded: true,
          getHexagon: (hexagon) => hexagon['cluster_id'],
          elevationScale: 10,
          highPrecision: false,
          getElevation: d => {
            if (this.chassisFilterList.length === 0) {
              return this.highlightHomeDepots ? 15000 : d.home_depot_statistics.home_depot_number_of_vehicles
            } else {
              return this.highlightHomeDepots && d.home_depot_statistics.number_of_matched_home_depot_chassis > 0 ? 15000 : d.home_depot_statistics.number_of_matched_home_depot_chassis
            }
          },
          getFillColor: d => [255, 200, 0],
          updateTriggers: {
            getElevation: [this.highlightHomeDepots]
          }
        })
        this.mapObject.addLayer(vehicleHomeDepotLayer)
      }
    },
    removeCustomerLayer (layerName) {
      this.mapObject.removeLayer(layerName)
      this.mapObject.removeSource(layerName)
      this.customerLayers = this.customerLayers.filter(layer => layer !== layerName)
      this.layerNames = this.layerNames.filter(layer => layer.layerName !== layerName)
    },
    reAddCustomerLayers (layer) {
      this.mapObject.addSource(layer.layerName, {
        type: 'geojson',
        data: layer.jsonSource
      })
      let newLayer = {
        'id': layer.layerName,
        'type': 'circle',
        'source': layer.layerName,
        'layout': {
          'visibility': 'visible'
        },
        'paint': {
          'circle-stroke-color': layer.color,
          'circle-stroke-width': layer.strokeWidth,
          'circle-color': 'rgba(0,0,0,.6)',
          'circle-radius': layer.radius
        }
      }
      this.mapObject.addLayer(newLayer)
    },
    addGeoJSONLayer (geoJSON) {
      this.customerLayers.push(geoJSON.name)
      let defaultColor = '#DFFE00'
      let defaultRadius = 8
      let defaultStrokeWidth = 4

      this.layerNames.push({
        jsonSource: geoJSON.geojson,
        layerName: geoJSON.name,
        color: defaultColor,
        radius: defaultRadius,
        strokeWidth: defaultStrokeWidth
      })
      this.mapObject.addSource(geoJSON.name, {
        type: 'geojson',
        data: geoJSON.geojson
      })

      let newLayer = {
        'id': geoJSON.name,
        'type': 'circle',
        'source': geoJSON.name,
        'layout': {
          'visibility': 'visible'
        },
        'paint': {
          'circle-stroke-color': defaultColor,
          'circle-stroke-width': defaultStrokeWidth,
          'circle-color': 'rgba(0,0,0,.6)',
          'circle-radius': defaultRadius
        }
      }
      this.mapObject.addLayer(newLayer)
      this.$store.commit('setLayerVisibility', [geoJSON.name, true])
      this.showSelectedDataLayers()
    },
    transposeCoordinates (h3CellIndex) {
      let invertedCoordinates = h3ToGeo(h3CellIndex)
      return [invertedCoordinates[1], invertedCoordinates[0]]
    },
    flyInOnLoad () {
      const locationAggregates = this.$store.getters['classified_location/getHexagonClusters']
      if (locationAggregates === null) return
      const maxObj = locationAggregates.reduce((prev, current) => (prev['stop_count'] > current['stop_count']) ? prev : current)
      const maxHex = maxObj['cluster_id']
      this.mapObject.easeTo({ center: this.transposeCoordinates(maxHex), zoom: 7, duration: 6000 })
    },
    handleDeckLayerChanged () {
      this.selectedHexagons = new Set()
      this.resetGraphData()
      this.hexagonCluster = null
      this.$store.commit('classified_location/setHexagonAggregates', {})

      /*
      let refreshedLayers = []
      let currLayers = this.mapObject.__deck.layerManager.getLayers()
      let hexagonLayer = currLayers.filter(l => l.id === 'location')[0]
      const locationData = this.fetchLocationData()
      const updatedData = [...locationData]

      let newHexagonLayer = hexagonLayer.clone({ data: updatedData })
      refreshedLayers.push(newHexagonLayer)

      this.mapObject.__deck.setProps({ layers: refreshedLayers })
      */
      this.addLocationLayerToMap()
      this.addHomeDepotTripLayerToMap()
      this.addHomeDepotDestinationLayerToMap()
      this.addDestinationOriginTripLayerToMap()
      this.addDestinationOriginLayerToMap()
      this.addHomeDepotLayerToMap()
      this.addOverNightStopLayerToMap()
      this.addHomeDepotOverNightStopLayerToMap()
    },
    updateGraphOptions (options) {
      this.graphOptions = options
    },
    updateGraphSelection (selection) {
      this.selectedGraphs = selection
    },
    selectMapStyle (mapStyle) {
      this.selectedBaseLayer = mapStyle
    },
    setUpZoom () {
      this.currentZoom = this.mapObject.getZoom()
    },
    setVehicleDistributionData (aggregateKeyPrefix, datasetKeyName) {
      // aggregateKeyName = home_depot_median_visits_per_week_part_and_hour
      // datasetKeyName = homeDepotNumberOfParkedVehicleDistribution
      const dayCategory = this[datasetKeyName + 'Day']
      let aggregateKeyName = null
      if (['Weekday', 'Weekend'].includes(dayCategory)) {
        aggregateKeyName = aggregateKeyPrefix + '_per_week_part_and_hour'
      } else {
        aggregateKeyName = aggregateKeyPrefix + '_per_week_day_and_hour'
      }

      const hexagonAggregates = this.$store.getters['classified_location/getHexagonAggregates']
      const data = hexagonAggregates[aggregateKeyName]['day_categories']
        .filter(d => d['day_category'].toUpperCase() === dayCategory.toUpperCase())[0].values
      this.$store.commit('classified_location/setGraphData', {
        data: data,
        datasetKeyName: datasetKeyName
      })
    }
  },
  watch: {
    '$store.state.triggerDeckRedraw': function () {
      if (this.$store.getters.getUserSelection === 'classified_location') {
        this.$store.commit('setLayerVisibility', ['location', true])
        this.selectedHexagons = new Set()
        this.hexagonCluster = null
        this.addLocationLayerToMap()
        this.addHomeDepotLayerToMap()
        this.addOverNightStopLayerToMap()
        this.addHomeDepotOverNightStopLayerToMap()
        this.$store.commit('setTriggerDeckRedraw', false)
        this.$store.commit('setResetModal', true)
        this.$store.commit('setModalVisibility', false)
      }
    },

    currentZoom: function () {
      if (this.mapDataFinishedLoading) {
        this.$logger.message(`{"currentZoom":"Zoom level changed to: ${this.mapObject.getZoom()}"}`, 'info')
      }
    },
    selectedBaseLayer: function () {
      this.switchBaseLayer().finally(() => {
        this.addLocationLayerToMap()
        this.addHomeDepotLayerToMap()
        this.addOverNightStopLayerToMap()
        this.addHomeDepotOverNightStopLayerToMap()
        this.layerNames.forEach(dataLayer => {
          this.reAddCustomerLayers(dataLayer)
        })
        this.showSelectedDataLayers()
      })
    },

    '$store.state.visibleLayers': function () {
      this.showSelectedDataLayers()
    },

    numberOfParkedVehicleDistributionDay: function () {
      this.setVehicleDistributionData('median_visits', 'numberOfParkedVehicleDistribution')
    },
    longHaulageNumberOfParkedVehicleDistributionDay: function () {
      this.setVehicleDistributionData('long_haulage_median_visits', 'longHaulageNumberOfParkedVehicleDistribution')
    },
    homeDepotNumberOfParkedVehicleDistributionDay: function () {
      this.setVehicleDistributionData('home_depot_median_visits', 'homeDepotNumberOfParkedVehicleDistribution')
    },
    homeDepotNumberOfArrivalsDistributionDay: function () {
      this.setVehicleDistributionData('home_depot_median_arrivals', 'homeDepotNumberOfArrivalsDistribution')
    },
    homeDepotNumberOfDeparturesDistributionDay: function () {
      this.setVehicleDistributionData('home_depot_median_departures', 'homeDepotNumberOfDeparturesDistribution')
    },
    homeDepotEnergyNeedDistributionEstimatedByDistanceDay: function () {
      this.setVehicleDistributionData('home_depot_energy_need_based_on_distance', 'homeDepotEnergyNeedDistributionEstimatedByDistance')
    }
  },
  async copyMatchingToClipboard (elementId) {
    try {
      const text = document.getElementById(elementId).getAttribute('title')
      const split = text.split(':')
      if (split.length > 1) {
        await navigator.clipboard.writeText(split[1].trim())
      }
    } catch (err) {
      console.error('Failed to copy!', err)
    }
  },
  beforeDestroy () {
    this.$nextTick(() => {
      this.mapObject.off('click', this.setUpLocationClickEvent)
      this.customerLayers.map(layer => {
        this.mapObject.off('click', layer, this.popupDataInfoOnClick)
      })
      this.mapObject.off('moveend', this.setUpZoom)
    })
  }

}
</script>

<style scoped>
.svg-container {
  display: inline-block;
  position: relative;
  width: 95%;
  height: 90vh;
  vertical-align: top;
  overflow: hidden;
  margin-top: 50px;
  margin-left: 0px;
}
.svg-content {
  display: inline-block;
  position: absolute;
  top: 10px;
  left: 0;
}

.loadingBar {
  margin-top: 10px;
  left: 0;
  right: 0;
  position: absolute;
  z-index: 1;
  color: white;
  font-family: 'Montserrat', sans-serif;
  font-size: 13px;
}
#privacyNotice {
  animation: cssAnimation2 0s 6s forwards;
  visibility: hidden;
}

span.block {
  display: block;
}
@keyframes cssAnimation1 {
  to {
    width: 0;
    height: 0;
    overflow: hidden;
  }
}
@-webkit-keyframes cssAnimation1 {
  to {
    width: 0;
    height: 0;
    visibility: hidden;
  }
}
@keyframes cssAnimation2 {
  to {
    visibility: visible;
  }
}

button {
  cursor: pointer;
}

/* remove blue outline */
button:focus {
  outline: 0;
}

.legendDescriptor {
  margin: 0.5vw;
  width:290px;
  font-family: 'Montserrat', sans-serif;
}

.legendRow {
  justify-content: left;
  display: flex;
  align-items: center;
  cursor: pointer;
}

.legendRowButton {
  justify-content: left;
  display: flex;
  align-items: center;
  cursor: pointer;
  margin: 3px;
}

.loadingIndication {
  display: inline-block;
  white-space: nowrap;
  margin: 0 5px;
}

#closeLegendButton {
  position: absolute;
  top: 1px;
  right: 4px;
  background-color: transparent;
  border: none;
}
#closeLegendButtonIcon {
  height: 12px;
  width: 12px;
}
#legend {
  background-color: white;
  border-radius: 3px;
  position: absolute;
  right: 20px;
  top: 98px;
  z-index: 3;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
  padding: 1rem 20px 1rem 20px;
  opacity: 0.9;
}
#legendButton .toolTipText {
  visibility: hidden;
  width: 100px;
  background-color: black;
  color: #fff;
  font-family: 'Montserrat', sans-serif;;
  text-align: center;
  border-radius: 6px;
  padding: 5px 0;

  /* Position the tooltip */
  position: absolute;
  z-index: 1;
  top: -3px;
  right: 105%;
}
#legendButton:hover .toolTipText {
  visibility: visible;
}
.mapButtonIcon {
  fill: white;
  height: 25px;
  width: 25px;
}

.fetchError {
  color: red
}

#legendButton {
  background-color: transparent;
  border: none;
  position: relative;
  display: inline-block;
}
#mapButtons {
  position: absolute;
  z-index: 1;
  right: 0;
  top: 80px;
  padding: 1rem 18px 1rem 20px;
}

.highlightSelectedLegendGroup {
  background: rgb(255, 0, 128);
  margin-top: 4px;
  width: 12px;
  height: 4px;
}
.expandCompressChartButton {
  position: absolute;
  top: 1vh;
  right: 1.5vw;
  background-color: transparent;
  border: none;
  z-index: 5;
}

.dayMultiSelect {
  position: absolute;
  top: 1vh;
  left: 1.5vw;
  background-color: transparent;
  border: none;
  width: 115px;
  height: 15px;
  z-index: 5;
}

.compressChartButtonIcon {
  height: 35px;
  width: 35px;
}

.expandChartButtonIcon {
  height: 18px;
  width: 18px;
}

.xSmallChartWidget {
  position: absolute;
  z-index: 3;
  max-width: 60vw;
  background-color: rgba(255,255,255,0.8);
  margin-left: 37vw;
  bottom: 8px;
  margin-bottom: 0.5vh;
}

.smallChartWidget {
  position: absolute;
  z-index: 3;
  max-width: 60vw;
  background-color: rgba(255,255,255,0.8);
  margin-left: 37vw;
  bottom: 8px;
  margin-bottom: 0.5vh;
}

.largeChartWidget {
  position: absolute;
  z-index: 4;
  width: 100%;
  height: 90vh;
  overflow: hidden;
  background-color: rgba(255,255,255,1);
  padding: 20px;
}

.xSmallChart {
  width: 20vw;
  height: 32vh;
  position: relative;
  float: left;
}

.smallChart {
  width: 30vw;
  height: 32vh;
  position: relative;
  float: left;
}

.largeChart {
  width: 100%;
  position: absolute;
  background-color: rgba(255,255,255,1);
  z-index: 10;
}
</style>

<style>
.mapboxgl-popup-content {
  word-wrap: break-word;
}
/* Custom styles for the table inside the popup */
.mapboxgl-popup-content table {
  font-family: Arial, Helvetica, sans-serif;
  border-collapse: collapse;
  width: 100%;
}

.mapboxgl-popup-content th,
.mapboxgl-popup-content td {
  border: 1px solid #ddd;
  padding: 2px;
}

.mapboxgl-popup-content th {
  background-color: #f2f2f2;
}

.mapboxgl-popup-content tr:nth-child(even) {
  background-color: #f2f2f2;
}
.mapboxgl-popup-content tr:hover {
  background-color: #ddd;
}
/* Styling for the tabs */
.mapboxgl-popup-content .tab-popup-container {
  display: flex;
  background-color: #f0f0f0;
  cursor: pointer;
  margin-left: 20px;
  margin-right: 20px;
}

.mapboxgl-popup-content .tab-popup {
  padding: 10px;
}

.mapboxgl-popup-content .tab-popup.active {
  background-color: #d1d1e0;
}

/* Styling for the tab content */
.mapboxgl-popup-content .tab-popup-content {
  display: none;
}

.mapboxgl-popup-content .tab-popup-content.active {
  display: block;
}
</style>

<style src="../../../node_modules/vue-multiselect/dist/vue-multiselect.min.css"></style>
