<template>
  <figure class="svg-container" :style="{'height': svgHeight}" v-if="plotData.length > 0">
    <svg
        class="svg-content"
        :viewBox="viewBox"
        ref="svgContainer"
        preserveAspectRatio="xMidYMid meet"
        @mouseleave="removeTooltip()"
      >
      <g :transform="`translate(${w/2}, ${margin.top})`">
        <text class="chartTitle">
          {{ graphTitle }}
        </text>
      </g>
      <g :transform="`translate(${margin.right*1.5}, ${h-(margin.left)})`">
        <text class="yAxisLabel">
          {{ yAxisLabel }}
        </text>
      </g>
      <g :transform="`translate(${width/2}, ${height-50})`">
        <text class="xAxisLabel">
        {{ xAxisLabel}}
        </text>
      </g>
      <template v-if="direction === 'vertical'">
        <transition-group tag="g">
          <g v-for="(bargroup, id) in groups" :key="generateVueUID(id)"
             :transform="`translate(${bandScale(bandAxisTicks[id])}, 0)`">
            <rect v-for="(value, subgroup, index) in bargroup" :key="generateVueUID(index)"
                  :x="bandSubgroupScale(subgroup)" :width="bandSubgroupScale.bandwidth()"
                  :y="linearScale(value)" :height="linearScale(0) - linearScale(value)"
                  :fill="color(subgroup)" class="animate"
                  @click="populateTooltip($event, subgroup, value, id)">
            </rect>
          </g>
        </transition-group>
        <g v-bandaxis="{scale: bandScale, tickLabels: bandAxisTicks, direction: 'vertical', subSampleXAxis: shouldSubSampleAxis}"
           class="axis" :transform="`translate(0, ${this.height - this.margin.bottom})`">
        </g>
        <g v-linearaxis="{scale: linearScale, direction: 'vertical', subSampleXAxis: shouldSubSampleAxis}" class="axis"
           :transform="`translate(${this.margin.left}, 0)`">
        </g>
      </template>
      <template v-else>
        <transition-group tag="g">
          <g v-for="(bargroup, id) in groups" :key="generateVueUID(id)"
             :transform="`translate(0 ,${bandScale(bandAxisTicks[id])})`">
            <rect v-for="(value, subgroup) in bargroup" :key="generateVueUID(subgroup)"
                  :x="linearScale(0)" :width="linearScale(value) - linearScale(0)"
                  :y="bandSubgroupScale(subgroup)" :height="bandSubgroupScale.bandwidth()"
                  :fill="color(subgroup)"
                  @click="populateTooltip($event, subgroup, value, id)">
            </rect>
          </g>
        </transition-group>
        <g v-linearaxis="{scale: linearScale, direction: 'horizontal', subSampleXAxis: shouldSubSampleAxis}"
           :transform="`translate(0, ${barAxisLocation === 'top' ? margin.top : height - margin.bottom})`"
           class="axis">
        </g>
        <g v-bandaxis="{scale: bandScale, tickLabels: bandAxisTicks, direction: 'horizontal', subSampleXAxis: shouldSubSampleAxis}"
           class="axis" :transform="`translate(${this.margin.left}, 0)`">
        </g>
      </template>
      </svg>
    <div :class="isEnlarged ? 'barChartLegendLarge' : 'barChartLegend'">
      <graph-legend v-if="legend" :legendData="legendData" alignment="vertical" :largeSize="isEnlarged"/>
    </div>
    <div ref="tooltip" v-show="showTooltip" class="tooltipContainer"
         :class="{ activeTooltip: showTooltip}"
         :style="{top: tooltip.y, left: tooltip.x}">
        <slot name="tooltip" :lines="tooltip.values">
            <span v-for="(value, key) in tooltip.values" :key="key">{{ key }}: {{ value }}</span>
        </slot>
    </div>
  </figure>
</template>

<script src="https://d3js.org/d3-scale-chromatic.v1.min.js"></script>
<script>
import * as d3 from 'd3'
import GraphLegend from '@/components/D3Graphs/Legend'

export default {
  name: 'BarChart',
  components: { GraphLegend },
  props: {
    getStateFunction: {
      type: String,
      required: true
    },
    plotDataName: {
      type: String,
      required: true
    },
    graphTitle: {
      type: String,
      default: 'This will be a graph title, that can be long'
    },
    fontSize: {
      default: '0.7rem',
      Type: String
    },
    svgHeight: {
      default: '90vh',
      Type: String
    },
    width: {
      type: Number,
      default: 600
    },
    height: {
      type: Number,
      default: 270
    },
    xKey: {
      default: '',
      Type: String
    },
    yKey: {
      default: '',
      Type: String
    },
    xAxisLabel: {
      type: String
    },
    yAxisLabel: {
      type: String
    },
    margin: {
      type: Object,
      default: function () {
        return { top: 20, right: 20, bottom: 20, left: -10 }
      }
    },
    legend: {
      type: Boolean,
      default: false
    },
    direction: {
      type: String,
      default: 'vertical',
      validator: function (value) {
        return ['vertical', 'horizontal'].indexOf(value) !== -1
      }
    },
    barAxisLocation: {
      type: String,
      default: 'bottom',
      validator: function (value) {
        return ['top', 'bottom'].indexOf(value) !== -1
      }
    },
    paddingBetweenBars: {
      type: Number,
      default: 0.15,
      validator: function (value) {
        return value >= 0 && value <= 1
      }
    },
    paddingBetweenGroups: {
      type: Number,
      default: 0.15,
      validator: function (value) {
        return value >= 0 && value <= 1
      }
    },
    xTickFormat: {
      type: Function,
      default: null
    },
    yTickFormat: {
      type: Function,
      default: null
    },
    xMin: {
      type: Number,
      default: null
    },
    xMax: {
      type: Number,
      default: null
    },
    yMin: {
      type: Number,
      default: null
    },
    yMax: {
      type: Number,
      default: null
    },
    useOnlyXKey: {
      type: Boolean,
      default: false
    }
  },

  data () {
    return {
      padding: 60,
      showTooltip: false,
      colors: ['#440154FF', '#404788FF', '#287D8EFF', '#20A387FF', '#73D055FF', '#FDE725FF'],
      tooltip: {
        x: 0,
        y: 0,
        values: {}
      }
    }
  },
  computed: {
    legendData () {
      let dataForLegend = []
      this.groupKeys.forEach(barGroup => {
        dataForLegend.push({ name: barGroup, color: this.color(barGroup) })
      })
      return dataForLegend
    },
    isEnlarged () {
      if (this.width > 600) return true
      return false
    },
    shouldSubSampleAxis () {
      return this.groups.length > 29
    },
    plotData () {
      return this.$store.getters[this.getStateFunction](this.plotDataName).slice(0, 100)
    },
    groups () {
      if (this.useOnlyXKey) {
        return this.plotData.map(data => ({ [ this.yKey ]: data[this.yKey] }))
      }
      return this.plotData.map(({ [ this.xKey ]: name, ...rest }) => rest)
    },
    w () {
      const strokeWidth = 130
      return this.width - this.margin.left - this.margin.right - (strokeWidth * 2)
    },
    h () {
      const strokeWidth = 25
      return this.height - this.margin.top - this.margin.bottom - (strokeWidth * 2)
    },
    viewBox () {
      return `0 0 ${this.width} ${this.height}`
    },
    groupKeys () {
      /**
       * Object keys for each of the y values
       */
      let groupKeys = Object.keys(this.plotData[0]).filter(item => item !== this.xKey)
      if (this.useOnlyXKey) {
        groupKeys = [ this.yKey ]
      }
      return this.direction === 'vertical' ? groupKeys : groupKeys.reverse()
    },
    bandAxisTicks () {
      return this.plotData.map(item => item[this.xKey])
    },
    color () {
      return d3.scaleOrdinal().range(this.colors)
    },
    bandScale () {
      const bandScale = d3.scaleBand()
        .domain(this.bandAxisTicks)
        .padding(this.paddingBetweenGroups)
      return this.direction === 'vertical'
        ? bandScale.range([this.margin.left, this.width - this.margin.right])
        : bandScale.range([this.margin.top, this.height - this.margin.bottom])
    },
    bandSubgroupScale () {
      const bandScale = d3.scaleBand()
        .domain(this.groupKeys)
        .padding(this.paddingBetweenBars)
      return this.direction === 'vertical'
        ? bandScale.rangeRound([0, this.bandScale.bandwidth()])
        : bandScale.rangeRound([this.bandScale.bandwidth(), 0])
    },
    linearScale () {
      // determine whether x or y min/max scale limits should be applied based on direction of chart
      const values = this.direction === 'vertical' ? [this.yMin, this.yMax] : [this.xMin, this.xMax]
      const minValue = values[0] ? values[0] : 0
      const maxValue = values[1] ? values[1] : this.getMax(this.groups)
      const linearScale = d3.scaleLinear()
        .domain([minValue, maxValue])
        .nice()
      return this.direction === 'vertical'
        ? linearScale.range([this.height - this.margin.bottom, this.margin.top])
        : linearScale.range([this.margin.left, this.width - this.margin.right])
    }
  },
  methods: {
    getMax (array) {
      return d3.max(array.map(item => d3.max(Object.values(item))))
    },
    generateVueUID () {
      return Math.random(Number.MAX_SAFE_INTEGER)
    },
    populateTooltip (evt, key, group, tickIndex) {
      this.tooltip.x = `${evt.pageX - 90}px`
      this.tooltip.y = `${evt.pageY + 5}px`
      this.tooltip.values = this.plotData[tickIndex]
      this.showTooltip = true
      this.$logger.message(`{"populateTooltip":"Clicked data in ${this.plotDataName}"}`, 'info')
    },
    removeTooltip () {
      this.showTooltip = false
    }
  },
  directives: {
    bandaxis (el, binding, vnode) {
      const scale = binding.value.scale
      const direction = binding.value.direction
      const yTickFormat = vnode.context._props.yTickFormat
      const subSample = binding.value.subSampleXAxis
      if (direction === 'vertical') {
        d3.select(el).transition().duration(500).call(d3.axisBottom(scale).ticks().tickFormat((interval, i) => {
          if (subSample) return i % 3 !== 0 ? ' ' : interval
          return interval
        }))
          .selectAll('text')
          .attr('transform', 'rotate(-45)')
          .attr('y', 2)
          .attr('x', -9)
          .style('text-anchor', 'end')
      } else if (direction === 'horizontal') {
        d3.select(el).transition().duration(500).call(d3.axisLeft(scale).ticks().tickFormat(yTickFormat))
      }
    },
    linearaxis (el, binding, vnode) {
      const yFormatter = (num, digits) => {
        const lookup = [
          { value: 1, symbol: '' },
          { value: 1e3, symbol: 'k' },
          { value: 1e6, symbol: 'M' },
          { value: 1e9, symbol: 'G' },
          { value: 1e12, symbol: 'T' },
          { value: 1e15, symbol: 'P' },
          { value: 1e18, symbol: 'E' }
        ]
        const rx = /\.0+$|(\.[0-9]*[1-9])0+$/
        let item = lookup.slice().reverse().find(function (item) {
          return num >= item.value
        })
        return item ? (num / item.value).toFixed(digits).replace(rx, '$1') + item.symbol : '0'
      }
      const scale = binding.value.scale
      const direction = binding.value.direction
      const axisType = vnode.context._props.barAxisLocation === 'bottom' ? d3.axisBottom : d3.axisTop
      const xTickFormat = vnode.context._props.xTickFormat
      if (direction === 'vertical') {
        d3.select(el).transition().duration(500).call(d3.axisLeft(scale).ticks(5).tickFormat((interval, i) => {
          return yFormatter(interval, 1)
        }))
      } else if (direction === 'horizontal') {
        d3.select(el).transition().duration(500).call(axisType(scale).ticks(5).tickFormat(xTickFormat))
      }
    }
  }
}
</script>
<style scoped>
.line-chart__line {
  fill: none;
  stroke-width: 1.5;
}
.yAxisLabel {
  transform: rotate(-90deg);
  text-anchor: end;
  font-size: 0.9rem;
  fill: #2c3e50;
}
.xAxisLabel {
  text-anchor: end;
  font-size: 0.9rem;
  fill: #2c3e50;
}

.tooltipContainer {
  position: fixed;
  font-size: 0.8rem;
  padding: 10px;
  border: solid 1px black;
  box-shadow: 4px 4px 10px rgba(0, 0, 0, 0.4);
  background-color: #ffffff;
  opacity: 0;
  pointer-events: none;
  transition: opacity 0.3s;
}
.activeTooltip {
  opacity: 0.95;
  transition: opacity 0.3s;
  z-index: 3;
}
.tooltipContainer span {
  display: block;
}
.svg-container {
  display: inline-block;
  position: relative;
  width: 100%;
  height: 90vh;
  vertical-align: top;
  overflow: hidden;
  margin-top: 30px;
}
.svg-content {
  display: inline-block;
  position: absolute;
  top: 0;
  left: 0;
}
.barChartLegend {
  position: absolute;
  right: 0;
  margin-right: 20px;
  margin-top: 20px;
}
.barChartLegendLarge {
  position: absolute;
  right: 0;
  margin-right: 60px;
  margin-top: 500px;
}
</style>
