<template>
  <figure class="svg-container" :style="{'height': svgHeight, 'width': svgWidth}">
    <svg class="svg-content"
      :viewBox="viewBox"
      ref="weekdayHeatmapContainer"
      preserveAspectRatio="xMidYMid meet"
    >
     <g :transform="`translate(${ w - padding * 1.8} ${margin.right * 1.5})`">
       <text class="chartTitle"
             :x="0"
             :y="0">
         {{ graphTitle }}
       </text>
     </g>
     <g :transform="`translate(${ margin.left * 1.8} ${margin.right * 4})`">
         <g>
           <text class="yAxisLabel" :x="-10" :dx="yaxis_dx"
               :y="10" :dy="yaxis_dy">
             {{ yAxisLabel }}
           </text>
           <text class="xAxisLabel" :x="margin.left+30" :y="-25">
             {{ xAxisLabel }}
           </text>
         </g>
         <g v-for="(weekday, idx) in nestedByGroup"
            :key="idx"
            :class="weekday.key"
            :transform="`translate(${gridSize} ${idx * gridSize})`"
            @mouseleave="removeTooltip()">
          <rect v-for="key in weekday.values"
            :key="generateVueUID(key)"
            :x="xScaleCalc(key.date)-gridSize"
            :y="0"
            :rx="4"
            :ry="4"
            :height="gridSize"
            :width="gridSize"
            :fill="getColourForDuration(key.median)"
            @click="populateTooltip($event, key)">
          >
          </rect>
        </g>
        <g v-xaxis="{ scale: xScale }"
           :transform="`translate(0 0)`"
           :style="{ 'font-size': xAxisTickLabelFontSize }"
         >
        </g>
        <g v-yaxis="{ scale: yScale, width: w}"
           :transform="`translate(0 0)`"
           :style="{ 'font-size': fontSize }"
         >
        </g>
      </g>
      <colour-legend :colourScale="colourScale"
                     :scaleType="scaleType"
                     :x="getLegendX"
                     :y="getLegendY"
                     :rectSize="15"
                     :margin="m"
                     :padding="30"
                     :alignment="legendDirection"/>
    </svg>
    <div v-if="showTooltip" class="tooltipContainer"
         :class="{ activeTooltip: showTooltip}"
         :style="{top: tooltip.y, left: tooltip.x}">
        <slot name="tooltip" :lines="tooltip.values">
            <span v-for="(value, key) in tooltip.values" :key="key">{{ key }}: {{ value }}</span>
        </slot>
    </div>
  </figure>
</template>

<script>
import * as d3 from 'd3'
import ColourLegend from '@/components/ColourLegend.vue'

export default {
  name: 'WeekdayChart',
  components: { ColourLegend },
  props: {
    legendAlignment: {
      type: String,
      default: 'horizontal'
    },
    colourScaleFunction: {
      type: String
    },
    structKey: {
      type: String
    },
    getStateFunction: {
      type: String,
      required: true
    },
    fontSize: {
      default: '0.7rem',
      Type: String
    },
    svgHeight: {
      default: '90vh',
      Type: String
    },
    graphTitle: {
      type: String,
      default: 'This will be a graph title, that can be long'
    },
    width: {
      type: Number,
      default: 600
    },
    height: {
      type: Number,
      default: 270
    },
    xKey: {
      default: '',
      Type: String
    },
    yKey: {
      default: '',
      Type: String
    },
    zKey: {
      default: '',
      Type: String
    },
    xAxisLabel: {
      type: String,
      default: ''
    },
    yAxisLabel: {
      type: String
    },
    xAxisLabelShift: {
      type: Object,
      default: function () {
        return { dx: 90, dy: 85 }
      }
    },
    yAxisLabelShift: {
      type: Object,
      default: function () {
        return { dx: 5, dy: -55 }
      }
    },
    margin: {
      type: Object,
      default: function () {
        return { top: 50, right: 20, bottom: 40, left: 40 }
      }
    },
    scaleType: {
      type: String,
      default: 'threshold',
      required: false
    }
  },

  data () {
    return {
      parentReady: false,
      direction: 'vertical',
      padding: 60,
      showTooltip: false,
      tooltip: {
        x: 0,
        y: 0,
        values: {}
      },
      weekdayOrder: {
        'Mon': 0, 'Tue': 1, 'Wed': 2, 'Thu': 3, 'Fri': 4, 'Sat': 5, 'Sun': 6
      },
      xAxisTickLabelFontSize: '11px',
      legendXY: { 'x': 0, 'y': 0 }
    }
  },
  computed: {
    svgWidth () {
      return this.width > 600 ? '90vw' : '30vw'
    },
    getLegendX () {
      if (this.width > 600) {
        return 80
      } else return this.w + this.margin.left * 3
    },
    getLegendY () {
      if (this.width > 600) {
        return this.gridSize
      } else {
        return this.margin.right
      }
    },
    m () {
      let m = this.margin
      m.top = this.width > 600 ? 300 : 60
      return m
    },
    legendDirection () {
      return this.width > 600 ? 'horizontal' : 'vertical'
    },
    gridSize () {
      return Math.floor(this.w / 21)
    },
    flattenValues () {
      return this.$store.getters[this.getStateFunction](this.structKey).map(dayTimeVal => {
        dayTimeVal['day_of_week'] = this.weekdayOrder[dayTimeVal[this.yKey]]
        return dayTimeVal
      }).sort((a, b) => d3.descending(a.day_of_week, b.day_of_week) || d3.descending(+b.date, +a.date))
    },
    w () {
      const strokeWidth = 80
      return this.width - this.margin.left - this.margin.right - (strokeWidth * 2)
    },
    h () {
      const strokeWidth = 40
      return this.height - this.margin.bottom - (strokeWidth * 2)
    },
    nestedByGroup () {
      return Array.from(d3.group(
        this.flattenValues,
        d => d.day_of_week
      ),
      ([key, values]) => ({
        key: key.toString(),
        values: values
      })
      ).sort((a, b) => d3.ascending(a.key, b.key))
    },
    colourScale () {
      return this.$store.getters[this.colourScaleFunction](this.flattenValues, this.zKey)
    },
    xScale () {
      return d3.scaleLinear()
        .domain([0, 24])
        .range([0, this.gridSize * 24])
    },
    yScale () {
      return d3.scaleBand()
        .domain(Object.keys(this.weekdayOrder))
        .range([0, this.gridSize * 7])
    },
    viewBox () {
      return `0 0 ${this.width} ${this.height}`
    },
    xaxis_dx () {
      return 'dx' in this.xAxisLabelShift ? this.xAxisLabelShift.dx : undefined
    },
    xaxis_dy () {
      return 'dy' in this.xAxisLabelShift ? this.xAxisLabelShift.dy : undefined
    },
    yaxis_dx () {
      return 'dx' in this.yAxisLabelShift ? this.yAxisLabelShift.dx : undefined
    },
    yaxis_dy () {
      return 'dy' in this.yAxisLabelShift ? this.yAxisLabelShift.dy : undefined
    }
  },
  methods: {
    generateVueUID (string) {
      return Math.random(Number.MAX_SAFE_INTEGER) + string
    },
    getColourForDuration (timeBucket) {
      return this.colourScale(timeBucket)
    },
    xScaleCalc (x) {
      return this.xScale(x)
    },
    populateTooltip (evt, key) {
      this.tooltip.x = `${evt.pageX + 5}px`
      this.tooltip.y = `${evt.pageY + 5}px`

      this.tooltip.values = {}
      this.tooltip.values['Median'] = key.median
      this.tooltip.values['Weekday'] = key.name_of_day
      this.tooltip.values['Min'] = key.min
      this.tooltip.values['Max'] = key.max
      this.showTooltip = true
      this.$logger.message(`{"populateTooltip":"Clicked data in WeekdayChart"}`, 'info')
    },
    removeTooltip () {
      this.showTooltip = false
    }
  },
  directives: {
    xaxis (el, binding) {
      const scale = binding.value.scale

      d3.select(el).transition().duration(500)
        .call(d3.axisTop().scale(scale)
          .tickFormat(d3.format('02d'))
          .ticks(24))
        .selectAll('.tick line')
        .style('stroke-width', '0.3px')
    },
    yaxis (el, binding) {
      const scale = binding.value.scale
      d3.select(el).transition().duration(500)
        .call(d3.axisLeft().scale(scale))
    }
  }
}
</script>
<style scoped>
.yAxisLabel {
    transform: rotate(-90deg);
    text-anchor: end;
    font-size: 0.90rem;
    fill: #2c3e50;
}
.xAxisLabel {
    text-anchor: end;
    font-size: 0.90rem;
    fill: #2c3e50;
}
.tooltipContainer {
    position: fixed;
    font-size: 0.8rem;
    padding: 10px;
    border: solid 1px black;
    box-shadow: 4px 4px 10px rgba(0, 0, 0, 0.4);
    background-color: #ffffff;
    opacity: 0;
    pointer-events: none;
    transition: opacity 0.3s;
}
.activeTooltip {
    opacity: 0.95;
    transition: opacity 0.3s;
    z-index: 3;
}
.tooltipContainer span {
    display: block;
}
.svg-container {
  display: inline-block;
  position: relative;
  width: 100%;
  height: 90vh;
  vertical-align: top;
  overflow: hidden;
  margin-top: 30px;
}
.svg-content {
  display: inline-block;
  position: absolute;
  top: 0;
  left: 0;
}

</style>
