<template>
  <div class='hex-loader' :style="{ 'width': size, 'height': size }">
    <div class='hex-spinner'>
      <div class='hex-container'>
        <div class='hex0'></div>
        <div class='hex120'></div>
        <div class='hex240'></div>
        <div class='hex-spinner'>
          <div class='hex-container'>
            <div class='hex0'></div>
            <div class='hex120'></div>
            <div class='hex240'></div>
            <div class='hex-spinner'>
              <div class='hex-container'>
                <div class='hex0'></div>
                <div class='hex120'></div>
                <div class='hex240'></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'HexSpinner',
  props: {
    size: {
      required: false,
      default: '50px'
    }
  }
}

</script>

<style scoped>
.hex-loader {
  box-sizing: border-box;
  position: absolute;
}

.hex-container {
  position: absolute;
  width: 75%;
  height: 75%;
  left: 12.5%;
  top: 12.5%;
}

.hex-spinner {
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
}

.hex-spinner{
  animation : rotation0 6s infinite linear, resize3 6s infinite linear;
}

.hex0, .hex120, .hex240{
  position: absolute;
  box-sizing: border-box;
  width: 100%;
  height: 57.74%;
  left: 0;
  top: 21.13%;
  border-left: 2px solid #fff;
  border-right: 2px solid #fff;
}

.hex0{
  animation: rotation0 4s infinite linear;
}

.hex120{
  transform: rotate(120deg);
  animation: rotation120 4s infinite linear;
}

.hex240 {
  transform: rotate(240deg);
  animation: rotation240 4s infinite linear;
}

@keyframes rotation0 {
  0%{
    transform: rotate(0deg);
  }
  100%{
    transform: rotate(360deg);
  }
}

@keyframes rotation120 {
  0% {
    transform: rotate(120deg);
  }
  100%{
    transform: rotate(480deg);
  }
}

@keyframes rotation240 {
  0% {
    transform: rotate(240deg);
  }
  100% {
    transform: rotate(600deg);
  }
}

@keyframes resize1 {
  0%,50%,100%{
    width:100%;
    height:100%;
    left:0%;
    top:0%;
  }
  25%,75%{
    width:120%;
    height:120%;
    left:-10%;
    top:-10%;
  }
  12.5%,37.5%,62.5%,87.5%{
    width:70%;
    height:70%;
    left:15%;
    top:15%;
  }
}

@keyframes resize2{
  0%,33.33%,66.66%,100%{
    width:100%;
    height:100%;
    left:0%;
    top:0%;
  }
  16.66%,49.99%, 83.333%{
    width:70%;
    height:70%;
    left:15%;
    top:15%;
  }
}

@keyframes resize3{
  0%,16.66%,33.33%,50%,66.66%,83.33%,100%{
    width:100%;
    height:100%;
    top:0;
    left:0;
  }
  2.77%,19.44%,36.11%,52.77%,69.44%,86.11%{
    width:93.5%;
    height:93.5%;
    top:3.25%;
    left:3.25%;
  }
  5.55%,22.22%,38.88%,55.55%,72.22%,88.88%{
    width:84.4%;
    height:84.4%;
    top:7.8%;
    left:7.8%;
  }
  8.33%,25%,41.66%,58.33%,75%,91.66%{
    width:73.2%;
    height:73.2%;
    top:13.4%;
    left:13.4%;
  }
  11.11%,27.77%,44.44%,61.11%,77.77%,94.44%{
    width:84.4%;
    height:84.4%;
    top:7.8%;
    left:7.8%;
  }
  13.88%,30.55%,47.22%,63.88%,80.55%,97.22%{
    width:93.5%;
    height:93.5%;
    top:3.25%;
    left:3.25%;
  }
}
</style>
