<template>
<div>
  <ClassifiedLocationMap v-if="classifiedLocation && userSelected === 'classified_location'"></ClassifiedLocationMap>
  <FlowMap v-if="outbound && userSelected === 'outbound'"></FlowMap>
  <ChassisMap v-if="chassis && userSelected.includes('chassis')"/>
  <LocationMap v-if="locations && userSelected === 'locations'"/>
  <BaseMap v-if="configLoaded && !outbound" @handle-on-map-loaded="baseMapExists"/>
  <Selection @handle-on-data-loaded="dataLoaded"/>
  <survey v-if="this.$store.getters.getAppConfigFlag('shouldAnswerUXQueries')"></survey>
</div>
</template>

<script>
import ChassisMap from '@/views/ChassisAnalytics/ChassisMap.vue'
import LocationMap from '@/views/LocationAnalytics/LocationMap.vue'
import ClassifiedLocationMap from '@/views/ClassifiedLocationAnalytics/ClassifiedLocationMap.vue'
import Selection from '@/views/Selection.vue'
import axios from 'axios'
import BaseMap from '@/views/BaseMap'
import Survey from '@/components/survey/Survey'
import FlowMap from '@/components/FlowMap'

export default {
  name: 'home-view',
  components: {
    BaseMap, Selection, LocationMap, Survey, FlowMap, ChassisMap, ClassifiedLocationMap
  },
  data () {
    return {
      appConfigFeatureFlags: [],
      configLoaded: false,
      userSelected: '',
      locations: false,
      chassis: false,
      outbound: false,
      classifiedLocation: false
    }
  },
  methods: {
    async getAllFeatureFlags () {
      let url = process.env.VUE_APP_APP_CONFIG_ENDPOINT + '/Prod'
      try {
        this.appConfigFeatureFlags = await axios
          .get(url)
          .then(res => {
            if (res.data.config.featureFlags.length) {
              return res.data.config.featureFlags
            }
            throw new Error('No feature flags found')
          })
        this.$store.commit('setAppConfigFeatureFlags', this.appConfigFeatureFlags)
      } catch (e) {
        console.debug('There was an error: ', e)
        this.$logger.message(`{"getAllFeatureFlags":"Error in getAllFeatureFlags: ${e}"}`, 'error')
      }

      this.configLoaded = true
    },
    dataLoaded () {
      this.userSelected = this.$store.getters.getUserSelection
      if (this.userSelected === 'locations') this.locations = true
      if (this.userSelected.includes('chassis')) this.chassis = true
      if (this.userSelected === 'outbound') this.outbound = true
      if (this.userSelected === 'classified_location') this.classifiedLocation = true

      if (this.userSelected === '') {
        this.chassis = false
        this.locations = false
        this.outbound = false
        this.classifiedLocation = false
      }
    },
    baseMapExists (baseMapObject) {
      this.$store.commit('setBaseMap', baseMapObject)
    }
  },
  computed: {
    isSignedIn () {
      return this.$store.getters.isSignedIn
    }
  },
  created () {
    this.getAllFeatureFlags()
  },
  watch: {
    '$store.state.userSelectedView': function () {
      this.userSelected = this.$store.getters.getUserSelection
    }
  }
}
</script>
