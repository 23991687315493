import { h3ToGeo, hexArea } from 'h3-js'

const getDefaultState = () => {
  return {
    minDate: new Date('2018-01-01'),
    maxDate: new Date(),
    hexagonResolution: 10,
    minMaxDuration: [Number.MIN_SAFE_INTEGER, Number.MAX_SAFE_INTEGER],
    selectedMinMaxDuration: [Number.MIN_SAFE_INTEGER, Number.MAX_SAFE_INTEGER],
    inOdoMinMax: [Number.MIN_SAFE_INTEGER, Number.MAX_SAFE_INTEGER],
    inOdoSelectedMinMax: [Number.MIN_SAFE_INTEGER, Number.MAX_SAFE_INTEGER],
    outOdoMinMax: [Number.MIN_SAFE_INTEGER, Number.MAX_SAFE_INTEGER],
    outOdoSelectedMinMax: [Number.MIN_SAFE_INTEGER, Number.MAX_SAFE_INTEGER],
    inDurMinMax: [Number.MIN_SAFE_INTEGER, Number.MAX_SAFE_INTEGER],
    inDurSelectedMinMax: [Number.MIN_SAFE_INTEGER, Number.MAX_SAFE_INTEGER],
    outDurMinMax: [Number.MIN_SAFE_INTEGER, Number.MAX_SAFE_INTEGER],
    outDurSelectedMinMax: [Number.MIN_SAFE_INTEGER, Number.MAX_SAFE_INTEGER],
    selectedHexagons: new Set(),
    locationAggregates: null,
    hexagonClusters: null,
    generalInfo: null,
    resolutionToRequest: 2,
    minNumStops: 100,
    minNumStopsToRequest: 100,
    selectedWeekdays: [],
    selectedProduct: [],
    selectedEnergy: [],
    selectedMinVehicles: 4,
    hexagonAggregates: null,
    graphDataStructure: {
      numberOfParkedVehicleDistribution: [],
      longHaulageNumberOfParkedVehicleDistribution: [],
      homeDepotNumberOfParkedVehicleDistribution: [],
      homeDepotNumberOfArrivalsDistribution: [],
      homeDepotNumberOfDeparturesDistribution: [],
      energyNeedDistribution: [],
      energyNeedDistributionEstimatedByDistance: [],
      homeDepotEnergyNeedDistribution: [],
      homeDepotEnergyNeedDistributionEstimatedByDistance: []
    },
    locationDataWithGeoInfo: [],
    edgeLayer: [],
    chosenCountries: []
  }
}

const state = getDefaultState()

const getters = {
  getMinDate: (state) => {
    const offset = state.minDate.getTimezoneOffset()
    return (new Date(state.minDate - (offset * 60 * 1000))).toISOString().split('T')[0]
  },
  getMaxDate: (state) => {
    const offset = state.maxDate.getTimezoneOffset()
    return (new Date(state.maxDate - (offset * 60 * 1000))).toISOString().split('T')[0]
  },
  getHexagonResolution: (state) => {
    return state.hexagonResolution
  },
  getResolutionToRequest: (state) => {
    return state.resolutionToRequest
  },
  getMinNumStops: (state) => {
    return state.minNumStops
  },
  getMinNumStopsToRequest: (state) => {
    return state.minNumStopsToRequest
  },
  getMinMaxDuration: state => {
    return state.minMaxDuration
  },
  getSelectedMinMaxDuration: state => {
    return state.selectedMinMaxDuration
  },
  getInOdoMinMax: state => {
    return state.inOdoMinMax
  },
  getInOdoSelectedMinMax: state => {
    return state.inOdoSelectedMinMax
  },
  getOutOdoMinMax: state => {
    return state.outOdoMinMax
  },
  getOutOdoSelectedMinMax: state => {
    return state.outOdoSelectedMinMax
  },
  getInDurMinMax: state => {
    return state.inDurMinMax
  },
  getInDurSelectedMinMax: state => {
    return state.inDurSelectedMinMax
  },
  getOutDurMinMax: state => {
    return state.outDurMinMax
  },
  getOutDurSelectedMinMax: state => {
    return state.outDurSelectedMinMax
  },
  getSelectedHexagons: state => {
    return state.selectedHexagons
  },
  getHexagonClusters: state => {
    return state.hexagonClusters
  },
  getGeneralInfo: state => {
    return state.generalInfo
  },
  getSelectedWeekdays: state => {
    return state.selectedWeekdays
  },
  getSelectedProduct: state => {
    return state.selectedProduct
  },
  getSelectedEnergy: state => {
    return state.selectedEnergy
  },
  getGraphDataStructure: state => (plotDataName) => {
    return state.graphDataStructure[plotDataName]
  },
  getLocationDataWithGeoInfo: state => {
    return state.locationDataWithGeoInfo
  },
  getSelectedMinVehicles: state => {
    return state.selectedMinVehicles
  },
  getEdgeLayer: state => {
    return state.edgeLayer
  },
  getChosenCountries: state => {
    return state.chosenCountries
  },
  getHexagonAggregates: state => {
    return state.hexagonAggregates
  }
}

const mutations = {
  setEdgeLayer (state, edgeLayer) {
    state.edgeLayer = Object.freeze(edgeLayer)
  },
  setHexagonResolution (state, hexagonResolution) {
    state.hexagonResolution = hexagonResolution
  },
  setResolutionToRequest (state, resolutionToRequest) {
    state.resolutionToRequest = resolutionToRequest
  },
  setMinNumStops (state, minNumStops) {
    state.minNumStops = minNumStops
  },
  setMinNumStopsToRequest (state, minNumStops) {
    state.minNumStopsToRequest = minNumStops
  },
  setSelectedHexagons (state, hexagons) {
    state.selectedHexagons = hexagons
  },
  setHexagonClusters (state, hexagonClusters) {
    state.hexagonClusters = Object.freeze(hexagonClusters)
  },
  setGeneralInfo (state, GeneralInfo) {
    state.generalInfo = Object.freeze(GeneralInfo)
  },
  resetStateToDefault (state) {
    Object.assign(state, getDefaultState())
  },
  setCountDistributionGraphDataStructure (state) {
    state.graphDataStructure.countDistribution = Object.freeze(state.locationAggregates
      .map(graph => { return { hexagons: graph.hexagons, no_stops: graph.no_stops, no_vehicles: graph.vehicles } })
      .sort(function (a, b) { return a.no_stops - b.no_stops })
      .reverse())
  },
  setCountDistributionLegendGraphDataStructure (state) {
    state.graphDataStructure.countDistributionLegend = Object.freeze(state.locationAggregates
      .map(graph => { return { hexagons: graph.hexagons, no_stops: graph.no_stops } })
      .sort(function (a, b) { return a.no_stops - b.no_stops }))
  },
  setGraphData (state, payload) {
    state.graphDataStructure[payload.datasetKeyName] = Object.freeze(payload.data)
  },
  setHexagonAggregates (state, plotData) {
    state.hexagonAggregates = Object.freeze(plotData)
  },
  setLocationDataWithGeoInfo (state) {
    state.locationDataWithGeoInfo = Object.freeze(state.locationAggregates.map(graph => {
      let latLong = h3ToGeo(graph.hexagons)
      let hexResolution = state.hexagonResolution
      return { ...graph, latitude: latLong[0], longitude: latLong[1], resolution: hexResolution, avgAreaKm2: hexArea(hexResolution, 'km2'), avgAreaM2: hexArea(hexResolution, 'm2') }
    })
      .sort(function (a, b) { return a.no_stops - b.no_stops })
      .reverse())
  },
  setChosenCountries (state, chosenCountries) {
    state.chosenCountries = chosenCountries
  }
}

const actions = {
  resetState ({ commit }) {
    commit('resetStateToDefault')
  },
  setLocationLayerAsync (context, layer) {
    context.commit('setHexagonClusters', layer)
  },
  setGeneralInfoLayerAsync (context, layer) {
    context.commit('setGeneralInfo', layer)
  },
  setCountDistributionGraphAsync (context) {
    context.commit('setCountDistributionGraphDataStructure')
    context.commit('setCountDistributionLegendGraphDataStructure')
  },
  setLocationDataWithGeoInfoAsync (context) {
    context.commit('setLocationDataWithGeoInfo')
  },
  setEdgeLayerAsync (context, layer) {
    context.commit('setEdgeLayer', layer)
  }
}

export default {
  namespaced: true,
  state: state,
  getters: getters,
  actions: actions,
  mutations: mutations
}
