import * as d3 from 'd3'

const getDefaultState = () => {
  return {
    minDate: new Date('2018-01-01'),
    maxDate: new Date(),
    isMarketUser: false,
    hexagonResolution: 'H3_CELL_INDEX_7',
    minMaxDuration: [Number.MIN_SAFE_INTEGER, Number.MAX_SAFE_INTEGER],
    selectedMinMaxDuration: [Number.MIN_SAFE_INTEGER, Number.MAX_SAFE_INTEGER],
    selectedWeekdays: [],
    tilesMinTime: new Date('2018-01-01'),
    tilesMaxTime: new Date(),
    tilesConstantMaxTime: new Date(),
    tilesChassis: [],
    minMaxOdometer: [Number.MIN_SAFE_INTEGER, Number.MAX_SAFE_INTEGER],
    minMaxFuel: [Number.MIN_SAFE_INTEGER, Number.MAX_SAFE_INTEGER],
    stopFilteredDataObj: [],
    selectedMinMaxOdometer: [Number.MIN_SAFE_INTEGER, Number.MAX_SAFE_INTEGER],
    selectedMinMaxFuel: [Number.MIN_SAFE_INTEGER, Number.MAX_SAFE_INTEGER],
    selectedMinTime: false,
    selectedMaxTime: false,
    selectedChassis: [],
    requestedTilesName: '',
    hexagonLayer: null,
    edgeLayer: null,
    stopLayerLoaded: false,
    hexagonLayerLoaded: false,
    edgeLayerLoaded: false,
    h3CellIndexOptions: [],
    selectedH3CellIndex: [],
    graphDataStructure: {
      odometerBoxplot: [],
      fuelBoxplot: [],
      dailyFuelBoxplot: [],
      dailyOdometerBoxplot: [],
      dailyAggregates: [],
      hourlyBoxplot: [],
      dailyHourlyAggregates: []
    },
    graphDataLoaded: false,
    queryTimeInSeconds: 0,
    requestTime: 'noRequestTimeInMock'
  }
}

const state = getDefaultState()

const getters = {
  getSelectedWeekdays: state => {
    return state.selectedWeekdays
  },
  getHexagonResolution: (state) => {
    return state.hexagonResolution
  },
  getUserType: (state) => {
    return state.isMarketUser
  },
  getMinMaxDuration: state => {
    return state.minMaxDuration
  },
  getSelectedMinMaxDuration: state => {
    return state.selectedMinMaxDuration
  },
  getRequestedTilesName: state => {
    return state.requestedTilesName
  },
  getTilesTimeWindow: state => {
    return [state.tilesMinTime, state.tilesMaxTime]
  },
  getTilesConstantMaxTime: state => {
    return state.tilesConstantMaxTime
  },
  getTilesChassis: state => {
    return state.tilesChassis
  },
  getMinMaxOdometer: state => {
    return state.minMaxOdometer
  },
  getMinMaxFuel: state => {
    return state.minMaxFuel
  },
  getSelectedMinMaxOdometer: state => {
    return state.selectedMinMaxOdometer
  },
  getSelectedMinMaxFuel: state => {
    return state.selectedMinMaxFuel
  },
  getSelectedMinTime: state => {
    return state.selectedMinTime
      ? state.selectedMinTime
      : state.tilesMinTime
  },
  getSelectedMaxTime: state => {
    return state.selectedMaxTime
      ? state.selectedMaxTime
      : state.tilesMaxTime
  },
  getSelectedChassis: state => {
    return state.selectedChassis.length > 0
      ? state.selectedChassis
      : state.tilesChassis
  },
  getHexagonLayer: state => {
    return state.hexagonLayer
  },
  getEdgeLayer: state => {
    return state.edgeLayer
  },
  getChartDataLoaded: state => {
    return state.chartDataLoaded
  },
  getMapLayerDataLoaded: state => (mapLayer) => {
    if (mapLayer === 'hexagon') {
      return state.hexagonLayerLoaded
    } else if (mapLayer === 'edge') {
      return state.edgeLayerLoaded
    } else if (mapLayer === 'stop') {
      return state.stopLayerLoaded
    }
  },
  getGraphDataStructure: state => (aggregateType) => {
    return state.graphDataStructure[aggregateType]
  },
  getGraphColorStructure: state => (chassisNumber) => {
    return state.chassisWithGraphColours.find(item => item.chassisnumber === chassisNumber).graphColour
  },
  getGraphColorScaleForDayOfWeekNumber: state => {
    const weekdayOrder = { 'Mon': 0, 'Tue': 1, 'Wed': 2, 'Thu': 3, 'Fri': 4, 'Sat': 5, 'Sun': 6 }

    return d3.scaleOrdinal()
      .domain(Object.keys(weekdayOrder))
      .range(d3.schemeAccent)
  },
  getPolygonColourScale: state => {
    return d3.scaleThreshold()
      .domain([0, 1, 8, 24, 168, 504])
      .range([
        [224, 170, 255],
        [199, 125, 255],
        [157, 78, 221],
        [123, 44, 191],
        [90, 24, 154],
        [60, 9, 108]
      ])
  },
  getHeatmapColourScale: state => (dataDomain, keyAsString) => {
    const colours = ['#ffe5f2', '#ffcce5', '#ffb2d8', '#ff66b2', '#ff4ca6', '#FF0080', '#E50073']
    const dataDomainForKey = dataDomain.map(d => d[keyAsString])
    return d3.scaleQuantile()
      .domain([0, d3.max(dataDomainForKey)])
      .range(colours)
  },
  getH3CellIndexOptions: state => {
    return state.h3CellIndexOptions
  },
  getSelectedH3CellIndex: state => {
    return state.selectedH3CellIndex
  },
  getQueryTimeInSeconds: state => {
    return state.queryTimeInSeconds
  },
  getRequestTime: state => {
    return state.requestTime
  },
  getGraphDataLoaded: state => {
    return state.graphDataLoaded
  },
  getEdgeLayerLoaded: state => {
    return state.edgeLayerLoaded
  },
  getHexagonLayerLoaded: state => {
    return state.hexagonLayerLoaded
  },
  getStopLayerLoaded: state => {
    return state.stopLayerLoaded
  }
}

const mutations = {
  setGraphDataFinishedLoading (state, graphDataLoaded) {
    state.graphDataLoaded = graphDataLoaded
  },
  setRequestedTilesName (state, requestedTilesName) {
    state.requestedTilesName = requestedTilesName
  },
  setTilesMinTime (state, minTime) {
    state.tilesMinTime = new Date(minTime)
  },
  setTilesMaxTime (state, maxTime) {
    state.tilesMaxTime = new Date(maxTime)
  },
  setTilesConstantMaxTime (state, maxTime) {
    state.tilesConstantMaxTime = new Date(maxTime)
  },
  setTilesChassis (state, chassis) {
    state.tilesChassis = chassis.split(',')
  },
  setSelectedChassis (state, chassisArray) {
    state.selectedChassis = chassisArray
  },
  setMinMaxOdometer (state, minMaxOdometerArray) {
    state.minMaxOdometer = minMaxOdometerArray
  },
  setMinMaxFuel (state, minMaxFuelArray) {
    state.minMaxFuel = minMaxFuelArray
  },
  setSelectedMinMaxOdometer (state, minMaxOdometerArray) {
    state.selectedMinMaxOdometer = minMaxOdometerArray
  },
  setSelectedMinMaxFuel (state, minMaxFuelArray) {
    state.selectedMinMaxFuel = minMaxFuelArray
  },
  setSelectedMinTime (state, minTime) {
    state.selectedMinTime = minTime
  },
  setSelectedMaxTime (state, maxTime) {
    state.selectedMaxTime = maxTime
  },
  setStopDataObj (state, stopDataObj) {
    // Parse the date / time
    let parseDate = d3.timeParse('%d/%m/%Y')

    // Get the data
    stopDataObj.forEach(function (d) {
      d.date = parseDate(d.date)
    })

    state.graphDataStructure.dailyAggregates = Object.freeze(stopDataObj)
  },
  setChartDataLoaded (state, chartDataLoaded) {
    state.chartDataLoaded = chartDataLoaded
  },
  setHexagonLayerLoaded (state, hexagonLayerLoaded) {
    state.hexagonLayerLoaded = hexagonLayerLoaded
  },
  setEdgeLayerLoaded (state, edgeLayerLoaded) {
    state.edgeLayerLoaded = edgeLayerLoaded
  },
  setStopLayerLoaded (state, stopLayerLoaded) {
    state.stopLayerLoaded = stopLayerLoaded
  },
  setGraphDataStructure (state, boxplot) {
    state.graphDataStructure.fuelBoxplot = Object.freeze(boxplot)
  },
  setOdometerGraphDataStructure  (state, boxplot) {
    state.graphDataStructure.odometerBoxplot = Object.freeze(boxplot)
  },
  setDailyFuelGraphDataStructure (state, boxplot) {
    state.graphDataStructure.dailyFuelBoxplot = Object.freeze(boxplot)
  },
  setDailyOdometerGraphDataStructure (state, boxplot) {
    state.graphDataStructure.dailyOdometerBoxplot = Object.freeze(boxplot)
  },
  setHourlyLocationDistributionDataStructure (state, boxplot) {
    state.graphDataStructure.hourlyBoxplot = Object.freeze(boxplot)
  },
  setDailyHourlyLocationDistributionDataStructure (state, boxplot) {
    state.graphDataStructure.dailyHourlyAggregates = Object.freeze(boxplot)
  },
  setHexagonLayer (state, hexagonLayer) {
    state.hexagonLayer = Object.freeze(hexagonLayer)
  },
  setEdgeLayer (state, edgeLayer) {
    state.edgeLayer = Object.freeze(edgeLayer)
  },
  setGraphColours: function (state) {
    let odometerAggregate = state.graphDataStructure.odometerBoxplot
    let colourMap = odometerAggregate.map(function (chassisAgg, index) {
      return {
        chassisnumber: chassisAgg.chassisnumber,
        graphColour: d3.interpolateRainbow(index / odometerAggregate.length)
      }
    })
    state.chassisWithGraphColours = Object.freeze(colourMap)
  },
  setSelectedWeekdays (state, selectedWeekdays) {
    state.selectedWeekdays = selectedWeekdays
  },
  setMinDate (state, minDate) {
    state.minDate = new Date(minDate)
  },
  setMaxDate (state, maxDate) {
    state.maxDate = new Date(maxDate)
  },
  setHexagonResolution (state, hexagonResolution) {
    state.hexagonResolution = 'H3_CELL_INDEX_' + hexagonResolution
  },
  setUserType (state, isMarketUserValue) {
    state.isMarketUser = isMarketUserValue
    state.hexagonResolution = 'H3_CELL_INDEX_7'
  },
  setMinMaxDuration (state, minMaxDurationArray) {
    state.minMaxDuration = minMaxDurationArray
  },
  setSelectedMinMaxDuration (state, minMaxDurationArray) {
    state.selectedMinMaxDuration = minMaxDurationArray
  },
  setSelectedMinOdometer (state, minValue) {
    if (!isNaN(parseInt(minValue))) {
      state.selectedMinMaxOdometer[0] = parseInt(minValue)
    } else {
      state.selectedMinMaxOdometer[0] = state.minMaxOdometer[0]
    }
  },
  setSelectedMaxOdometer (state, maxValue) {
    if (!isNaN(parseInt(maxValue))) {
      state.selectedMinMaxOdometer[1] = parseInt(maxValue)
    } else {
      state.selectedMinMaxOdometer[1] = state.minMaxOdometer[1]
    }
  },
  setSelectedMinFuel (state, minValue) {
    if (!isNaN(parseInt(minValue))) {
      state.selectedMinMaxFuel[0] = parseInt(minValue)
    } else {
      state.selectedMinMaxFuel[0] = state.minMaxFuel[0]
    }
  },
  setSelectedMaxFuel (state, maxValue) {
    if (!isNaN(parseInt(maxValue))) {
      state.selectedMinMaxFuel[1] = parseInt(maxValue)
    } else {
      state.selectedMinMaxFuel[1] = state.minMaxFuel[1]
    }
  },
  setSelectedMinDuration (state, minValue) {
    if (!isNaN(parseInt(minValue))) {
      state.selectedMinMaxDuration[0] = parseInt(minValue)
    } else {
      state.selectedMinMaxDuration[0] = state.minMaxDuration[0]
    }
  },
  setSelectedMaxDuration (state, maxValue) {
    if (!isNaN(parseInt(maxValue))) {
      state.selectedMinMaxDuration[1] = parseInt(maxValue)
    } else {
      state.selectedMinMaxDuration[1] = state.minMaxDuration[1]
    }
  },
  setSelectedH3CellIndex (state, h3Cells) {
    state.selectedH3CellIndex = h3Cells
  },
  setH3CellIndexOptions (state, h3Cells) {
    state.h3CellIndexOptions = h3Cells
  },
  setH3CellIndexOption (state, h3CellIndex) {
    let index = state.h3CellIndexOptions.indexOf(h3CellIndex)
    if (index > -1) {
      state.h3CellIndexOptions.splice(index, 1)
    } else {
      state.h3CellIndexOptions.push(h3CellIndex)
    }
  },
  resetStateToDefault (state) {
    Object.assign(state, getDefaultState())
  },
  setTimePrediction (state, queryTimeInSeconds) {
    state.queryTimeInSeconds = queryTimeInSeconds
  },
  setRequestTime (state, requestTimestamp) {
    state.requestTime = requestTimestamp
  }
}

const actions = {
  setDailyOdometerGraphDataStructureAsync (context, boxplots) {
    context.commit('setDailyOdometerGraphDataStructure', boxplots)
  },
  setStopDataObjAsync (context, stops) {
    context.commit('setStopDataObj', stops)
  },
  setGraphDataStructureAsync (context, boxplots) { // fuel
    context.commit('setGraphDataStructure', boxplots)
  },
  setOdometerGraphDataStructureAsync (context, odometerBoxplot) {
    context.commit('setOdometerGraphDataStructure', odometerBoxplot)
  },
  setDailyFuelGraphDataStructureAsync (context, boxplots) {
    context.commit('setDailyFuelGraphDataStructure', boxplots)
  },
  setHourlyLocationDistributionDataStructureAsync (context, boxplot) {
    context.commit('setHourlyLocationDistributionDataStructure', boxplot)
  },
  setDailyHourlyLocationDistributionDataStructureAsync (context, boxplot) {
    context.commit('setDailyHourlyLocationDistributionDataStructure', boxplot)
  },
  setGraphColoursAsync (context) {
    context.commit('setGraphColours')
  },
  setHexagonLayerAsync (context, layer) {
    context.commit('setHexagonLayer', layer)
  },
  setEdgeLayerAsync (context, layer) {
    context.commit('setEdgeLayer', layer)
  },
  resetState ({ commit }) {
    commit('resetStateToDefault')
  }
}

export default {
  namespaced: true,
  state: state,
  getters: getters,
  mutations: mutations,
  actions: actions
}
