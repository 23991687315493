import { h3ToGeo, hexArea } from 'h3-js'

const getDefaultState = () => {
  return {
    minDate: new Date('2018-01-01'),
    maxDate: new Date(),
    hexagonResolution: 2,
    baseSelection: {
      baseHexes: '',
      baseCountries: ''
    },
    minMaxDuration: [Number.MIN_SAFE_INTEGER, Number.MAX_SAFE_INTEGER],
    selectedMinMaxDuration: [Number.MIN_SAFE_INTEGER, Number.MAX_SAFE_INTEGER],
    inOdoMinMax: [Number.MIN_SAFE_INTEGER, Number.MAX_SAFE_INTEGER],
    inOdoSelectedMinMax: [Number.MIN_SAFE_INTEGER, Number.MAX_SAFE_INTEGER],
    outOdoMinMax: [Number.MIN_SAFE_INTEGER, Number.MAX_SAFE_INTEGER],
    outOdoSelectedMinMax: [Number.MIN_SAFE_INTEGER, Number.MAX_SAFE_INTEGER],
    inDurMinMax: [Number.MIN_SAFE_INTEGER, Number.MAX_SAFE_INTEGER],
    inDurSelectedMinMax: [Number.MIN_SAFE_INTEGER, Number.MAX_SAFE_INTEGER],
    outDurMinMax: [Number.MIN_SAFE_INTEGER, Number.MAX_SAFE_INTEGER],
    outDurSelectedMinMax: [Number.MIN_SAFE_INTEGER, Number.MAX_SAFE_INTEGER],
    minMaxAnnualMileage: [0, Number.MAX_SAFE_INTEGER],
    selectedMinMaxAnnualMileage: [0, 1000000],
    selectedHexagons: new Set(),
    locationAggregates: null,
    resolutionToRequest: 2,
    selectedWeekdays: [],
    selectedProduct: [],
    selectedChassisAdaptation: [],
    selectedCabType: [],
    selectedEnergy: [],
    selectedActivity: [],
    enableAnnualMileage: [],
    enableTopLocation: [],
    selectedMinVehicles: 4,
    selectedMinCustomers: 0,
    selectedMinLocations: 0,
    graphDataStructure: {
      durationDistribution: [],
      countDistribution: [],
      countDistributionForCustomers: [],
      countDistributionForVehicles: [],
      startHourDistribution: [],
      countDistributionLegend: []
    },
    locationDataWithGeoInfo: [],
    selectedHour: [],
    locationsWithGeoInfo: [],
    locationRequestObject: {},
    filteredHexes: {}
  }
}

const state = getDefaultState()

const generateGraphDataStructure = (state, property, mapCallback, sortCallback) => {
  state.graphDataStructure[property] = Object.freeze(
    state.locationAggregates
      .map(mapCallback)
      .sort(sortCallback)
      .reverse()
  )
}

const getters = {
  // Use ES6 shorthand for simple getters.
  getFilteredHexes: state => state.filteredHexes,
  getLocationRequestObject: state => state.locationRequestObject,
  getBaseSelection: state => baseSelectionKey => state.baseSelection[baseSelectionKey],
  getHexagonResolution: state => state.hexagonResolution,
  getResolutionToRequest: state => state.resolutionToRequest,
  getMinMaxDuration: state => state.minMaxDuration,
  getSelectedMinMaxDuration: state => state.selectedMinMaxDuration,
  getInOdoMinMax: state => state.inOdoMinMax,
  getInOdoSelectedMinMax: state => state.inOdoSelectedMinMax,
  getOutOdoMinMax: state => state.outOdoMinMax,
  getOutOdoSelectedMinMax: state => state.outOdoSelectedMinMax,
  getInDurMinMax: state => state.inDurMinMax,
  getInDurSelectedMinMax: state => state.inDurSelectedMinMax,
  getOutDurMinMax: state => state.outDurMinMax,
  getOutDurSelectedMinMax: state => state.outDurSelectedMinMax,
  getMinMaxAnnualMileage: state => state.minMaxAnnualMileage,
  getSelectedMinMaxAnnualMileage: state => state.selectedMinMaxAnnualMileage,
  getSelectedHexagons: state => state.selectedHexagons,
  getLocationAggregates: state => state.locationAggregates,
  getSelectedWeekdays: state => state.selectedWeekdays,
  getSelectedProduct: state => state.selectedProduct,
  getSelectedActivity: state => state.selectedActivity,
  getEnableAnnualMileage: state => state.enableAnnualMileage,
  getEnableTopLocation: state => state.enableTopLocation,
  getSelectedEnergy: state => state.selectedEnergy,
  getSelectedChassisAdaptation: state => state.selectedChassisAdaptation,
  getSelectedCabType: state => state.selectedCabType,
  getLocationDataWithGeoInfo: state => state.locationDataWithGeoInfo,
  getSelectedMinVehicles: state => state.selectedMinVehicles,
  getSelectedMinCustomers: state => state.selectedMinCustomers,
  getSelectedMinLocations: state => state.selectedMinLocations,
  getLocationsWithGeoInfo: state => state.locationsWithGeoInfo,

  // Use a function for more complex getters.
  getMinDate: state => {
    const offset = state.minDate.getTimezoneOffset()
    return new Date(state.minDate - offset * 60 * 1000).toISOString().split('T')[0]
  },
  getMaxDate: state => {
    const offset = state.maxDate.getTimezoneOffset()
    return new Date(state.maxDate - offset * 60 * 1000).toISOString().split('T')[0]
  },
  getGraphDataStructure: state => plotDataName => state.graphDataStructure[plotDataName],
  getSelectedHour: state => [...new Set(state.selectedHour)]
}

const mutations = {
  setFilteredHexagons (state, payload) {
    state.filteredHexes = { ...payload }
  },
  setBaseSelection (state, [forKey, val]) {
    state.baseSelection[forKey] = val
  },
  removeFromSelectedHour (state, selectedHours) {
    state.selectedHour = state.selectedHour.filter(h => {
      return !selectedHours.includes(h)
    })
  },
  addToSelectedHour (state, selectedHours) {
    state.selectedHour = [...state.selectedHour, ...selectedHours]
  },
  setSelectedMinVehicles (state, selectedMinVehicles) {
    state.selectedMinVehicles = selectedMinVehicles
  },
  setSelectedMinCustomers (state, selectedMinCustomers) {
    state.selectedMinCustomers = selectedMinCustomers
  },
  setSelectedMinLocations (state, selectedMinLocations) {
    state.selectedMinLocations = selectedMinLocations
  },
  setSelectedWeekdays (state, selectedWeekdays) {
    state.selectedWeekdays = selectedWeekdays
  },
  setSelectedProduct (state, selectedProduct) {
    state.selectedProduct = selectedProduct
  },
  setSelectedActivity (state, selectedActivity) {
    state.selectedActivity = selectedActivity
  },
  setEnableAnnualMileage (state, enableAnnualMileage) {
    state.enableAnnualMileage = enableAnnualMileage
  },
  setEnableTopLocation (state, enableTopLocation) {
    state.enableTopLocation = enableTopLocation
  },
  setSelectedEnergy (state, selectedEnergy) {
    state.selectedEnergy = selectedEnergy
  },
  setSelectedChassisAdaptation (state, selectedChassisAdaptation) {
    state.selectedChassisAdaptation = selectedChassisAdaptation
  },
  setSelectedCabType (state, selectedCabType) {
    state.selectedCabType = selectedCabType
  },
  setMinDate (state, minDate) {
    state.minDate = new Date(minDate)
  },
  setMaxDate (state, maxDate) {
    state.maxDate = new Date(maxDate)
  },
  setHexagonResolution (state, hexagonResolution) {
    state.hexagonResolution = hexagonResolution
  },
  setResolutionToRequest (state, resolutionToRequest) {
    state.resolutionToRequest = resolutionToRequest
  },
  setMinMaxDuration (state, minMaxDurationArray) {
    state.minMaxDuration = minMaxDurationArray
  },
  setInOdoMinMax (state, inOdoMinMaxArray) {
    state.inOdoMinMax = inOdoMinMaxArray
  },
  setOutOdoMinMax (state, outOdoMinMaxArray) {
    state.outOdoMinMax = outOdoMinMaxArray
  },
  setInDurMinMax (state, inDurMinMaxArray) {
    state.inDurMinMax = inDurMinMaxArray
  },
  setOutDurMinMax (state, outDurMinMaxArray) {
    state.outDurMinMax = outDurMinMaxArray
  },
  setSelectedMinMaxDuration (state, minMaxDurationArray) {
    state.selectedMinMaxDuration = minMaxDurationArray
  },
  setMinMaxAnnualMileage (state, minMaxAnnualMileageArray) {
    state.minMaxAnnualMileage = minMaxAnnualMileageArray
  },
  setSelectedMinMaxAnnualMileage (state, minMaxAnnualMileageArray) {
    state.selectedMinMaxAnnualMileage = minMaxAnnualMileageArray
  },
  setInOdoSelectedMinMax (state, inOdoMinMaxArray) {
    state.inOdoSelectedMinMax = inOdoMinMaxArray
  },
  setOutOdoSelectedMinMax (state, outOdoMinMaxArray) {
    state.outOdoSelectedMinMax = outOdoMinMaxArray
  },
  setInDurSelectedMinMax (state, inDurMinMaxArray) {
    state.inDurSelectedMinMax = inDurMinMaxArray
  },
  setOutDurSelectedMinMax (state, outDurMinMaxArray) {
    state.outDurSelectedMinMax = outDurMinMaxArray
  },
  setSelectedMinDuration (state, minValue) {
    if (!isNaN(parseInt(minValue))) {
      state.selectedMinMaxDuration[0] = parseInt(minValue)
    } else {
      state.selectedMinMaxDuration[0] = state.minMaxDuration[0]
    }
  },
  setInOdoSelectedMin (state, minValue) {
    if (!isNaN(parseInt(minValue))) {
      state.inOdoSelectedMinMax[0] = parseInt(minValue)
    } else {
      state.inOdoSelectedMinMax[0] = state.inOdoMinMax[0]
    }
  },
  setOutOdoSelectedMin (state, minValue) {
    if (!isNaN(parseInt(minValue))) {
      state.outOdoSelectedMinMax[0] = parseInt(minValue)
    } else {
      state.outOdoSelectedMinMax[0] = state.outOdoMinMax[0]
    }
  },
  setInDurSelectedMin (state, minValue) {
    if (!isNaN(parseInt(minValue))) {
      state.inDurSelectedMinMax[0] = parseInt(minValue)
    } else {
      state.inDurSelectedMinMax[0] = state.inDurMinMax[0]
    }
  },
  setOutDurSelectedMin (state, minValue) {
    if (!isNaN(parseInt(minValue))) {
      state.outDurSelectedMinMax[0] = parseInt(minValue)
    } else {
      state.outDurSelectedMinMax[0] = state.outDurMinMax[0]
    }
  },
  setSelectedMinAnnualMileage (state, minValue) {
    if (!isNaN(parseInt(minValue))) {
      state.selectedMinMaxAnnualMileage[0] = parseInt(minValue)
    } else {
      state.selectedMinMaxAnnualMileage[0] = state.minMaxAnnualMileage[0]
    }
  },
  setSelectedMaxAnnualMileage (state, maxValue) {
    if (!isNaN(parseInt(maxValue))) {
      state.selectedMinMaxAnnualMileage[1] = parseInt(maxValue)
    } else {
      state.selectedMinMaxAnnualMileage[1] = state.minMaxAnnualMileage[1]
    }
  },
  setSelectedMaxDuration (state, maxValue) {
    if (!isNaN(parseInt(maxValue))) {
      state.selectedMinMaxDuration[1] = parseInt(maxValue)
    } else {
      state.selectedMinMaxDuration[1] = state.minMaxDuration[1]
    }
  },
  setInOdoSelectedMax (state, maxValue) {
    if (!isNaN(parseInt(maxValue))) {
      state.inOdoSelectedMinMax[1] = parseInt(maxValue)
    } else {
      state.inOdoSelectedMinMax[1] = state.inOdoMinMax[1]
    }
  },
  setOutOdoSelectedMax (state, maxValue) {
    if (!isNaN(parseInt(maxValue))) {
      state.outOdoSelectedMinMax[1] = parseInt(maxValue)
    } else {
      state.outOdoSelectedMinMax[1] = state.outOdoMinMax[1]
    }
  },
  setInDurSelectedMax (state, maxValue) {
    if (!isNaN(parseInt(maxValue))) {
      state.inDurSelectedMinMax[1] = parseInt(maxValue)
    } else {
      state.inDurSelectedMinMax[1] = state.inDurMinMax[1]
    }
  },
  setOutDurSelectedMax (state, maxValue) {
    if (!isNaN(parseInt(maxValue))) {
      state.outDurSelectedMinMax[1] = parseInt(maxValue)
    } else {
      state.outDurSelectedMinMax[1] = state.outDurMinMax[1]
    }
  },
  setSelectedHexagons (state, hexagons) {
    state.selectedHexagons = hexagons
  },
  setLocationAggregates (state, locationAggregates) {
    state.locationAggregates = Object.freeze(locationAggregates)
  },
  resetStateToDefault (state) {
    Object.assign(state, getDefaultState())
  },
  setCountDistributionGraphDataStructure (state) {
    generateGraphDataStructure(
      state,
      'countDistribution',
      graph => ({ hexagons: graph.hexagons, no_stops: graph.no_stops, no_vehicles: graph.vehicles, no_customers: graph.no_customers }),
      (a, b) => a.no_stops - b.no_stops
    )
  },
  setCountDistributionForCustomersGraphDataStructure (state) {
    generateGraphDataStructure(
      state,
      'countDistributionForCustomers',
      graph => ({ hexagons: graph.hexagons, no_customers: graph.no_customers }),
      (a, b) => a.no_customers - b.no_customers
    )
  },
  setCountDistributionForVehiclesGraphDataStructure (state) {
    generateGraphDataStructure(
      state,
      'countDistributionForVehicles',
      graph => ({ hexagons: graph.hexagons, no_vehicles: graph.vehicles }),
      (a, b) => a.no_vehicles - b.no_vehicles
    )
  },
  setCountDistributionLegendGraphDataStructure (state) {
    generateGraphDataStructure(
      state,
      'countDistributionLegend',
      graph => ({ hexagons: graph.hexagons, no_stops: graph.no_stops }),
      (a, b) => a.no_stops - b.no_stops
    )
  },
  setDurationGraphDataStructure (state, plotData) {
    state.graphDataStructure.durationDistribution = Object.freeze(plotData)
  },
  setStartTimeGraphDataStructure (state, plotData) {
    state.graphDataStructure.startHourDistribution = Object.freeze(plotData)
  },
  setSelectedMinMaxOdometer (state, minMaxOdometerArray) {
    state.selectedMinMaxOdometer = minMaxOdometerArray
  },
  setSelectedMinOdometer (state, minValue) {
    if (!isNaN(parseInt(minValue))) {
      state.selectedMinMaxOdometer[0] = parseInt(minValue)
    } else {
      state.selectedMinMaxOdometer[0] = state.minMaxOdometer[0]
    }
  },
  setSelectedMaxOdometer (state, maxValue) {
    if (!isNaN(parseInt(maxValue))) {
      state.selectedMinMaxOdometer[1] = parseInt(maxValue)
    } else {
      state.selectedMinMaxOdometer[1] = state.minMaxOdometer[1]
    }
  },
  setLocationDataWithGeoInfo (state) {
    state.locationDataWithGeoInfo = Object.freeze(state.locationAggregates.map(graph => {
      let latLong = h3ToGeo(graph.hexagons)
      let hexResolution = state.hexagonResolution
      return { ...graph, latitude: latLong[0], longitude: latLong[1], resolution: hexResolution, avgAreaKm2: hexArea(hexResolution, 'km2'), avgAreaM2: hexArea(hexResolution, 'm2') }
    })
      .sort(function (a, b) { return a.no_stops - b.no_stops })
      .reverse())
  },
  setLocationRequestObject (state, requestObject) {
    state.locationRequestObject = requestObject
  }
}

const actions = {
  resetState ({ commit }) {
    commit('resetStateToDefault')
  },
  setLocationLayerAsync (context, layer) {
    context.commit('setLocationAggregates', layer)
  },
  setCountDistributionGraphAsync (context) {
    context.commit('setCountDistributionGraphDataStructure')
    context.commit('setCountDistributionLegendGraphDataStructure')
    context.commit('setCountDistributionForCustomersGraphDataStructure')
    context.commit('setCountDistributionForVehiclesGraphDataStructure')
  },
  setLocationDataWithGeoInfoAsync (context) {
    context.commit('setLocationDataWithGeoInfo')
  }
}

export default {
  namespaced: true,
  state: state,
  getters: getters,
  actions: actions,
  mutations: mutations
}
