<template>
  <b-tab id="tileSetTab" :title=tabName @click="listUserTiles">
    <div class="tileSet" v-for="tile in tileSet" :key="tile.setName">
      <div class="tileTitle">
        <label class="tileLabel">{{ tile.setName }}</label>
      </div>
      <b-button class="tileSetButton" v-bind:class="tile.loadButtonState" :disabled="isFetchingHistoricalTiles"
                @click="getUserHistoricalTiles(tile.setName)">
        <b-spinner v-if="tile.requestedForFetch" small type="grow"></b-spinner>
        Load
      </b-button>
      <b-button class="tileSetButton" v-bind:class="tile.deleteButtonState" :disabled="isFetchingHistoricalTiles"
                @click="deleteUserHistoricalTile(tile.setName)">
        <b-spinner v-if="tile.requestedForDelete" small type="grow"></b-spinner>
        Delete
      </b-button>
    </div>
  </b-tab>
</template>

<script>
import axios from 'axios'
import axiosRetry from 'axios-retry'
import snowflake from '../mixins/snowflake'

export default {
  name: 'DataSetSelection',
  mixins: [snowflake],
  props: {
    tabName: {
      type: String,
      required: false,
      default: 'Previously created datasets'
    },
    lambdaPath: {
      type: String,
      default: '/Prod/PostSnowflakeQueryCache'
    }
  },
  data () {
    return {
      tiles: [],
      isDeletingHistoricalTile: false,
      isFetchingHistoricalTiles: false,
      timeStart: 0
    }
  },
  computed: {
    tileSet () {
      return [...new Set(this.tiles)]
    }
  },
  methods: {
    async apiTilesCall (url, bucketPath) {
      const axiosClient = axios.create()

      const retryCondition = (error) => {
        return (
          axiosRetry.isNetworkOrIdempotentRequestError(error) || error.response.status === 404
        )
      }

      axiosRetry(axiosClient, { retries: 200, retryDelay: axiosRetry.exponentialDelay, retryCondition })

      return axiosClient
        .get(url, {
          headers: { 'Authorization': this.$store.getters.getBearerToken, 'aggregatekey': bucketPath }
        })
    },

    async apiUserTilesCall (url, userName) {
      const axiosClient = axios.create()

      const retryCondition = (error) => {
        return (
          axiosRetry.isNetworkOrIdempotentRequestError(error) || error.response.status === 404
        )
      }

      axiosRetry(axiosClient, { retries: 200, retryDelay: axiosRetry.exponentialDelay, retryCondition })

      return axiosClient
        .get(url, {
          headers: { user: userName, Authorization: this.$store.getters.getBearerToken }
        })
    },

    async apiUserDeleteTileCall (url, bucketPath) {
      const axiosClient = axios.create()

      const retryCondition = (error) => {
        return (
          axiosRetry.isNetworkOrIdempotentRequestError(error) || error.response.status === 500
        )
      }

      axiosRetry(axiosClient, { retries: 200, retryDelay: axiosRetry.exponentialDelay, retryCondition })

      return axiosClient
        .delete(url, {
          headers: { bucketpath: bucketPath, Authorization: this.$store.getters.getBearerToken }
        })
    },

    listUserTiles () {
      this.tiles = []
      if (process.env.VUE_APP_ENV === 'mock') {
        this.tiles.push({ setName: 'shabcj-123/', loadButtonState: 'btn-success', deleteButtonState: 'btn-danger', requestedForFetch: false, requestedForDelete: false })
        this.tiles.push({ setName: 'shabcj-124/', loadButtonState: 'btn-success', deleteButtonState: 'btn-danger', requestedForFetch: false, requestedForDelete: false })
        return true
      }

      let endpoint = this.$store.getters.getAppConfigFlag('useStagingStack')
        ? process.env.VUE_APP_QPGW_ENDPOINT_B : process.env.VUE_APP_QPGW_ENDPOINT
      const tilesURL = endpoint + '/Prod/getTiles'
      this.isFetchingHistoricalTiles = false

      let base64Payload = this.$store.getters.getBearerToken.split('.')[1]
      let payload = Buffer.from(base64Payload, 'base64')
      let decodedAndParsedString = JSON.parse(payload.toString())
      let email = decodedAndParsedString['preferred_username']
      let userName = email.slice(0, -11)

      this.apiUserTilesCall(tilesURL, userName)
        .then(res => {
          let tileSets = res.data.tile_folders
          for (let i = 0; i < tileSets.length; i += 1) {
            this.tiles.push({
              setName: tileSets[i],
              loadButtonState: 'btn-success',
              deleteButtonState: 'btn-danger',
              requestedForFetch: false,
              requestedForDelete: false
            })
          }
          this.tiles = [...new Set(this.tiles)]
        })
        .catch(err => {
          this.$logger.message(`{"listUserTiles":"DataSetSelection.vue listUserTiles: ${err}"}`, 'error')
        })
    },
    deleteUserHistoricalTile (bucketPath) {
      this.timeStart = Date.now()
      let endpoint = this.$store.getters.getAppConfigFlag('useStagingStack') ? process.env.VUE_APP_QPGW_ENDPOINT_B : process.env.VUE_APP_QPGW_ENDPOINT
      const URL = endpoint + '/Prod/deleteTile'
      this.isDeletingHistoricalTile = true

      for (let i = 0; i < this.tiles.length; i += 1) {
        if (this.tiles[i]['setName'] === bucketPath) {
          this.tiles[i]['requestedForDelete'] = true
          this.tiles[i]['loadButtonState'] = 'btn-light'
        } else {
          this.tiles[i]['loadButtonState'] = 'btn-light'
          this.tiles[i]['deleteButtonState'] = 'btn-light'
        }
      }
      this.apiUserDeleteTileCall(URL, bucketPath)
        .then(res => {
          this.tiles = []
          this.listUserTiles()
          let endTime = Date.now()
          this.$logger.message(`{"deleteUserHistoricalTile":{"message":"Finished","time":"${endTime - this.timeStart}"}}`, 'info')
        })
        .catch(err => {
          this.$logger.message(`{"deleteUserHistoricalTile":"DataSetSelection.vue: ${err}"}`, 'error')
        })
    },

    getUserHistoricalTiles (tilesURL) {
      this.timeStart = Date.now()
      this.$store.commit('setStartTimeLog', Date.now())
      this.$store.commit('setTriggerDeckRedraw', false)
      this.$store.commit('setUserSelection', '')
      this.$store.commit('resetVisibleLayers')
      this.$store.dispatch('chassis/resetState')
      this.$logger.message(`{"getUserHistoricalTiles":"${tilesURL}"}`, 'info')
      this.isFetchingHistoricalTiles = true
      for (let i = 0; i < this.tiles.length; i += 1) {
        if (this.tiles[i]['setName'] === tilesURL) {
          this.tiles[i]['requestedForFetch'] = true
          this.tiles[i]['deleteButtonState'] = 'btn-light'
        } else {
          this.tiles[i]['loadButtonState'] = 'btn-light'
          this.tiles[i]['deleteButtonState'] = 'btn-light'
        }
      }
      this.$emit('handle-on-view-selection')
      if (process.env.VUE_APP_ENV !== 'mock') {
        let endpoint = this.$store.getters.getAppConfigFlag('useStagingStack')
          ? process.env.VUE_APP_QPGW_ENDPOINT_B : process.env.VUE_APP_QPGW_ENDPOINT
        const url = endpoint + '/Prod/GetAggregate'
        let bucketPath = tilesURL + 'meta_data.json'

        this.apiTilesCall(url, bucketPath).then(res => {
          this.$emit('handle-on-data-response', res.data)
          const stopsSqlFile = 'STOPS'
          const rawStops = this.getQueryIdFromSnowflake(stopsSqlFile, 'rawStops')
          this.$store.commit('chassis/setRequestedTilesName', tilesURL.substring(0, tilesURL.length - 1))
          this.$store.commit('setTriggerDeckRedraw', true)
          this.$emit('handle-on-data-loaded')
          return Promise.all([rawStops])
        }).then(() => {
          const stopQueryState = this.getStopQueryState()
          return Promise.all([stopQueryState])
        }).then(() => {
          const getMinMaxQueryId = this.getQueryIdFromSnowflake('MIN_MAX_RANGES',
            'minMaxRanges')
          const getEdgesQueryId = this.getQueryIdFromSnowflake('EDGES', 'edges')
          const getDailyAggregatesQueryId = this.getQueryIdFromSnowflake('DAILY_AGGREGATES',
            'dailyAggregates')
          const getOdometerBoxplotQueryId = this.getQueryIdFromSnowflake('ODOMETER_BOXPLOT',
            'odometerBoxplot')
          const getFuelBoxplotQueryId = this.getQueryIdFromSnowflake('FUEL_BOXPLOT',
            'fuelBoxplot')
          const getHexagonLayerQueryId = this.getQueryIdFromSnowflake('HEXAGONS',
            'hexagons')
          const getDailyFuelBoxplotQueryId = this.getQueryIdFromSnowflake('DAILY_FUEL_BOXPLOT',
            'dailyFuelBoxplot')
          const getDailyOdometerBoxplotQueryId = this.getQueryIdFromSnowflake('DAILY_ODOMETER_BOXPLOT',
            'dailyOdometerBoxplot')
          const getHourlyLocationDistQueryId = this.getQueryIdFromSnowflake('HOURLY_ONGOING_STOPS',
            'hourlyLocationDistribution')
          const getDailyHourlyLocationDistQueryId = this.getQueryIdFromSnowflake('DAILY_HOURLY_ONGOING_STOPS',
            'dailyHourlyLocationDistribution')
          return Promise.all([getMinMaxQueryId, getEdgesQueryId, getDailyAggregatesQueryId, getOdometerBoxplotQueryId,
            getFuelBoxplotQueryId, getHexagonLayerQueryId, getDailyFuelBoxplotQueryId, getDailyOdometerBoxplotQueryId,
            getHourlyLocationDistQueryId, getDailyHourlyLocationDistQueryId])
        }).then(() => {
          const getOdometerBoxplotFromSnowflake = this.getOdometerBoxplotFromSnowflake()
          const getFuelBoxplotFromSnowflake = this.getFuelBoxplotFromSnowflake()
          const getMinMaxValuesFromSnowflake = this.getMinMaxValuesFromSnowflake()
          const getDailyGraphAggregateFromSnowflake = this.getDailyGraphAggregateFromSnowflake()
          const getEdgeLayerFromSnowflake = this.getEdgeLayerFromSnowflake()
          const getHexagonLayerFromSnowflake = this.getHexagonLayerFromSnowflake()
          const getDailyFuelBoxplot = this.getDailyFuelBoxplotFromSnowflake()
          const getDailyOdometerBoxplot = this.getDailyOdometerBoxplotFromSnowflake()
          const getHourlyLocationDistribution = this.getHourlyLocationDistributionFromSnowflake()
          const getDailyHourlyLocationDistribution = this.getDailyHourlyLocationDistributionFromSnowflake()

          return Promise.all([getOdometerBoxplotFromSnowflake, getFuelBoxplotFromSnowflake, getMinMaxValuesFromSnowflake,
            getDailyGraphAggregateFromSnowflake, getEdgeLayerFromSnowflake, getHexagonLayerFromSnowflake, getDailyFuelBoxplot,
            getDailyOdometerBoxplot, getHourlyLocationDistribution, getDailyHourlyLocationDistribution])
        }).then(() => {
          let endTime = Date.now()
          this.$logger.message(`{"GetUserHistoricalTiles":{"message":"POST & GET is done for ${tilesURL}", "time":"${endTime - this.timeStart}"}}`, 'info')
          this.$store.commit('chassis/setGraphDataFinishedLoading', true)
        }).catch(err => {
          if (err.response.status === 500) {
            this.$logger.message(`{"GetUserHistoricalTiles":"DataSet.vue getUserTiles: ${err.response.data.message}"}`, 'error')
            const errorMessageHistorical = 'Not all components will be available when finished loading.'
            this.$emit('handle-on-response-error', errorMessageHistorical)
          } else {
            this.$logger.message(`{"GetUserHistoricalTiles":"DataSet.vue getUserTiles: ${err.response.data.message}"}`, 'error')
            const errorMessageHistorical = 'Not all components will be available when finished loading.'
            this.$emit('handle-on-response-error', errorMessageHistorical)
          }
        })
      } if (process.env.VUE_APP_ENV === 'mock') {
        this.$store.commit('chassis/setTilesConstantMaxTime', new Date())
        this.$store.commit('chassis/setTilesChassis', '2127922')
        this.$store.commit('setUserSelectionType', '_mock')
        const stopsSqlFile = 'STOPS'
        const rawStops = this.getQueryIdFromSnowflake(stopsSqlFile, 'rawStops')
        this.$store.commit('chassis/setRequestedTilesName', tilesURL.substring(0, tilesURL.length - 1))
        this.$store.commit('setTriggerDeckRedraw', true)
        this.$emit('handle-on-data-loaded')
        Promise.all([rawStops]).then(() => {
          const stopQueryState = this.getStopQueryState()
          return Promise.all([stopQueryState])
        }).then(() => {
          const getMinMaxQueryId = this.getQueryIdFromSnowflake('MIN_MAX_RANGES',
            'minMaxRanges')
          const getEdgesQueryId = this.getQueryIdFromSnowflake('EDGES', 'edges')
          const getDailyAggregatesQueryId = this.getQueryIdFromSnowflake('DAILY_AGGREGATES',
            'dailyAggregates')
          const getOdometerBoxplotQueryId = this.getQueryIdFromSnowflake('ODOMETER_BOXPLOT',
            'odometerBoxplot')
          const getFuelBoxplotQueryId = this.getQueryIdFromSnowflake('FUEL_BOXPLOT',
            'fuelBoxplot')
          const getHexagonLayerQueryId = this.getQueryIdFromSnowflake('HEXAGONS',
            'hexagons')
          const getDailyFuelBoxplotQueryId = this.getQueryIdFromSnowflake('DAILY_FUEL_BOXPLOT',
            'dailyFuelBoxplot')
          const getDailyOdometerBoxplotQueryId = this.getQueryIdFromSnowflake('DAILY_ODOMETER_BOXPLOT',
            'dailyOdometerBoxplot')
          const getHourlyLocationDistQueryId = this.getQueryIdFromSnowflake('HOURLY_ONGOING_STOPS',
            'hourlyLocationDistribution')
          const getDailyHourlyLocationDistQueryId = this.getQueryIdFromSnowflake('DAILY_HOURLY_ONGOING_STOPS',
            'dailyHourlyLocationDistribution')
          return Promise.all([getMinMaxQueryId, getEdgesQueryId, getDailyAggregatesQueryId, getOdometerBoxplotQueryId,
            getFuelBoxplotQueryId, getHexagonLayerQueryId, getDailyFuelBoxplotQueryId, getDailyOdometerBoxplotQueryId,
            getHourlyLocationDistQueryId, getDailyHourlyLocationDistQueryId])
        }).then(() => {
          const getOdometerBoxplotFromSnowflake = this.getOdometerBoxplotFromSnowflake()
          const getFuelBoxplotFromSnowflake = this.getFuelBoxplotFromSnowflake()
          const getMinMaxValuesFromSnowflake = this.getMinMaxValuesFromSnowflake()
          const getDailyGraphAggregateFromSnowflake = this.getDailyGraphAggregateFromSnowflake()
          const getEdgeLayerFromSnowflake = this.getEdgeLayerFromSnowflake()
          const getHexagonLayerFromSnowflake = this.getHexagonLayerFromSnowflake()
          const getDailyFuelBoxplot = this.getDailyFuelBoxplotFromSnowflake()
          const getDailyOdometerBoxplot = this.getDailyOdometerBoxplotFromSnowflake()
          const getHourlyLocationDistribution = this.getHourlyLocationDistributionFromSnowflake()
          const getDailyHourlyLocationDistribution = this.getDailyHourlyLocationDistributionFromSnowflake()

          return Promise.all([getOdometerBoxplotFromSnowflake, getFuelBoxplotFromSnowflake, getMinMaxValuesFromSnowflake,
            getDailyGraphAggregateFromSnowflake, getEdgeLayerFromSnowflake, getHexagonLayerFromSnowflake, getDailyFuelBoxplot,
            getDailyOdometerBoxplot, getHourlyLocationDistribution, getDailyHourlyLocationDistribution])
        }).then(() => {
          let endTime = Date.now()
          this.$logger.message(`{"GetUserHistoricalTiles":{"message":"POST & GET is done for ${tilesURL}", "time":"${endTime - this.timeStart}"}}`, 'info')
          this.$store.commit('chassis/setGraphDataFinishedLoading', true)
        }).catch(err => {
          if (err.response.status === 500) {
            this.$logger.message(`{"GetUserHistoricalTiles":"DataSet.vue getUserTiles: ${err.response.data.message}"}`, 'error')
            const errorMessageHistorical = 'Not all components will be available when finished loading.'
            this.$emit('handle-on-response-error', errorMessageHistorical)
          } else {
            this.$logger.message(`{"GetUserHistoricalTiles":"DataSet.vue getUserTiles: ${err.response.data.message}"}`, 'error')
            const errorMessageHistorical = 'Not all components will be available when finished loading.'
            this.$emit('handle-on-response-error', errorMessageHistorical)
          }
        })
      }
    }
  },
  watch: {
    '$store.state.resetModal': function () {
      if (this.$store.getters.getResetModal) {
        this.listUserTiles()
        this.isFetchingHistoricalTiles = false
        this.isDeletingHistoricalTile = false
        this.$emit('handle-data-set-selection-reset', 'dataSetSelection')
      }
    }
  }
}
</script>

<style scoped>
#tileSetTab {
  max-height: 50vh;
  overflow-y: auto;
}

.tileSetButton {
  margin: 0.2rem;
}

.tileTitle {
  position: relative;
  top: -0.8em;
}

.tileSetButton {
  margin-left: 0.4rem;
  margin-right: 0.4rem;
  margin-bottom: 0.4rem;
  margin-top: -0.4rem;
}

.tileSet {
  margin: 0.8rem;
  float: left;
  border: 0.5px solid darkgray;
}

.tileLabel {
  background-color: white;
  margin-left: 0.4rem;
  margin-right: 0.4rem;
}
</style>
