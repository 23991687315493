<template>
    <DeckGLBase
        :layers="layers"
        :viewState="viewState"
        @click="handleClick"
        @view-state-change="updateViewState"
    >
        <div id="outboundmap" ref="outboundmap"></div>
    </DeckGLBase>
</template>

<script>
import DeckGLBase from '@/components/DeckGLBase.vue'
import { FlowmapLayer } from '@flowmap.gl/layers'
import mapboxgl from 'mapbox-gl'
import { geoToH3, h3ToGeo } from 'h3-js'
import snowflake from '../mixins/snowflake'
import { getViewStateForLocations } from '@flowmap.gl/data'

export default {
  name: 'FlowMap',
  mixins: [snowflake],
  components: { DeckGLBase },
  data () {
    return {
      accessToken: process.env.VUE_APP_MAP_TOKEN,
      cachedData: [],
      style: 'mapbox://styles/connected-intelligence/ckhpx27eb02zt19qrm3qphq46',
      viewState: {
        latitude: 0,
        longitude: 0,
        zoom: 1.62,
        bearing: 0,
        pitch: 0
      }
    }
  },
  created () {
    this.deckMapObject = null
  },
  mounted () {
    this.fetchDataSync()
    this.createFlowMap()
      .then(() => {
        setTimeout(() => {
          const [width, height] = [window.innerWidth, window.innerHeight]
          this.viewState = getViewStateForLocations(
            this.cachedData[0],
            (loc) => [loc.lon, loc.lat],
            [width, height],
            { pad: 0.3 }
          )
        }, 2000)
      })
    // this.$store.commit('setResetModal', true)
    this.$store.commit('setModalVisibility', false)
  },
  methods: {
    handleClick ({ event, info }) {
      console.log(event)
      console.log(info)
    },
    updateViewState (viewState) {
      this.viewState = {
        ...viewState
      }
      this.deckMapObject.jumpTo({
        center: [viewState.longitude, viewState.latitude],
        zoom: viewState.zoom,
        bearing: viewState.bearing,
        pitch: viewState.pitch
      })
      setTimeout(() => {
        window.dispatchEvent(new Event('resize'))
      }, 300)
    },
    createFlowMap () {
      return new Promise(resolve => {
        this.deckMapObject = new mapboxgl.Map({
          accessToken: this.accessToken,
          container: this.$refs.outboundmap,
          width: '100%',
          height: '100%',
          interactive: false,
          style: this.style,
          center: [this.viewState.longitude, this.viewState.latitude],
          zoom: this.viewState.zoom,
          pitch: this.viewState.pitch,
          bearing: this.viewState.bearing,
          maxZoom: 18
        })
      })
    },
    transposeCoordinates (h3CellIndex) {
      let invertedCoordinates = h3ToGeo(h3CellIndex)
      return [invertedCoordinates[1], invertedCoordinates[0]]
    },
    getClusterLevelsH3 (locations, minZoom = 1, maxZoom = 20) {
      let nodes = locations.map((d) => ({
        id: d.id,
        zoom: maxZoom,
        lat: +d.lat,
        lon: +d.lon
      }))

      const result = []
      let rawZoom = null
      for (let zoom = maxZoom - 1; zoom >= minZoom; zoom--) {
        const h3Zoom = zoom - 4
        const nodesByH3 = nodes.reduce((acc, d) => {
          const h3Id = geoToH3(+d.lat, +d.lon, h3Zoom)
          if (!acc[h3Id]) {
            acc[h3Id] = []
          }
          acc[h3Id].push(d)
          return acc
        }, {})

        const keys = Object.keys(nodesByH3)
        if (keys.length < locations.length) {
          if (rawZoom === null) {
            rawZoom = zoom + 1
          }
          nodes = keys.map((id) => {
            if (nodesByH3[id].length === 1) {
              const node = nodesByH3[id][0]
              return {
                id: `{[${node.id}:${zoom}]}`,
                zoom,
                lat: node.lat,
                lon: node.lon,
                children: [node.id]
              }
            }
            return {
              id: `{[${id}:${zoom}]}`,
              zoom,
              lat: h3ToGeo(id, true)[0],
              lon: h3ToGeo(id, true)[1],
              children: nodesByH3[id].map((d) => d.id)
            }
          })

          result.unshift({
            zoom,
            nodes
          })
        }

        if (keys.length <= 1) {
          break
        }
      }

      result.push({
        zoom: rawZoom ?? maxZoom,
        nodes: locations
      })

      return result
    },
    fetchDataSync (clusterMethod = 'HCA') {
      const edges = this.$store.getters['outbound/getEdgeLayer']
      const origins = new Set(edges.map(l => { return l.hexagons_origin }))
      const destinations = new Set(edges.map(l => { return l.hexagon_destination }))
      const locations = [...new Set([...origins, ...destinations])].map(loc => ({
        id: loc,
        name: 'hexid:' + loc,
        lat: h3ToGeo(loc, true)[0],
        lon: h3ToGeo(loc, true)[1]
      }))
      const flows = edges.map((row) => ({
        origin: row.hexagons_origin,
        dest: row.hexagon_destination,
        count: Number(row.no_of_trips)
      }))
      this.cachedData = { locations, flows }
      return {
        ...this.cachedData,
        ...(clusterMethod === 'H3'
          ? { clusterLevels: this.getClusterLevelsH3(this.cachedData.locations) }
          : null)
      }
    }
  },
  computed: {
    layers () {
      const flowData = this.fetchDataSync()
      const flowLayer = new FlowmapLayer({
        id: 'outbound-flow',
        data: flowData,
        pickable: true,
        clusteringEnabled: true,
        clusteringMethod: 'H3',
        clusteringAuto: true,
        fadeEnabled: true,
        getLocationId: (loc) => loc.id,
        getLocationLat: (loc) => loc.lat,
        getLocationLon: (loc) => loc.lon,
        getFlowOriginId: (flow) => flow.origin,
        getFlowDestId: (flow) => flow.dest,
        getFlowMagnitude: (flow) => flow.count,
        getLocationName: (loc) => loc.name
      })
      return [ flowLayer ]
    }
  }
}

</script>

<style>
#outboundcontainer > * {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
</style>
