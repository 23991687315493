import Vue from 'vue'
import Vuex from 'vuex'
import logger from '../plugin/logger'
import locationStore from './location/store.js'
import chassisStore from './chassis/store.js'
import outboundStore from './outbound/store.js'
import classifiedLocationStore from './classifiedlocation/store.js'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    signedIn: process.env.VUE_APP_API_CLIENT === 'mock2',
    bearerToken: null,
    showModal: true,
    resetModal: null,
    chassisWithGraphColours: null,
    queryIds: {
      edges: '',
      dailyAggregates: '',
      minMaxRanges: '',
      hexagons: '',
      odometerBoxplot: '',
      fuelBoxplot: '',
      dailyFuelBoxplot: '',
      dailyOdometerBoxplot: '',
      hourlyLocationDistribution: '',
      dailyHourlyLocationDistribution: '',
      rawStops: '',
      dailyAggregatesTest: '',
      locationsCache: '',
      classified_locations: '',
      outboundCache: ''
    },
    visibleLayers: [],
    username: null,
    userRoles: [],
    queryStates: {
      edges: false,
      dailyAggregates: false,
      minMaxRanges: false,
      hexagons: false,
      odometerBoxplot: false,
      fuelBoxplot: false,
      dailyFuelBoxplot: false,
      dailyOdometerBoxplot: false,
      hourlyLocationDistribution: false,
      dailyHourlyLocationDistribution: false,
      rawStops: false,
      dailyAggregatesTest: false,
      locationsCache: false,
      locations: false,
      outboundCache: false
    },
    appConfigFeatureFlags: null,
    lastFlagStatus: [],
    requestedFilters: [],
    userSelectedView: '',
    userSelectionType: '',
    startTimeLog: 0,
    triggerDeckRedraw: false,
    mapCenters: {
      defaultMapCenter: [24, 33],
      locationsMapCenter: [24, 33],
      chassisMapCenter: [24, 33]
    },
    baseMap: null,
    mapLayerIds: ['stop', 'hexagon', 'edge', 'location'],
    availableLayers: [],
    minDate: process.env.VUE_APP_ENV === 'mock' || process.env.VUE_APP_ENV === 'dev' ? new Date('2017-01-01') : new Date(new Date() - new Date(1000 * 24 * 3600 * 365)),
    maxDate: new Date()
  },
  mutations: {
    resetVisibleLayers (state) {
      state.visibleLayers = []
    },
    setBaseMap (state, baseMapObj) {
      state.baseMap = baseMapObj
    },
    setMinDate (state, minDate) {
      state.minDate = new Date(minDate)
    },
    setMaxDate (state, maxDate) {
      state.maxDate = new Date(maxDate)
    },
    addMapLayer (state, mapLayerID) {
      state.mapLayerIds.push(mapLayerID)
    },
    removeMapLayer (state, mapLayerID) {
      state.mapLayerIds.splice(state.mapLayerIds.indexOf(mapLayerID), 1)
    },
    setTriggerDeckRedraw (state, triggerDeckRedraw) {
      state.triggerDeckRedraw = triggerDeckRedraw
    },
    setCenterFor (state, [ centerFor, centerValues ]) {
      state.mapCenters[centerFor] = centerValues
    },
    setUserSelection (state, userSelectedView) {
      state.userSelectedView = userSelectedView
    },
    setUserSelectionType (state, userSelectionType) {
      state.userSelectionType = userSelectionType
    },
    setResetModal (state, resetModal) {
      state.resetModal = resetModal
    },
    setSignedIn (state, signedInStatus) {
      state.signedIn = signedInStatus
    },
    setUsername (state, name) {
      state.username = name
    },
    setStartTimeLog (state, time) {
      state.startTimeLog = time
    },
    setUserRoles (state, roles) {
      state.userRoles = roles
    },
    setBearerToken (state, token) {
      if (token) {
        state.bearerToken = `Bearer ${token}`
      } else state.bearerToken = token
    },
    setModalVisibility (state, show) {
      state.showModal = show
    },
    setQueryIdFor (state, { queryFor, queryId }) {
      state.queryIds[queryFor] = queryId
    },
    setLayerVisibility (state, [ layerName, visibility ]) {
      if (state.visibleLayers.indexOf(layerName) > -1) {
        if (!visibility) {
          state.visibleLayers = state.visibleLayers.filter(e => e !== layerName)
        }
      } else {
        if (visibility) {
          state.visibleLayers.push(layerName)
        }
      }
    },
    setQueryStatus (state, { queryFor, queryState }) {
      state.queryStates[queryFor] = queryState
    },
    setAppConfigFeatureFlags (state, appConfigFeatureFlags) {
      state.appConfigFeatureFlags = appConfigFeatureFlags
    },
    setAvailableLayers (state, availableLayers) {
      state.availableLayers = availableLayers
    },
    setRequestedFilters (state, requestedFilters) {
      state.requestedFilters = requestedFilters
    }
  },

  getters: {
    getMinDate: (state) => {
      const offset = state.minDate.getTimezoneOffset()
      return (new Date(state.minDate - (offset * 60 * 1000))).toISOString().split('T')[0]
    },
    getMaxDate: (state) => {
      const offset = state.maxDate.getTimezoneOffset()
      return (new Date(state.maxDate - (offset * 60 * 1000))).toISOString().split('T')[0]
    },
    getCenterFor: state => (centerFor) => {
      return state.mapCenters[centerFor]
    },
    getMapLayerIds: state => {
      return state.mapLayerIds
    },
    getTriggerDeckRedraw: state => {
      return state.triggerDeckRedraw
    },
    getUserSelection: state => {
      return state.userSelectedView
    },
    getUserSelectionType: state => {
      return state.userSelectionType
    },
    getResetModal: state => {
      return state.resetModal
    },
    isSignedIn: state => {
      return state.signedIn
    },
    getStartTimeLog: state => {
      return state.startTimeLog
    },
    getUsername: state => {
      return state.username
    },
    getBearerToken: state => {
      return state.bearerToken
    },
    getModalVisibility: state => {
      return state.showModal
    },
    getQueryIdFor: state => (queryIdFor) => {
      return state.queryIds[queryIdFor]
    },
    getLayerVisibility: state => (layerName) => {
      if (state.visibleLayers.indexOf(layerName) > -1) return true
      return false
    },
    getLayers: state => {
      return state.visibleLayers
    },
    getQueryStateFor: state => (queryIdFor) => {
      return state.queryStates[queryIdFor]
    },
    getAppConfigs: state => {
      return state.appConfigFeatureFlags
    },
    getAppConfigFlag: state => (flagName) => {
      if (!state.appConfigFeatureFlags) return false
      if (Object.keys(state.appConfigFeatureFlags).length === 0) return false
      let user = 'none'
      let group = 'none'
      let enabled = 'none'
      state.appConfigFeatureFlags.forEach(obj => {
        if (obj.hasOwnProperty(flagName)) {
          enabled = obj[flagName].isEnabled
          if (obj[flagName].hasOwnProperty('userGroups')) {
            group = state.userRoles.filter(role => obj[flagName].userGroups.includes(role)).length > 0
          }
          if (obj[flagName].hasOwnProperty('userIDs')) {
            user = obj[flagName].userIDs.includes(state.username)
          }
          let flagStatus = `[{"flagName":"${flagName}", "enabled":"${enabled}", "group":"${group}", "user":"${user}",]`
          if (state.lastFlagStatus.indexOf(flagStatus) === -1) {
            state.lastFlagStatus.push(flagStatus)
            logger.message(`{"getAppConfigFlag":"${JSON.stringify(flagStatus)}"}`, 'debug')
          }
        }
      })
      if (user === 'none' && group === 'none') {
        return enabled
      }
      if (user === 'none' && !(group === 'none')) {
        return enabled && group
      }
      if (!(user === 'none') && group === 'none') {
        return enabled && user
      }
      if (!(user === 'none') && !(group === 'none')) {
        return enabled && (user || group)
      }
    },
    getLocationAccessAllowed: (state, getters) => {
      if (state.userRoles.some(role => ['charging-infra'].includes(role)) === true) {
        return true
      }
      return state.userRoles.some(role => ['gsi-admin'].includes(role)) && getters.getAppConfigFlag('location')
    },
    getClassifiedLocationAccessAllowed: (state, getters) => {
      return getters.getAppConfigFlag('classifiedlocation')
    },
    getMarketAccessAllowed: (state, getters) => {
      if (state.userRoles.some(role => ['bu-user'].includes(role)) === true) {
        return true
      }
      return state.userRoles.some(role => ['gsi-admin'].includes(role)) && getters.getAppConfigFlag('chassis')
    },
    getChassisAccessAllowed: (state, getters) => {
      if (state.userRoles.some(role => ['sweete-user-tier1'].includes(role)) === true) {
        return true
      }
      return state.userRoles.some(role => ['gsi-admin'].includes(role)) && getters.getAppConfigFlag('chassis')
    },
    getAdminAccess: (state) => {
      return state.userRoles.some(role => ['gsi-admin'].includes(role))
    },
    getBaseMap: (state) => {
      return state.baseMap
    },
    getAvailableLayers: (state) => {
      return state.availableLayers
    },
    getRequestedFilters: state => {
      return state.requestedFilters
    }
  },

  modules: {
    location: locationStore,
    chassis: chassisStore,
    outbound: outboundStore,
    classified_location: classifiedLocationStore
  }
})
