<template>
<div id="exportButtonContainer">
  <b-button id="exportButton"
            :disabled="disabled"
            pill
            variant="success"
            size="sm"
            @click="parseJsonAndWriteToExcel">
   Export selected graphs
  </b-button>
  <span id="exportErrorMessage" v-if="failed">
    {{ failMessage }}
  </span>
</div>
</template>

<script>
import XLSX from 'xlsx'
import { jsonToXLSXWB } from '@/helpers/worker-api'
import snowflake from '@/mixins/snowflake'
import { h3ToGeo, hexArea } from 'h3-js'

export default {
  name: 'ExportButton',
  mixins: [snowflake],
  props: {
    selectedGraphs: {
      type: Array,
      required: true
    },
    getStateFunction: {
      type: String,
      default: 'chassis/getGraphDataStructure'
    }
  },
  data () {
    return {
      failed: false,
      failMessage: '',
      disabled: false
    }
  },
  methods: {
    async parseInWebWorker (selectedGraphs, functionAsString) {
      try {
        const result = await jsonToXLSXWB(selectedGraphs, functionAsString)
        const transposeWorksheet = a => Object.keys(a[0]).map(c => {
          let ret = a.map(r => r[c])
          ret.unshift(c)
          return ret
        })
        const filterData = this.$store.getters['getRequestedFilters'].map(filter => {
          let hexRes = parseInt(filter.hexresolution.split('_').pop())
          return {
            ...filter,
            avgAreaKm2: hexArea(hexRes, 'km2'),
            avgAreaM2: hexArea(hexRes, 'm2')
          }
        })
        let filterWorkSheet = XLSX.utils.json_to_sheet(transposeWorksheet(filterData), { skipHeader: 1 })
        XLSX.utils.book_append_sheet(result.wb, filterWorkSheet, 'Filters')
        XLSX.writeFile(result.wb, `sweete_export_${new Date().toISOString().slice(0, 10)}.xlsx`)
        if (result.errors.length > 0) {
          let failedGraphTitles = result.errors.join(' ')
          this.failed = true
          this.failMessage = 'Unfortunately ' + failedGraphTitles + ' graphs can´t be exported to Excel.'
          this.$logger.message(`{"exportJsonAsXlsx":"Failed on graph export: ${failedGraphTitles}"}`, 'error')
        }
      } catch (e) {
        this.$logger.message(`{"exportJsonAsXlsx":"Failed with: ${e}"}`, 'error')
        throw e
      }
    },
    getHexagonAggregates () {
      const requestParams = this.$store.getters['location/getLocationRequestObject']
      const countDistRequestParams = { ...requestParams, sqlfilename: 'COUNT_DISTRIBUTION' }
      const countDistRequest = this.getQueryIdForLocations('countDistribution', countDistRequestParams)

      return Promise.all([countDistRequest])
        .then(() => {
          const countDistributionState = this.getStatusForLocationQuery(this.$store.getters.getQueryIdFor('countDistribution'))
          return Promise.all([countDistributionState])
        })
        .then(() => {
          const countDistribution = this.getLocationDataForQueryId(this.$store.getters.getQueryIdFor('countDistribution'))
          return Promise.all([countDistribution])
        })
        .catch(error => {
          this.$logger.message(`{"Exporting hexagon data failed":"${error}"}`, 'error')
          throw new Error('Data wasn´t able to be returned!')
        })
    },
    async parseJsonAndWriteToExcel () {
      this.failed = false
      this.disabled = true
      this.failMessage = ''
      let graphData = {}
      let graphsSelected = this.selectedGraphs
      for (let graph of this.selectedGraphs) {
        if (graph.tabTitle === 'countDistribution') {
          // Fetch hexagon aggregates when clicking the export button
          if (this.$store.getters.getAppConfigFlag('isTestingWithoutPreCache')) {
            await this.getHexagonAggregates()
              .then(response => {
                graphData['hexagons'] = response[0].data.map(graph => {
                  let latLong = h3ToGeo(graph.hexagons)
                  return { ...graph, latitude: latLong[0], longitude: latLong[1] }
                })
                graphsSelected = [...this.selectedGraphs.filter(g => g.tabTitle !== 'countDistribution'), {
                  tabTitle: 'hexagons',
                  chartHeader: [],
                  graphVariable: 'hexagons'
                }]
              })
          } else {
            graphData['countDistribution'] = this.$store.getters[graph.graphVariable]
          }
        } else {
          graphData[graph.tabTitle] = this.$store.getters[this.getStateFunction](graph.graphVariable)
        }
      }
      const createFileExport = this.parseInWebWorker(graphsSelected, graphData)
      const graphTitles = graphsSelected.map(g => { return g.tabTitle })

      Promise.all([createFileExport]).then(() => {
        this.$logger.message(`{"exportJsonAsXlsx":"Graphs exported: ${graphTitles}"}`, 'info')
        this.disabled = false
      })
    }
  }
}

</script>
<style scoped>
#exportButtonContainer {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  margin-top:20px;
  width: 80%;
}
#exportButton {
  font-size: small;
  height: 30px;
  width: 160px;
  flex: none;
}
#exportErrorMessage {
  margin-top:10px;
  color:white;
  font-style:italic;
  align-self: flex-end;
}
</style>
