<template>
  <div id="admin-panel">
    <b-card>
      <b-button id="app-config" v-b-toggle="'change-app-config-collapse'" style="background: #130f40">App config</b-button>
      <b-collapse id="change-app-config-collapse">
        <b-card-body>
          <b-form-textarea
            id="adminPanel"
            v-model="config"
            placeholder="{}"
            row="10"
            max-rows="15"/>
          <b-button id="post-app-config-button"
                    v-bind:class="button.state"
                    :disabled="!isConfigValid"
                    @click="postSet">
            <b-spinner v-if="button.isFetchingDataOnRequest" small type="grow"></b-spinner>
            {{ button.info }}
          </b-button>
        </b-card-body>
      </b-collapse>
    </b-card>
    <b-card>
      <b-button id="customer-layer-upload-admin"
                v-b-toggle="'customer-upload-collapse'"
                style="background:#130f40"
                @click="populateUploadsList()">Customer layer upload</b-button>
      <b-collapse id="customer-upload-collapse">
        <b-card-body>
          <div class="customerUpload"
               v-for="uploadedFile in customerUploads"
               :key="'vue-key-' + uploadedFile.fileName">
            <div class="setTitle">
              <label class="uploadLabel">{{ uploadedFile.fileName }}</label>
            </div>
            <b-button :id="'delete-'+uploadedFile.fileName"
                      variant="danger"
                      class="deleteSetButton"
                      :disabled="deletionInProgress"
                      @click="deleteUpload(uploadedFile)">
              <b-spinner v-if="uploadedFile.requestedForDelete" small type="grow"></b-spinner>
              Delete
            </b-button>
          </div>
        </b-card-body>
      </b-collapse>
    </b-card>
  </div>
</template>

<script>
import axios from 'axios'

export default {
  name: 'AdminPanel',
  components: { },
  data () {
    return {
      deletionInProgress: false,
      customerUploads: [],
      appConfigFeatureFlags: '',
      config: '',
      errorMessage: '',
      message: '',
      showInfo: false,
      button: { info: 'Post config', isFetchingDataOnRequest: false, state: 'btn-success' },
      buttonGetConfig: { info: 'Get config', isFetchingDataOnRequest: false, state: 'btn-success' }
    }
  },
  methods: {
    populateUploadsList () {
      let availableLayers = []

      let lambdaPath = '/Prod/ListLocationFiles'
      let endpoint = this.$store.getters.getAppConfigFlag('useStagingStack') ? process.env.VUE_APP_UPLOAD_ENDPOINT_B : process.env.VUE_APP_UPLOAD_ENDPOINT
      let getUrl = endpoint + lambdaPath

      return axios
        .get(getUrl, {
          headers: { 'Authorization': this.$store.getters.getBearerToken },
          params: { 'foldername': 'points' }
        })
        .then(res => {
          res.data.file_folders.forEach(element => {
            availableLayers.push({ fileName: element, requestedForDelete: false })
          })
          this.customerUploads = availableLayers
        })
        .catch((e) => {
          this.$logger.message(`{"populateUploadsList":"There was an error: ${e}"}`, 'error')
        })
    },
    deleteUpload (upload) {
      this.deletionInProgress = true
      upload.requestedForDelete = true
      let lambdaPath = '/Prod/DeleteCustomerUpload'
      let endpoint = this.$store.getters.getAppConfigFlag('useStagingStack') ? process.env.VUE_APP_UPLOAD_ENDPOINT_B : process.env.VUE_APP_UPLOAD_ENDPOINT
      let url = endpoint + lambdaPath

      return axios
        .delete(url, {
          headers: { 'Authorization': this.$store.getters.getBearerToken },
          params: { 'filetodelete': upload.fileName }
        })
        .then(() => {
          this.populateUploadsList()
          this.deletionInProgress = false
        })
        .catch((e) => {
          this.$logger.message(`{"deleteUpload":"There was an error when deleting a customer upload: ${e}"}`, 'error')
          this.deletionInProgress = false
          upload.requestedForDelete = false
          this.populateUploadsList()
        })
    },
    configValid () {
      try {
        if (!this.config) return false
        JSON.parse(this.config)
        return true
      } catch (e) {
        this.$logger.message(`{"configValid":"Error in configValid: ${e}, ${this.config}"}`, 'debug')
        return false
      }
    },
    async getConfig () {
      this.buttonGetConfig.isFetchingDataOnRequest = true
      let getUrl = process.env.VUE_APP_APP_CONFIG_ENDPOINT + '/Prod'
      try {
        this.appConfigFeatureFlags = await axios
          .get(getUrl)
          .then(res => {
            if (res.data.config.featureFlags.length) {
              return res.data.config.featureFlags
            }
            throw new Error('No feature flags found')
          })
        this.$store.commit('setAppConfigFeatureFlags', this.appConfigFeatureFlags)
      } catch (e) {
        this.$logger.message(`{"getConfig":"There was an error: ${e}"}`, 'error')
      }
      this.buttonGetConfig.isFetchingDataOnRequest = false
    },
    async postSet () {
      this.button.isFetchingDataOnRequest = true
      let setUrl = process.env.VUE_APP_APP_CONFIG_ENDPOINT + '/Prod/CreateConfig'
      let data = '{"featureFlags":' + this.config.replace(/(\r\n|\n|\r|\s)/g, '') + '}'
      this.$logger.message(`{"postSet":"start postset: ${setUrl}"}`, 'debug')
      try {
        await axios
          .post(setUrl, data, {
            headers: {
              'Authorization': this.$store.getters.getBearerToken
            }
          })
          .then(res => {
            this.getConfig()
          })
      } catch (e) {
        this.$logger.message(`{"postSet":"There was an error: ${e}"}`, 'error')
        this.button.state = 'btn-failure'
      }
      this.$logger.message('{"postSet":"Done deploy"}', 'info')
      this.button.isFetchingDataOnRequest = false
      this.button.state = 'btn-success'
      return true
    },
    parseConfig (conf) {
      this.config = JSON.stringify(conf, null, 4)
    }
  },
  mounted () {
    this.parseConfig(this.$store.getters.getAppConfigs)
  },
  computed: {
    isConfigValid () {
      return this.configValid()
    }
  },
  watch: {
    '$store.state.resetModal': function () {
      if (this.$store.getters.getResetModal) {
        this.showInfo = false
        this.config = this.$store.appConfigFeatureFlags
        this.errorMessage = ''
        this.message = null
        this.button = { info: 'Get data', isFetchingDataOnRequest: false, state: 'btn-success' }
        this.$emit('handle-config-selection-reset', 'configSelection')
      }
    }
  }
}
</script>

<style scoped>

.label {
  /* ... */
  /* Show an ellipsis if the text takes more than one line */
  margin-left: 5px;
  text-overflow: ellipsis;
  white-space: nowrap;
  margin-right: 15px;
}

.deleteSetButton {
  margin: 0.2rem;
}

.setTitle {
  position: relative;
  top: -0.8em;
}

.customerUpload {
  margin: 0.8rem;
  float: left;
  border: 0.5px solid darkgray;
}

.uploadLabel {
  background-color: white;
  margin-left: 0.4rem;
  margin-right: 0.4rem;
}

.btn-failure {
  background-color: rgb(255, 0, 0);
  border-color: white;
}
</style>
