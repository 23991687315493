<template>
  <b-tab id="locationsTab" title="Location" @click="selectHexagons">
   <div id="areaSelection" style="margin-bottom: 10px; display: inline-block;" >
     <b-button-group>
       <b-button variant="outline-info" :pressed.sync="selectByHexagons" id="hideSelectByHexagons" @click="showMapContainer">
         Map
       </b-button>
       <b-button variant="outline-info" :pressed.sync="selectByCountry" id="selectByCountry" @click="showCountryDropdown">
         Country
       </b-button>
       <b-button v-if="this.$store.getters.getAppConfigFlag('useStagingStack')"  variant="outline-info" :pressed.sync="selectByPolygon" id="selectByPolygon" @click="showPolygonOptions">
         Polygon
       </b-button>
     </b-button-group>
   </div>
    <div id="dateRange" style=" display: flex; place-content: center;margin-bottom: 15px;">
      <div class="datePicker" style="z-index: 999; margin-left: 20px; margin-right: 20px;">
        <vc-date-picker v-model="startDate" >
          <template v-slot="{ inputValue, togglePopover }">
            <div class="flex items-center">
              <button
                class="p-2 bg-blue-100 border border-blue-200 hover:bg-blue-200 text-blue-600 rounded-l
                              focus:bg-blue-500 focus:text-white focus:border-blue-500 focus:outline-none"
                @click="togglePopover()"
              >
                <v-icon name="calendar"/>
              </button>
              <input
                :value="inputValue"
                class="bg-white text-gray-700 w-full py-1 px-2 appearance-none border rounded-r focus:outline-none focus:border-blue-500"
                readonly
              />
            </div>
          </template>
        </vc-date-picker>
      </div>
      <div class="datePicker" style="z-index: 999; margin-left: 20px; margin-right: 20px;">
        <vc-date-picker v-model="endDate">
          <template v-slot="{ inputValue, togglePopover }">
            <div class="flex items-center">
              <button
                class="p-2 bg-blue-100 border border-blue-200 hover:bg-blue-200 text-blue-600 rounded-l
                            focus:bg-blue-500 focus:text-white focus:border-blue-500 focus:outline-none"
                @click="togglePopover()"
              >
                <v-icon name="calendar"/>
              </button>
              <input
                :value="inputValue"
                class="bg-white text-gray-700 w-full py-1 px-2 appearance-none border rounded-r focus:outline-none focus:border-blue-500"
                readonly
              />
            </div>
          </template>
        </vc-date-picker>
      </div>
    </div>
    <div id="byPolygon" v-show="selectByPolygon" style=" border: 1px solid rgba(0, 0, 0, 0.125); margin: 10px; padding: 10px;">
      <div id="polygonSelection" style="margin-bottom: 10px; display: inline-block;" >
        <b-button-group size="sm">
          <b-button :pressed.sync="selectByNewPolygon" id="selectByNewPolygon" @click="showNewPolygon">
            Upload New Polygon
          </b-button>
          <b-button :pressed.sync="selectByExistingPolygon" id="selectByExistingPolygon" @click="showExistingPolygon">
            Existing Polygon
          </b-button>
        </b-button-group>
      </div>
      <div style="margin-top: 10px"  v-show="selectByNewPolygon">
        <upload-data />
      </div>
      <div style="margin-top: 10px"  v-show="selectByExistingPolygon">
        <multiselect id="customerpolygonLayers"
                     label="name"
                     track-by="name"
                     placeholder="Select a layer"
                     open-direction="bottom"
                     :options="availablePolygonLayers"
        >
          <template slot="tag" slot-scope="{ option, remove }">
                <span class="custom__tag">
                  <span>{{ option.name }}</span>
                  <span class="custom__remove" @click="remove(option)">❌</span></span>
          </template>
          <template slot="clear" slot-scope="props">
            <div class="multiselect__clear"
                 @mousedown.prevent.stop="clearAll(props.search)"></div>
          </template>
          <span slot="noResult">Oops! No layers found.</span>
        </multiselect>
      </div>
    </div>
    <div id="byCountry" v-show="selectByCountry">
      <multiselect v-model="chosenCountries"
                   track-by="code"
                   placeholder="Select a country"
                   :preserve-search="true"
                   label="name"
     :multiple="true"
     :options="options"></multiselect>
   </div>
    <div class="layer-visibility-buttons" id="byAreaOfInterest" v-show="selectByHexagons"  >
      <b-button-group size="sm">
        <b-button class="btn-light layer-button" v-if="!showBBox" id="showBBox" @click="toggleBBoxVisibility">
          Show area
        </b-button>
        <b-button class="btn-light layer-button" v-if="showBBox" id="hideBBox" @click="toggleBBoxVisibility">
          Hide area
        </b-button>
        <b-button class="btn-light layer-button" v-if="!showHexagons" id="showHexagons" @click="toggleHexagonVisibility">
          Show hexagons
        </b-button>
        <b-button class="btn-light layer-button" v-if="showHexagons" id="hideHexagons" @click="toggleHexagonVisibility">
          Hide hexagons
        </b-button>
      </b-button-group>
    </div>
    <LocationSelectionMap id="locationSelectMap"
                          v-show="showMap && resizeWindow() && selectByHexagons"
                          @on-hexagon-update="updateSelectedHexagons"
                          @on-bbox-update="updateCentroid"
                          :hexagonResolution="hexagonResolution"
                          :showHexagons="showHexagons"
                          :showBBox="showBBox"/>
    <div class="fetch-location-container"  v-if="request.selectedHexes.length > 0">
      <h4 style="margin-top: 5px; display: inline-block"
          v-if="!isFetchingLocations && errorOnFetching">{{ errorWhenFetching }}</h4>
      <b-button style="align-self: flex-end;" class="btn-success"
                id="requestLocations" @click="requestLocations"
                :disabled="isFetchingLocations">
        Get locations
      </b-button>
    </div>
    <step-progress v-show="isFetchingLocations"
                   :steps="locationFetchSteps"
                   :activeStep="currentStep"
    />
  </b-tab>
</template>

<script>
import snowflake from '../mixins/snowflake'
import LocationSelectionMap from '@/components/LocationSelectionMap'
import * as lz from 'lz-string'
import StepProgress from '@/components/StepProgress'
import Multiselect from '../../node_modules/vue-multiselect/src'
import axios from 'axios'
import UploadData from './UploadExcel.vue'

export default {
  name: 'LocationsSelection',
  components: { LocationSelectionMap, StepProgress, Multiselect, UploadData },
  mixins: [snowflake],
  data () {
    return {
      renderComponent: true,
      showMap: false,
      showBBox: true,
      showHexagons: true,
      selectByCountry: false,
      selectByHexagons: true,
      selectByPolygon: false,
      selectByNewPolygon: false,
      selectByExistingPolygon: false,
      request: {
        selectedHexes: []
      },
      hexagonResolution: 3,
      isFetchingLocations: false,
      errorWhenFetching: 'Error',
      errorOnFetching: false,
      flyToCentroid: [24, 33],
      locationFetchSteps: [ 'Start query', 'Await result', 'Fetch result' ],
      currentStep: 0,
      timeStart: 0,
      chosenCountries: [],
      availablePolygonLayers: [],
      options: [{ code: 'UU', name: 'Unicorn Country' }]
    }
  },
  mounted () {
    this.selectHexagons()

    const lambdaPath = '/Prod/GetCountryListFromS3'
    let endpoint = this.$store.getters.getAppConfigFlag('useStagingStack') ? process.env.VUE_APP_LOCATIONS_ENDPOINT_B : process.env.VUE_APP_LOCATIONS_ENDPOINT

    const url = endpoint + lambdaPath
    const request = axios.create()

    request
      .get(url, {
        headers: { 'Authorization': this.$store.getters.getBearerToken }
      })
      .then(response => {
        let options = []
        response.data.trim().split('\n').map(countryField => {
          let country = countryField.split(';')
          const countryCode = country[0]
          const countryName = country[1]
          options.push({ 'code': countryCode, 'name': countryName.replaceAll('"', '') })
        })
        options.sort((c1, c2) => {
          if (c1.name < c2.name) { return -1 }
          if (c1.name > c2.name) { return 1 }
          return 0
        })
        this.options = options
      })
  },
  computed: {
    startDate: {
      get () {
        return new Date(this.$store.getters.getMinDate)
      },
      set (value) {
        this.$store.commit('setMinDate', value)
      }
    },
    endDate: {
      get () {
        return new Date(this.$store.getters.getMaxDate)
      },
      set (value) {
        this.$store.commit('setMaxDate', value)
      }
    }
  },
  methods: {
    resizeWindow () {
      setTimeout(() => {
        window.dispatchEvent(new Event('resize'))
      }, 2500)
      return true
    },
    updateCentroid (centroid) {
      this.flyToCentroid = centroid.geometry.coordinates
      this.$store.commit('setCenterFor', ['locationsMapCenter', this.flyToCentroid])
    },
    requestLocations () {
      this.$store.commit('setStartTimeLog', Date.now())
      this.timeStart = Date.now()
      this.errorOnFetching = false
      this.isFetchingLocations = true
      const countryCodes = this.chosenCountries.map((countries) => { return countries['code'] }).join(',')
      this.$store.commit('setTriggerDeckRedraw', false)
      this.$store.commit('setUserSelection', '')
      this.$store.commit('resetVisibleLayers')
      this.$store.dispatch('location/resetState')
      this.$store.commit('location/setHexagonResolution', 3)
      this.$store.commit('location/setResolutionToRequest', 3)
      const requestParamsLocationOrCountry = this.selectByCountry
        ? { countrycodes: countryCodes }
        : { basehexes: this.request.selectedHexes }

      this.selectByCountry ? this.$store.commit('location/setBaseSelection', ['baseCountries', countryCodes]) : this.$store.commit('location/setBaseSelection', ['baseHexes', this.request.selectedHexes])

      const requestParamsStopsForSelectedDateRange = process.env.VUE_APP_ENV === 'mock' || process.env.VUE_APP_ENV === 'dev'
        ? { ...requestParamsLocationOrCountry, mindate: '2017-01-01', maxdate: this.$store.getters.getMaxDate }
        : { ...requestParamsLocationOrCountry, mindate: this.$store.getters.getMinDate, maxdate: this.$store.getters.getMaxDate }
      this.$store.commit('location/setLocationRequestObject', requestParamsStopsForSelectedDateRange)

      const locations = this.getQueryIdForLocations('locations', { ...requestParamsStopsForSelectedDateRange, sqlfilename: 'LOCATIONS' })

      const durationDistributionId = this.getQueryIdForLocations('durationDistribution',
        { ...requestParamsStopsForSelectedDateRange, sqlfilename: 'DURATION_DISTRIBUTION' })
      const startTimeDistributionId = this.getQueryIdForLocations('startHourDistribution',
        { ...requestParamsStopsForSelectedDateRange, sqlfilename: 'START_HOUR_DISTRIBUTION' })
      const minMaxRanges = this.getQueryIdForLocations('minMaxRanges',
        { ...requestParamsStopsForSelectedDateRange, sqlfilename: 'MIN_MAX_RANGES' })
      this.currentStep++

      Promise.all([locations, durationDistributionId, startTimeDistributionId, minMaxRanges])
        .then(() => {
          const durationDistributionState = this.getStatusForLocationQuery(this.$store.getters.getQueryIdFor('durationDistribution'))
          const startTimeDistributionState = this.getStatusForLocationQuery(this.$store.getters.getQueryIdFor('startHourDistribution'))
          return Promise.all([durationDistributionState, startTimeDistributionState])
        })
        .then(() => {
          this.currentStep++
          const durationDistribution = this.getLocationDataForQueryId(this.$store.getters.getQueryIdFor('durationDistribution'))
          return Promise.all([durationDistribution])
        })
        .then((response) => {
          this.$store.commit('location/setDurationGraphDataStructure', response[0].data)
          return true
        })
        .then(() => {
          const startHourDistribution = this.getLocationDataForQueryId(this.$store.getters.getQueryIdFor('startHourDistribution'))
          return Promise.all([startHourDistribution])
        })
        .then(response => {
          this.$store.commit('location/setStartTimeGraphDataStructure', response[0].data)
          return true
        })
        .then(() => {
          const locationQueryState = this.getStatusForLocationQuery(this.$store.getters.getQueryIdFor('locations'))

          return Promise.all([locationQueryState])
        })
        .then(() => {
          const minMaxRangesState = this.getStatusForLocationQuery(this.$store.getters.getQueryIdFor('minMaxRanges'))
          return Promise.all([minMaxRangesState])
        })
        .then(() => {
          this.currentStep++
          const minmaxData = this.getLocationDataForQueryId(this.$store.getters.getQueryIdFor('minMaxRanges'))
          return Promise.all([minmaxData])
        })
        .then((response) => {
          this.$store.commit('location/setMinMaxDuration', [response[0].data[0].min_duration_in_set, response[0].data[0].max_duration_in_set])
          this.$store.commit('location/setInOdoMinMax', [response[0].data[0].min_ingoing_distance_in_set, response[0].data[0].max_ingoing_distance_in_set])
          this.$store.commit('location/setOutOdoMinMax', [response[0].data[0].min_outgoing_distance_in_set, response[0].data[0].max_outgoing_distance_in_set])
          this.$store.commit('location/setInDurMinMax', [response[0].data[0].min_ingoing_duration_in_set, response[0].data[0].max_ingoing_duration_in_set])
          this.$store.commit('location/setOutDurMinMax', [response[0].data[0].min_outgoing_duration_in_set, response[0].data[0].max_outgoing_duration_in_set])
          this.$store.commit('location/setMinDate', response[0].data[0].min_date_in_set)
          this.$store.commit('location/setMaxDate', response[0].data[0].max_date_in_set)
          return true
        })
        .then(() => {
          const locationData = this.getLocationDataForQueryId(this.$store.getters.getQueryIdFor('locations'))

          return Promise.all([locationData])
        })
        .then((response) => {
          const dataObj = this.$store.dispatch('location/setLocationLayerAsync', response[0].data)
          this.$store.commit('setTriggerDeckRedraw', true)
          this.$emit('handle-on-data-response')
          this.$emit('handle-on-data-loaded')
          return Promise.all([dataObj])
        })
        .then(() => {
          const graphSet = this.$store.dispatch('location/setCountDistributionGraphAsync')

          return Promise.all([graphSet])
        })
        .catch(error => {
          this.$logger.message(`{"requestLocationAnalysis":"Error in requestLocations: ${error}"}`, 'error')
          this.errorOnFetching = true
          try {
            this.errorWhenFetching = error.response.data.message
          } catch {
            this.errorWhenFetching = error
          }
        })
        .finally(() => {
          this.currentStep = 0
          this.isFetchingLocations = false
          let endTime = Date.now()
          this.$logger.message(`{"requestLocationAnalysis":{"message":"Request locations finished","time":"${endTime - this.timeStart}"}}`, 'info')
        })
        .then(() => {
          const graphSet = this.$store.dispatch('location/setCountDistributionGraphAsync')
          return Promise.all([graphSet])
        })
    },
    updateSelectedHexagons (hexagons) {
      const hexString = hexagons.join(',')
      const lzCompressed = lz.compressToEncodedURIComponent(hexString)
      this.request.selectedHexes = lzCompressed
      this.errorOnFetching = false
    },
    selectHexagons () {
      this.showMap = true
    },
    toggleHexagonVisibility () {
      if (this.showHexagons) {
        this.showHexagons = false
      } else {
        this.showHexagons = true
      }
    },
    toggleBBoxVisibility () {
      if (this.showBBox) {
        this.showBBox = false
      } else {
        this.showBBox = true
      }
    },
    showMapContainer () {
      this.selectByHexagons = true
      this.selectByCountry = false
      this.selectByPolygon = false
      this.selectByNewPolygon = false
      this.selectByExistingPolygon = false
    },
    showCountryDropdown () {
      this.selectByCountry = true
      this.selectByHexagons = false
      this.selectByPolygon = false
      this.selectByNewPolygon = false
      this.selectByExistingPolygon = false
    },
    showPolygonOptions () {
      this.selectByCountry = false
      this.selectByHexagons = false
      this.selectByPolygon = true
      this.selectByNewPolygon = false
      this.selectByExistingPolygon = false
    },
    showNewPolygon () {
      this.selectByCountry = false
      this.selectByHexagons = false
      this.selectByPolygon = true
      this.selectByNewPolygon = true
      this.selectByExistingPolygon = false
    },
    showExistingPolygon () {
      this.selectByCountry = false
      this.selectByHexagons = false
      this.selectByPolygon = true
      this.selectByNewPolygon = false
      this.selectByExistingPolygon = true
    }
  }
}
</script>

<style scoped>
#locationSelectMap {
  z-index: 20;
  display: flex;
  height: 500px;
  position: relative;
  overflow: hidden;
}

.layer-button {
  margin-right: 5px;
}

.layer-visibility-buttons {
  position: absolute;
  z-index: 150;
  margin: 20px;
}
.fetch-location-container {
  margin-top: 10px;
  margin-bottom: 20px;
  display: flex;
  flex-direction: column-reverse;
}
#byCountry {
  height: 25vh;
  width: 100%;
}

</style>
<style src="../../node_modules/vue-multiselect/dist/vue-multiselect.min.css"></style>
