<template>
  <div>
    <v-icon class="tabButton" :id="tabId" :name="iconName" :color="color"/>
  </div>
</template>
<script>
export default {
  name: 'SideMenuTab',
  props: {
    color: {
      type: String,
      required: true
    },
    tabId: {
      type: String,
      required: true
    },
    iconName: {
      type: String,
      required: true
    }
  }
}
</script>

<style scoped>
  .tabButton {
    height: 25px;
    width: 25px;
  }
</style>
