<template>
  <div>
    <b-card>
      <div id="selectUpload">
        <b-form-file accept=".xlsx, .xls"
                     :state="Boolean(excelAsJsonString)"
                     ref="file"
                     @input="uploadFile"
                     placeholder="Browse or drop a file here."
                     drop-placeholder="Drop file here."
                     class="mb-2"></b-form-file>
      </div>
      <b-card v-if="excelAsJsonString.length > 0">
        <div id="columnNames" >
          <div v-for="(i, headerName) in columnsForUpload"
               :key="headerName">
            <strong>-- Column for {{ headerName }}: --</strong>
            <b-form-select class="select-column"
                           style="width:10vw;"
                           v-model="columnsForUpload[headerName]"
                           size="sm"
                           :options="columnsUploaded">
            </b-form-select>
          </div>
        </div>
        <div id="extraColumns">
          <strong>-- Select one or more columns (optional): --</strong>
          <b-form-select class="select-column"
                         v-model="extraColumns"
                         size="sm"
                         multiple
                         :options="columnsUploaded">
          </b-form-select>
        </div>
        <div id="fileName">
          <strong>-- Store locations at: --</strong>
          <b-form-input id="customSetNameInputField"
                        v-model="customSetName"
                        placeholder="Name your dataset"
                        :state="isValid"
          ></b-form-input>
          <i v-if="!isValid">Check the file name for unique name, spaces or special characters.</i>
        </div>
      </b-card>
      <div id="selection-footer"
           v-show="excelAsJsonString.length > 0 && !postedFile && !fileUploadSuccess">
        <b-button variant="success"
                  style="margin-right:1.5vw;"
                  @click="submitFile"
                  :pressed="postedFile"
                  :disabled='excelAsJsonString.length === 0 || !columnHeadersAreSet || postedFile || !isValid'>
          Upload locations
        </b-button>
      </div>
      <div id="selection-footer-after-upload"
           v-if="submittedFile">
        <b-alert :show="fileError"
                 variant="danger">
          {{ errorResponse }}
        </b-alert>
        <b-alert :show="fileUploadSuccess"
                 variant="success">
          Your file was successfully uploaded!
        </b-alert>
        <b-button v-if="fileUploadSuccess"
                  variant="outline-success"
                  style="margin-right:1.5vw;"
                  @click="createNewUpload">
          -- New upload --
        </b-button>
      </div>
    </b-card>
  </div>
</template>

<script>
import XLSX from 'xlsx'
import axios from 'axios'
import * as lz from 'lz-string'

function initialState () {
  return {
    customSetName: '',
    excelAsJsonString: '',
    columnsForUpload: {
      longitude: null,
      latitude: null,
      function: null
    },
    extraColumns: [],
    columnsUploaded: [],
    file: null,
    fileError: false,
    errorResponse: '',
    submittedFile: false,
    postedFile: false,
    fileUploadSuccess: false
  }
}
export default {
  name: 'UploadData',
  data () {
    return initialState()
  },
  computed: {
    fetchAvailableLayerList () {
      return this.$store.getters['getAvailableLayers']
    },
    isValid () {
      if (this.customSetName.length > 0 && !this.fetchAvailableLayerList.some(layer => layer.name.toLowerCase() === this.customSetName.toLowerCase())) {
        const regExpStr = '(^[a-zA-Z0-9_\\-]{2,1024}$)'
        const result = new RegExp(regExpStr, 'g').test(this.customSetName)
        return result
      } else {
        return false
      }
    },
    columnHeadersAreSet () {
      if (this.customSetName === '') return false
      const columnHeadersAreSet = Object.values(this.columnsForUpload).every(colValue => colValue)
      return columnHeadersAreSet
    }
  },
  methods: {
    async uploadLocationData () {
      this.submittedFile = true
      this.postedFile = true
      this.fileError = false
      this.fileUploadSuccess = false
      const lambdaPath = '/Prod/PostLocationFile'
      let endpoint = this.$store.getters.getAppConfigFlag('useStagingStack')
        ? process.env.VUE_APP_UPLOAD_ENDPOINT_B
        : process.env.VUE_APP_UPLOAD_ENDPOINT

      const url = endpoint + lambdaPath
      const request = axios.create()
      let queryParams = {
        longitudename: lz.compressToEncodedURIComponent(this.columnsForUpload.longitude),
        latitudename: lz.compressToEncodedURIComponent(this.columnsForUpload.latitude),
        functionname: lz.compressToEncodedURIComponent(this.columnsForUpload.function),
        filename: lz.compressToEncodedURIComponent(this.customSetName)
      }
      if (this.extraColumns.length > 0) {
        queryParams = {
          ...queryParams,
          othercolumns: lz.compressToEncodedURIComponent(this.extraColumns.join(','))
        }
      }
      return request
        .post(url, lz.compressToEncodedURIComponent(this.excelAsJsonString), {
          headers: {
            Authorization: this.$store.getters.getBearerToken
          },
          params: queryParams
        })
        .then((response) => {
          this.$logger.message(`{"uploadLocationData":"UploadExcel.vue postSet: ${response.data.message}"}`, 'info')
          this.$root.$emit('populateLayerListEvent')
          this.fileUploadSuccess = true
        })
        .catch(err => {
          this.$logger.message(`{"uploadLocationData":"UploadExcel.vue postSet: ${err.response.data.message}"}`, 'error')
          this.fileError = true
          this.errorResponse = 'Uploading map data failed! ' + err.response.data.message
        })
    },
    uploadFile () {
      let fileReader = new FileReader()
      fileReader.readAsArrayBuffer(this.$refs.file.files[0])
      fileReader.onload = function (e) {
        let arrayBuffer = fileReader.result
        let data = new Uint8Array(arrayBuffer)
        let arr = []
        for (let i = 0; i !== data.length; ++i) {
          arr[i] = String.fromCharCode(data[i])
        }
        let binaryStr = arr.join('')
        let workbook = XLSX.read(binaryStr, { type: 'binary' })
        let sheetName = workbook.SheetNames[0]
        let worksheet = workbook.Sheets[sheetName]
        this.jsonObj = XLSX.utils.sheet_to_json(worksheet, { raw: true })
        this.excelAsJsonString = JSON.stringify(this.jsonObj)
        this.columnsUploaded = Object.keys(this.jsonObj[0])
      }.bind(this)
      this.customSetName = this.$refs.file.files[0].name.replace(/(\.\w+)+$/, '')
    },
    submitFile () {
      // post to api with column names and the json data
      this.uploadLocationData()
      this.postedFile = false
    },
    createNewUpload () {
      this.submittedFile = false
      this.$refs.file.value = null
      this.clearAllData()
    },
    clearAllData () {
      Object.assign(this.$data, initialState())
    }
  },
  watch: {
    columnsForUpload: {
      handler (newVal, oldVal) {
        this.fileError = false
      },
      deep: true
    }
  }
}

</script>

<style scoped>
#selectUpload {
  width: 100%;
  margin-top: 0.1vh;
  display: inline-block;
}
.select-column {
  margin-right: 10px;
}
#selection-footer {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  margin-top: 1vh;
}
#columnNames {
  display: inline-flex;
  flex-flow: row;
}
#extraColumns {
  margin-top: 10px;
  display: inline-flex;
  flex-flow: column wrap;
  justify-content: flex-end;
  width: 100%;
}
#fileName {
  margin-top: 20px;
  display: inline-flex;
  flex-flow: column wrap;
  justify-content: flex-end;
  width: 100%;
}
</style>
