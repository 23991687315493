const textPaint = {
  'text-halo-width': 4,
  'text-halo-blur': 2,
  'text-halo-color': 'rgba(230, 230, 230, 1)'
}

const operatorText = ['step', ['zoom'],
  ['get', 'name'],
  14, ['case', ['has', 'operator'],
    ['concat', ['get', 'name'], ' (', ['get', 'operator'], ')'],
    ['get', 'name']
  ]
]

const undergroundP = ['any',
  ['==', ['get', 'location'], 'underground'],
  ['==', ['get', 'location'], 'underwater'],
  ['==', ['get', 'tunnel'], true],
  ['all', // Power cables are underground by default
    ['==', ['get', 'type'], 'cable'],
    ['==', ['get', 'location'], '']
  ]
]

const font = ['Montserrat Regular']

export { textPaint, operatorText, undergroundP, font }
