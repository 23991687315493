<template>
  <div id="graphPanel">
    <div id="graphSelection">
      <div id="graphs" v-if="this.$store.getters.getAppConfigFlag('isMarketUser')">
        <div class="graphChoiceButtons"
             v-for="choices in graphOptions "
             :key="choices.value">
          <label class="checkboxContainer" v-if="choices.featureOn && choices.marketOn">
            <input
              class="checkboxInput"
              type="checkbox"
              :checked="choices.checked"
              @change="onCheckboxChange(choices.value)"
            />
            <span class="switch"></span>
            <span class="label">{{ choices.text }}</span>
          </label>
        </div>
      </div>
      <div id="graphs" v-else>
        <div class="graphChoiceButtons"
             v-for="choices in graphOptions"
             :key="choices.value">
          <label class="checkboxContainer" v-if="choices.featureOn">
            <input
              class="checkboxInput"
              type="checkbox"
              :checked="choices.checked"
              @change="onCheckboxChange(choices.value)"
            />
            <span class="switch"></span>
            <span class="label">{{ choices.text }}</span>
          </label>
        </div>
      </div>
    </div>
    <div id="exportButtonDiv">
      <export-button :selectedGraphs="selectedGraphsForExport"></export-button>
    </div>
  </div>
</template>

<script>
import ExportButton from '@/components/ExportButton.vue'

export default {
  name: 'GraphPanel',
  components: { ExportButton },
  data () {
    return {
      selectedGraphs: [],
      graphOptions: [
        { text: 'Daily mileage', default_text: 'Daily mileage', value: 'odometerchart', stateFunction: 'dailyAggregates', tabName: 'Daily aggregates', checked: false, visible: false, featureOn: true, marketOn: false },
        { text: 'Daily fuel', default_text: 'Daily fuel', value: 'fuelchart', stateFunction: 'dailyAggregates', tabName: 'Daily aggregates', checked: false, visible: false, featureOn: true, marketOn: false },
        { text: 'Daily mileage boxplot', default_text: 'Daily mileage boxplot', value: 'dailyodometerboxplot', stateFunction: 'dailyOdometerBoxplot', tabName: 'Daily mileage boxplot', checked: false, visible: false, featureOn: true, marketOn: false },
        { text: 'Daily fuel boxplot', default_text: 'Daily fuel boxplot', value: 'dailyfuelboxplot', stateFunction: 'dailyFuelBoxplot', tabName: 'Daily fuel boxplot', checked: false, visible: false, featureOn: true, marketOn: false },
        { text: 'Distance between stops boxplot', default_text: 'Distance between stops boxplot', value: 'odometerboxplot', stateFunction: 'odometerBoxplot', tabName: 'Distance between stops boxplot', checked: false, visible: false, featureOn: true, marketOn: true },
        { text: 'Fuel between stops boxplot', default_text: 'Fuel between stops boxplot', value: 'fuelboxplot', stateFunction: 'fuelBoxplot', tabName: 'Fuel between stops boxplot', checked: false, visible: false, featureOn: true, marketOn: false },
        { text: 'Ongoing stops, hourly', default_text: 'Ongoing stops, hourly', value: 'hourlyboxplot', stateFunction: 'hourlyBoxplot', tabName: 'Hourly ongoing stops', checked: false, visible: false, featureOn: true, marketOn: false },
        { text: 'Daily Ongoing stops, hourly', default_text: 'Daily Ongoing stops, hourly', value: 'dailyHourlyAggregates', stateFunction: 'dailyHourlyAggregates', tabName: 'Daily Hourly ongoing stops', checked: false, visible: false, featureOn: true, marketOn: false },
        { text: 'Daily hourly stop heatmap', default_text: 'Daily hourly stop heatmap', value: 'dailyHourlyAggregatesHeatmap', stateFunction: 'dailyHourlyAggregates', tabName: 'Daily hourly stop heatmap', checked: false, visible: false, featureOn: true, marketOn: true }
      ]
    }
  },
  methods: {
    onCheckboxChange (graph) {
      for (var i = 0; i < this.graphOptions.length; i++) {
        if (this.graphOptions[i].value === graph) {
          if (this.graphOptions[i].visible) {
            for (let i = 0; i < this.selectedGraphs.length; i++) {
              if (this.selectedGraphs[i] === graph) {
                this.selectedGraphs.splice(i, 1)
              }
            }
            this.graphOptions[i].visible = false
            this.$logger.message(`{"onCheckboxChange":[{"component":"GraphPanel"}, {"state":"disabled"},{"name":"${graph}"}]}`, 'info')
          } else {
            this.graphOptions[i].visible = true
            this.selectedGraphs.push(graph)
            this.$logger.message(`{"onCheckboxChange":[{"component":"GraphPanel"}, {"state":"enable"},{"name":"${graph}"}]}`, 'info')
          }
        }
      }
      this.$emit('update-graph-options', this.graphOptions)
      this.$emit('update-graph-selection', this.selectedGraphs)
    },
    getGraphTitle (graph) {
      return this.graphOptions.find(item => item.value === graph).text
    },
    getGraphTabName (graph) {
      return this.graphOptions.find(item => item.value === graph).tabName
    }
  },
  computed: {
    selectedGraphsForExport () {
      let graphsForExport = []
      this.selectedGraphs.forEach(graphName => {
        let graphObj = {}
        graphObj['tabTitle'] = this.getGraphTabName(graphName)
        graphObj['graphVariable'] = this.graphOptions.find(item => item.text === this.getGraphTitle(graphName)).stateFunction
        graphsForExport.push(graphObj)
      })
      return graphsForExport
    }
  },
  watch: {
    '$store.state.chassis.graphDataStructure.hourlyBoxplot': function () {
      if (this.$store.getters['chassis/getSelectedH3CellIndex'].length > 0) {
        for (let i = 0; i < this.graphOptions.length; i++) {
          this.graphOptions[i].text = this.graphOptions[i].default_text + ' @' +
            this.$store.getters['chassis/getSelectedH3CellIndex'].join(', ')
        }
      } else {
        for (let i = 0; i < this.graphOptions.length; i++) {
          this.graphOptions[i].text = this.graphOptions[i].default_text
        }
      }
    }
  }
}
</script>

<style scoped>
#graphPanel {
  display: flex;
  width: 100%;
  justify-content: flex-start;
  flex-direction: column;
}

#graphSelection {
  display: flex;
  justify-content: center;
}

#graphs {
  border-radius: 2px;
  font-family: 'Montserrat', sans-serif;
  background: #f8f9fa;
  color: #212529;
  margin-top: 10px;
  margin-bottom: 14px;
  width: 70%;
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  padding: 8px 4px;
}

.graphChoiceButtons {
  font-size: small;
  padding: 8px;
}

.graphChoiceButtons:empty {
  display: none;
}
.checkboxContainer {
  cursor: pointer;
  display: flex;
  align-items: center;
  margin: 0;
  color: #1a202c;
}

.checkboxInput {
  display: none;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border-width: 0;
}

.switch {
  --switch-container-width: 35px;
  --switch-size: 20px;
}

.switch {
  --switch-container-width: 35px;
  --switch-size: 20px;

  /* Vertically center the inner circle */
  display: flex;
  align-items: center;
  position: relative;
  height: var(--switch-size);
  flex-basis: var(--switch-container-width);
  /* Make the container element rounded */
  border-radius: var(--switch-size);
  background-color: #e2e8f0;
}

.switch::before {
  content: "";
  /* Move a little bit the inner circle to the right */
  left: 1px;
  height: calc(var(--switch-size) - 4px);
  width: calc(var(--switch-size) - 4px);
  /* Make the inner circle fully rounded */
  border-radius: 9999px;
  background-color: white;
}

.checkboxInput:checked + .switch {
  /* Teal background */
  background-color: #4fd1c5;
}

.checkboxInput:checked + .switch::before {
  border-color: #4fd1c5;
  /* Move the inner circle to the right */
  transform: translateX(
    calc(var(--switch-container-width) - var(--switch-size))
  );
}

.switch {
  /* ... */
  transition: background-color 0.25s ease-in-out;
}

.switch::before {
  /* ... */
  transition: transform 0.375s ease-in-out;
}

.switch {
  /* ... */

  --light-gray: #e2e8f0;
  --gray: #cbd5e0;
  --dark-gray: #a0aec0;
  --teal: #4fd1c5;
  --dark-teal: #319795;

  /* ... */
  background-color: var(--light-gray);
}

.checkboxInput:checked + .switch {
  background-color: var(--teal);
}

.checkboxInput:checked + .switch::before {
  border-color: var(--teal);
  /* ... */
}

.switch::before {
  /* ... */
  border: 2px solid var(--light-gray);
}

.checkboxInput:focus + .switch::before {
  border-color: var(--dark-gray);
}

.checkboxInput:focus:checked + .switch::before {
  border-color: var(--dark-teal);
}
.checkboxInput:disabled + .switch {
  background-color: var(--gray);
}

.checkboxInput:disabled + .switch::before {
  background-color: var(--dark-gray);
  border-color: var(--dark-gray);
}

.switch {
  /* ... */
  /* In case the label gets long, the toggle shouldn't shrink. */
  flex-shrink: 0;
}

.label {
  /* ... */
  /* Show an ellipsis if the text takes more than one line */
  margin-left: 5px;
  text-overflow: ellipsis;
  white-space: nowrap;
  margin-right: 15px;
}

#exportButtonDiv {
  font-size: small;
  display: flex;
  justify-content: flex-end;
  margin-right: 15%;
}

</style>
