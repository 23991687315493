<template>
  <div>
    <div id="burger"
         :class="{ 'active' : isBurgerActive }"
         @click.prevent="toggleMenu">
      <button type="button" class="burger-button" title="Menu">
        <span class="hidden">Toggle menu</span>
        <span class="burger-bar burger-bar--1"></span>
        <span class="burger-bar burger-bar--2"></span>
        <span class="burger-bar burger-bar--3"></span>
        <span class="burger-bar burger-bar--4"></span>
      </button>
    </div>
    <div id="side-menu-location-analytics" class="sidemenu">
      <div id="side-menu-location-analytics-backdrop"
           class="sidemenu-backdrop"
           @click="closeSideMenuPanel"
           v-show="isMenuOpen"></div>
      <transition name="slide">
        <div v-show="isMenuOpen"
              class="sidemenu-panel">
          <div id="sidemenu-set-container">
            <data-set-selector/>
            <div id="btnContainer">
              <button class="dataButton"
                      @click="toggleUploadModal"
                      v-if="this.$store.getters.getAppConfigFlag('isAllowedToUploadGeoLayer')"
                      v-b-tooltip.hover
                      title="Upload Data">
                <v-icon class="dataButtonIcon" name="cloud-upload-alt" />
              </button>
            </div>
          </div>
          <div id="sidemenu-tab-container">
            <side-menu-tab @click.native="selectTab('tabLocationFilter')"
                           tabId="tabLocationfilter"
                           iconName="filter"
                           :color="selectedTab === 'tabLocationFilter' ? 'white' :'#3E6990'"/>
            <side-menu-tab @click.native="selectTab('tabGraph')"
                           tabId="tabGraph"
                           iconName="chart-line"
                           :color="selectedTab === 'tabGraph' ? 'white' :'#3E6990'"/>
            <side-menu-tab @click.native="selectTab('tabMapStyle')"
                           tabId="tabMapStyle"
                           iconName="map"
                           :color="selectedTab === 'tabMapStyle' ? 'white' :'#3E6990'"/>
            <side-menu-tab v-if="this.$store.getters.getAdminAccess"
                           @click.native="selectTab('tabAdmin')"
                           tabId="tabAdmin"
                           iconName="dragon"
                           :color="selectedTab === 'tabAdmin' ? 'white' :'#3E6990'"/>
          </div>
          <div id="sidemenu-panel-container">
            <filter-location-panel v-show="selectedTab === 'tabLocationFilter'"
                                   @handle-on-filter="handleOnFilter"
                                   @update-chassis-filter="handleUpdateChassis"
            />
            <graph-panel v-show="selectedTab === 'tabGraph'"
                         @update-graph-options="updateGraphOptions"
                         @update-graph-selection="updateGraphSelection"/>
            <map-style-panel v-show="selectedTab === 'tabMapStyle'"
                             @update-map-display-options="updateMapDisplayOptions"
                             @select-map-style="selectMapStyle"
                             @fetch-customer-data="addMapLayer"
                             @remove-map-layer="removeMapLayer"/>
            <admin-panel v-if="this.$store.getters.getAdminAccess"
                         v-show="selectedTab === 'tabAdmin'"
                         @select-map-style="selectAdmin"/>
          </div>
        </div>
      </transition>
    </div>
    <upload-file :isVisible="isUploadModalActive"
                 @close-modal="toggleUploadModal">
    </upload-file>
  </div>
</template>
<script>
import SideMenuTab from '../../components/SideMenuTab.vue'
import DataSetSelector from '../../components/DataSetSelector.vue'
import MapStylePanel from '@/views/ClassifiedLocationAnalytics/components/MapStylePanel.vue'
import FilterLocationPanel from '@/views/ClassifiedLocationAnalytics/FilterLocationPanel.vue'
import AdminPanel from '../AdminPanel.vue'
import GraphPanel from '@/views/ClassifiedLocationAnalytics/GraphPanel'
import UploadFile from '@/components/UploadFile'

export default {
  components: {
    UploadFile,
    SideMenuTab,
    DataSetSelector,
    FilterLocationPanel,
    MapStylePanel,
    GraphPanel,
    AdminPanel },
  name: 'SideMenu',
  data () {
    return {
      isMenuOpen: false,
      isBurgerActive: false,
      selectedTab: 'tabLocationFilter',
      isUploadModalActive: false
    }
  },
  methods: {
    updateMapDisplayOptions (optionUpdate) {
      this.$emit('update-map-display-options', optionUpdate)
    },
    handleUpdateChassis (chassisList) {
      this.$emit('update-chassis-filter', chassisList)
    },
    toggleUploadModal () {
      this.isUploadModalActive = !this.isUploadModalActive
    },
    toggleMenu () {
      if (this.isBurgerActive && this.isMenuOpen) {
        this.isBurgerActive = false
        this.isMenuOpen = false
      } else {
        this.isBurgerActive = true
        this.isMenuOpen = true
      }
    },

    closeSideMenuPanel () {
      this.isMenuOpen = false
      this.isBurgerActive = false
    },

    selectTab (tabName) {
      this.selectedTab = tabName
    },

    handleDeckLayerChanged () {
      this.$emit('handle-deck-layer-changed')
    },

    handleOnFilter () {
      this.$emit('handle-on-filter')
    },

    updateGraphOptions (options) {
      this.$emit('update-graph-options', options)
    },

    updateGraphSelection (selection) {
      this.$emit('update-graph-selection', selection)
    },

    selectMapStyle (mapStyle) {
      this.$emit('select-map-style', mapStyle)
    },
    addMapLayer (mapLayerData) {
      this.$emit('add-map-layer', mapLayerData)
    },
    removeMapLayer (mapLayerName) {
      this.$emit('remove-map-layer', mapLayerName)
    },
    selectAdmin (admin) {
      this.$emit('select-admin', admin)
    }
  }
}
</script>

<style scoped>
  .dataButton {
    background-color: #f8f9fa;
    border: none;
    height: 35px;
    width: 35px;
    border-radius: 5px;
    margin-right: 8.5%;
    margin-bottom: 10px;
  }

  .dataButtonIcon {
    height: 25px;
    width: 25px;
  }
  /* remove blue outline */
  button:focus {
    outline: 0;
  }

  #btnContainer {
    display: flex;
    justify-content: center;
    margin-left: 20px;
    margin-right: 20px;
  }
  #burger {
    position: fixed;
    z-index: 10;
  }

  .burger-button {
    position: relative;
    top: 4px;
    height: 30px;
    width: 32px;
    display: block;
    z-index: 2;
    border: 0;
    border-radius: 0;
    background-color: transparent;
    pointer-events: all;
    transition: transform .6s cubic-bezier(.165,.84,.44,1);
  }

  .burger-bar {
    background-color: #fff;
    position: absolute;
    top: 50%;
    right: 6px;
    left: 6px;
    height: 2px;
    width: auto;
    margin-top: -1px;
    transition: transform .6s cubic-bezier(.165,.84,.44,1),opacity .3s cubic-bezier(.165,.84,.44,1),background-color .6s cubic-bezier(.165,.84,.44,1);
  }

  .burger-bar--1 {
    -webkit-transform: translateY(-6px);
    transform: translateY(-6px);
  }

  .burger-bar--2 {
    transform-origin: 100% 50%;
    transform: scaleX(.8);
  }

  .burger-button:hover .burger-bar--2 {
    transform: scaleX(1);
  }

  .no-touchevents .burger-bar--2:hover {
    transform: scaleX(1);
  }

  .burger-bar--3 {
    transform: translateY(6px);
  }

  .burger-bar--4 {
    transform: translateY(6px);
  }

  #burger.active .burger-button {
    transform: rotate(-180deg);
  }

  #burger.active .burger-bar {
    background-color: #fff;
  }

  #burger.active .burger-bar--1 {
    transform: rotate(45deg)
  }

  #burger.active .burger-bar--2 {
    opacity: 0;
  }

  #burger.active .burger-bar--3 {
    transform: rotate(-45deg)
  }

  #burger.active .burger-bar--4 {
    transform: rotate(-45deg)
  }
  .slide-enter-active,
  .slide-leave-active
  {
    transition: transform 0.2s ease;
  }

  .slide-enter,
  .slide-leave-to {
    transform: translateX(-100%);
    transition: all 150ms ease-in 0s
  }

  .sidemenu-backdrop {
    background-color: rgba(0,0,0,.5);
    width: 100vw;
    height: 100vh;
    position: fixed;
    top: 0;
    left: 0;
    cursor: pointer;
  }

  .sidemenu-panel {
    overflow-y: auto;
    overflow-x: hidden;
    background-color: #130f40;
    position: fixed;
    left: 0;
    height: 92.1vh;
    z-index: 3;
    width: 37vw;
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
  }

  .hidden {
    visibility: hidden;
  }

  button {
    cursor: pointer;
  }

  #sidemenu-set-container {
    margin-top: 10px;
    margin-right: 20px;
    padding-top: 24px;
    width: 100%;
    height: 90px;
    display: flex;
    justify-content: right;
  }

  #sidemenu-tab-container {
    padding-top: 15px;
    width: 100%;
    height: 50px;
    display: flex;
    justify-content: space-evenly;
  }

  #sidemenu-panel-container {
    margin-top: 15px;
    width: 100%;
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
  }
</style>
