<template>
  <div>
    <side-menu v-if="allMapLayersLoaded > 0"
               @handle-deck-layer-changed="handleDeckLayerChanged"
               @handle-on-filter="handleOnFilter"
               @update-graph-options="updateGraphOptions"
               @update-graph-selection="updateGraphSelection"
               @select-map-style="selectMapStyle"
               @add-power-map-layer="addPowerLayer"
               @hide-power-map-layer="hidePowerLayer"/>
    <div id="BaseMap-div-chartWidget" v-if="graphDataFinishedLoading" :class="checkGraphWidgetSize()">
      <div v-show="selectedGraphs.includes('odometerchart')"
           class="vehicleChart"
           :class="checkGraphSize('odometerchart')"
           id="BaseMap-div-odometerchart">
        <div class="chartHeader">
          <button id="BaseMap-button-odometerchart-expand" v-if="enlargedChart !== 'odometerchart'" @click="handleClick('odometerchart')" class="expandCompressChartButton" title="Expand">
            <v-icon name="expand" class="expandChartButtonIcon"/>
          </button>
          <button id="BaseMap-button-odometerchart-compress" v-if="enlargedChart === 'odometerchart'" @click="closeLarge('odometerchart')" class="expandCompressChartButton" title="Compress">
            <v-icon name="compress" class="compressChartButtonIcon"/>
          </button>
        </div>
        <lineChart getStateFunction="chassis/getGraphDataStructure"
                   getColourScale="chassis/getGraphColorStructure"
                   :width="enlargedChart === 'odometerchart' ? chartWidthLarge : chartWidth"
                   :height="enlargedChart === 'odometerchart' ? chartHeightLarge : chartHeight"
                   :svgHeight="enlargedChart === 'odometerchart' ? chartSVGHeightLarge : chartSVGHeight"
                   :margin="enlargedChart === 'odometerchart' ? chartMarginLarge : chartMarginSmall"
                   :r="enlargedChart === 'odometerchart' ? chartCircleLarge : chartCircleSmall"
                   yAxisLabel="Mileage (km)"
                   xAxisLabel="Date"
                   :graphTitle="getGraphTitle('odometerchart')"
                   lineChartType="dailyAggregates"
                   yKey="odometer"
                   zAxisLabel="ChassisNumber"/>
      </div>
      <div v-show="selectedGraphs.includes('fuelchart')"
           class="vehicleChart"
           :class="checkGraphSize('fuelchart')"
           id="fuelchart">
        <div class="chartHeader">
          <button id="BaseMap-button-fuelchart-expand" v-if="enlargedChart !== 'fuelchart'" @click="handleClick('fuelchart')" class="expandCompressChartButton" title="Expand">
            <v-icon name="expand" class="expandChartButtonIcon"/>
          </button>
          <button id="BaseMap-button-fuelchart-compress" v-if="enlargedChart === 'fuelchart'" @click="closeLarge('fuelchart')" class="expandCompressChartButton" title="Compress">
            <v-icon name="compress" class="compressChartButtonIcon"/>
          </button>
        </div>
        <lineChart getStateFunction="chassis/getGraphDataStructure"
                   getColourScale="chassis/getGraphColorStructure"
                   :width="enlargedChart === 'fuelchart' ? chartWidthLarge : chartWidth"
                   :height="enlargedChart === 'fuelchart' ? chartHeightLarge : chartHeight"
                   :svgHeight="enlargedChart === 'fuelchart' ? chartSVGHeightLarge : chartSVGHeight"
                   :margin="enlargedChart === 'fuelchart' ? chartMarginLarge : chartMarginSmall"
                   :r="enlargedChart === 'fuelchart' ? chartCircleLarge : chartCircleSmall"
                   yAxisLabel="Daily fuel (l)"
                   xAxisLabel="Date"
                   :graphTitle="getGraphTitle('fuelchart')"
                   lineChartType="dailyAggregates"
                   yKey="totalfuel"
                   zAxisLabel="ChassisNumber"/>
      </div>
      <div v-show="selectedGraphs.includes('odometerboxplot')"
           class="vehicleChart"
           id="odometerboxplot"
           :class="checkGraphSize('odometerboxplot')"
      >
        <div class="chartHeader">
          <button v-if="enlargedChart !== 'odometerboxplot'" @click="handleClick('odometerboxplot')" class="expandCompressChartButton" title="Expand">
            <v-icon name="expand" class="expandChartButtonIcon"/>
          </button>
          <button v-if="enlargedChart === 'odometerboxplot'" @click="closeLarge('odometerboxplot')" class="expandCompressChartButton" title="Compress">
            <v-icon name="compress" class="compressChartButtonIcon"/>
          </button>
        </div>
        <boxPlot getStateFunction="chassis/getGraphDataStructure"
                 :width="enlargedChart === 'odometerboxplot' ? chartWidthLarge : chartWidth"
                 :height="enlargedChart === 'odometerboxplot' ? chartHeightLarge : chartHeight"
                 :margin="enlargedChart === 'odometerboxplot' ? chartMarginLarge : chartMarginSmall"
                 :svgHeight="enlargedChart === 'odometerboxplot' ? chartSVGHeightLarge : chartSVGHeight"
                 yAxisLabel="Mileage (km)"
                 boxPlotType="odometerBoxplot"
                 :graphTitle="getGraphTitle('odometerboxplot')"
                 yKey="odometer"/>
      </div>
      <div v-show="selectedGraphs.includes('fuelboxplot')"
           class="vehicleChart"
           id="fuelboxplot"
           :class="checkGraphSize('fuelboxplot')"
      >
        <div class="chartHeader">
          <button v-if="enlargedChart !== 'fuelboxplot'" @click="handleClick('fuelboxplot')" class="expandCompressChartButton" title="Expand">
            <v-icon name="expand" class="expandChartButtonIcon"/>
          </button>
          <button v-if="enlargedChart === 'fuelboxplot'" @click="closeLarge('fuelboxplot')" class="expandCompressChartButton" title="Compress">
            <v-icon name="compress" class="compressChartButtonIcon"/>
          </button>
        </div>
        <boxPlot getStateFunction="chassis/getGraphDataStructure"
                 :width="enlargedChart === 'fuelboxplot' ? chartWidthLarge : chartWidth"
                 :height="enlargedChart === 'fuelboxplot' ? chartHeightLarge : chartHeight"
                 :margin="enlargedChart === 'fuelboxplot' ? chartMarginLarge : chartMarginSmall"
                 :svgHeight="enlargedChart === 'fuelboxplot' ? chartSVGHeightLarge : chartSVGHeight"
                 yAxisLabel="Fuel (l)"
                 boxPlotType="fuelBoxplot"
                 :graphTitle="getGraphTitle('fuelboxplot')"
                 yKey="totalfuel"/>
      </div>
      <div v-show="selectedGraphs.includes('dailyodometerboxplot')"
           class="vehicleChart"
           id="dailyodometerboxplot"
           :class="checkGraphSize('dailyodometerboxplot')"
      >
        <div class="chartHeader">
          <button v-if="enlargedChart !== 'dailyodometerboxplot'" @click="handleClick('dailyodometerboxplot')" class="expandCompressChartButton" title="Expand">
            <v-icon name="expand" class="expandChartButtonIcon"/>
          </button>
          <button v-if="enlargedChart === 'dailyodometerboxplot'" @click="closeLarge('dailyodometerboxplot')" class="expandCompressChartButton" title="Compress">
            <v-icon name="compress" class="compressChartButtonIcon"/>
          </button>
        </div>
        <boxPlot getStateFunction="chassis/getGraphDataStructure"
                 :width="enlargedChart === 'dailyodometerboxplot' ? chartWidthLarge : chartWidth"
                 :height="enlargedChart === 'dailyodometerboxplot' ? chartHeightLarge : chartHeight"
                 :margin="enlargedChart === 'dailyodometerboxplot' ? chartMarginLarge : chartMarginSmall"
                 :svgHeight="enlargedChart === 'dailyodometerboxplot' ? chartSVGHeightLarge : chartSVGHeight"
                 yAxisLabel="Distance (km)"
                 boxPlotType="dailyOdometerBoxplot"
                 :graphTitle="getGraphTitle('dailyodometerboxplot')"
                 yKey="odometer"/>
      </div>
      <div v-show="selectedGraphs.includes('dailyfuelboxplot')"
           class="vehicleChart"
           id="dailyfuelboxplot"
           :class="checkGraphSize('dailyfuelboxplot')"
      >
        <div class="chartHeader">
          <button v-if="enlargedChart !== 'dailyfuelboxplot'" @click="handleClick('dailyfuelboxplot')" class="expandCompressChartButton" title="Expand">
            <v-icon name="expand" class="expandChartButtonIcon"/>
          </button>
          <button v-if="enlargedChart === 'dailyfuelboxplot'" @click="closeLarge('dailyfuelboxplot')" class="expandCompressChartButton" title="Compress">
            <v-icon name="compress" class="compressChartButtonIcon"/>
          </button>
        </div>
        <boxPlot getStateFunction="chassis/getGraphDataStructure"
                 :width="enlargedChart === 'dailyfuelboxplot' ? chartWidthLarge : chartWidth"
                 :height="enlargedChart === 'dailyfuelboxplot' ? chartHeightLarge : chartHeight"
                 :margin="enlargedChart === 'dailyfuelboxplot' ? chartMarginLarge : chartMarginSmall"
                 :svgHeight="enlargedChart === 'dailyfuelboxplot' ? chartSVGHeightLarge : chartSVGHeight"
                 yAxisLabel="Fuel (l)"
                 boxPlotType="dailyFuelBoxplot"
                 :graphTitle="getGraphTitle('dailyfuelboxplot')"
                 yKey="totalfuel"/>
      </div>
      <div v-show="selectedGraphs.includes('hourlyboxplot')"
           class="vehicleChart"
           id="hourlyboxplot"
           :class="checkGraphSize('hourlyboxplot')"
      >
        <div class="chartHeader">
          <button v-if="enlargedChart !== 'hourlyboxplot'" @click="handleClick('hourlyboxplot')" class="expandCompressChartButton" title="Expand">
            <v-icon name="expand" class="expandChartButtonIcon"/>
          </button>
          <button v-if="enlargedChart === 'hourlyboxplot'" @click="closeLarge('hourlyboxplot')" class="expandCompressChartButton" title="Compress">
            <v-icon name="compress" class="compressChartButtonIcon"/>
          </button>
        </div>
        <boxPlot getStateFunction="chassis/getGraphDataStructure"
                 :width="enlargedChart === 'hourlyboxplot' ? chartWidthLarge : chartWidth"
                 :height="enlargedChart === 'hourlyboxplot' ? chartHeightLarge : chartHeight"
                 :svgHeight="enlargedChart === 'hourlyboxplot' ? chartSVGHeightLarge : chartSVGHeight"
                 :margin="enlargedChart === 'hourlyboxplot' ? chartMarginLarge : chartMarginSmall"
                 yAxisLabel="Stop count"
                 boxPlotType="hourlyBoxplot"
                 :graphTitle="getGraphTitle('hourlyboxplot')"
                 yKey="count"
                 xKey="time"
                 xAxisValue="time"
                 xAxisLabel="Time" />
      </div>
      <div v-show="selectedGraphs.includes('dailyHourlyAggregates')"
           class="vehicleChart"
           id="dailyHourlyAggregates"
           :class="checkGraphSize('dailyHourlyAggregates')"
      >
        <div class="chartHeader">
          <button v-if="enlargedChart !== 'dailyHourlyAggregates'" @click="handleClick('dailyHourlyAggregates')" class="expandCompressChartButton" title="Expand">
            <v-icon name="expand" class="expandChartButtonIcon"/>
          </button>
          <button v-if="enlargedChart === 'dailyHourlyAggregates'" @click="closeLarge('dailyHourlyAggregates')" class="expandCompressChartButton" title="Compress">
            <v-icon name="compress" class="compressChartButtonIcon"/>
          </button>
        </div>
        <lineChart getStateFunction="chassis/getGraphDataStructure"
                   getColourScale="chassis/getGraphColorScaleForDayOfWeekNumber"
                   :width="enlargedChart === 'dailyHourlyAggregates' ? chartWidthLarge : chartWidth"
                   :height="enlargedChart === 'dailyHourlyAggregates' ? chartHeightLarge : chartHeight"
                   :svgHeight="enlargedChart === 'dailyHourlyAggregates' ? chartSVGHeightLarge : chartSVGHeight"
                   :margin="enlargedChart === 'dailyHourlyAggregates' ? chartMarginLarge : chartMarginSmall"
                   :r="enlargedChart === 'dailyHourlyAggregates' ? chartCircleLarge : chartCircleSmall"
                   yAxisLabel="Daily stops"
                   :graphTitle="getGraphTitle('dailyHourlyAggregates')"
                   lineChartType="dailyHourlyAggregates"
                   yKey="median"
                   xKey="date"
                   xAxisValue="time"
                   xAxisLabel="Time"
                   zAxisLabel="Day of week"
                   scaleType="ordinal"
                   :legend=true />
      </div>
      <div v-show="selectedGraphs.includes('dailyHourlyAggregatesHeatmap')"
           class="vehicleChart"
           id="dailyHourlyAggregatesHeatmap"
           :class="checkGraphSize('dailyHourlyAggregatesHeatmap')"
      >
        <div class="chartHeader">
          <button v-if="enlargedChart !== 'dailyHourlyAggregatesHeatmap'" @click="handleClick('dailyHourlyAggregatesHeatmap')" class="expandCompressChartButton" title="Expand">
            <v-icon name="expand" class="expandChartButtonIcon"/>
          </button>
          <button v-if="enlargedChart === 'dailyHourlyAggregatesHeatmap'" @click="closeLarge('dailyHourlyAggregatesHeatmap')" class="expandCompressChartButton" title="Compress">
            <v-icon name="compress" class="compressChartButtonIcon"/>
          </button>
        </div>
        <weekdayChart getStateFunction="chassis/getGraphDataStructure"
                      colourScaleFunction="chassis/getHeatmapColourScale"
                      :width="enlargedChart === 'dailyHourlyAggregatesHeatmap' ? chartWidthLarge : chartWidth"
                      :height="enlargedChart === 'dailyHourlyAggregatesHeatmap' ? chartHeightLarge : chartHeight"
                      :svgHeight="enlargedChart === 'dailyHourlyAggregatesHeatmap' ? '70vh' : '21vh'"
                      yAxisLabel="Weekday"
                      :graphTitle="getGraphTitle('dailyHourlyAggregatesHeatmap')"
                      structKey="dailyHourlyAggregates"
                      yKey="name_of_day"
                      zKey="median"
                      xKey="date"
                      xAxisLabel="Hour of day"/>
      </div>
    </div>
    <div id="mapButtons" v-if="showMapButtons">
      <button id="legendButton"
              @click="showLegend = true; showMapButtons = false">
        <span class="toolTipText">Legend</span>
        <font-awesome-icon icon="fa-solid fa-layer-group" inverse
                           class="mapButtonIcon"/>
      </button>
    </div>
    <div id="legend" v-if="showLegend" style="font-family: Montserrat; font-size: 0.8rem;">
      <button id="closeLegendButton" @click="showLegend = false; showMapButtons = true">
        <v-icon name="times" id="closeLegendButtonIcon"/>
      </button>

      <b-card class="layerDescription" v-if="stopLayerVisible">
        <header>
          <h7>Point size</h7>
          <p style="font-style: italic;font-size:10px">is based on stop duration.</p>
        </header>
        <div class="legendRow" v-for="(pxlSize, pxlIndex) in layerNames.stop.pixelSizes" :key="pxlIndex">
          <div class="circleBase" :style="{ width: pxlSize * 2 + 'px', height: pxlSize * 2 + 'px'}"></div>
          <div class="legendDescriptor"> > {{ layerNames.stop.stopValues[pxlIndex] }} hours</div>
        </div>

        <hr class="solid">

        <header>
          <h7>Point colour</h7>
          <p style="font-style: italic;font-size:10px">is based on count of stops.</p>
        </header>
        <div class="legendGradientKey" :style="{ 'background': 'linear-gradient(to right, ' +
          layerNames.stop.colorValues[0] + ' 0%, ' + layerNames.stop.colorValues[1] + ' 100%)' }"></div>
        <div style="font-size:12px">
          <span id="firstFeaturePropDescriptor">Low</span>
          <span id="lastFeaturePropDescriptor">High</span>
        </div>
      </b-card>

      <b-card class="layerDescription" v-if="hexagonLayerVisible">
        <header>
          <h7>Hexagon colour</h7>
          <p style="font-style: italic;font-size:10px">is based on median stop duration.</p>
        </header>
        <div class="legendRow" v-for="colour in polygonColours" :key="colour.colour">
          <div :style="{ background: colour.colour, width: '12px', height: '12px'}"></div>
          <div class="legendDescriptor"> {{ colour.domain[0] }} - {{ colour.domain[1] }} hours</div>
        </div>
      </b-card>

      <b-card class="layerDescription" v-if="edgeLayerVisible">
        <header>
          <h7>Trip colour</h7>
        </header>
        <div class="legendRow" v-for="colour in layerNames.edge.color" :key="colour.colourFor">
          <b-form-input :style="{  width: '24px', height: '24px'}" @change="handleDeckLayerChanged()"
                        v-model="colour.hexString" type="color">
          </b-form-input>
          <div class="legendDescriptor"> {{ colour.colourFor }} location</div>
        </div>
      </b-card>

    </div>
  </div>
</template>

<script>

import mapboxgl from 'mapbox-gl'
import { ArcLayer } from '@deck.gl/layers'
import { H3HexagonLayer } from '@deck.gl/geo-layers'
import { MapboxLayer } from '@deck.gl/mapbox'
import { h3ToGeo } from 'h3-js'
import axios from 'axios'
import axiosRetry from 'axios-retry'
import LineChart from '@/components/D3Graphs/LineChart.vue'
import WeekdayChart from '@/components/D3Graphs/WeekdayChart.vue'
import BoxPlot from '@/components/D3Graphs/BoxPlot.vue'
import snowflake from '../../mixins/snowflake'
import SideMenu from './SideMenu.vue'
import * as mapboxExpression from 'mapbox-gl/src/style-spec/dist/index.es.js'
import * as powerLayer from '@/components/layers/power'

export default {
  name: 'ChassisMap',
  components: { LineChart, BoxPlot, SideMenu, WeekdayChart },
  mixins: [snowflake],
  data () {
    return {
      activeChassisFilter: ['match', ['get', 'chassisnumber'], [''], true, true],
      activeMinTimeFilter: ['>=', ['to-number', ['get', 'stop_end_time']], Number.MIN_SAFE_INTEGER],
      activeMaxTimeFilter: ['<=', ['to-number', ['get', 'stop_end_time']], Number.MAX_SAFE_INTEGER],
      activeMinOdometerFilter: ['>=', ['to-number', ['get', 'odometer']], Number.MIN_SAFE_INTEGER],
      activeMaxOdometerFilter: ['<=', ['to-number', ['get', 'odometer']], Number.MAX_SAFE_INTEGER],
      activeMinFuelFilter: ['>=', ['to-number', ['get', 'totalfuel']], Number.MIN_SAFE_INTEGER],
      activeMaxFuelFilter: ['<=', ['to-number', ['get', 'totalfuel']], Number.MAX_SAFE_INTEGER],
      activeMinDurationFilter: ['>=', ['to-number', ['get', 'stop_duration']], Number.MIN_SAFE_INTEGER],
      activeMaxDurationFilter: ['<=', ['to-number', ['get', 'stop_duration']], Number.MAX_SAFE_INTEGER],
      updatedFilter: null,
      selectedBaseLayer: 'scania',
      layerNames: {
        stop: {
          sizeFeatureProp: 'circle-radius',
          colorFeatureProp: 'circle-color',
          json_source: 'stop',
          layerName: 'stop',
          pixelSizes: [],
          stopValues: [],
          featureProperty: null,
          featurePropertyValues: [],
          colorValues: []
        },
        hexagon: {
          json_source: 'hexagon',
          layerName: 'hexagon'
        },
        edge: {
          json_source: 'edge',
          layerName: 'edge',
          color: [
            { colourFor: 'Source', hexString: '#ff0080' },
            { colourFor: 'Target', hexString: '#ffa200' }
          ]
        }
      },
      selectedGraphs: [],
      graphOptions: [
        { text: 'Daily Mileage', default_text: 'Daily mileage', value: 'odometerchart', stateFunction: 'dailyAggregates', tabName: 'Daily aggregates', checked: false, visible: false },
        { text: 'Daily fuel', default_text: 'Daily fuel', value: 'fuelchart', stateFunction: 'dailyAggregates', tabName: 'Daily aggregates', checked: false, visible: false },
        { text: 'Daily mileage boxplot', default_text: 'Daily mileage boxplot', value: 'dailyodometerboxplot', stateFunction: 'dailyOdometerBoxplot', tabName: 'Daily mileage boxplot', checked: false, visible: false },
        { text: 'Daily fuel boxplot', default_text: 'Daily fuel boxplot', value: 'dailyfuelboxplot', stateFunction: 'dailyFuelBoxplot', tabName: 'Daily fuel boxplot', checked: false, visible: false },
        { text: 'Distance between stops boxplot', default_text: 'Distance between stops boxplot', value: 'odometerboxplot', stateFunction: 'odometerBoxplot', tabName: 'Distance between stops boxplot', checked: false, visible: false },
        { text: 'Fuel between stops boxplot', default_text: 'Fuel between stops boxplot', value: 'fuelboxplot', stateFunction: 'fuelBoxplot', tabName: 'Fuel between stops boxplot', checked: false, visible: false },
        { text: 'Ongoing stops, hourly', default_text: 'Ongoing stops, hourly', value: 'hourlyboxplot', stateFunction: 'hourlyBoxplot', tabName: 'Hourly ongoing stops', checked: false, visible: false },
        { text: 'Daily Ongoing stops, hourly', default_text: 'Daily Ongoing stops, hourly', value: 'dailyHourlyAggregates', stateFunction: 'dailyHourlyAggregates', tabName: 'Daily Hourly ongoing stops', checked: false, visible: false },
        { text: 'Daily hourly stop heatmap', default_text: 'Daily hourly stop heatmap', value: 'dailyHourlyAggregatesHeatmap', stateFunction: 'dailyHourlyAggregates', tabName: 'Daily hourly stop heatmap', checked: false, visible: false }
      ],
      currentZoom: 0,
      showLegend: false,
      showMapButtons: false,
      chartWidth: 600,
      chartHeight: 300,
      chartSVGHeight: '28vh',
      chartWidthLarge: 860,
      chartHeightLarge: 340,
      chartSVGHeightLarge: '90vh',
      weekdayChartMargin: { top: 60, right: 20, bottom: 40, left: 40 },
      chartMarginSmall: { top: 0, right: 20, bottom: 40, left: 40 },
      chartMarginLarge: { top: 0, right: 20, bottom: 40, left: 40 },
      chartCircleSmall: 5,
      chartCircleLarge: 5,
      chartFontSize: '0.7rem',
      enlargedChart: null,
      hexagonObject: null,
      edgeObject: null,
      hexagonPopup: null,
      hasRun: false,
      tilesLoaded: false,
      allMapLayersLoaded: 0,
      powerLayerVisible: false
    }
  },

  created () {
    this.showMapButtons = true
    if (this.$store.getters['chassis/getRequestedTilesName']) {
      let url = ''
      if (process.env.VUE_APP_ENV === 'mock') {
        url = 'data/shabcj/123/.stop_done'
      } else {
        let endpoint = this.$store.getters.getAppConfigFlag('useStagingStack')
          ? process.env.VUE_APP_CF_DIST_ONE_ENDPOINT_B : process.env.VUE_APP_CF_DIST_ONE_ENDPOINT
        url = endpoint + '/' + this.$store.getters['chassis/getRequestedTilesName'] + '/.stop_done'
      }

      const requestTiles = () => {
        const startTime = Date.now()
        this.$logger.message(`{"created":"Start time for requesting tiles: ${startTime}"}`, 'info')

        this.apiTilesCall(url).then((res) => {
          const endTime = Date.now()
          const secondsUntilTilesDone = (endTime - startTime) / 1000
          this.$logger.message(`{"created":"Tiles were ready at: ${endTime}"}`, 'info')
          this.$logger.message(`{"created":"Tiles took ${secondsUntilTilesDone} seconds until they arrived in the app."}`, 'info')

          this.$store.commit('chassis/setStopLayerLoaded', true)

          this.mapObject.on('sourcedata', this.sourceAddedCallback)
          this.updateMapWithSources()
          this.updateMapWithData()

          let timeWindow = this.$store.getters['chassis/getTilesTimeWindow']
          this.startDate = new Date(timeWindow[0])
          this.endDate = new Date(timeWindow[1])
          this.$store.commit('setLayerVisibility', ['stop', true])

          this.showSelectedDataLayers()
          this.tilesLoaded = true
          this.allMapLayersLoaded++
          this.$store.commit('setModalVisibility', false)
        })
      }
      this.setupMapEvents()
      if (this.$store.getters.getAppConfigFlag('isAlphaTester')) {
        const delayInMS = this.$store.getters['chassis/getQueryTimeInSeconds'] * 1000
        const delayTileRequest = new Promise(resolve => { setTimeout(resolve, delayInMS) })

        delayTileRequest.then(() => {
          requestTiles()
        })
      } else {
        requestTiles()
      }
    }
    if (this.$store.getters.getAppConfigFlag('canSeePowerLayers')) {
      this.addGridSource()
      this.addPowerLayer()
    }
  },

  computed: {
    graphDataFinishedLoading () {
      return this.$store.getters['chassis/getGraphDataLoaded']
    },
    stopLayerVisible () {
      return this.$store.getters.getLayerVisibility('stop')
    },
    edgeLayerVisible () {
      return this.$store.getters.getLayerVisibility('edge')
    },
    hexagonLayerVisible () {
      return this.$store.getters.getLayerVisibility('hexagon')
    },
    mapObject: {
      get () {
        return this.$store.getters.getBaseMap
      },
      set (newMap) {
        this.$store.commit('setBaseMap', newMap)
      }
    },
    startDate: {
      get () {
        return this.$store.getters['chassis/getSelectedMinTime']
      },
      set (value) {
        this.$store.commit('chassis/setSelectedMinTime', value)
      }
    },
    endDate: {
      get () {
        return this.$store.getters['chassis/getSelectedMaxTime']
      },
      set (value) {
        this.$store.commit('chassis/setSelectedMaxTime', value)
      }
    },
    polygonColours () {
      const colourScale = this.$store.getters['chassis/getPolygonColourScale']
      const colourObject = colourScale.range().map((range) => {
        const red = range[0]
        const green = range[1]
        const blue = range[2]
        const rgbString = `rgb(${red},${green},${blue})`
        return { colour: rgbString, domain: colourScale.invertExtent(range) }
      })
      return colourObject
    }
  },

  methods: {
    addGridSource () {
      this.mapObject.addSource('openinframap', {
        'type': 'vector',
        'minzoom': 4,
        'maxzoom': 17,
        'tiles': ['https://openinframap.org/tiles/{z}/{x}/{y}.pbf']
      })
    },
    addPowerLayer () {
      if (!this.mapObject.getSource('openinframap')) {
        this.addGridSource()
      }
      this.$logger.message(`{addPowerLayer":"Will SHOW the power layer"}`, 'info')
      powerLayer.layers.forEach(layer => {
        if (this.mapObject.getLayer(layer.id)) {
          this.mapObject.removeLayer(layer.id)
        }
        this.mapObject.addLayer(layer)
        this.$store.commit('setLayerVisibility', [layer.id, true])
        this.mapObject.setLayoutProperty(layer.id, 'visibility', 'visible')
      })
      this.powerLayerVisible = true
    },
    hidePowerLayer () {
      this.powerLayerVisible = false
      this.$logger.message(`{"hidePowerLayer":"Hiding the Power layers from map"}`, 'info')
      powerLayer.layers.forEach(layer => {
        this.mapObject.setLayoutProperty(layer.id, 'visibility', 'none')
        this.$store.commit('setLayerVisibility', [layer.id, false])
      })
    },
    createExpression (expression, type) {
      const result = mapboxExpression.expression.createPropertyExpression(expression, {
        type,
        'property-type': 'data-driven',
        expression: {
          parameters: ['zoom'],
          interpolated: true
        }
      })
      if (result.result === 'error') throw result.value[0]
      return result.value
    },
    getGraphTitle (graph) {
      return this.graphOptions.find(item => item.value === graph).text
    },

    getGraphTabName (graph) {
      return this.graphOptions.find(item => item.value === graph).tabName
    },

    checkGraphSize (graph) {
      if (this.enlargedChart === graph) {
        return 'largeChart'
      } else if (this.selectedGraphs.length <= 4) {
        return 'smallChart'
      } else {
        return 'xSmallChart'
      }
    },

    checkGraphWidgetSize () {
      if (this.enlargedChart !== null) {
        return 'largeChartWidget'
      } else if (this.selectedGraphs.length <= 4) {
        return 'smallChartWidget'
      } else {
        return 'xSmallChartWidget'
      }
    },

    handleClick (activeEnlargedGraph) {
      this.enlargedChart = activeEnlargedGraph
    },

    closeLarge (activeEnlargedGraph) {
      this.enlargedChart = null
    },

    async apiTilesCall (url) {
      const axiosClient = axios.create()

      const retryCondition = (error) => {
        return (
          axiosRetry.isNetworkOrIdempotentRequestError(error) || error.response.status === 403
        )
      }

      axiosRetry(axiosClient, { retries: 200, retryDelay: axiosRetry.exponentialDelay, retryCondition })

      return axiosClient
        .get(url, {
          headers: { 'authorizationToken': this.$store.getters.getBearerToken }
        })
        .then(res => {
          this.$logger.message('{"apiTilesCall":"Response is fetched"}')
        })
    },

    setSizesForLegendOfPointLayer (layerName, paintProperty) {
      let layerStyle = this.mapObject.getStyle().layers.find(l => l.id === layerName)
      let paintExpression = layerStyle.paint[paintProperty][layerStyle.paint[paintProperty].length - 1]

      this.layerNames[layerName].stopValues = []
      if (paintExpression[0] === 'step') {
        this.layerNames[layerName].stopValues.push(0.0001)
        if (Array.isArray(paintExpression[1])) {
          for (let i = 3; i < paintExpression.length; i += 2) {
            this.layerNames[layerName].stopValues.push(paintExpression[i])
          }
        } else {
          for (let i = 1; i < paintExpression.length; i += 2) {
            this.layerNames[layerName].stopValues.push(paintExpression[i])
          }
        }
      }

      this.layerNames[layerName].pixelSizes = []

      for (let i = 0; i < this.layerNames[layerName].stopValues.length; i++) {
        let feature = {
          properties: {
            stop_duration: this.layerNames[layerName].stopValues[i] * 3600
          }
        }
        const featureExpression = this.createExpression(layerStyle.paint[paintProperty], 'number')
        this.layerNames[layerName].pixelSizes.push(featureExpression.evaluate({ zoom: this.currentZoom }, feature))
      }
    },

    setColorsForLegendOfPointLayer (layerName, paintProperty) {
      let layerStyle = this.mapObject.getStyle().layers.find(l => l.id === layerName)
      let paintExpression = layerStyle.paint[paintProperty]

      this.layerNames[layerName].colorValues = []
      for (let i = 4; i < paintExpression.length; i += 2) {
        this.layerNames[layerName].colorValues.push(paintExpression[i][1])
      }

      this.layerNames[layerName].featureProperty = paintExpression[2][1]

      this.layerNames[layerName].featurePropertyValues = []
      for (let i = 3; i < paintExpression.length; i += 2) {
        this.layerNames[layerName].featurePropertyValues.push(paintExpression[i])
      }
    },

    onDateRangeChange () {
      let startDateTimestamp = Date.parse(this.startDate) / 1000
      let endDateTimestamp = Date.parse(this.endDate) / 1000 + 86399
      this.activeMinTimeFilter = ['>=', ['to-number', ['get', 'stop_start_time']], startDateTimestamp]
      this.activeMaxTimeFilter = ['<=', ['to-number', ['get', 'stop_start_time']], endDateTimestamp]
    },

    onOdometerRangeChange () {
      let odometerMinValue = this.$store.getters['chassis/getSelectedMinMaxOdometer'][0] == null ? this.$store.getters['chassis/getMinMaxOdometer'][0] : this.$store.getters['chassis/getSelectedMinMaxOdometer'][0]
      let odometerMaxValue = this.$store.getters['chassis/getSelectedMinMaxOdometer'][1] == null ? this.$store.getters['chassis/getMinMaxOdometer'][1] : this.$store.getters['chassis/getSelectedMinMaxOdometer'][1]
      this.activeMinOdometerFilter = ['>=', ['to-number', ['get', 'odometer']], odometerMinValue]
      this.activeMaxOdometerFilter = ['<=', ['to-number', ['get', 'odometer']], odometerMaxValue]
    },

    onFuelRangeChange () {
      let fuelMinValue = this.$store.getters['chassis/getSelectedMinMaxFuel'][0] == null ? this.$store.getters['chassis/getMinMaxFuel'][0] : this.$store.getters['chassis/getSelectedMinMaxFuel'][0]
      let fuelMaxValue = this.$store.getters['chassis/getSelectedMinMaxFuel'][1] == null ? this.$store.getters['chassis/getMinMaxFuel'][1] : this.$store.getters['chassis/getSelectedMinMaxFuel'][1]
      this.activeMinFuelFilter = ['>=', ['to-number', ['get', 'totalfuel']], fuelMinValue]
      this.activeMaxFuelFilter = ['<=', ['to-number', ['get', 'totalfuel']], fuelMaxValue]
    },

    onDurationRangeChange () {
      let durationMinValue = this.$store.getters['chassis/getSelectedMinMaxDuration'][0] == null ? this.$store.getters['chassis/getMinMaxDuration'][0] : this.$store.getters['chassis/getSelectedMinMaxDuration'][0]
      let durationMaxValue = this.$store.getters['chassis/getSelectedMinMaxDuration'][1] == null ? this.$store.getters['chassis/getMinMaxDuration'][1] : this.$store.getters['chassis/getSelectedMinMaxDuration'][1]

      this.activeMinDurationFilter = ['>=', ['to-number', ['get', 'stop_duration']], durationMinValue]
      this.activeMaxDurationFilter = ['<=', ['to-number', ['get', 'stop_duration']], durationMaxValue]
    },

    onChassisFilterChange () {
      let filteredChassis = this.$store.getters['chassis/getSelectedChassis']

      // Either filter on choosen chassis
      if (filteredChassis.length > 0) {
        this.activeChassisFilter = ['match', ['get', 'chassisnumber'], filteredChassis, true, false]
      } else { // Or remove filters
        this.activeChassisFilter = ['match', ['get', 'chassisnumber'], [''], true, true]
      }
    },

    filterMapAndData () {
      this.updatedFilter = ['all', this.activeChassisFilter, this.activeMinTimeFilter,
        this.activeMaxTimeFilter, this.activeMinOdometerFilter, this.activeMaxOdometerFilter,
        this.activeMinFuelFilter, this.activeMaxFuelFilter,
        this.activeMinDurationFilter, this.activeMaxDurationFilter]
      this.mapObject.setFilter('stop', this.updatedFilter)
    },

    showSelectedDataLayers () {
      // Hide all custom layers
      let numberOfMapIds = this.$store.getters.getMapLayerIds.length
      while (numberOfMapIds--) {
        let layerId = this.$store.getters.getMapLayerIds[numberOfMapIds]
        if (typeof this.mapObject.getLayer(layerId) !== 'undefined') {
          this.mapObject.setLayoutProperty(layerId, 'visibility', 'none')
        }
      }

      // Show the layer set to visible
      let numberOfVisibleMapIds = this.$store.getters.getLayers.length
      while (numberOfVisibleMapIds--) {
        let layerId = this.$store.getters.getLayers[numberOfVisibleMapIds]
        if (typeof this.mapObject.getLayer(layerId) !== 'undefined') {
          this.mapObject.setLayoutProperty(layerId, 'visibility', 'visible')
        }
      }
    },

    switchBaseLayer () {
      if (this.mapObject.getLayer('hexagon')) {
        this.mapObject.removeLayer('hexagon')
      }
      if (this.mapObject.getLayer('edge')) {
        this.mapObject.removeLayer('edge')
      }
      return new Promise(resolve => {
        // Get source and layer to add them back after the style change,
        // since the map's style object is the one holding the layers and the respective filters, etc.
        let layer = this.selectedBaseLayer
        if (layer === 'scania') {
          this.mapObject.setStyle('mapbox://styles/connected-intelligence/ckhpx27eb02zt19qrm3qphq46')
        } else {
          this.mapObject.setStyle('mapbox://styles/mapbox/' + layer)
        }
        this.mapObject.on('style.load', _ => {
          resolve()
        })
      })
    },

    setupMapEvents () {
      this.mapObject.on('click', this.layerNames['stop'].layerName, this.popupDataInfoOnClick)

      this.mapObject.on('click', this.setUpHexagonAndTripClickEvent)
      this.mapObject.on('moveend', this.setUpZoom)
    },
    setUpHexagonAndTripClickEvent (e) {
      const deck = this.mapObject.__deck
      if (deck !== null) {
        let pointerObject = deck._lastPointerDownInfo
        if (pointerObject.layer) {
          if (pointerObject.layer.id === 'hexagon' && this.$store.getters.getLayerVisibility('hexagon')) {
            this.popupDataInfoOnClickPolygon(e)
          } else if (pointerObject.layer.id === 'edge' && this.$store.getters.getLayerVisibility('edge')) {
            this.popupDataInfoOnClickEdge(e)
          }
        }
      }
    },
    setUpZoom () {
      this.currentZoom = this.mapObject.getZoom()
      this.$logger.message(`{"setUpZoom":"Zoom in ChassisMap to: ${this.currentZoom}"}`, 'info')
    },
    popupDataInfoOnClick (e) {
      this.$logger.message(`{"popupDataInfoOnClick":"Clicked on stop layer"}`, 'info')
      let coordinates = e.features[0].geometry.coordinates.slice()
      let stopStartTime = new Date(e.features[0].properties.stop_start_time * 1000)
      let stopEndTime = new Date(e.features[0].properties.stop_end_time * 1000)
      let description = '<strong> Chassisnumber: ' + e.features[0].properties.chassisnumber +
          '</br>Stop start time: ' + stopStartTime +
          '</br>Stop end time: ' + stopEndTime +
          '</br>Odometer: ' + e.features[0].properties.odometer +
          '</br>Total fuel: ' + e.features[0].properties.totalfuel +
          '</br>Duration: ' + (e.features[0].properties.stop_duration / 3600).toFixed(2) + 'h' + '</strong>'
      // Ensure that if the map is zoomed out such that multiple
      // copies of the feature are visible, the popup appears
      // over the copy being pointed to.

      while (Math.abs(e.lngLat.lng - coordinates[0]) > 180) {
        coordinates[0] += e.lngLat.lng > coordinates[0] ? 360 : -360
      }
      new mapboxgl.Popup()
        .setLngLat(coordinates)
        .setHTML(description)
        .addTo(this.mapObject)
    },

    popupDataInfoOnClickPolygon (e) {
      if (this.hexagonObject) {
        this.$store.commit('chassis/setH3CellIndexOption', this.hexagonObject.h3_cell_index)
        this.$logger.message(`{"popupDataInfoOnClickPolygon":"The hex id: ${this.hexagonObject.h3_cell_index} was clicked."}`, 'info')
        let coordinates = this.transposeCoordinates(this.hexagonObject.h3_cell_index)
        let chassisList = this.hexagonObject.chassi_list.join(', ')
        let sumStopDuration = (this.hexagonObject.sum_stop_duration / 3600).toFixed(2)
        let description = '<strong> Hexagon id: ' + this.hexagonObject.h3_cell_index +
            '</strong></br><strong> Chassis list: </strong>' + chassisList +
            '</br><strong>Sum of stop time: </strong>' + sumStopDuration + 'h' +
            '<br/><strong>Median stop time: </strong>' + (this.hexagonObject.median_stop_duration / 3600).toFixed(2) + 'h' +
            '<br/><strong>Number of stops: </strong>' + this.hexagonObject.stop_count
        // Ensure that if the map is zoomed out such that multiple
        // copies of the feature are visible, the popup appears
        // over the copy being pointed to.
        while (Math.abs(e.lngLat.lng - coordinates[0] > 180)) {
          coordinates[0] += e.lngLat.lng > coordinates[0] ? 360 : -360
        }
        this.hexagonPopup = new mapboxgl.Popup()
          .setLngLat(coordinates)
          .setHTML(description)
          .addTo(this.mapObject)
      }
    },

    popupDataInfoOnClickEdge (e) {
      if (this.edgeObject) {
        let coordinates = this.transposeCoordinates(this.edgeObject.origin_h3_cell_index)
        this.$logger.message(`{"popupDataInfoOnClickEdge":"A trip was clicked. Origin was the hex id: ${this.edgeObject.origin_h3_cell_index}."}`, 'info')
        let chassisList = this.edgeObject.chassis_list.join(', ')
        let medianIngoingDeltaOdometer = this.edgeObject.median_ingoing_delta_odometer
        let medianIngoingDeltaFuel = this.edgeObject.median_ingoing_delta_fuel
        let tripCount = this.edgeObject.count_of_trips
        let description = '<strong> Origin grid </strong>' +
            '<br/><strong> Chassis list: </strong>' + chassisList +
            '<br/><strong>Number of trips: </strong>' + tripCount +
            '<br/><strong>Median ingoing delta odometer: </strong>' + medianIngoingDeltaOdometer +
            '<br/><strong>Median ingoing delta fuel: </strong>' + medianIngoingDeltaFuel
        // Ensure that if the map is zoomed out such that multiple
        // copies of the feature are visible, the popup appears
        // over the copy being pointed to.
        while (Math.abs(e.lngLat.lng - coordinates[0] > 180)) {
          coordinates[0] += e.lngLat.lng > coordinates[0] ? 360 : -360
        }
        new mapboxgl.Popup()
          .setLngLat(coordinates)
          .setHTML(description)
          .addTo(this.mapObject)
      }
    },
    cleanUpMap () {
      for (let dataLayer in this.layerNames) {
        let id = this.layerNames[dataLayer].layerName
        if (this.mapObject.getLayer(id)) {
          this.mapObject.removeLayer(id)
        }
        if (this.mapObject.getSource(id)) {
          this.mapObject.removeSource(id)
        }
      }
    },
    updateMapWithSources () {
      const endpointOne = this.$store.getters.getAppConfigFlag('useStagingStack')
        ? process.env.VUE_APP_CF_DIST_ONE_ENDPOINT_B : process.env.VUE_APP_CF_DIST_ONE_ENDPOINT
      const endpointTwo = this.$store.getters.getAppConfigFlag('useStagingStack')
        ? process.env.VUE_APP_CF_DIST_TWO_ENDPOINT_B : process.env.VUE_APP_CF_DIST_TWO_ENDPOINT
      for (let dataLayer in this.layerNames) {
        const isMockEnv = process.env.VUE_APP_ENV === 'mock'
        const layerName = this.layerNames[dataLayer].layerName
        const sourceTiles = isMockEnv && layerName === 'stop'
          ? [`${process.env.VUE_APP_CF_DIST_ONE_ENDPOINT}/data/${this.$store.getters['chassis/getRequestedTilesName']}-${this.layerNames[dataLayer].json_source}/{z}/{x}/{y}.pbf`]
          : [
            `${endpointOne}/${this.$store.getters['chassis/getRequestedTilesName']}/${this.layerNames[dataLayer].json_source}/{z}/{x}/{y}.pbf`,
            `${endpointTwo}/${this.$store.getters['chassis/getRequestedTilesName']}/${this.layerNames[dataLayer].json_source}/{z}/{x}/{y}.pbf`
          ]

        if (layerName === 'stop') {
          this.mapObject.addSource(layerName, {
            type: 'vector',
            tiles: sourceTiles
          })
        }
      }
    },
    sourceAddedCallback (e) {
      if (this.mapObject.getSource('stop') &&
          this.mapObject.isSourceLoaded('stop') &&
          e.isSourceLoaded) {
        this.$store.commit('setResetModal', true)
        this.mapObject.off('sourcedata', this.sourceAddedCallback) // Unbind event here
      }
    },
    updateMapWithData () {
      let visibleProp = this.stopLayerVisible ? 'visible' : 'none'
      let stopStyleLayer = {
        'id': this.layerNames['stop'].layerName,
        'type': 'circle',
        'source': this.layerNames['stop'].layerName,
        'source-layer': this.layerNames['stop'].layerName,
        'layout': {
          'visibility': visibleProp
        },
        'paint': {
          'circle-opacity': 0.7,
          'circle-color': [
            'interpolate',
            ['linear'],
            ['get', 'tippecanoe_feature_density'],
            0, ['to-color', '#D6001C'],
            260, ['to-color', '#EFBCD5']
          ],
          'circle-radius': [
            'interpolate', ['linear'], ['zoom'],
            0, ['step',
              ['/', ['get', 'stop_duration'], 3600],
              0.5, 1,
              1, 8,
              3, 24,
              4, 168,
              5, 504,
              6],
            18, ['step',
              ['/', ['get', 'stop_duration'], 3600],
              8, 1,
              12, 8,
              17, 24,
              19, 168,
              23, 504,
              28]
          ]
        }
      }

      // Add layers to map
      if (this.mapObject.getLayer('hexagon')) this.mapObject.addLayer(stopStyleLayer, 'hexagon')
      else this.mapObject.addLayer(stopStyleLayer)

      if (this.updatedFilter) {
        this.mapObject.setFilter('stop', this.updatedFilter)
      }
      this.setSizesForLegendOfPointLayer('stop', this.layerNames.stop.sizeFeatureProp)
      this.setColorsForLegendOfPointLayer('stop', this.layerNames.stop.colorFeatureProp)
    },
    fetchHexagonData () {
      return this.$store.getters['chassis/getHexagonLayer']
    },
    fetchEdgeData () {
      return this.$store.getters['chassis/getEdgeLayer']
    },
    flyInOnLoad () {
      const locationAggregates = this.$store.getters['chassis/getHexagonLayer']
      if (locationAggregates === null) return
      const maxObj = locationAggregates.reduce((prev, current) => (prev.stop_count > current.stop_count) ? prev : current)
      const maxHex = maxObj.h3_cell_index
      this.mapObject.easeTo({ center: this.transposeCoordinates(maxHex), zoom: 5, duration: 8000, pitch: 60 })
    },
    hexToRgb (hex) {
      let res = hex.match(/[a-f0-9]{2}/gi)
      return res && res.length === 3
        ? res.map(h => { return parseInt(h, 16) })
        : null
    },
    addHexagonLayerToMap () {
      if (this.mapObject.getLayer('hexagon')) {
        this.mapObject.removeLayer('hexagon')
      }
      const colourScale = this.$store.getters['chassis/getPolygonColourScale']
      const hexagonLayer = new MapboxLayer({
        id: 'hexagon',
        type: H3HexagonLayer,
        data: this.fetchHexagonData(),
        autoHighlight: true,
        opacity: 0.8,
        elevationScale: this.fetchHexagonData() && this.fetchHexagonData().length ? 50 : 0,
        getHexagon: hexagon => hexagon.h3_cell_index,
        getElevation: hexagon => hexagon.stop_count,
        getFillColor: hexagon => colourScale(hexagon.median_stop_duration / 3600),
        extruded: true,
        pickable: true,
        onClick: hexagon => {
          this.hexagonObject = hexagon.object
          return true
        }
      })
      this.mapObject.addLayer(hexagonLayer)
    },
    addEdgeLayerToMap () {
      if (this.mapObject.getLayer('edge')) {
        this.mapObject.removeLayer('edge')
      }

      const edgeLayer = new MapboxLayer({
        id: 'edge',
        type: ArcLayer,
        data: this.fetchEdgeData(),
        widthMaxPixels: 20,
        opacity: 0.6,
        getSourcePosition: d => this.transposeCoordinates(d.origin_h3_cell_index),
        getTargetPosition: d => this.transposeCoordinates(d.destination_h3_cell_index),
        getSourceColor: this.hexToRgb(this.layerNames.edge.color[0].hexString),
        getTargetColor: this.hexToRgb(this.layerNames.edge.color[1].hexString),
        getWidth: d => Math.max(1, Math.log(d.count_of_trips)),
        getTilt: 10,
        autoHighlight: true,
        pickable: true,
        onClick: edge => {
          this.edgeObject = edge.object
          return true
        }
      })
      this.mapObject.addLayer(edgeLayer)
    },
    transposeCoordinates (h3CellIndex) {
      let invertedCoordinates = h3ToGeo(h3CellIndex)
      return [invertedCoordinates[1], invertedCoordinates[0]]
    },
    handleDeckLayerChanged () {
      this.addHexagonLayerToMap()
      this.addEdgeLayerToMap()
      this.showSelectedDataLayers()
    },
    handleOnFilter () {
      this.handleDeckLayerChanged()
      this.showSelectedDataLayers()

      this.onChassisFilterChange()
      this.onDateRangeChange()
      this.onOdometerRangeChange()
      this.onFuelRangeChange()
      this.onDurationRangeChange()
      this.filterMapAndData()
    },
    updateGraphOptions (options) {
      this.graphOptions = options
    },
    updateGraphSelection (selection) {
      this.selectedGraphs = selection
    },
    selectMapStyle (mapStyle) {
      this.selectedBaseLayer = mapStyle
    },
    cleanUpAndReset () {
      this.tilesLoaded = false
      this.allMapLayersLoaded = 0
      this.hasRun = false
      let url = ''
      if (process.env.VUE_APP_ENV === 'mock') {
        url = 'data/shabcj/123/.stop_done'
      } else {
        let endpoint = this.$store.getters.getAppConfigFlag('useStagingStack')
          ? process.env.VUE_APP_CF_DIST_ONE_ENDPOINT_B : process.env.VUE_APP_CF_DIST_ONE_ENDPOINT
        url = endpoint + '/' + this.$store.getters['chassis/getRequestedTilesName'] + '/.stop_done'
      }
      this.apiTilesCall(url).then((res) => {
        this.mapObject.on('sourcedata', this.sourceAddedCallback)
        this.cleanUpMap()
        this.updateMapWithSources()
        this.updateMapWithData()
        let timeWindow = this.$store.getters['chassis/getTilesTimeWindow']
        this.startDate = new Date(timeWindow[0])
        this.endDate = new Date(timeWindow[1])
        this.handleDeckLayerChanged()
        this.showSelectedDataLayers()
        this.showMapButtons = true
      })
    }
  },

  watch: {
    '$store.state.triggerDeckRedraw': function () {
      if (this.$store.getters.getUserSelection.includes('chassis') && this.$store.getters.getTriggerDeckRedraw) {
        this.cleanUpAndReset()
        this.$store.commit('setTriggerDeckRedraw', false)
        this.$store.commit('setModalVisibility', false)
      }
    },

    currentZoom: function () {
      if (this.tilesLoaded) {
        this.setSizesForLegendOfPointLayer('stop', 'circle-radius')
      }
    },
    allMapLayersLoaded: function () {
      if (this.allMapLayersLoaded === 3) {
        let endTime = Date.now()
        this.$logger.message(`{"created":"Time before user can interact with all map layers: ${endTime - this.$store.getters.getStartTimeLog}"}`, 'info')
      }
    },
    selectedBaseLayer: function () {
      this.switchBaseLayer().finally(() => {
        if (this.powerLayerVisible) {
          this.addGridSource()
          this.addPowerLayer()
        }
        this.updateMapWithSources()
        this.updateMapWithData()
        this.addHexagonLayerToMap()
        this.addEdgeLayerToMap()
        this.showSelectedDataLayers()
      })
    },
    '$store.state.visibleLayers': function () {
      this.showSelectedDataLayers()
    },
    '$store.state.chassis.hexagonLayerLoaded': {
      handler () {
        if (this.$store.getters['chassis/getHexagonLayerLoaded']) {
          this.addHexagonLayerToMap()
          this.showSelectedDataLayers()
          if (this.$store.getters['chassis/getGraphDataLoaded'] && !this.$store.getters['chassis/getStopLayerLoaded']) {
            this.$store.commit('setLayerVisibility', ['hexagon', true])
            this.$store.commit('setModalVisibility', false)
            this.showSelectedDataLayers()
            this.allMapLayersLoaded++
            if (!this.hasRun) {
              this.flyInOnLoad()
              this.hasRun = true
            }
          }
        }
      },
      immediate: true
    },
    '$store.state.chassis.edgeLayerLoaded': {
      handler () {
        if (this.$store.getters['chassis/getEdgeLayerLoaded']) {
          this.addEdgeLayerToMap()
          this.showSelectedDataLayers()
          this.allMapLayersLoaded++
          this.$store.commit('setModalVisibility', false)
        }
      },
      immediate: true
    }
  },
  beforeDestroy () {
    this.$nextTick(() => {
      this.mapObject.off('click', this.layerNames['stop'].layerName, this.popupDataInfoOnClick)
      this.mapObject.off('click', this.setUpHexagonAndTripClickEvent)
      this.mapObject.off('moveend', this.setUpZoom)
      this.$logger.message(`{"beforeDestroy":"ChassisMap before destroy, events un-binded"}`, 'info')
    })
  }
}
</script>

<style scoped>
.basemap {
  top: 0;
  bottom: 0;
  width: 100%;
  height: 92.2vh;
}
.loadingBar {
  margin-top: 10px;
  left: 0;
  right: 0;
  position: absolute;
  z-index: 1;
  color: white;
  font-family: 'Montserrat', sans-serif;
  font-size: 13px;
}
#privacyNotice {
  animation: cssAnimation2 0s 6s forwards;
  visibility: hidden;
}

span.block {
  display: block;
}
@keyframes cssAnimation1 {
  to {
    width: 0;
    height: 0;
    overflow: hidden;
  }
}
@-webkit-keyframes cssAnimation1 {
  to {
    width: 0;
    height: 0;
    visibility: hidden;
  }
}
@keyframes cssAnimation2 {
  to {
    visibility: visible;
  }
}
#layerButtons {
  position: absolute;
  right: 50px;
  top: 80px;
  z-index: 1;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
  padding: 1rem 20px 1rem 20px;
  opacity: 0.9;
  border-radius: 2px;
}
#baseStyleButtons {
  background: #f8f9fa;
  color: #212529;
  padding: 6px;
  padding-left: 12px;
  font-family: 'Montserrat', sans-serif;
  border-radius: 2px;
  font-size: 15px;
}

.form-group {
  margin: 0;
}

.hidden {
  visibility: hidden;
}

button {
  cursor: pointer;
}

/* remove blue outline */
button:focus {
  outline: 0;
}

.expandCompressChartButton {
  position: absolute;
  top: 1vh;
  right: 1.5vw;
  background-color: transparent;
  border: none;
  z-index: 5;
}

.compressChartButtonIcon {
  height: 35px;
  width: 35px;
}

.expandChartButtonIcon {
  height: 18px;
  width: 18px;
}

.xSmallChartWidget {
  position: absolute;
  z-index: 3;
  max-width: 60vw;
  background-color: rgba(255,255,255,0.8);
  margin-left: 37vw;
  bottom: 8px;
  margin-bottom: 0.5vh;
}

.smallChartWidget {
  position: absolute;
  z-index: 3;
  max-width: 60vw;
  background-color: rgba(255,255,255,0.8);
  margin-left: 37vw;
  bottom: 8px;
  margin-bottom: 0.5vh;
}

.largeChartWidget {
  position: absolute;
  z-index: 4;
  width: 100%;
  height: 90vh;
  overflow: hidden;
  background-color: rgba(255,255,255,1);
  padding: 20px;
}

.xSmallChart {
  width: 20vw;
  height: 32vh;
  position: relative;
  float: left;
}

.smallChart {
  width: 30vw;
  height: 32vh;
  position: relative;
  float: left;
}

.largeChart {
  width: 100%;
  position: absolute;
  background-color: rgba(255,255,255,1);
  z-index: 10;
}

.legendDescriptor {
  margin: 0px 0px 0px 5px;
  font-family: 'Montserrat', sans-serif;
}
.circleBase {
  border-radius: 50%;
  background: red;
  margin: 0.5vw;
  display: inline;
}
.legendRow {
  justify-content: left;
  display: flex;
  align-items: center;
}
#firstFeaturePropDescriptor {
  position: absolute;
  left: 40px;
}
#lastFeaturePropDescriptor {
  position: absolute;
  right: 40px;
}
.legendGradientKey {
  margin-top: 5px;
  background: red;
  height: 30px;
}
#closeLegendButton {
  background-color: transparent;
  border: none;
  width: 100%;
  display: flex;
  justify-content: flex-end;
}
#closeLegendButtonIcon {
  height: 16px;
  width: 16px;
}
#legend {
  background-color: white;
  border-radius: 3px;
  position: absolute;
  right: 10px;
  top: 87px;
  z-index: 3;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
  padding: 0.8rem 10px 0.8rem 10px;
  opacity: 0.9;
  max-height: 87%;
  display: flex;
  flex-flow: row wrap;
  max-width: 40vw;
  overflow: auto;
  justify-content: space-evenly;
}

#legendButton .toolTipText {
  visibility: hidden;
  width: 100px;
  background-color: black;
  color: #fff;
  font-family: 'Montserrat', sans-serif;;
  text-align: center;
  border-radius: 6px;
  padding: 5px 0;

  /* Position the tooltip */
  position: absolute;
  z-index: 1;
  top: -3px;
  right: 105%;
}

#legendButton:hover .toolTipText {
  visibility: visible;
}

.mapButtonIcon {
  fill: white;
  height: 25px;
  width: 25px;
}

#legendButton {
  background-color: transparent;
  border: none;
  position: relative;
  display: inline-block;
}

#mapButtons {
  position: absolute;
  z-index: 1;
  right: 0;
  top: 80px;
  padding: 1rem 18px 1rem 20px;
}

.layerDescription {
  padding: 7px;
  margin: 5px;
  align-self: flex-start;
  width: 170px;
}

hr.solid {
  border-top: 2px solid #bbb;
}

.card-body {
  padding: 0.7rem;
}
</style>

<style>
.mapboxgl-popup-content {
  word-wrap: break-word;
}
</style>

<style src="../../../node_modules/vue-multiselect/dist/vue-multiselect.min.css"></style>
