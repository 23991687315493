<template>
  <div id="mapStylePanel">
    <div id="mapDisplayOptions">
      <div id="options">
        <div class="displayChoiceButtons"
            v-for="choices in mapDisplayOptions"
            :key="choices.value">
          <div class="legendContainer">
            <div class="legendColor" :style="{ background: choices.legendColor, width: '12px', height: '12px'}"/>
            <label class="checkboxContainer" v-if="choices.featureOn">
              <input
                class="checkboxInput"
                type="checkbox"
                :checked="choices.checked"
                @change="onDisplayOptionsChange(choices.value)"
              />
              <span class="switch"></span>
              <span class="label">{{ choices.text }}</span>
            </label>
          </div>
        </div>
      </div>
    </div>
    <div id='baseStyleButtons'>
      <b-form-radio-group
        id="base-style-choice"
        v-model="selectedBaseLayer"
        name="base-style-choice"
      >
        <b-form-radio class="baseLayerSelection" value="scania">Scania</b-form-radio>
        <b-form-radio class="baseLayerSelection" value="light-v10">Light</b-form-radio>
        <b-form-radio class="baseLayerSelection" value="outdoors-v11">Bright</b-form-radio>
        <b-form-radio class="baseLayerSelection" value="satellite-v9">Satellite</b-form-radio>
        <b-form-radio class="baseLayerSelection" value="navigation-night-v1">Dark streets</b-form-radio>
        <b-form-radio class="baseLayerSelection" value="navigation-day-v1">Light streets</b-form-radio>
        <b-form-radio class="baseLayerSelection" value="satellite-streets-v11">Streets+Satellite</b-form-radio>
       </b-form-radio-group>
    </div>
    <div style="margin-top: 10px"  v-if="this.$store.getters.getAppConfigFlag('canSeeUploadedGeoLayer')">
      <b-form-group id="dataLayers">
        <div id="listLayers" v-show=true>
          <multiselect v-model="chosenLayer"
                       id="customerLayers"
                       label="name"
                       track-by="name"
                       placeholder="Select a layer"
                       open-direction="bottom"
                       :options="availableLayers"
                       :multiple="true"
                       :searchable="true"
                       :loading="isLoading"
                       :internal-search="false"
                       :clear-on-select="false"
                       :close-on-select="false"
                       :options-limit="300"
                       :limit="3"
                       :limit-text="limitText"
                       :max-height="600"
                       :show-no-results="false"
                       :hide-selected="true"
                       @open="populateLayerList"
                       @select="fetchLayer"
                       @remove="removeLayer">
            <template slot="tag" slot-scope="{ option, remove }">
                <span class="custom__tag">
                  <span>{{ option.name }}</span>
                  <span class="custom__remove" @click="remove(option)">❌</span></span>
            </template>
            <template slot="clear" slot-scope="props">
              <div class="multiselect__clear"
                   v-if="chosenLayer.length"
                   @mousedown.prevent.stop="clearAll(props.search)"></div>
            </template>
            <span slot="noResult">Oops! No layers found.</span>
          </multiselect>
        </div>
      </b-form-group>
    </div>
  </div>
</template>

<script>
import Multiselect from '../../../../node_modules/vue-multiselect/src'
import axios from 'axios'

export default {
  name: 'MapStylePanel',
  components: { Multiselect },
  data () {
    return {
      selectedBaseLayer: 'scania',
      chosenLayer: [],
      isLoading: false,
      mapDisplayOptions: [
        { text: 'Show home-depot transport pattern edges',
          value: 'showHomeDepotTransportPatternEdges',
          checked: true,
          visible: true,
          featureOn: true,
          legendColor: '#0028C8'
        },
        { text: 'Show home-depot transport pattern destinations',
          value: 'showHomeDepotTransportPatternDestination',
          checked: false,
          visible: true,
          featureOn: true,
          legendColor: '#0080FF'
        },
        { text: 'Show contributing home-depot traffic edges',
          value: 'showOriginEdges',
          checked: true,
          visible: true,
          featureOn: true,
          legendColor: '#00C828'
        },
        { text: 'Show home-depot contribution',
          value: 'showOriginContribution',
          checked: false,
          visible: true,
          featureOn: true,
          legendColor: '#00FF80'
        },
        { text: 'Show en route overnight parking',
          value: 'showEnRouteOverNightParkings',
          checked: true,
          visible: true,
          featureOn: true,
          legendColor: '#008000'
        },
        { text: 'Show home depot overnight parking',
          value: 'showHomeDepotOverNightParkings',
          checked: true,
          visible: true,
          featureOn: true,
          legendColor: '#800080'
        }
      ]
    }
  },
  computed: {
    mapObject: {
      get () {
        return this.$store.getters.getBaseMap
      },
      set (newMap) {
        this.$store.commit('setBaseMap', newMap)
      }
    },
    availableLayers: {
      get () {
        return this.$store.getters['getAvailableLayers']
      },
      set (value) {
        this.$store.commit('setAvailableLayers', value)
      }
    }
  },
  mounted () {
    this.populateLayerList()
  },
  methods: {
    onDisplayOptionsChange (optionValue) {
      const updatedOption = this.mapDisplayOptions.find(option => option.value === optionValue)
      updatedOption.checked = !updatedOption.checked
      this.$emit('update-map-display-options', {
        option: optionValue, value: updatedOption.checked
      })
    },
    limitText (count) {
      return `and ${count} other layers`
    },
    clearAll () {
      this.chosenLayer = []
    },
    populateLayerList () {
      let startTime = Date.now()
      let availableLayers = []

      let lambdaPath = '/Prod/ListLocationFiles'
      let endpoint = this.$store.getters.getAppConfigFlag('useStagingStack') ? process.env.VUE_APP_UPLOAD_ENDPOINT_B : process.env.VUE_APP_UPLOAD_ENDPOINT
      let getUrl = endpoint + lambdaPath
      try {
        axios
          .get(getUrl, {
            headers: { 'Authorization': this.$store.getters.getBearerToken },
            params: { 'foldername': 'points' }
          })
          .then(res => {
            res.data.file_folders.forEach(element => {
              let rx = /points\/(.+)\.json/g
              let name = rx.exec(element)
              availableLayers.push({ name: name[1] })
            })
            this.availableLayers = availableLayers
          })
        this.$logger.message(`{"populateLayerList":"Fetched datalayers: ${JSON.stringify(this.availableLayers)}, it took: ${(Date.now() - startTime) / 1000}"}`, 'info')
      } catch (e) {
        this.$logger.message(`{"populateLayerList":"There was an error in populateLayerList: ${e}"}`, 'error')
      }
    },
    removeLayer (id) {
      this.$logger.message(`{"removeLayer":"emit an event to hide/remove layer from map for id + ${id.name}"}`)
      this.$emit('remove-map-layer', id.name)
    },
    async fetchLayer (id) {
      let lambdaPath = '/Prod/FetchLocationFile'
      let endpoint = this.$store.getters.getAppConfigFlag('useStagingStack') ? process.env.VUE_APP_UPLOAD_ENDPOINT_B : process.env.VUE_APP_UPLOAD_ENDPOINT
      let getUrl = endpoint + lambdaPath
      try {
        await axios
          .get(getUrl, {
            headers: { 'Authorization': this.$store.getters.getBearerToken
            },
            params: {
              filename: id.name,
              foldername: 'points'
            }
          })
          .then(res => {
            this.$emit('fetch-customer-data', { geojson: res.data, name: id.name })
          })
        this.$logger.message(`{"fetchLayer":"Selected datalayer: ${JSON.stringify(this.chosenLayer)}"}`, 'info')
      } catch (e) {
        this.$logger.message(`{"fetchLayer":"There was an error in fetchLayer: ${e}"}`, 'error')
      }
    }
  },
  watch: {
    selectedBaseLayer: function () {
      this.$emit('select-map-style', this.selectedBaseLayer)
      this.$logger.message(`{"selectedBaseLayer":"select-map-style ${this.selectedBaseLayer}"}`, 'info')
    }
  }
}
</script>

<style scoped>
#mapStylePanel {
  display: flex;
  width: 75%;
  justify-content: center;
  flex-direction: column;
  margin-left: 12.5%;
}

#baseStyleButtons {
  background: #f8f9fa;
  color: #212529;
  padding: 10px 2px;
  border-radius: 2px;
  font-size: 14px;
  font-family: 'Montserrat', sans-serif;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}

.baseLayerSelection {
  width: 140px;
}

#base-style-choice {
  padding-left: 10px;
}

#mapDisplayOptions {
  display: flex;
  justify-content: flex-start;
}

#options {
  border-radius: 2px;
  font-family: 'Montserrat', sans-serif;
  background: #f8f9fa;
  color: #212529;
  margin-top: 10px;
  margin-bottom: 14px;
  width: 100%;
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  padding: 8px 4px;
}

.displayChoiceButtons {
  font-size: small;
  padding: 8px;
  display: flex;
  justify-content: flex-start;
}

.checkboxContainer {
  cursor: pointer;
  display: flex;
  align-items: center;
  margin: 0;
  color: #1a202c;
}

.checkboxInput {
  display: none;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border-width: 0;
}

.legendContainer {
  display: inline-flex;
}

.legendColor {
  white-space: nowrap;
  margin: 3px;
}

.switch {
  --switch-container-width: 35px;
  --switch-size: 20px;
}

.switch {
  --switch-container-width: 35px;
  --switch-size: 20px;

  /* Vertically center the inner circle */
  display: flex;
  align-items: center;
  position: relative;
  height: var(--switch-size);
  flex-basis: var(--switch-container-width);
  /* Make the container element rounded */
  border-radius: var(--switch-size);
  background-color: #e2e8f0;
}

.switch::before {
  content: "";
  /* Move a little bit the inner circle to the right */
  left: 1px;
  height: calc(var(--switch-size) - 4px);
  width: calc(var(--switch-size) - 4px);
  /* Make the inner circle fully rounded */
  border-radius: 9999px;
  background-color: white;
}

.checkboxInput:checked + .switch {
  /* Teal background */
  background-color: #4fd1c5;
}

.checkboxInput:checked + .switch::before {
  border-color: #4fd1c5;
  /* Move the inner circle to the right */
  transform: translateX(
    calc(var(--switch-container-width) - var(--switch-size))
  );
}

.switch {
  /* ... */
  transition: background-color 0.25s ease-in-out;
}

.switch::before {
  /* ... */
  transition: transform 0.375s ease-in-out;
}

.switch {
  /* ... */

  --light-gray: #e2e8f0;
  --gray: #cbd5e0;
  --dark-gray: #a0aec0;
  --teal: #4fd1c5;
  --dark-teal: #319795;

  /* ... */
  background-color: var(--light-gray);
}

.checkboxInput:checked + .switch {
  background-color: var(--teal);
}

.checkboxInput:checked + .switch::before {
  border-color: var(--teal);
  /* ... */
}

.switch::before {
  /* ... */
  border: 2px solid var(--light-gray);
}

.checkboxInput:focus + .switch::before {
  border-color: var(--dark-gray);
}

.checkboxInput:focus:checked + .switch::before {
  border-color: var(--dark-teal);
}
.checkboxInput:disabled + .switch {
  background-color: var(--gray);
}

.checkboxInput:disabled + .switch::before {
  background-color: var(--dark-gray);
  border-color: var(--dark-gray);
}

.switch {
  /* ... */
  /* In case the label gets long, the toggle shouldn't shrink. */
  flex-shrink: 0;
}

.label {
  /* ... */
  /* Show an ellipsis if the text takes more than one line */
  margin-left: 5px;
  text-overflow: ellipsis;
  white-space: nowrap;
  margin-right: 15px;
}

</style>
