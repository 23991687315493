import axios from 'axios'
import store from '../store/store.js'
import axiosRetry from 'axios-retry'
import * as fetchRetry from 'fetch-retry'

export default {
  methods: {
    async getQueryIdFromSnowflake (sqlFileName, queryForFilterVariable) {
      let endpoint = this.$store.getters.getAppConfigFlag('useStagingStack') ? process.env.VUE_APP_GRAPH_ENDPOINT_B : process.env.VUE_APP_GRAPH_ENDPOINT
      // let path = this.$store.getters.getUserSelection === 'chassis' ? '/Prod/PostSnowflakeQueryCache' : lambdaPath
      let path
      switch (this.$store.getters.getUserSelection) {
        case 'chassis':
          path = '/Prod/PostSnowflakeQueryCache'
          break
        case 'chassis-bu':
          path = '/Prod/PostSnowflakeMarketQueryCache'
          break
      }
      const url = endpoint + path
      const request = axios.create()

      this.$store.commit('setQueryStatus', {
        queryFor: queryForFilterVariable,
        queryState: false
      })

      return request
        .post(url, {}, {
          headers: {
            Authorization: this.$store.getters.getBearerToken
          },
          params: {
            minodometer: this.$store.getters['chassis/getSelectedMinMaxOdometer'][0] == null ? this.$store.getters['chassis/getMinMaxOdometer'][0] : this.$store.getters['chassis/getSelectedMinMaxOdometer'][0],
            maxodometer: this.$store.getters['chassis/getSelectedMinMaxOdometer'][1] == null ? this.$store.getters['chassis/getMinMaxOdometer'][1] : this.$store.getters['chassis/getSelectedMinMaxOdometer'][1],
            minfuel: this.$store.getters['chassis/getSelectedMinMaxFuel'][0] == null ? this.$store.getters['chassis/getMinMaxFuel'][0] : this.$store.getters['chassis/getSelectedMinMaxFuel'][0],
            maxfuel: this.$store.getters['chassis/getSelectedMinMaxFuel'][1] == null ? this.$store.getters['chassis/getMinMaxFuel'][1] : this.$store.getters['chassis/getSelectedMinMaxFuel'][1],
            maxduration: this.$store.getters.getAppConfigFlag('isMarketUser') ? this.$store.getters['chassis/getSelectedMinMaxDuration'][1] == null ? this.$store.getters['chassis/getMinMaxDuration'][1] * 60 : this.$store.getters['chassis/getSelectedMinMaxDuration'][1] * 60 : this.$store.getters['chassis/getSelectedMinMaxDuration'][1] == null ? this.$store.getters['chassis/getMinMaxDuration'][1] : this.$store.getters['chassis/getSelectedMinMaxDuration'][1],
            minduration: this.$store.getters.getAppConfigFlag('isMarketUser') ? this.$store.getters['chassis/getSelectedMinMaxDuration'][0] == null ? this.$store.getters['chassis/getMinMaxDuration'][0] * 60 : this.$store.getters['chassis/getSelectedMinMaxDuration'][0] * 60 : this.$store.getters['chassis/getSelectedMinMaxDuration'][0] == null ? this.$store.getters['chassis/getMinMaxDuration'][0] : this.$store.getters['chassis/getSelectedMinMaxDuration'][0],
            // minodometer: this.$store.getters['chassis/getSelectedMinMaxOdometer'][0],
            // maxodometer: this.$store.getters['chassis/getSelectedMinMaxOdometer'][1],
            // minfuel: this.$store.getters['chassis/getSelectedMinMaxFuel'][0],
            // maxfuel: this.$store.getters['chassis/getSelectedMinMaxFuel'][1],
            // minduration: this.$store.getters.getAppConfigFlag('isMarketUser') ? this.$store.getters['chassis/getSelectedMinMaxDuration'][0] * 60 : this.$store.getters['chassis/getSelectedMinMaxDuration'][0],
            // maxduration: this.$store.getters.getAppConfigFlag('isMarketUser') ? this.$store.getters['chassis/getSelectedMinMaxDuration'][1] * 60 : this.$store.getters['chassis/getSelectedMinMaxDuration'][1],
            mindate: this.$store.getters['chassis/getSelectedMinTime'].toLocaleDateString('sv-SE'), // + 'T00:00',
            maxdate: this.$store.getters['chassis/getSelectedMaxTime'].toLocaleDateString('sv-SE'), // + 'T23:59',
            constantmaxdate: this.$store.getters['chassis/getTilesConstantMaxTime'].toLocaleString('sv-SE'),
            hexresolution: this.$store.getters['chassis/getHexagonResolution'],
            chassis: this.$store.getters['chassis/getSelectedChassis'].join(','),
            hexagonhexes: this.$store.getters['chassis/getSelectedH3CellIndex'].length > 0
              ? this.$store.getters['chassis/getSelectedH3CellIndex'].join(',') : 'none',
            stopqueryid: this.$store.getters.getQueryIdFor('rawStops'),
            selectedweekdays: this.$store.getters['chassis/getSelectedWeekdays'].length > 0
              ? this.$store.getters['chassis/getSelectedWeekdays'].join(',') : 'none',
            sqlfilename: sqlFileName
          }
        })
        .then(response => {
          this.$store.commit('setQueryIdFor', {
            queryFor: queryForFilterVariable,
            queryId: response.data.queryId
          })
        })
    },
    async getQueryIdForLocations (queryForFilterVariable, requestParams) {
      const lambdaPath = '/Prod/PostLocationQuery'
      let endpoint = this.$store.getters.getAppConfigFlag('useStagingStack') ? process.env.VUE_APP_LOCATIONS_ENDPOINT_B : process.env.VUE_APP_LOCATIONS_ENDPOINT

      const url = endpoint + lambdaPath
      const request = axios.create()
      this.$store.commit('setQueryStatus', {
        queryFor: queryForFilterVariable,
        queryState: false
      })

      return request
        .post(url, {}, {
          headers: {
            Authorization: this.$store.getters.getBearerToken
          },
          params: requestParams
        })
        .then(response => {
          this.$store.commit('setQueryIdFor', {
            queryFor: queryForFilterVariable,
            queryId: response.data.queryId
          })
        })
    },
    async getQueryIdForClassifiedLocations (queryForFilterVariable, requestParams) {
      const lambdaPath = '/Prod/PostLocationQuery'
      let endpoint = this.$store.getters.getAppConfigFlag('useStagingStack') ? process.env.VUE_APP_CLASSIFIED_LOCATIONS_ENDPOINT_B : process.env.VUE_APP_CLASSIFIED_LOCATIONS_ENDPOINT

      const url = endpoint + lambdaPath
      const request = axios.create()
      this.$store.commit('setQueryStatus', {
        queryFor: queryForFilterVariable,
        queryState: false
      })

      return request
        .post(url, {}, {
          headers: {
            Authorization: this.$store.getters.getBearerToken
          },
          params: requestParams
        })
        .then(response => {
          this.$store.commit('setQueryIdFor', {
            queryFor: queryForFilterVariable,
            queryId: response.data.queryId
          })
        })
    },
    async getQueryResultsForClassifiedLocations (queryForFilterVariable, requestParams) {
      const lambdaPath = '/Prod/PostLocationQuery'
      let endpoint = this.$store.getters.getAppConfigFlag('useStagingStack') ? process.env.VUE_APP_CLASSIFIED_LOCATIONS_ENDPOINT_B : process.env.VUE_APP_CLASSIFIED_LOCATIONS_ENDPOINT

      const url = endpoint + lambdaPath
      const request = axios.create()
      this.$store.commit('setQueryStatus', {
        queryFor: queryForFilterVariable,
        queryState: false
      })

      return request
        .post(url, {}, {
          headers: {
            Authorization: this.$store.getters.getBearerToken
          },
          params: requestParams
        })
    },
    async getQueryIdForOutbound (queryForFilterVariable) {
      const lambdaPath = '/Prod/PostOutboundQuery'
      let endpoint = this.$store.getters.getAppConfigFlag('useStagingStack')
        ? process.env.VUE_APP_OUTBOUND_ENDPOINT_B : process.env.VUE_APP_OUTBOUND_ENDPOINT

      const url = endpoint + lambdaPath
      const request = axios.create()
      this.$store.commit('setQueryStatus', {
        queryFor: queryForFilterVariable,
        queryState: false
      })

      return request
        .post(url, {}, {
          headers: {
            Authorization: this.$store.getters.getBearerToken
          }
        })
        .then(response => {
          this.$store.commit('setQueryIdFor', {
            queryFor: queryForFilterVariable,
            queryId: response.data.queryId
          })
        })
    },
    async getLocationDataForQueryId (queryId) {
      let endpoint = this.$store.getters.getAppConfigFlag('useStagingStack') ? process.env.VUE_APP_LOCATIONS_ENDPOINT_B : process.env.VUE_APP_LOCATIONS_ENDPOINT

      const url = endpoint + '/Prod/GetLocationDataForQueryId'
      const axiosClient = axios.create()
      const retryCondition = (error) => {
        return (
          axiosRetry.isNetworkOrIdempotentRequestError(error) || error.response.status === 504
        )
      }
      axiosRetry(axiosClient, { retries: 200, retryDelay: axiosRetry.exponentialDelay, retryCondition })

      return axiosClient
        .post(url, {}, {
          headers: {
            Authorization: store.getters.getBearerToken
          },
          params: {
            snowflakequeryid: queryId
          }
        })
    },
    async getClassifiedLocationDataForQueryId (queryId) {
      let endpoint = this.$store.getters.getAppConfigFlag('useStagingStack') ? process.env.VUE_APP_CLASSIFIED_LOCATIONS_ENDPOINT_B : process.env.VUE_APP_CLASSIFIED_LOCATIONS_ENDPOINT

      const url = endpoint + '/Prod/GetLocationDataForQueryId'
      const axiosClient = axios.create()
      const retryCondition = (error) => {
        return (
          axiosRetry.isNetworkOrIdempotentRequestError(error) || error.response.status === 504
        )
      }
      axiosRetry(axiosClient, { retries: 200, retryDelay: axiosRetry.exponentialDelay, retryCondition })

      return axiosClient
        .post(url, {}, {
          headers: {
            Authorization: store.getters.getBearerToken
          },
          params: {
            snowflakequeryid: queryId
          }
        })
    },
    async getOutboundDataForQueryId (queryId) {
      let endpoint = this.$store.getters.getAppConfigFlag('useStagingStack')
        ? process.env.VUE_APP_OUTBOUND_ENDPOINT_B : process.env.VUE_APP_OUTBOUND_ENDPOINT

      const url = endpoint + '/Prod/GetOutboundDataForQueryId'
      const axiosClient = axios.create()
      const retryCondition = (error) => {
        return (
          axiosRetry.isNetworkOrIdempotentRequestError(error) || error.response.status === 504
        )
      }
      axiosRetry(axiosClient, { retries: 200, retryDelay: axiosRetry.exponentialDelay, retryCondition })

      return axiosClient
        .post(url, {}, {
          headers: {
            Authorization: store.getters.getBearerToken
          },
          params: {
            snowflakequeryid: queryId
          }
        })
    },
    async getStatusForQueryId (queryId) {
      let endpoint = this.$store.getters.getAppConfigFlag('useStagingStack') ? process.env.VUE_APP_GRAPH_ENDPOINT_B : process.env.VUE_APP_GRAPH_ENDPOINT
      const url = endpoint + '/Prod/GetStatusForQueryId'
      const axiosClient = axios.create()

      const retryCondition = (error) => {
        return (
          axiosRetry.isNetworkOrIdempotentRequestError(error) || error.response.status === 504
        )
      }

      axiosRetry(axiosClient, { retries: 200, retryDelay: axiosRetry.exponentialDelay, retryCondition })

      return axiosClient
        .post(url, {}, {
          headers: {
            Authorization: store.getters.getBearerToken
          },
          params: {
            snowflakequeryid: queryId
          }
        })
    },
    async getStatusForClassifiedLocationQuery (queryId) {
      let endpoint = this.$store.getters.getAppConfigFlag('useStagingStack')
        ? process.env.VUE_APP_CLASSIFIED_LOCATIONS_ENDPOINT_B : process.env.VUE_APP_CLASSIFIED_LOCATIONS_ENDPOINT

      const url = endpoint + '/Prod/GetStatusForQueryId'
      const axiosClient = axios.create()

      const retryCondition = (error) => {
        return (
          axiosRetry.isNetworkOrIdempotentRequestError(error) || error.response.status === 504
        )
      }

      axiosRetry(axiosClient, { retries: 200, retryDelay: axiosRetry.exponentialDelay, retryCondition })

      return axiosClient
        .post(url, {}, {
          headers: {
            Authorization: store.getters.getBearerToken
          },
          params: {
            snowflakequeryid: queryId
          }
        })
    },
    async getStatusForLocationQuery (queryId) {
      let endpoint = this.$store.getters.getAppConfigFlag('useStagingStack')
        ? process.env.VUE_APP_LOCATIONS_ENDPOINT_B : process.env.VUE_APP_LOCATIONS_ENDPOINT

      const url = endpoint + '/Prod/GetStatusForQueryId'

      const params = { snowflakequeryid: queryId }
      const fetchUrl = url + '?' + (new URLSearchParams(params)).toString()
      const fetchWithRetry = fetchRetry(fetch)
      return fetchWithRetry(fetchUrl, {
        method: 'POST',
        headers: {
          Authorization: store.getters.getBearerToken
        },
        retryDelay: 2000,
        retryOn: [504],
        retries: 1000
      })
    },
    async getStatusForOutboundQuery (queryId) {
      let endpoint = this.$store.getters.getAppConfigFlag('useStagingStack')
        ? process.env.VUE_APP_OUTBOUND_ENDPOINT_B : process.env.VUE_APP_OUTBOUND_ENDPOINT

      const url = endpoint + '/Prod/GetStatusForOutboundQueryId'
      const axiosClient = axios.create()

      const retryCondition = (error) => {
        return (
          axiosRetry.isNetworkOrIdempotentRequestError(error) || error.response.status === 504
        )
      }

      axiosRetry(axiosClient, { retries: 200, retryDelay: axiosRetry.exponentialDelay, retryCondition })

      return axiosClient
        .post(url, {}, {
          headers: {
            Authorization: store.getters.getBearerToken
          },
          params: {
            snowflakequeryid: queryId
          }
        })
    },
    async getStopQueryState () {
      let queryId = store.getters.getQueryIdFor('rawStops')
      await this.getStatusForQueryId(queryId)
        .then(response => {
          this.$store.commit('setQueryStatus', {
            queryFor: 'rawStops',
            queryState: true
          })
        })
    },

    async getDataForQueryId (queryId) {
      let endpoint = this.$store.getters.getAppConfigFlag('useStagingStack')
        ? process.env.VUE_APP_GRAPH_ENDPOINT_B : process.env.VUE_APP_GRAPH_ENDPOINT
      const url = endpoint + '/Prod/GetDataForQueryId'
      const axiosClient = axios.create()
      const retryCondition = (error) => {
        return (
          axiosRetry.isNetworkOrIdempotentRequestError(error) || error.response.status === 504
        )
      }

      axiosRetry(axiosClient, { retries: 200, retryDelay: axiosRetry.exponentialDelay, retryCondition })

      return axiosClient
        .post(url, {}, {
          headers: {
            Authorization: store.getters.getBearerToken
          },
          params: {
            snowflakequeryid: queryId
          }
        })
    },

    async getHexagonLayerFromSnowflake () {
      let queryId = this.$store.getters.getQueryIdFor('hexagons')
      return this.getDataForQueryId(queryId)
        .then(response => {
          const dataObj = store.dispatch('chassis/setHexagonLayerAsync', response.data)

          return Promise.all([dataObj]).then(() => {
            this.$store.commit('setQueryStatus', {
              queryFor: 'hexagons',
              queryState: true
            })
            this.$store.commit('chassis/setHexagonLayerLoaded', true)
          })
        })
    },

    async getFuelBoxplotFromSnowflake () {
      let queryId = this.$store.getters.getQueryIdFor('fuelBoxplot')

      return this.getDataForQueryId(queryId)
        .then(response => {
          const graphObj = store.dispatch('chassis/setGraphDataStructureAsync', response.data)

          return Promise.all([graphObj]).then(() => {
            this.$store.commit('setQueryStatus', {
              queryFor: 'fuelBoxplot',
              queryState: true
            })
          })
        })
    },

    async getDailyOdometerBoxplotFromSnowflake () {
      let queryId = this.$store.getters.getQueryIdFor('dailyOdometerBoxplot')

      return this.getDataForQueryId(queryId)
        .then(response => {
          const graphObj = this.$store.dispatch('chassis/setDailyOdometerGraphDataStructureAsync', response.data)

          return Promise.all([graphObj]).then(() => {
            this.$store.commit('setQueryStatus', {
              queryFor: 'dailyOdometerBoxplot',
              queryState: true
            })
          })
        })
    },

    async getDailyFuelBoxplotFromSnowflake () {
      let queryId = this.$store.getters.getQueryIdFor('dailyFuelBoxplot')

      return this.getDataForQueryId(queryId)
        .then(response => {
          const graphObj = store.dispatch('chassis/setDailyFuelGraphDataStructureAsync', response.data)

          return Promise.all([graphObj]).then(() => {
            this.$store.commit('setQueryStatus', {
              queryFor: 'dailyFuelBoxplot',
              queryState: true
            })
          })
        })
    },

    async getOdometerBoxplotFromSnowflake () {
      let queryId = store.getters.getQueryIdFor('odometerBoxplot')

      return this.getDataForQueryId(queryId)
        .then(response => {
          const graphObj = store.dispatch('chassis/setOdometerGraphDataStructureAsync', response.data)

          return Promise.all([graphObj]).then(() => {
            this.$store.dispatch('chassis/setGraphColoursAsync').then(() => {
              this.$store.commit('setQueryStatus', {
                queryFor: 'odometerBoxplot',
                queryState: true
              })
            })
          })
        })
    },

    async getHourlyLocationDistributionFromSnowflake () {
      let queryId = this.$store.getters.getQueryIdFor('hourlyLocationDistribution')

      return this.getDataForQueryId(queryId)
        .then(response => {
          const graphObj = store.dispatch('chassis/setHourlyLocationDistributionDataStructureAsync', response.data)
          return Promise.all([graphObj]).then(() => {
            this.$store.commit('setQueryStatus', {
              queryFor: 'hourlyLocationDistribution',
              queryState: true
            })
          })
        })
    },

    async getDailyGraphAggregateFromSnowflake () {
      let queryId = this.$store.getters.getQueryIdFor('dailyAggregates')
      return this.getDataForQueryId(queryId)
        .then(response => {
          const dataObj = store.dispatch('chassis/setStopDataObjAsync', response.data)
          return Promise.all([dataObj]).then(() => {
            this.$store.commit('setQueryStatus', {
              queryFor: 'dailyAggregates',
              queryState: true
            })
          })
        })
    },

    async getDailyHourlyLocationDistributionFromSnowflake () {
      let queryId = this.$store.getters.getQueryIdFor('dailyHourlyLocationDistribution')
      return this.getDataForQueryId(queryId)
        .then(response => {
          const graphObj = store.dispatch('chassis/setDailyHourlyLocationDistributionDataStructureAsync', response.data)
          return Promise.all([graphObj]).then(() => {
            this.$store.commit('setQueryStatus', {
              queryFor: 'dailyHourlyLocationDistribution',
              queryState: true
            })
          })
        })
    },

    async getMinMaxValuesFromSnowflake () {
      let queryId = this.$store.getters.getQueryIdFor('minMaxRanges')

      return this.getDataForQueryId(queryId)
        .then(response => {
          const responseData = response.data[0]
          this.$store.commit('chassis/setMinMaxOdometer', [responseData.min_odometer_in_set, responseData.max_odometer_in_set])
          this.$store.commit('chassis/setMinMaxFuel', [responseData.min_fuel_in_set, responseData.max_fuel_in_set])
          this.$store.getters.getAppConfigFlag('isMarketUser') ? this.$store.commit('chassis/setMinMaxDuration', [Math.floor(responseData.min_duration_in_set / 60), Math.round(responseData.max_duration_in_set / 60)]) : this.$store.commit('chassis/setMinMaxDuration', [ responseData.min_duration_in_set, responseData.max_duration_in_set ])
          this.$store.commit('chassis/setSelectedMinMaxOdometer', [null, null])
          this.$store.commit('chassis/setSelectedMinMaxFuel', [null, null])
          this.$store.commit('chassis/setSelectedMinMaxDuration', [null, null])
          this.$store.commit('setQueryStatus', {
            queryFor: 'minMaxRanges',
            queryState: true
          })
        })
    },
    async getSelectedMinMaxValuesFromSnowflake () {
      let queryId = this.$store.getters.getQueryIdFor('minMaxRanges')

      return this.getDataForQueryId(queryId)
        .then(response => {
          this.$store.commit('setQueryStatus', {
            queryFor: 'minMaxRanges',
            queryState: true
          })
        })
    },

    async getEdgeLayerFromSnowflake () {
      let queryId = this.$store.getters.getQueryIdFor('edges')
      return this.getDataForQueryId(queryId)
        .then(response => {
          const dataObj = store.dispatch('chassis/setEdgeLayerAsync', response.data)
          return Promise.all([dataObj]).then(() => {
            this.$store.commit('setQueryStatus', {
              queryFor: 'edges',
              queryState: true
            })
            this.$store.commit('chassis/setEdgeLayerLoaded', true)
          })
        })
        .catch((e) => {
          store.dispatch('chassis/setEdgeLayerAsync', [])
          this.$store.commit('chassis/setEdgeLayerLoaded', false)
          this.$logger.message(`{"onFilterButton":"No edges for the filter: ${e}"}`, 'error')
          return true
        })
    }
  }
}
