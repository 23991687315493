<template>
  <div id="graphPanel">
    <div id="graphSelection">
      <div id="graphs">
        <div class="graphChoiceButtons"
             v-for="choices in graphOptions"
             :key="choices.value">
          <label class="checkboxContainer" v-if="choices.featureOn">
            <input
              class="checkboxInput"
              type="checkbox"
              :checked="choices.checked"
              @change="onCheckboxChange(choices.value)"
            />
            <span class="switch"></span>
            <span class="label">{{ choices.text }}</span>
          </label>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: 'GraphPanel',
  components: {},
  data () {
    return {
      selectedGraphs: [
        'homeDepotNumberOfParkedVehicleDistribution',
        'homeDepotEnergyNeedDistributionEstimatedByDistance'
      ],
      graphOptions: [
        { text: 'Distribution of parked vehicles',
          default_text: 'Distribution of parked vehicles',
          value: 'numberOfParkedVehicleDistribution',
          stateFunction: 'numberOfParkedVehicleDistribution',
          tabName: 'parkedVehicles',
          checked: false,
          visible: true,
          featureOn: true,
          chartHeader: ['no_vehicles']
        },
        { text: 'Distribution of parked long haulage vehicles',
          default_text: 'Distribution of parked long haulage vehicles',
          value: 'longHaulageNumberOfParkedVehicleDistribution',
          stateFunction: 'longHaulageNumberOfParkedVehicleDistribution',
          tabName: 'longHaulageParkedVehicles',
          checked: false,
          visible: true,
          featureOn: true,
          chartHeader: ['no_long_haulage_vehicles']
        },
        { text: 'Distribution of parked home depot vehicles',
          default_text: 'Distribution of parked home depot vehicles',
          value: 'homeDepotNumberOfParkedVehicleDistribution',
          stateFunction: 'homeDepotNumberOfParkedVehicleDistribution',
          tabName: 'homeDepotParkedVehicles',
          checked: true,
          visible: true,
          featureOn: true,
          chartHeader: ['no_home_depot_vehicles']
        },
        { text: 'Distribution of arriving home depot vehicles',
          default_text: 'Distribution of arriving home depot vehicles',
          value: 'homeDepotNumberOfArrivalsDistribution',
          stateFunction: 'homeDepotNumberOfArrivalsDistribution',
          tabName: 'homeDepotArrivingVehicles',
          checked: false,
          visible: true,
          featureOn: true,
          chartHeader: ['no_arriving_vehicles']
        },
        { text: 'Distribution of departing home depot vehicles',
          default_text: 'Distribution of departing home depot vehicles',
          value: 'homeDepotNumberOfDeparturesDistribution',
          stateFunction: 'homeDepotNumberOfDeparturesDistribution',
          tabName: 'homeDepotDepartingVehicles',
          checked: false,
          visible: true,
          featureOn: true,
          chartHeader: ['no_departing_vehicles']
        },
        { text: 'Distribution of home depot energy need (v2) estimated by cycle distance',
          default_text: 'Distribution of home depot energy need estimated by cycle distance',
          value: 'homeDepotEnergyNeedDistributionEstimatedByDistance',
          stateFunction: 'homeDepotEnergyNeedDistributionEstimatedByDistance',
          tabName: 'homeDepotEnergyNeedEstimatedByCycleDistance',
          checked: true,
          visible: true,
          featureOn: true,
          chartHeader: ['home_depot_energy_need_estimated_by_cycle_distance']
        }
      ]
    }
  },
  methods: {
    onCheckboxChange (graph) {
      const index = this.selectedGraphs.indexOf(graph)
      const optionIndex = this.graphOptions.findIndex(obj => obj.value === graph)
      if (index === -1) {
        this.selectedGraphs.push(graph)
        this.graphOptions[optionIndex].visible = true
      } else {
        this.selectedGraphs.splice(this.selectedGraphs.indexOf(graph), 1)
        this.graphOptions[optionIndex].visible = false
      }
      this.$emit('update-graph-options', this.graphOptions)
      this.$emit('update-graph-selection', this.selectedGraphs)
    },
    getGraphTitle (graph) {
      return this.graphOptions.find(item => item.value === graph).text
    },
    getGraphTabName (graph) {
      return this.graphOptions.find(item => item.value === graph).tabName
    }
  }
}
</script>

<style scoped>
#graphPanel {
  display: flex;
  width: 100%;
  justify-content: flex-start;
  flex-direction: column;
}

#graphSelection {
  display: flex;
  justify-content: center;
}

#graphs {
  border-radius: 2px;
  font-family: 'Montserrat', sans-serif;
  background: #f8f9fa;
  color: #212529;
  margin-top: 10px;
  margin-bottom: 14px;
  width: 95%;
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  padding: 8px 4px;
}

.graphChoiceButtons {
  font-size: small;
  padding: 8px;
}

.checkboxContainer {
  cursor: pointer;
  display: flex;
  align-items: center;
  margin: 0;
  color: #1a202c;
}

.checkboxInput {
  display: none;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border-width: 0;
}

.switch {
  --switch-container-width: 35px;
  --switch-size: 20px;
}

.switch {
  --switch-container-width: 35px;
  --switch-size: 20px;

  /* Vertically center the inner circle */
  display: flex;
  align-items: center;
  position: relative;
  height: var(--switch-size);
  flex-basis: var(--switch-container-width);
  /* Make the container element rounded */
  border-radius: var(--switch-size);
  background-color: #e2e8f0;
}

.switch::before {
  content: "";
  /* Move a little bit the inner circle to the right */
  left: 1px;
  height: calc(var(--switch-size) - 4px);
  width: calc(var(--switch-size) - 4px);
  /* Make the inner circle fully rounded */
  border-radius: 9999px;
  background-color: white;
}

.checkboxInput:checked + .switch {
  /* Teal background */
  background-color: #4fd1c5;
}

.checkboxInput:checked + .switch::before {
  border-color: #4fd1c5;
  /* Move the inner circle to the right */
  transform: translateX(
    calc(var(--switch-container-width) - var(--switch-size))
  );
}

.switch {
  /* ... */
  transition: background-color 0.25s ease-in-out;
}

.switch::before {
  /* ... */
  transition: transform 0.375s ease-in-out;
}

.switch {
  /* ... */

  --light-gray: #e2e8f0;
  --gray: #cbd5e0;
  --dark-gray: #a0aec0;
  --teal: #4fd1c5;
  --dark-teal: #319795;

  /* ... */
  background-color: var(--light-gray);
}

.checkboxInput:checked + .switch {
  background-color: var(--teal);
}

.checkboxInput:checked + .switch::before {
  border-color: var(--teal);
  /* ... */
}

.switch::before {
  /* ... */
  border: 2px solid var(--light-gray);
}

.checkboxInput:focus + .switch::before {
  border-color: var(--dark-gray);
}

.checkboxInput:focus:checked + .switch::before {
  border-color: var(--dark-teal);
}
.checkboxInput:disabled + .switch {
  background-color: var(--gray);
}

.checkboxInput:disabled + .switch::before {
  background-color: var(--dark-gray);
  border-color: var(--dark-gray);
}

.switch {
  /* ... */
  /* In case the label gets long, the toggle shouldn't shrink. */
  flex-shrink: 0;
}

.label {
  /* ... */
  /* Show an ellipsis if the text takes more than one line */
  margin-left: 5px;
  text-overflow: ellipsis;
  white-space: nowrap;
  margin-right: 15px;
}

#exportButtonDiv {
  font-size: small;
  display: flex;
  justify-content: flex-end;
  margin-right: 15%;
}

</style>
