<template>
  <b-tab id="locationsTab" title="Depot Analytics">
    <div id="byCountry" v-show="selectByCountry">
      <multiselect v-model="chosenCountries"
                   track-by="code"
                   placeholder="Select a country"
                   :preserve-search="true"
                   label="name"
     :multiple="true"
     :options="options"></multiselect>
    </div>
    <div id="byActivity">
      <number-input v-model="minNumStops" :min="0" :max="2000" inline center controls></number-input>
      <label style="width:100%;font-size:small;color:#9BADBF;">Activity filter is set to show locations with more than {{ minNumStops }} stops</label>
    </div>
    <div class="fetch-location-container"  v-if="1 > 0">
      <h4 style="margin-top: 5px; display: inline-block"
          v-if="!isFetchingLocations && errorOnFetching">{{ errorWhenFetching }}</h4>
      <b-button style="align-self: flex-end;" class="btn-success"
                id="requestLocations" @click="requestLocations"
                :disabled="isFetchingLocations || chosenCountries.length === 0">
        Get locations
      </b-button>
    </div>
    <step-progress v-show="isFetchingLocations"
                   :steps="locationFetchSteps"
                   :activeStep="currentStep"
    />
  </b-tab>
</template>

<script>
import snowflake from '../mixins/snowflake'
import StepProgress from '@/components/StepProgress'
import Multiselect from '../../node_modules/vue-multiselect/src'
import NumberInput from '@chenfengyuan/vue-number-input'
import axios from 'axios'

export default {
  name: 'ClassifiedLocationsSelection',
  components: { StepProgress, Multiselect, NumberInput },
  mixins: [snowflake],
  data () {
    return {
      renderComponent: true,
      showMap: false,
      showBBox: true,
      showHexagons: true,
      selectByCountry: true,
      selectByHexagons: true,
      selectByPolygon: false,
      selectByNewPolygon: false,
      selectByExistingPolygon: false,
      request: {
        selectedHexes: []
      },
      hexagonResolution: 10,
      isFetchingLocations: false,
      errorWhenFetching: 'Error',
      errorOnFetching: false,
      flyToCentroid: [24, 33],
      locationFetchSteps: [ 'Start query', 'Await result', 'Fetch result' ],
      currentStep: 0,
      timeStart: 0,
      chosenCountries: [],
      minNumStops: 100,
      availablePolygonLayers: [],
      options: [{ code: 'UU', name: 'Unicorn Country' }]
    }
  },
  mounted () {
    const lambdaPath = '/Prod/GetCountryListFromS3'
    let endpoint = this.$store.getters.getAppConfigFlag('useStagingStack') ? process.env.VUE_APP_CLASSIFIED_LOCATIONS_ENDPOINT_B : process.env.VUE_APP_CLASSIFIED_LOCATIONS_ENDPOINT

    const url = endpoint + lambdaPath
    const request = axios.create()

    request
      .get(url, {
        headers: { 'Authorization': this.$store.getters.getBearerToken }
      })
      .then(response => {
        let options = []
        response.data.trim().split('\n').map(countryField => {
          let country = countryField.split(';')
          const countryCode = country[0]
          const countryName = country[1]
          options.push({ 'code': countryCode, 'name': countryName.replaceAll('"', '') })
        })
        options.sort((c1, c2) => {
          if (c1.name < c2.name) { return -1 }
          if (c1.name > c2.name) { return 1 }
          return 0
        })
        this.options = options
      })
  },
  methods: {
    resizeWindow () {
      setTimeout(() => {
        window.dispatchEvent(new Event('resize'))
      }, 2500)
      return true
    },
    updateCentroid (centroid) {
      this.flyToCentroid = centroid.geometry.coordinates
      this.$store.commit('setCenterFor', ['locationsMapCenter', this.flyToCentroid])
    },
    requestLocations () {
      this.$store.commit('setStartTimeLog', Date.now())
      this.timeStart = Date.now()
      this.errorOnFetching = false
      this.isFetchingLocations = true
      this.$store.commit('setTriggerDeckRedraw', false)
      this.$store.commit('setUserSelection', '')
      this.$store.commit('resetVisibleLayers')
      this.$store.dispatch('classified_location/resetState')
      const dateOneYearAgo = new Date(new Date() - new Date(1000 * 24 * 3600 * 365))

      const requestParams = {
        hexresolution: 'H3_CELL_INDEX_' + this.hexagonResolution,
        minnumstops: this.minNumStops
      }

      const requestParamsCountry = { ...requestParams,
        sqlfilename: 'FILTER_BY_COUNTRY',
        countrycodes: this.chosenCountries.map((countries) => { return countries['code'] }).join(',') }

      this.$store.commit('classified_location/setChosenCountries', this.chosenCountries)
      this.$store.commit('classified_location/setResolutionToRequest', this.hexagonResolution)
      this.$store.commit('classified_location/setMinNumStopsToRequest', this.minNumStops)
      this.$store.commit('classified_location/setMinNumStops', this.minNumStops)

      const requestParamsStops = process.env.VUE_APP_ENV === 'mock' || process.env.VUE_APP_ENV === 'dev'
        ? { ...requestParamsCountry, mindate: '2017-01-01' }
        : { ...requestParamsCountry, mindate: dateOneYearAgo.toISOString().substring(0, 10) }
      const results = this.getQueryResultsForClassifiedLocations('classifiedLocationsCache', requestParamsStops)

      const requestParamsGeneralInfo = {
        ...requestParams,
        sqlfilename: 'GET_GENERNAL_INFO',
        countrycodes: this.chosenCountries.map((countries) => { return countries['code'] }).join(',')
      }
      const resultsGeneralInfo = this.getQueryResultsForClassifiedLocations('classifiedLocationsCache', requestParamsGeneralInfo)

      this.currentStep++
      Promise.all([results, resultsGeneralInfo])
        .then((response) => {
          this.currentStep++
          this.currentStep++
          const dataObj = this.$store.dispatch(
            'classified_location/setLocationLayerAsync',
            response[0].data
          )
          const generalInfoDataObj = this.$store.dispatch(
            'classified_location/setGeneralInfoLayerAsync',
            response[1].data
          )
          this.$store.commit('setTriggerDeckRedraw', true)
          this.$emit('handle-on-data-response')
          this.$emit('handle-on-data-loaded')
          return Promise.all([dataObj, generalInfoDataObj])
        })
        .catch(error => {
          this.$logger.message(`{"requestLocations":"Error in requestLocations: ${error}"}`, 'error')
          this.errorOnFetching = true
          try {
            this.errorWhenFetching = error.response.data.message
          } catch {
            this.errorWhenFetching = error
          }
        })
        .finally(() => {
          this.currentStep = 0
          this.isFetchingLocations = false
          let endTime = Date.now()
          this.$logger.message(`{"requestLocations":{"message":"Request locations finished","time":"${endTime - this.timeStart}"}}`, 'info')
        })
    },
    showCountryDropdown () {
      this.selectByCountry = true
      this.selectByHexagons = false
      this.selectByPolygon = false
      this.selectByNewPolygon = false
      this.selectByExistingPolygon = false
    }
  }
}
</script>

<style scoped>
#locationSelectMap {
  z-index: 20;
  display: flex;
  height: 500px;
  position: relative;
  overflow: hidden;
}

.layer-button {
  margin-right: 5px;
}

.layer-visibility-buttons {
  position: absolute;
  z-index: 150;
  margin: 20px;
}
.fetch-location-container {
  margin-top: 10px;
  margin-bottom: 20px;
  display: flex;
  flex-direction: column-reverse;
}
#byCountry {
  height: 25vh;
  width: 100%;
}

#byActivity {
  height: 25vh;
  width: 100%;
}

</style>
<style src="../../node_modules/vue-multiselect/dist/vue-multiselect.min.css"></style>
