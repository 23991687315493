function convertSecondsToHoursMinutes (seconds) {
  if (seconds < 60) {
    return '0h 0m ' + seconds + 's'
  }
  const hr = Math.floor(seconds / 3600)
  const min = Math.floor((seconds % 3600) / 60)
  return hr + 'h ' + min + 'm'
}

function convertDecimalToPercent (decimal, nFractionDigits = 0) {
  return (decimal * 100).toFixed(nFractionDigits) + '<strong>%</strong>'
}

function toFixed (decimal, decimals = 2) {
  try {
    return decimal.toFixed(decimals)
  } catch {
    return ''
  }
}

function capitalizeFirstLetter (str) {
  if (str.length < 3) {
    return str.toUpperCase()
  }
  const lower = str.toLowerCase()
  return lower.toLowerCase().charAt(0).toUpperCase() + lower.slice(1)
}

function getArrayKeys (arr) {
  return arr
    .filter(item => item[0] !== 'None')
    .map(item => capitalizeFirstLetter(item[0].toUpperCase())).join('&nbsp;&nbsp;')
}

function getArrayValuesAsRates (arr) {
  return arr
    .filter(item => item[0] !== 'None')
    .map(item => convertDecimalToPercent(item[1])).join('&nbsp;&nbsp;')
}

function addOtherPercentage (data) {
  // Calculate the sum of percentages
  let totalPercentage = data.reduce((acc, curr) => acc + curr[1], 0)

  // Check if the total is less than 0.995
  if (totalPercentage < 0.995) {
    // Calculate the "Other" percentage
    let otherPercentage = 1 - totalPercentage
    // Append the "Other" key and its percentage to the data
    data.push(['Oth.', otherPercentage])
  }
  return data
}

async function copyToClipboard (content) {
  try {
    await navigator.clipboard.writeText(content)
    console.log('Content copied!')
  } catch (err) {
    console.error('Failed to copy text: ', err)
  }
}

function matchingChassis (hexagonObject, chassisFilter) {
  const withFilter = `
    <tr>
    <td><strong id="chassisString" title="The number of vehicles matching the chassi filter that have visited the hexagon. chassi numbers: ${hexagonObject['matched_chassis']}">No. chass. matching filter</strong></td>
    <td>
      <span>${hexagonObject['number_of_matched_chassis']}</span>
      ${hexagonObject['number_of_matched_chassis'] > 0 ? '<button class="copyMatched">Copy</button>' : ''}
    </td>
  </tr>
  <tr>`

  return chassisFilter ? withFilter : ''
}

function matchingDepotChassis (statistics, chassisFilter) {
  const withFilter = `
  <tr>
    <td><strong id="depotChassisString" title="The number of depot vehicles matching the chassi filter that have visited the hexagon. chassi numbers: ${statistics['matched_home_depot_chassis']}">No. depot chass. matching filter</strong></td>
    <td>
      <span>${statistics['number_of_matched_home_depot_chassis']}</span>
      ${statistics['number_of_matched_home_depot_chassis'] > 0 ? '<button class="copyMatchedDepot">Copy</button>' : ''}
    </td>
  </tr>`

  return chassisFilter ? withFilter : ''
}
function generateDepotHtml (depotInfo, chassisFilter) {
  let statistics = depotInfo['home_depot_statistics']
  return `<table style="width: 100%; border-collapse: collapse; border: 1px solid #ccc; margin: 10px;">
       <tbody style="text-align: start;">
       <tr>
       <td colSpan="2"><strong>Statistics for vehicle cycles at this depot:</strong></td>
       </tr>
       <tr>
       <td><strong title="Number of depot vehicles">No. depot vehicles</strong></td>
       <td>${statistics['home_depot_number_of_vehicles']}</td>
       </tr>
       ${matchingDepotChassis(statistics, chassisFilter)}
       <tr>
       <td><strong title="Number of depot customers">No. depot customers</strong></td>
       <td>${statistics['home_depot_number_of_customers']}</td>
       </tr>
       <tr>
       <td><strong title="Most common fuel type">Most common fuel type</strong></td>
       <td>${statistics['most_common_fuel_type']}</td>
       </tr>
       <tr>
       <td><strong title="The median number of locations visited during a cycle">No. of locations visited per cycle (Med.)</strong></td>
       <td>${toFixed(statistics['med_number_of_cycle_steps'], 1)}</td>
       </tr>
       <tr>
       <td><strong title="Median counts per cycle visits by location type">Counts per loc. type (Med.) [Depot&nbsp;&nbsp;Enr.&nbsp;&nbsp;Dest.&nbsp;&nbsp;Park.]</strong></td>
       <td>${toFixed(statistics['med_number_of_home_depot_stops'], 1)}&nbsp;&nbsp;${toFixed(statistics['med_number_of_enroute_stops'], 1)}&nbsp;&nbsp;${toFixed(statistics['med_number_of_destinations'], 1)}&nbsp;&nbsp;${toFixed(statistics['med_number_of_parking_stops'], 1)}</td>
       </tr>
       <tr>
       <td><strong title="The median distance travelled by a depot vehicle during a cycle/mission ">Cycle range (Med.)</strong></td>
       <td>${toFixed(Number(statistics['med_cycle_distance']) / 1000, 1)} km</td>
       </tr>
       <tr>
       <td><strong title="The median cycle duration">Cycle duration (Med.)</strong></td>
       <td>${convertSecondsToHoursMinutes(statistics['med_cycle_duration'])}</td>
       </tr>
       <tr>
       <td><strong title="The median mission duration">Mission duration (Med.)</strong></td>
       <td>${convertSecondsToHoursMinutes(statistics['med_mission_duration'])}</td>
       </tr>
       <tr>
       <td><strong title="The median fuel used per cycle">Cycle fuel (Med.) </strong></td>
       <td>${toFixed(Number(statistics['med_cycle_fuel']), 1)} L</td>
       </tr>
       <tr>
       <td><strong title="The median energy used per cycle estimated by fuel consumption">Cycle energy (Med.) estimated by fuel consumption</strong></td>
       <td>${toFixed(statistics['cycle_energy_dist'][2], 1)} kWh</td>
       </tr>
       <tr>
       <td><strong title="The median energy used per cycle estimated by cycle distance">Cycle energy (Med.) estimated by cycle range</strong></td>
       <td>${toFixed(statistics['cycle_energy_dist_based_on_distance'][2], 1)} kWh</td>
       </tr>
       <tr>
       <td><strong title="The median number of en route over night stops per cycle/mission">No. enroute overnight stops per cycle [Med.&nbsp;&nbsp;Avg.]</strong></td>
       <td>${toFixed(statistics['med_number_of_en_route_over_night_stops'], 1)}&nbsp;&nbsp;${toFixed(statistics['avg_number_of_en_route_over_night_stops'], 1)}</td>
       </tr>
       <tr>
       <td><strong title="Distribution of top most common chassis adaptation">${getArrayKeys(addOtherPercentage(statistics.top_3_home_depot_chassis_adaptation_visit_rate))}</strong></td>
       <td>${getArrayValuesAsRates(addOtherPercentage(statistics.top_3_home_depot_chassis_adaptation_visit_rate))}</td>
       </tr>
       <tr>
       <td><strong title="Distribution of top most common wheel config">${getArrayKeys(addOtherPercentage(statistics.top_3_home_depot_wheel_configuration_visit_rate))}</strong></td>
       <td>${getArrayValuesAsRates(addOtherPercentage(statistics.top_3_home_depot_wheel_configuration_visit_rate))}</td>
       </tr>
       <tr>
       <td><strong title="Distribution of top most common cab type">${getArrayKeys(addOtherPercentage(statistics.top_3_home_depot_cab_type_visit_rate))}</strong></td>
       <td>${getArrayValuesAsRates(addOtherPercentage(statistics.top_3_home_depot_cab_type_visit_rate))}</td>
       </tr>
       </tbody>
       </table>`
}

function generateLocationPopupHtml (hexagonObject, chassisFilter) {
  let tab2 = '<div class="tab-popup active" data-tab="tab1">General location info</div>'
  let description = `
     <div class="tab-popup-content active " id="tab-content-1" data-tab="tab1" >
       <div id="popUpDiv" style="overflow-x: auto; display: flex;margin: 10px;background-color: #d1d1e0;">
         <table style="width: 100%; border-collapse: collapse; border: 1px solid #ccc; margin: 10px;">
           <tbody style="text-align: start;">
           <tr>
           <td><strong title="The id of the cluster (Cluster similarity id: ${hexagonObject['cluster_similarity_cluster_id']})">Hexagon cluster id</strong></td>
           <td>${hexagonObject['cluster_id']}</td>
           </tr>
           <tr>
           <td><strong title="The id of the depot cluster">Cluster id</strong></td>
           <td>${hexagonObject['cluster_id']}</td>
           </tr>
           <tr>
           <td><strong title="The number of unique vehicles that have visited the hexagon">No. vehicles</strong></td>
           <td>${hexagonObject['unique_vehicles']}</td>
           </tr>
           ${matchingChassis(hexagonObject, chassisFilter)}
           <td><strong title="The number of stops in this hexagon">No. stops</strong></td>
           <td>${hexagonObject['stop_count']}</td>
           </tr>
           <tr>
           <td><strong title="The category this stop location is assigned to">Location category</strong></td>
           <td>${hexagonObject['location_classification']}</td>
           </tr>
           <tr>
           <td><strong title="The median duration (with a cap of 12 hours) of a stop starting in the afternoon">Afternoon duration (Med.)</strong></td>
            <td>${convertSecondsToHoursMinutes(hexagonObject['med_duration_per_day_part'][1])}</td>
           </tr>
           <tr>
           <td><strong title="The median duration (with a cap of 12 hours) of a stop starting in the evening">Evening duration (Med.)</strong></td>
           <td>${convertSecondsToHoursMinutes(hexagonObject['med_duration_per_day_part'][2])}</td>
           </tr>
           <tr>
           <td><strong title="The median duration (with a cap of 12 hours) of a stop starting in the morning">Morning duration (Med.)</strong></td>
           <td>${convertSecondsToHoursMinutes(hexagonObject['med_duration_per_day_part'][0])}</td>
           </tr>
           <tr>
           <td><strong title="The median duration (with a cap of 12 hours) of a stop starting in the night">Night duration (Med.)</strong></td>
           <td>${convertSecondsToHoursMinutes(hexagonObject['med_duration_per_day_part'][3])}</td>
           </tr>
           <tr>
           <td><strong title="Median number of long ( > 1 hour) per week and vehicle - a measure of recurring long stops">No. long stop per week/veh. (Med.)</strong></td>
           <td>${toFixed(hexagonObject['median_long_stops_per_week_and_vehicle'], 1)}</td>
           </tr>
           <tr>
           <td><strong title="The 75th percentile of the stop duration (capped at 12 hours)">Stop duration (75 percentile)</strong></td>
           <td>${convertSecondsToHoursMinutes(hexagonObject['p75_stop_duration'])}</td>
           </tr>
           <tr>
           <td><strong title="The relative frequency of visits per day part Morning(06-12) Afternoon(12-18) Evening(18-24) Night(00-06)">Visit freq [Morn.&nbsp;&nbsp;Aft.&nbsp;&nbsp;Eve.&nbsp;&nbsp;Ngt.]</strong></td>
           <td>${convertDecimalToPercent(hexagonObject['rel_freq_per_day_part'][0])}&nbsp;&nbsp;${convertDecimalToPercent(hexagonObject['rel_freq_per_day_part'][1])}&nbsp;&nbsp;${convertDecimalToPercent(hexagonObject['rel_freq_per_day_part'][2])}&nbsp;&nbsp;${convertDecimalToPercent(hexagonObject['rel_freq_per_day_part'][3])}</td>
           </tr>
           <tr>
           <td><strong title="The relative frequency of weight changes">Rel weight change [Inc.&nbsp;&nbsp;Dec.]</strong></td>
           <td>${convertDecimalToPercent(hexagonObject['rel_freq_weight_change'][0])}&nbsp;&nbsp;${convertDecimalToPercent(hexagonObject['rel_freq_weight_change'][1])}</td>
           </tr>
           <tr>
           <td><strong title="The number of unique customers divided by the number of stops - a low value means few unique customers">Rel count of unique customers</strong></td>
           <td>${convertDecimalToPercent(hexagonObject['relative_count_of_unique_customers'], 2)}</td>
           </tr>
           <tr>
           <td><strong title="The weekly number of enroute overnight stops - duration >= 8 hours and overlapping the time between 0 am and 6 am">Weekly no. enroute overnight stops [Med.&nbsp;&nbsp;Avg.]</strong></td>
           <td>${toFixed(hexagonObject['weekly_median_number_of_en_route_over_night_parkings'], 1)}&nbsp;&nbsp;${toFixed(hexagonObject['weekly_avg_number_of_en_route_over_night_parkings'], 1)}</td>
           </tr>
           <tr>
           <td><strong title="Distribution of top most common chassis adaptation">${getArrayKeys(addOtherPercentage(hexagonObject.top_3_chassis_adaptation_visit_rate))}</strong></td>
           <td>${getArrayValuesAsRates(addOtherPercentage(hexagonObject.top_3_chassis_adaptation_visit_rate))}</td>
           </tr>
           <tr>
           <td><strong title="Distribution of top most common wheel config">${getArrayKeys(addOtherPercentage(hexagonObject.top_3_wheel_configuration_visit_rate))}</strong></td>
           <td>${getArrayValuesAsRates(addOtherPercentage(hexagonObject.top_3_wheel_configuration_visit_rate))}</td>
           </tr>
           <tr>
           <td><strong title="Distribution of top most common cab type">${getArrayKeys(addOtherPercentage(hexagonObject.top_3_cab_type_visit_rate))}</strong></td>
           <td>${getArrayValuesAsRates(addOtherPercentage(hexagonObject.top_3_cab_type_visit_rate))}</td>
           </tr>
           </tbody>
         </table>
       </div>
     </div>`
  if (hexagonObject.hasOwnProperty('home_depot_statistics') && (chassisFilter.length === 0 || hexagonObject.home_depot_statistics.number_of_matched_home_depot_chassis > 0)) {
    description += `
     <div class="tab-popup-content" data-tab="tab2">
         <div id="depot-content" style="overflow-x: auto; display: flex; margin: 10px; background-color: #d1d1e0;">
             <p>Loading depot information...</p> <!-- Placeholder text -->
         </div>
     </div>`
    tab2 = tab2 + '<div class="tab-popup depot-tab" data-tab="tab2" >Depot vehicle statistics</div>'
  }
  description = '<div class="tab-popup-container"> ' + tab2 + ' </div>' + description
  return description
}

export { generateLocationPopupHtml, generateDepotHtml, copyToClipboard }
