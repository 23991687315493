<template>
  <figure class="svg-container" :style="{'height': svgHeight}">
    <svg
        class="svg-content"
        :viewBox="viewBox"
        ref="svgContainer"
        preserveAspectRatio="xMidYMid meet"
        @mouseleave="removeTooltip()"
      >
      <g :transform="`translate(${margin.left * 2}, ${margin.right})`">
        <g>
          <text class="chartTitle"
                :x="0" :dx="xaxis_dx"
                :y="0" :dy="-5">
            {{ graphTitle }}
          </text>
          <text class="yAxisLabel" :x="-margin.top" :dx="yaxis_dx"
              :y="0" :dy="yaxis_dy">
            {{ yAxisLabel }}
          </text>
          <text class="xAxisLabel"
              :x="w - margin.right" :y="h"
              :dx="xaxis_dx" :dy="xaxis_dy">
             {{ xAxisLabel}}
          </text>
        </g>
        <g>
          <circle stroke="black" r="8.5"
            style="fill: none;"
            :cx="circle_cx"
            :cy="circle_cy"
            v-if="showTooltip"
            :class="{ activeTooltip: showTooltip}"
            >
          </circle>
        </g>
        <g :transform="`translate(-${margin.left - 1.5},-${margin.top})`">
          <path
            v-for="chassi in y_values"
            :key="generateVueUID(chassi)"
            :id="chassi.name"
            :d="lineCalc(chassi.values)"
            class="line-chart__line"
            :stroke="chassi.colour"
            :transform="`translate(${margin.left},0)`"
          >
          </path>
        </g>
        <g v-for="chassi in y_values"
           :key="generateVueUID(chassi)"
           :fill="chassi.colour">
           <circle v-for="key in chassi.values"
                   :key="generateVueUID(key)"
                   :r="r"
                   stroke="white"
                   :cx="circleCXCalc(key.date)"
                   :cy="circleCYCalc(key[yKey])"
                   @click="populateTooltip($event, chassi.name, key[yKey], key.date)">
           </circle>
        </g>
        <g v-xaxis="{ scale: xScale, height: h}"
           :transform="`translate(0, ${h})`"
           :style="{'font-size':fontSize}"
           >
        </g>
        <g v-yaxis="{ scale: yScale, width: w}"
           :transform="`translate(0 0)`"
           :style="{'font-size':fontSize}"
           >
        </g>
        </g>
      <colour-legend v-if="legend"
                     :colourScale="colourScale"
                     :scaleType="scaleType"
                     :x="(w + margin.left*3)"
                     :y="20"
                     :margin="margin"
                     :alignment="'vertical'"/>
      </svg>
    <div v-show="showTooltip" class="tooltipContainer"
         :class="{ activeTooltip: showTooltip}"
         :style="{top: tooltip.y, left: tooltip.x}">
        <slot name="tooltip" :lines="tooltip.values">
            <span v-for="(value, key) in tooltip.values" :key="key">{{ key }}: {{ value }}</span>
        </slot>
    </div>
  </figure>
</template>

<script src="https://d3js.org/d3-scale-chromatic.v1.min.js"></script>
<script>
import * as d3 from 'd3'
import ColourLegend from '@/components/ColourLegend.vue'

export default {
  name: 'LineChart',
  components: { ColourLegend },
  props: {
    lineChartType: {
      type: String
    },
    getStateFunction: {
      type: String,
      required: true
    },
    getColourScale: {
      type: String,
      required: true
    },
    r: {
      type: Number,
      required: true
    },
    graphTitle: {
      type: String,
      default: 'This will be a graph title, that can be long'
    },
    fontSize: {
      default: '0.7rem',
      Type: String
    },
    svgHeight: {
      default: '90vh',
      Type: String
    },
    width: {
      type: Number,
      default: 600
    },
    height: {
      type: Number,
      default: 270
    },
    zKey: {
      default: 'key',
      Type: String
    },
    xKey: {
      default: 'date',
      Type: String
    },
    yKey: {
      default: '',
      Type: String
    },
    xAxisLabel: {
      type: String
    },
    xAxisValue: {
      default: 'chassis',
      Type: String
    },
    yAxisLabel: {
      type: String
    },
    zAxisLabel: {
      type: String
    },
    xAxisLabelShift: {
      type: Object,
      default: function () {
        return { dx: 30, dy: 85}
      }
    },
    yAxisLabelShift: {
      type: Object,
      default: function () {
        return { dx: 5, dy: -55}
      }
    },
    margin: {
      type: Object,
      default: function () {
        return { top: 20, right: 20, bottom: 30, left: 100 }
      }
    },
    legend: {
      type: Boolean,
      default: false
    },
    scaleType: {
      type: String,
      default: 'threshold',
      required: false
    }
  },

  data () {
    return {
      padding: 60,
      showTooltip: false,
      tooltip: {
          x: 0,
          y: 0,
          values: {}
      }
    }
  },
  computed: {
    flattenValues () {
      return this.$store.getters[this.getStateFunction](this.lineChartType)
    },
    w () {
      const strokeWidth = 80
      return this.width - this.margin.left - this.margin.right - (strokeWidth * 2)
    },
    h () {
      const strokeWidth = 40
      return this.height - this.margin.top - this.margin.bottom - (strokeWidth * 2)
    },
    nestedByGroup () {
      return Array.from(
          d3.group(
              this.flattenValues,
              this.xAxisValue === "time" ? (d) => d.name_of_day : (d) => d.chassisnumber
          ),
          ([key, values]) => ({ key, values })
      ).sort((a, b) => d3.ascending(a.values.date, b.values.date))

    },
    yValueKeys () {
      let result =  Object.values(this.nestedByGroup).filter(item => item !== this.xKey).sort()
      return result
    },
    y_values () {
      return this.yValueKeys.map((key, index) => ({ name: key.key, colour: this.getColourForChassi(key.key), values: key.values }))
    },
    xScale () {
      return d3.scaleTime()
        .domain(d3.extent(this.flattenValues, function (d) { return d.date }))
        .range([0, this.w])
        .nice()
    },
    yScale () {
      let key = this.yKey
      return d3.scaleLinear()
        .range([this.h, 0])
        .domain([0, d3.max(this.flattenValues, function (d) { return d[key] })])
        .nice()
    },
    lineCalc () {
      const formatX = (d) => (this.xAxisValue === "time") ? d.date : new Date(d.date)

      return d3.line()
        .defined((d) => d[1] !== null)
        .x((d) => this.xScale(formatX(d)))
        .y((d) => this.yScale(+d[this.yKey]))
    },
    viewBox () {
      return `0 0 ${this.width} ${this.height}`
    },
    xaxis_dx() {
      return 'dx' in this.xAxisLabelShift ? this.xAxisLabelShift.dx : undefined
    },
    xaxis_dy() {
      return 'dy' in this.xAxisLabelShift ? this.xAxisLabelShift.dy : undefined
    },
    yaxis_dx() {
      return 'dx' in this.yAxisLabelShift ? this.yAxisLabelShift.dx : undefined
    },
    yaxis_dy() {
      return 'dy' in this.yAxisLabelShift ? this.yAxisLabelShift.dy : undefined
    },
    xBisector() {
      return d3.bisector(d => new Date(d[this.xKey])).left
    },
    colourScale () {
      return this.$store.getters[this.getColourScale]
    }
  },
  methods: {
    groupKeyByDay (d) {
      return d.name_of_day
    },
    groupKeyByChassis (d) {
      return d.chassisnumber
    },
    getColourForChassi (key) {
      return this.$store.getters[this.getColourScale](key)
    },
    generateVueUID (chassisnumber) {
      return Math.random(Number.MAX_SAFE_INTEGER)
    },
    circleCXCalc (x) {
      return this.xScale(x)
    },
    circleCYCalc (y) {
      return this.yScale(y)
    },
    populateTooltip(evt, chassisnumber, y_key, x_key) {
      this.tooltip.x = `${evt.pageX + 5}px`
      this.tooltip.y = `${evt.pageY + 5}px`

      this.tooltip.values = {}
      this.tooltip.values[this.zAxisLabel] = chassisnumber
      this.tooltip.values[this.yKey] = y_key
      this.tooltip.values[this.xKey] = x_key
      this.circle_cx = this.xScale(x_key)
      this.circle_cy = this.yScale(y_key)

      this.showTooltip = true
      this.$logger.message(`{"populateTooltip":"Clicked data in ${this.lineChartType} ${this.yKey}"}`, 'info')
    },
    removeTooltip() {
      this.showTooltip = false
    },
  },
  directives: {
    xaxis (el, binding, vnode) {
      const scale = binding.value.scale
      const format = vnode.context._props.lineChartType === 'dailyHourlyAggregates' ? d3.format("02d") : d3.timeFormat("%b %d -%y")
      d3.select(el).transition().duration(500)
        .call(d3.axisBottom().scale(scale)
        .tickFormat(format))
        .selectAll('text')
        .attr('transform', 'rotate(90)')
        .attr('y', 0)
        .attr('x', 9)
        .attr('dy', '.35em')
        .style('text-anchor', 'start')
    },
    yaxis (el, binding) {
      const scale = binding.value.scale
      const yFormatter = (num, digits) => {
        const lookup = [
          { value: 0, symbol: '' },
          { value: 1e3, symbol: 'k' },
          { value: 1e6, symbol: 'M' },
          { value: 1e9, symbol: 'G' },
          { value: 1e12, symbol: 'T' },
          { value: 1e15, symbol: 'P' },
          { value: 1e18, symbol: 'E' }
        ]
        const rx = /\.0+$|(\.[0-9]*[1-9])0+$/
        let item = lookup.slice().reverse().find(function(item) {
          return num >= item.value
        })
        return item ? (num ).toFixed(digits).replace(rx, '$1') + item.symbol : '0'
      }
      d3.select(el).transition().duration(500)
        .call(d3.axisLeft().scale(scale).tickFormat((interval, i) => {
          return yFormatter(interval, 1)
        }))
    }
  }
}
</script>
<style scoped>
.line-chart__line {
  fill: none;
  stroke-width: 1.5;
}
.yAxisLabel {
  transform: rotate(-90deg);
  text-anchor: end;
  font-size: 0.9rem;
  fill: #2c3e50;
}
.xAxisLabel {
  text-anchor: end;
  font-size: 0.9rem;
  fill: #2c3e50;
}

.tooltipContainer {
  position: fixed;
  font-size: 0.8rem;
  padding: 10px;
  border: solid 1px black;
  box-shadow: 4px 4px 10px rgba(0, 0, 0, 0.4);
  background-color: #ffffff;
  opacity: 0;
  pointer-events: none;
  transition: opacity 0.3s;
}
.activeTooltip {
  opacity: 0.95;
  transition: opacity 0.3s;
  z-index: 3;
}
.tooltipContainer span {
  display: block;
}
.svg-container {
  display: inline-block;
  position: relative;
  width: 100%;
  height: 90vh;
  vertical-align: top;
  overflow: hidden;
  margin-top: 30px;
}
.svg-content {
  display: inline-block;
  position: absolute;
  top: 0;
  left: 0;
}
</style>
