<template>
  <b-tab>
    <template #title>
      <v-icon class="tabButton" id="outbound-selection" name="rocket"/>
    </template>
    <b-card>
      <b-button pill variant="success" size="sm" style="font-size: small; height: 30px;" @click="requestLocations">
        <b-spinner v-if="isFetchingDataOnRequest"
                   small type="grow"></b-spinner>
        Outbound Flow Analytics
      </b-button>
    </b-card>
  </b-tab>
</template>

<script>

import snowflake from '@/mixins/snowflake'

export default {
  name: 'OutboundSelection',
  mixins: [snowflake],
  data () {
    return {
      isFetchingDataOnRequest: false,
      hexagonResolution: 12
    }
  },
  methods: {
    requestLocations () {
      this.timeStart = Date.now()
      this.errorOnFetching = false
      this.isFetchingDataOnRequest = true
      this.$store.commit('setTriggerDeckRedraw', false)
      this.$store.dispatch('outbound/resetState')

      const outboundCache = this.getQueryIdForOutbound('outboundCache')
      const delayInMS = process.env.VUE_APP_ENV === 'mock' ? 1 : 40000
      const delayStatusQuery = new Promise(resolve => { setTimeout(resolve, delayInMS) })

      Promise.all([outboundCache, delayStatusQuery])
        .then(() => {
          const outboundQueryState = this.getStatusForOutboundQuery(this.$store.getters.getQueryIdFor('outboundCache'))
          Promise.all([outboundQueryState])
        })
        .then(() => {
          const outboundData = this.getOutboundDataForQueryId(this.$store.getters.getQueryIdFor('outboundCache'))
          return Promise.all([outboundData])
        })
        .then((response) => {
          const outboundEdges = this.$store.dispatch('outbound/setEdgeLayerAsync', response[0].data)
          return Promise.all([outboundEdges])
        })
        .then(() => {
          this.$emit('handle-on-data-response')
          this.$emit('handle-on-data-loaded')
        })
        .catch(error => {
          this.$logger.message(`{"requestLocations":"Error in requestOutbound: ${error}"}`, 'error')
          this.errorOnFetching = true
          try {
            this.errorWhenFetching = error.response.data.message
          } catch {
            this.errorWhenFetching = error
          }
        })
        .finally(() => {
          this.isFetchingDataOnRequest = false
          let endTime = Date.now()
          this.$logger.message(`{"requestLocations":{"message":"Request outbound finished","time":"${endTime - this.timeStart}"}}`, 'info')
        })
    }
  }
}

</script>
