<template>
  <transition-group name="modal-fade">
    <div class="modal-backdrop" v-show="isVisible" @click="closeModal" key="upload-backdrop">
    </div>
    <div class="upload-modal" v-show="isVisible" key="upload-modal">
      <section class="modal-mid">
        <slot name="body">
          <div>
            <button id="closeModal" @click="closeModal">
              <v-icon id="closeModalIcon" name="times-circle"/>
            </button>
          </div>
          <upload-data />
        </slot>
      </section>
    </div>
  </transition-group>
</template>

<script>
import UploadData from './UploadExcel.vue'

export default {
  components: { UploadData },
  props: {
    isVisible: {
      type: Boolean,
      required: true,
      default: false
    }
  },
  methods: {
    closeModal () {
      this.$emit('close-modal')
    }
  }
}
</script>

<style scoped >
.modal-backdrop {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.7);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 10;
}

.upload-modal {
  background: #FFFFFF;
  box-shadow: 2px 2px 20px 1px;
  display: inline-flex;
  flex-direction: row;
  width: 40vw;
  top: 35vh;
  left: 30vw;
  position: fixed;
  z-index: 11;
  overflow-x: hidden;
}
.modal-mid {
  position: relative;
  padding: 20px 10px;
  width: 100%
}

.modal-fade-enter,
.modal-fade-leave-to {
  opacity: 0;
}

.modal-fade-leave-active {
  transition: opacity 0.5s ease;
}
#closeModal {
  margin-bottom:10px;
}
</style>
