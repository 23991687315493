<template>
  <b-tab>
    <template #title>
      <v-icon class="tabButton" id="survey-responses" name="magic"/>
    </template>
    <b-card>
      <b-button pill variant="success" size="sm" style="font-size: small; height: 30px;" @click="exportJson">
        <b-spinner v-if="isFetchingDataOnRequest"
                   small type="grow"></b-spinner>
        Export users' responses
      </b-button>
    </b-card>
  </b-tab>
</template>

<script>
import axios from 'axios'
export default {
  name: 'SurveyResponses',
  data () {
    return {
      isFetchingDataOnRequest: false
    }
  },
  methods: {
    exportJson () {
      this.isFetchingDataOnRequest = true
      const lambdaPath = '/Prod/FetchFeedback'
      let endpoint = process.env.VUE_APP_UX_FEEDBACK_ENDPOINT

      const url = endpoint + lambdaPath
      const request = axios.create()

      request
        .get(url, {
          headers: {
            Authorization: this.$store.getters.getBearerToken
          },
          responseType: 'arraybuffer'
        }).then(response => {
          let title = `sweete_survey_responses_${new Date().toISOString().slice(0, 10)}.json`
          const url = window.URL.createObjectURL(new Blob([response.data]))
          const link = document.createElement('a')
          link.href = url
          link.setAttribute('download', title)
          document.body.appendChild(link)
          link.click()
          this.isFetchingDataOnRequest = false
        })
    }
  }
}

</script>
