import { ChoiceOption, QuestionModel, QuestionType } from '@ditdot-dev/vue-flow-form'

export default {
  QUESTIONS: [
    new QuestionModel({
      id: 'task_desc',
      tagline: 'Hi! To improve SweetE we need your help 😊.',
      title: 'Tell us - what are you trying to do?',
      type: QuestionType.LongText,
      helpTextShow: false,
      required: true,
      placeholder: 'My aim was to...'
    }),
    new QuestionModel({
      id: 'succeed',
      title: 'Did you succeed with your task?',
      helpTextShow: false,
      type: QuestionType.MultipleChoice,
      required: true,
      multiple: false,
      nextStepOnAnswer: true,
      options: [
        new ChoiceOption({
          label: 'Yes',
          value: 'yes'
        }),
        new ChoiceOption({
          label: 'No',
          value: 'no'
        })
      ],
      jump: {
        no: 'task_failed',
        yes: 'feature_suggestions'
      }
    }),
    new QuestionModel({
      id: 'task_failed',
      tagline: 'Sorry to hear that 😳',
      title: 'What did you get stuck in?',
      type: QuestionType.LongText,
      helpTextShow: false,
      required: true,
      placeholder: 'I got stuck in...',
      jump: {
        feature_suggestions: 'feature_suggestions'
      }
    }),
    new QuestionModel({
      id: 'feature_suggestions',
      title: 'Share your great ideas 💡',
      content: 'Is there something you’re missing in SweetE?',
      type: QuestionType.LongText,
      helpTextShow: false,
      required: false,
      placeholder: 'I would like to be able to...',
      jump: {
        _other: '_submit'
      }
    })
  ]
}
