import browserLanguage from 'in-browser-language'

/* List of supported languages in the OpenInfraMap layer.
 * This should be kept up to date with the list in tegola/layers.yml.
 */
const layerSupportedLanguages = ['en', 'es', 'de', 'fr', 'hi', 'ur', 'zh', 'ru', 'pt', 'ja']

/* List of name tags to check, in order */
const localNameTags = browserLanguage
  .list()
  .filter(code => layerSupportedLanguages.includes(code))
  .map(code => `name_${code}`)
  .concat(['name'])

export { layerSupportedLanguages, localNameTags }
